import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';

const XAlertModal = ({ title, children, onClose }) => {
    const dir = useAppSelector((x) => x.account.direction);
    const { t } = useTranslation();

    return (
        <Dialog dir={dir} open={true} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions sx={{ m: 1 }}>
                <Button size="small" variant="outlined" onClick={onClose}>
                    {t('common.modals.alert_close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default XAlertModal;
