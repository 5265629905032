import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import UserItem from './UserItem';
import Logo from 'src/components/LogoSign';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import AppsIcon from '@mui/icons-material/Apps';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import { signOut } from 'src/screens/account/slice';

export default function AccountMenu() {
    const navigateTo = useNavigate();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { user, direction } = useAppSelector((x) => x.account);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (to: string) => {
        setAnchorEl(null);
        if (to) navigateTo(to);
    };
    return (
        <div dir={direction}>
            <IconButton
                sx={{ mr: 1, ml: 1, mt: 2, mb: 3 }}
                onClick={handleClick}
                size="small"
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
            >
                <Avatar sx={{ bgcolor: 'rgb(230, 26, 128)' }}>
                    {(user.firstName[0] + user.lastName[0]).toUpperCase()}
                </Avatar>
            </IconButton>
            <Menu
                dir={direction}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: direction == 'ltr' ? 'right' : 'left'
                }}
                open={open}
                onClose={() => handleClose('')}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        ml: direction == 'ltr' ? 5 : 0,
                        mr: direction == 'ltr' ? 0 : 50,
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 30,
                            left: direction == 'ltr' ? -5 : 0,
                            right: direction == 'rtl' ? -5 : 0,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
            >
                <Logo />
                <Divider sx={{ opacity: 0 }} />
                <UserItem />
                <Divider sx={{ mb: 1, mt: 1 }} />
                <MenuItem onClick={() => handleClose('/teams')}>
                    <ListItemIcon>
                        <GroupsIcon fontSize="small" />
                    </ListItemIcon>
                    <Box sx={{ color: '#000' }}>{t('layout.sidebar.business_management.teams')}</Box>
                </MenuItem>
                <MenuItem onClick={() => handleClose(`/businesses`)}>
                    <ListItemIcon>
                        <BusinessIcon fontSize="small" />
                    </ListItemIcon>
                    <Box sx={{ color: '#000' }}>{t('layout.sidebar.administration.businesses')}</Box>
                </MenuItem>
                <MenuItem onClick={() => handleClose('/users')}>
                    <ListItemIcon>
                        <PeopleAltIcon fontSize="small" />
                    </ListItemIcon>
                    <Box sx={{ color: '#000' }}> {t('layout.sidebar.business_management.users')}</Box>
                </MenuItem>

                <Divider sx={{ mb: 2, mt: 1 }} />
                <MenuItem onClick={() => handleClose('/apps')}>
                    <ListItemIcon>
                        <AppsIcon fontSize="small" />
                    </ListItemIcon>
                    <Box sx={{ color: '#000' }}> {t('layout.sidebar.business_management.apps')}</Box>
                </MenuItem>
                <Divider sx={{ mb: 2, mt: 1 }} />

                <MenuItem onClick={() => handleClose('/account/profile')}>
                    <ListItemIcon>
                        <PersonIcon fontSize="small" />
                    </ListItemIcon>
                    <Box sx={{ color: '#000' }}> {t('layout.header.user_dropdown.profile')}</Box>
                </MenuItem>
                <MenuItem onClick={() => dispatch(signOut())}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <Box sx={{ color: '#000' }}> {t('layout.header.user_dropdown.sign_out')}</Box>
                </MenuItem>
            </Menu>
        </div>
    );
}
