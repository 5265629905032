import { Box, Typography, styled } from '@mui/material';

const ButtonFragment = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 4,
    borderRadius: 4,
    textAlign: 'center',
    overflowWrap: 'break-word',
    fontSize: 12
}));

const MediaFragment = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    // ...theme.typography.body2,
    // padding: 4,
    // borderRadius: 4,
    // color: theme.palette.text.secondary,
    // overflowWrap: 'break-word',
    // fontSize: 12
}));

const SimpleFragment = styled('div')(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    borderRadius: 4,
    fontSize: 12,
    color: theme.palette.text.secondary,
    overflowWrap: 'break-word'
}));

const TextPreview = styled(Typography)(({ theme }) => ({
    overflowWrap: 'break-word',
    marginBottom: '5px',
    fontSize: 12
}));

const PreviewWrapper = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 8,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    overflowWrap: 'break-word',
    fontSize: 12
}));

const ButtonPreview = styled(Box)(({ theme }) => ({
    // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 4,
    borderRadius: 4,
    textAlign: 'center',
    color: theme.colors.primary.main,
    width: '100%',
    height: '98%',
    overflowWrap: 'break-word',
    marginTop: '2px',
    fontSize: 12
}));

const CallToActionButtonPreview = ({ icon, text }: { icon: React.ReactNode; text: string }) => {
    return (
        <Box
            sx={{
                background: 'transparent',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
            }}
        >
            {icon}
            <Typography
                color="primary"
                variant="body1"
                sx={{
                    fontSize: 12
                }}
            >
                {text}
            </Typography>
        </Box>
    );
};

export {
    SimpleFragment,
    MediaFragment,
    ButtonFragment,
    PreviewWrapper,
    TextPreview,
    ButtonPreview,
    CallToActionButtonPreview
};
