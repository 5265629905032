import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { UsersService } from 'src/services/users.service';
import { RootState } from 'src/store';
import { User } from 'src/types';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import { SubmitUserInputs } from './submit/schema';
import i18next from 'i18next';

const service = new UsersService();

export interface SliceState {
    list: {
        state: ActionState;
        tableData: TableData<User>;
    };
    submit: {
        state: ActionState;
    };
}

const initialState: SliceState = {
    list: {
        state: undefined,
        tableData: DefaultTableData()
    },
    submit: {
        state: undefined
    }
};

export const getUsers = createAsyncThunk('GET_USERS', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.users.list.tableData;
    const response = await service.getUsers(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addUser = createAsyncThunk('ADD_USER', async (inputs: SubmitUserInputs, thunkAPI) => {
    const response = await service.addUser(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const deleteUser = createAsyncThunk('DELETE_USER', async (userId: string, thunkAPI) => {
    const response = await service.deleteUser(userId);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateUser = createAsyncThunk('UPDATE_USER', async ({ id, inputs }: { id: string; inputs: SubmitUserInputs }, thunkAPI) => {
    const response = await service.updateUser(id, inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

const slice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.list.tableData.page = action.payload.page;
            state.list.tableData.limit = action.payload.limit;
        }
    },
    extraReducers(builder) {
        builder.addCase(getUsers.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.list.tableData = action.payload;
            state.list.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getUsers.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('user.slice.errors.fetch_users'));
        });

        builder.addCase(addUser.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(addUser.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('user.slice.success.user_added'));
            state.submit.state = 'successful';
        });
        builder.addCase(addUser.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('user.slice.errors.add_user'));
        });

        builder.addCase(deleteUser.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(deleteUser.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('user.slice.success.user_deleted'));
            state.submit.state = 'successful';
        });
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('user.slice.errors.delete_user'));
        });

        builder.addCase(updateUser.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(updateUser.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('user.slice.success.user_updated'));
            state.submit.state = 'successful';
        });
        builder.addCase(updateUser.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('user.slice.errors.update_user'));
        });
    }
});

export const { setPaging } = slice.actions;

export default slice.reducer;
