import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Input } from './schema';

export default function Email() {
    const {
        register,
        formState: { errors }
    } = useFormContext<Input>();

    return (
        <TextField
            fullWidth
            error={errors?.email && true}
            {...register('email')}
            id="email"
            placeholder="Email"
            size="medium"
            helperText={errors?.email?.message}
        />
    );
}
