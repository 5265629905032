import { Typography, Box, Autocomplete, TextField, FormHelperText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Template, getTemplateVariables } from 'src/types';
import { useFormContext } from 'react-hook-form';
import { SubmitSallaTemplateInputs } from './schema';
import { useEffect } from 'react';
import { t } from 'i18next';

const TemplatesDropdown = ({ templates }: { templates: Template[] }) => {
    const { t } = useTranslation();
    const {
        setValue,
        watch,
        formState: { errors }
    } = useFormContext<SubmitSallaTemplateInputs>();

    const onTemplateSelected = (templateId: string) => {
        setValue('metaTemplateId', templateId, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        });
    };

    const templateId = watch('metaTemplateId');
    const template = templates.find((x) => x.id == templateId);

    useEffect(() => {
        if (template) {
            const variables = getTemplateVariables(template);
            setValue('placeholders', [...variables.map((x) => ({ ...x, sallaEventCategoryFieldId: '' }))], {
                shouldTouch: true
            });
        }
    }, [template]);

    return (
        <Box sx={{ mt: 2 }}>
            <Typography sx={{ my: 1, fontWeight: 'bold' }} variant="subtitle2">
                {t('salla.event_setup.template.heading')}
            </Typography>
            <Autocomplete
                defaultValue={template}
                disablePortal
                sx={{ mt: 2, width: { xs: '100%', md: '50%' } }}
                onChange={(_, value) => onTemplateSelected((value as { id: string }).id)}
                options={templates}
                value={templateId ?? ''}
                disableClearable
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        var val = templates.find((x) => x.id == option);
                        return val?.name ? `${val.name} (${val.language})` : '';
                    }
                    return option?.name ? `${option.name} (${option.language})` : '';
                }}
                renderInput={(params) => <TextField {...params} label={t('salla.event_setup.template.select')} />}
                renderOption={(props, option) =>
                    option.id ? (
                        <Box key={`${option.id}-${option.language}`} id={option.id} component="li" {...props}>
                            <Typography>{option.name}</Typography>
                            <Typography sx={{ ml: 1, mr: 1 }} fontSize={'smaller'} color={'primary'}>
                                ({option.language})
                            </Typography>
                        </Box>
                    ) : (
                        <span key={`${option.id}-${option.language}`}></span>
                    )
                }
            />
            {errors.metaTemplateId && (
                <FormHelperText style={{ color: 'red' }}>{errors.metaTemplateId?.message}</FormHelperText>
            )}
        </Box>
    );
};

export default TemplatesDropdown;
