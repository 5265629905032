import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { QuickReplyInputs } from './schema';
import { XToolbar } from 'src/components';
import { useRef } from 'react';

const Message = () => {
    const { t } = useTranslation();
    const {
        register,
        setValue,
        formState: { errors }
    } = useFormContext<QuickReplyInputs>();

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <TextField
                inputRef={inputRef}
                sx={{ pb: 1 }}
                fullWidth
                error={errors?.message && true}
                {...register(nameOf<QuickReplyInputs>('message'))}
                id={nameOf<QuickReplyInputs>('message')}
                placeholder={t('conversations.quickReplies.list.head.message')}
                label={t('conversations.quickReplies.list.head.message')}
                size="medium"
                type="text"
                multiline
                rows={3}
                helperText={errors?.message?.message}
            />

            <XToolbar
                emoji={{
                    placement: 'bottom',
                    onEmojiSelect: (newMsg: string) => {
                        setValue(nameOf<QuickReplyInputs>('message'), newMsg, {
                            shouldDirty: true
                        });
                    }
                }}
                placeHolder={{
                    onPlaceHolderSelect: (newMsg: string) => {
                        setValue(nameOf<QuickReplyInputs>('message'), newMsg, {
                            shouldDirty: true
                        });
                    }
                }}
                inputRef={inputRef}
            />
        </>
    );
};

export default Message;
