import { TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { WelcomeMessageInputs } from './schema';

const TitleArabic = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<WelcomeMessageInputs>();

    return (
        <>
            <Typography
                variant="caption"
                sx={{
                    pt: 1,
                    pl: 1,
                    display: 'flex',
                    justifyContent: 'start',
                    color: 'rgb(230, 26, 128)'
                }}
            >
                {t('components.languageSwitch.arabic')}
            </Typography>
            <TextField
                error={errors?.titleArabic && true}
                {...register(nameOf<WelcomeMessageInputs>('titleArabic'))}
                id={nameOf<WelcomeMessageInputs>('titleArabic')}
                placeholder={t('bot.settings.welcomeMessage.titleArabic')}
                size="small"
                type="text"
                helperText={errors?.titleArabic?.message}
                sx={{
                    p: 1
                }}
                rows={2}
            ></TextField>
        </>
    );
};

export default TitleArabic;
