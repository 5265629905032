import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useAppSelector } from 'src/store/hooks';
import { SubmitTemplateInputs, ButtonGroupType, schema } from './schema';
import { useEffect, useState } from 'react';
import { DefaultCountryCode, Template, TemplateCategory, getMediaType } from 'src/types';
import _ from 'lodash';

const getDefaultValues = (template: Template) => {
    let defaultValues: SubmitTemplateInputs = {
        id: '',
        templateType: '' as TemplateCategory,
        name: '',
        languageCode: '',
        header: {
            type: 'NONE',
            text: '',
            textSample: '',
            mediaType: getMediaType(template?.components.header?.format),
            mediaSample: ''
        },
        body: {
            text: '',
            variables: []
        },
        footer: '',
        buttons: {
            buttonGroupType: 'NONE',
            callToactions: [],
            quickReplies: []
        }
    };

    if (template) {
        const header = template.components.header;
        const body = template.components.body;
        const buttons = template.components.buttons ?? [];
        let buttonGroupType: ButtonGroupType = 'NONE';

        defaultValues.id = template.id;
        defaultValues.name = template.name;
        defaultValues.languageCode = template.language;
        defaultValues.templateType = template.category;

        if (buttons.find((x) => x.type == 'PHONE_NUMBER' || x.type == 'URL')) {
            buttonGroupType = 'CALL_TO_ACTION_GROUP';
        } else if (buttons.find((x) => x.type == 'QUICK_REPLY')) {
            buttonGroupType = 'QUICK_REPLY_GROUP';
        }

        if (header?.text) {
            defaultValues.header.text = header.text;
            defaultValues.header.type = 'TEXT';
        }

        if (header?.fileUrl) {
            defaultValues.header.mediaSample = header.fileUrl;
            defaultValues.header.type = 'MEDIA';
        }

        if (body) {
            defaultValues.body.text = body.text;
        }

        if (buttons) {
            defaultValues.buttons = {
                buttonGroupType: buttonGroupType,
                callToactions: buttons
                    .filter((x) => x.type == 'PHONE_NUMBER' || x.type == 'URL')
                    .map((x) => ({
                        buttonText: x.text,
                        countryCode: DefaultCountryCode,
                        phoneNumber: x.phoneNumber.replace('+966', ''),
                        type: x.phoneNumber ? 'CALL_PHONE_NUMBER' : 'VISIT_WEBSITE',
                        urlType: x.example ? 'DYNAMIC' : 'STATIC',
                        website: x.url,
                        websiteSample: x.example
                    })),
                quickReplies: buttons
                    .filter((x) => x.type == 'QUICK_REPLY')
                    .map((x) => ({
                        buttonText: x.text,
                        type: 'CUSTOM',
                        footer: ''
                    }))
            };
        }

        defaultValues.footer = template.components.footer;
    }

    return defaultValues;
};

const useSubmitForm = (setShowPreview: any) => {
    const { id, sampleTemplateId } = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const samples = useAppSelector((x) => x.templates.samples.items);

    const isSampleActive = pathname.split('/').includes('samples');

    let template: Template;
    if (isSampleActive && samples.length > 0) {
        const sampleTemplate = samples.find((x) => x.id == id);
        template = sampleTemplate?.template;
    } else if (sampleTemplateId && samples.length > 0) {
        const sampleTemplate = samples.find((x) => x.id == sampleTemplateId);
        template = {
            ...sampleTemplate?.template,
            name: ''
        };
    } else {
        template = useAppSelector((x) => x.templates.list.items.find((x) => x.id == id));
    }

    const [inputs, setInputs] = useState<SubmitTemplateInputs>();

    useEffect(() => {
        if (isSampleActive && id && !template) {
            navigate('/templates/samples');
        } else if ((id || sampleTemplateId) && !template) {
            navigate('/templates');
        }
    }, [id, sampleTemplateId, template]);

    const methods = useForm<SubmitTemplateInputs>({
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(template)
    });

    const onSubmit = (value: SubmitTemplateInputs) => {
        setInputs(value);
        setShowPreview(true);
    };

    return { id, inputs, template, methods, onSubmit };
};

export default useSubmitForm;
