import { UpdateProfileInputs } from 'src/screens/account/profile/schema';
import { ApiResponse, Tokens, User, UserLocale } from 'src/types';
import api from './apiClient';
import LocalTokenService from './localToken.service';
import { UpdatePasswordInputs } from 'src/screens/account/password/schema';
import { SetPasswordInputs } from 'src/screens/account/setPassword/schema';

export class AuthService {
    async signIn(username: string, password: string): Promise<ApiResponse<User>> {
        const payload = {
            username,
            password
        };
        var response = await api.post<ApiResponse<Tokens>>('/auth/signin', payload);
        if (response.data.status === 'Failed')
            return {
                status: response.data.status,
                message: response.data.message,
                data: {
                    email: '',
                    firstName: '',
                    id: '',
                    lastName: '',
                    businessId: '',
                    businessName: '',
                    locale: 'en',
                    role: 'Staff',
                    teams: [],
                    exp: 0,
                    refreshTokenLifeTime: 0
                }
            };
        LocalTokenService.setTokens(response.data.data);

        const user = LocalTokenService.parseAccessToken(response.data.data.accessToken);
        return {
            status: response.data.status,
            message: response.data.message,
            data: user
        };
    }

    async signOut() {
        LocalTokenService.deleteTokens();
    }

    async register(username: string, email: string, password: string) {
        await api.post('/auth/signup', {
            username,
            email,
            password
        });
    }

    async setPassword(inputs: SetPasswordInputs) {
        if (inputs.reset) {
            const { data } = await api.post('/auth/reset-password', inputs);
            return data;
        } else {
            const { data } = await api.post('/auth/set-password', inputs);
            return data;
        }
    }

    async refreshToken() {
        const apiResponse = await api.post<ApiResponse<Tokens>>('/auth/refresh-token', {
            refreshToken: LocalTokenService.getTokens().refreshToken
        });
        if (apiResponse.data.status === 'Successful') {
            LocalTokenService.setTokens(apiResponse.data.data);
        } else {
            // LocalTokenService.deleteTokens();
            window.location.href = '/sign-in';
        }
    }

    getCurrentUser(): User {
        const token = LocalTokenService.getTokens()?.accessToken;
        if (token) {
            var data = LocalTokenService.parseAccessToken(token);
            return {
                email: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                id: data.id,
                businessId: data.businessId,
                businessName: data.businessName,
                locale: data.locale,
                role: data.role,
                teams: data.teams,
                exp: data.exp,
                refreshTokenLifeTime: data.refreshTokenLifeTime
            };
        }
    }

    async updateProfile(inputs: UpdateProfileInputs): Promise<ApiResponse<string>> {
        var { data } = await api.put<ApiResponse<string>>('/users/profile', inputs);
        if (data.status == 'Successful') {
            await this.refreshToken();
        }
        return data;
    }

    async updatePassword(inputs: UpdatePasswordInputs): Promise<ApiResponse<string>> {
        var { data } = await api.put<ApiResponse<string>>('/auth/password', {
            oldPassword: inputs.currentPassword,
            newPassword: inputs.newPassword
        });
        if (data.status == 'Successful') {
            await this.refreshToken();
        }
        return data;
    }

    async forgotPassword(email: string): Promise<ApiResponse<string>> {
        const res = await api.post<ApiResponse<string>>('/auth/forgot-password', { email });
        return res.data;
    }
}

export default new AuthService();
