import { Box, Grid, Typography } from '@mui/material';
import { InteractiveButtons } from 'src/types';
import { ButtonFragment, MediaFragment, SimpleFragment } from './Fragments';
import Thumbnail from './Thumbnail';

const InteractiveButtonMessagePreview = ({ message }: { message: InteractiveButtons }) => {
    return (
        <>
            <Grid container spacing={0.2}>
                {message.media && (
                    <Grid item xs={12}>
                        <MediaFragment>
                            <Thumbnail content={message.media.link} type={message.media.type}></Thumbnail>
                            {message.text && (
                                <Typography
                                    sx={{
                                        fontSize: 12
                                    }}
                                >
                                    {message.text}
                                </Typography>
                            )}
                        </MediaFragment>
                    </Grid>
                )}

                {!message.media && message.text && (
                    <Grid item xs={12}>
                        <SimpleFragment>{message.text}</SimpleFragment>
                    </Grid>
                )}

                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    {message.replyButtons.map((x) => (
                        <Grid
                            key={x.id}
                            item
                            xs={12}
                            sx={{
                                background: '#fff',
                                borderRadius: '3px',
                                margin: '5px',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                transition: 'box-shadow 0.3s ease',
                                cursor: 'pointer'
                            }}
                        >
                            <ButtonFragment>{x.title}</ButtonFragment>
                        </Grid>
                    ))}
                </Box>
            </Grid>
        </>
    );
};

export default InteractiveButtonMessagePreview;
