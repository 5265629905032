import { useFormContext } from 'react-hook-form';
import { CallToActionType, SubmitTemplateInputs } from './../../schema';
import CallToActionTypes from './CallToActionTypes';
import { Box, Button, Grid, IconButton, Paper } from '@mui/material';
import ButtonText from './ButtonText';
import UrlTypes from './UrlTypes';
import WebsiteUrl from './WebsiteUrl';
import CountryCode from './CountryCode';
import PhoneNumber from './PhoneNumber';
import WebsiteSample from './WebsiteSample';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { DefaultCountryCode } from 'src/types';
import { useStyles } from 'src/components';

const CallToAction = ({ inputColor }: { inputColor: string }) => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext<SubmitTemplateInputs>();
    const callToactions = watch('buttons.callToactions');
    const { borderRounded } = useStyles();

    const onAddButtonClick = () => {
        const type = callToactions[0].type == 'CALL_PHONE_NUMBER' ? 'VISIT_WEBSITE' : 'CALL_PHONE_NUMBER';

        callToactions.push({
            buttonText: '',
            type: type,
            websiteSample: '',
            urlType: 'STATIC',
            website: '',
            countryCode: DefaultCountryCode,
            phoneNumber: ''
        });

        setValue(`buttons.callToactions`, callToactions, {
            shouldDirty: true
        });
    };

    const onRemoveCallToActionClick = (type: CallToActionType) => {
        const newCallToactions = callToactions.filter((x) => x.type != type);
        setValue(`buttons.callToactions`, newCallToactions, {
            shouldDirty: true
        });
    };

    return (
        <>
            {callToactions &&
                callToactions.map((x, index) => (
                    <Box key={x.type} sx={{ ...borderRounded, pt: 2, pl: 1, pr: 1, pb: 1, mt: 1 }}>
                        <Grid container>
                            <Grid item xs={3} sx={{}}>
                                <CallToActionTypes index={index} inputColor={inputColor} />
                            </Grid>
                            <Grid item xs={3} sx={{ pl: 1 }}>
                                <ButtonText index={index} inputColor={inputColor} />
                            </Grid>
                            <Grid item xs={2} sx={{ pl: 1 }}>
                                {callToactions[index].type == 'VISIT_WEBSITE' && (
                                    <UrlTypes index={index} inputColor={inputColor} />
                                )}
                                {callToactions[index].type == 'CALL_PHONE_NUMBER' && (
                                    <CountryCode index={index} inputColor={inputColor} />
                                )}
                            </Grid>
                            <Grid item xs={3} sx={{ pl: 1 }}>
                                {callToactions[index].type == 'VISIT_WEBSITE' && (
                                    <WebsiteUrl index={index} inputColor={inputColor} />
                                )}
                                {callToactions[index].type == 'CALL_PHONE_NUMBER' && (
                                    <PhoneNumber index={index} inputColor={inputColor} />
                                )}
                            </Grid>
                            <Grid item xs={1} sx={{ pl: 1 }} style={{ display: 'flex', alignItems: 'end' }}>
                                <IconButton
                                    disabled={callToactions?.length == 1}
                                    onClick={() => onRemoveCallToActionClick(x.type)}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {callToactions[index]?.urlType == 'DYNAMIC' && (
                                <WebsiteSample
                                    url={callToactions[index]?.website}
                                    index={index}
                                    inputColor={inputColor}
                                />
                            )}
                        </Grid>
                    </Box>
                ))}
            <Button size="small" sx={{ mt: 1 }} disabled={callToactions?.length == 2} onClick={onAddButtonClick}>
                {t('templates.submit.buttons.add')}
            </Button>
        </>
    );
};

export default CallToAction;
