import { Button } from '@mui/material';

const ActionButton = ({
    text,
    disabled,
    used,
    onActionClick,
    startIcon
}: {
    text: string;
    disabled?: boolean;
    used?: boolean;
    onActionClick: (event: React.MouseEvent<HTMLElement>) => void;
    startIcon?: React.ReactNode;
}) => {
    return (
        <Button
            disabled={disabled}
            onClick={(event) => onActionClick(event)}
            variant="outlined"
            size="small"
            sx={{ mt: 1, p: '3px 6px', borderRadius: '3px' }}
            color={used || disabled ? 'inherit' : 'primary'}
            startIcon={startIcon}
        >
            {text}
        </Button>
    );
};

export default ActionButton;
