import { ApiListResponse, ApiResponse, Catalog, Group, Product, SampleTemplate, Set, Template } from 'src/types';
import apiClient from './apiClient';

export class WhatsAppService {
    async getSets() {
        const { data } = await apiClient.get<ApiListResponse<Set>>('whatsapp/sets');
        return data;
    }

    async getOwnedCatalogs() {
        const { data } = await apiClient.get<ApiListResponse<Set>>('whatsapp/OwnedCatalogs');
        return data;
    }

    async getProducts(page: number, limit: number, setId: string) {
        const { data } = await apiClient.get<ApiListResponse<Product>>('whatsapp/products', {
            params: {
                page,
                limit,
                setId
            }
        });
        return data;
    }

    async linkCatalog() {
        const { data } = await apiClient.post<ApiResponse<{ catalogName: string; catalogAvailable: boolean }>>(
            'whatsapp/catalog'
        );
        return data;
    }

    async getTemplates(pageUrl: string) {
        const { data } = await apiClient.get<ApiResponse<Template[]>>(`whatsapp/Templates?pageUrl=${pageUrl}`);
        return data;
    }

    async addTemplate(template: Template) {
        const payload = await this.getFormData(template);
        const { data } = await apiClient.post<ApiResponse<string>>(`whatsapp/Templates`, payload, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        return data;
    }

    async updateTemplate(template: Template) {
        const payload = await this.getFormData(template);
        const { data } = await apiClient.put<ApiResponse<string>>(`whatsapp/Templates/${template.id}`, payload, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        return data;
    }

    async getFormData(template: Template) {
        const formData = new FormData();
        formData.append('allowWhatsappToChangeCategory', String(false));

        if (template.id) formData.append('id', template.id);
        if (template.name) formData.append('name', template.name);
        if (template.category) formData.append('category', template.category);
        if (template.language) formData.append('language', template.language);

        if (template.components.header.text) {
            if (template.components.header.text)
                formData.append('components.header.text', template.components.header.text);

            if (template.components.header.example)
                formData.append('components.header.example', template.components.header.example);
        } else if (template.components.header.fileUrl) {
            let blob_file = await fetch(template.components.header.fileUrl).then((r) => r.blob());
            let file = new File([blob_file], 'mediaFile', {
                type: blob_file.type
            });
            formData.append('components.header.file', file, 'mediaFile');
        }

        if (template.components.body.text) formData.append('components.body.text', template.components.body.text);

        template.components.body.examples.forEach((example, index) => {
            if (example) formData.append(`components.body.examples[${index}]`, example);
        });

        if (template.components.footer) formData.append('components.footer', template.components.footer);

        template.components.buttons.forEach((button, index) => {
            if (button.text) formData.append(`components.buttons[${index}].text`, button.text);
            if (button.type) formData.append(`components.buttons[${index}].type`, button.type);
            if (button.countryCode) formData.append(`component.button[${index}].countryCode`, button.countryCode);
            if (button.phoneNumber) formData.append(`components.buttons[${index}].phoneNumber`, button.phoneNumber);
            if (button.url) formData.append(`components.buttons[${index}].url`, button.url);
            if (button.example) formData.append(`components.buttons[${index}].example`, button.example);
        });

        return formData;
    }

    async getSampleTemplates() {
        const { data } = await apiClient.get<ApiResponse<SampleTemplate[]>>(`whatsapp/SampleTemplates`);
        return data;
    }

    async addSampleTemplate(sampleTemplate: SampleTemplate) {
        const payload = await this.getFormData(sampleTemplate.template);
        const { data } = await apiClient.post<ApiResponse<string>>(`whatsapp/SampleTemplates`, payload, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        return data;
    }

    async updateSampleTemplate(sampleTemplate: SampleTemplate) {
        const payload = await this.getFormData(sampleTemplate.template);
        const { data } = await apiClient.put<ApiResponse<string>>(
            `whatsapp/SampleTemplates/${sampleTemplate.id}`,
            payload,
            {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            }
        );
        return data;
    }

    async deleteSampleTemplate(id: string) {
        const { data } = await apiClient.delete<ApiResponse<string>>(`whatsapp/SampleTemplates/${id}`);
        return data;
    }

    async deleteTemplate(id: string, name: string) {
        const { data } = await apiClient.delete<ApiResponse<string>>(`whatsapp/Templates/?id=${id}&name=${name}`);
        return data;
    }
}

export default new WhatsAppService();
