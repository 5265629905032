import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const XToolbar = ({ children }: { children: React.ReactNode }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box
            title={t('components.media_picker.tooltip')}
            sx={{
                cursor: 'pointer',
                px: '7px',
                pt: '5px',
                '&:hover': {
                    backgroundColor: theme.colors.primary.lighter,
                    borderRadius: 1
                }
            }}
        >
            {children}
        </Box>
    );
};

export default XToolbar;
