import { InteractiveList } from 'src/types';
import { XIconButton, XSidebarListItem, useAuthPolicy } from 'src/components';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useTranslation } from 'react-i18next';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import { deleteOption } from '../../slice';
import useAfterDelete from '../useAfterDelete';

const Sidebar = ({ interactiveList, disabled }: { interactiveList: InteractiveList; disabled: boolean }) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const dispatch = useAppDispatch();
    const allowDelete = useAuthPolicy('Supervision');
    useAfterDelete();

    const onDeleteClick = async (optionId: string) => {
        const ok = await confirm(t('groups.list.data.delete_confirm_title'), t('groups.list.data.delete_confirm_body'));
        if (ok) {
            dispatch(deleteOption(optionId));
        }
    };

    return (
        <>
            {interactiveList.options.map((x) => (
                <XSidebarListItem
                    disabled={disabled}
                    to={`/bot/options/${x.id}`}
                    key={x.id}
                    title={x.title}
                    actions={
                        <XIconButton
                            disabled={!allowDelete}
                            onClick={() => onDeleteClick(x.id)}
                            tooltip={t('common.lists.delete')}
                        >
                            <DeleteTwoToneIcon color={'error'} fontSize="small"></DeleteTwoToneIcon>
                        </XIconButton>
                    }
                />
            ))}
        </>
    );
};

export default Sidebar;
