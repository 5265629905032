import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { WhatsaapInfoInputs } from './schema';
import HelperIcon from './HelperIcon';

const WhatsAppBusinessAccountId = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<WhatsaapInfoInputs>();

    return (
        <Box sx={{ display: 'flex' }}>
            <TextField
                fullWidth
                error={errors?.whatsAppBusinessAccountId && true}
                {...register(nameOf<WhatsaapInfoInputs>('whatsAppBusinessAccountId'))}
                id={nameOf<WhatsaapInfoInputs>('whatsAppBusinessAccountId')}
                placeholder={t('business.submit.whatsapp_business_id')}
                label={t('business.submit.whatsapp_business_id')}
                size="medium"
                type="text"
                helperText={errors?.whatsAppBusinessAccountId?.message}
            />
            <HelperIcon imageName="business-id" />
        </Box>
    );
};

export default WhatsAppBusinessAccountId;
