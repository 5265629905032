import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Loader from '../Loader';
import { lazy } from 'react';
import Products from 'src/screens/catalog/content';
import LinkCatalogInitiated from 'src/screens/catalog/content/LinkCatalogInitiated';

const Catalog = Loader(lazy(() => import('src/screens/catalog')));

const productRoutes: RouteObject = {
    path: 'catalog',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <Catalog />
        },
        {
            path: 'initiated',
            element: <LinkCatalogInitiated />
        },
        {
            path: 'products',
            element: <Catalog />,
            children: [
                {
                    path: ':setId',
                    element: <Products />
                }
            ]
        }
    ]
};

export default productRoutes;
