import { Box, Button, Grid } from '@mui/material';
import { useAppDispatch } from 'src/store/hooks';
import { CampaignStep } from 'src/types';
import { addCampaignInstance, publishInstance, resetTestCampaign } from '../slice';
import { XLinkButton } from 'src/components';
import { useTranslation } from 'react-i18next';
import TermsAndConditions from './TermsAndConditions';
import { useState } from 'react';
import TestCampaign from './TestCampaign';
import Collapse from '@mui/material/Collapse';
import Schedule from './Schedule';

const SendCampaign = ({ nextStep }: { nextStep: CampaignStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [showTest, setShowTest] = useState(false);
    const [schedule, setSchedule] = useState({ show: false, date: '' });

    const [termsAndConditions, setTermsAndConditions] = useState({ accepted: false, error: '' });

    const onSendClick = () => {
        if (!termsAndConditions.accepted) {
            setTermsAndConditions({
                accepted: false,
                error: t('campaigns.instance.terms_and_conditions.error')
            });
            return;
        }

        if (nextStep?.status == 'UploadProcessed') {
            dispatch(
                publishInstance({
                    campaignId: nextStep?.campaign?.id,
                    scheduledFor: schedule.show ? schedule.date : '',
                    id: nextStep?.instance?.id,
                    acceptTerms: termsAndConditions.accepted
                })
            );
        } else {
            dispatch(
                addCampaignInstance({
                    groupId: nextStep.groupId,
                    campaignId: nextStep?.campaign?.id,
                    scheduledFor: schedule.show ? schedule.date : '',
                    acceptTerms: termsAndConditions.accepted
                })
            );
        }
    };

    return (
        <Grid container sx={{ mt: 0 }}>
            <Grid item>
                {nextStep.showSend && (
                    <TermsAndConditions
                        error={termsAndConditions.error}
                        onChange={(accepted) => {
                            setTermsAndConditions({
                                accepted,
                                error: ''
                            });
                        }}
                    />
                )}
            </Grid>
            {!showTest && !schedule.show && (
                <Grid container sx={{ display: 'flex', justifyContent: 'start', mt: 2 }}>
                    {nextStep.showSend && (
                        <>
                            <Button sx={{ mr: 1 }} onClick={() => onSendClick()} variant="outlined" size="small">
                                {t('campaigns.submit.send')}
                            </Button>
                            <Button
                                onClick={() => {
                                    dispatch(resetTestCampaign());
                                    setSchedule({ date: '', show: false });
                                    setShowTest(!showTest);
                                }}
                                sx={{ mr: 1 }}
                                variant="outlined"
                                size="small"
                            >
                                {t('campaigns.instance.test.test')}
                            </Button>
                            <Button
                                onClick={() => {
                                    setSchedule({ date: '', show: !schedule.show });
                                    setShowTest(false);
                                }}
                                sx={{ mr: 1 }}
                                variant="outlined"
                                size="small"
                            >
                                {t('campaigns.instance.schedule.schedule')}
                            </Button>
                        </>
                    )}
                    <Box sx={{ mr: 1 }}>
                        <XLinkButton to="/campaigns" text={t('components.forms.formButtons.close')}></XLinkButton>
                    </Box>
                </Grid>
            )}
            <Grid item>
                <Collapse in={showTest} sx={{ mt: 2 }}>
                    <TestCampaign onClose={() => setShowTest(false)} campaignId={nextStep?.campaign?.id} />
                </Collapse>
                <Collapse in={schedule.show} sx={{ mt: 2 }}>
                    <Schedule
                        date={schedule.date}
                        onClose={() => setSchedule({ date: '', show: false })}
                        onScheduleChange={(date: string) => {
                            setSchedule({
                                show: schedule.show,
                                date: date
                            });
                        }}
                        onScheduleClick={onSendClick}
                    />
                </Collapse>
            </Grid>
        </Grid>
    );
};

export default SendCampaign;
