import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { updateGroup } from '../../slice';
import { GroupInputs, schema } from './schema';
import { useEffect } from 'react';
import { nameOf } from 'src/utils/utils.shared';

const useSubmitForm = (id: string) => {
    const group = useAppSelector((x) => x.groups.list.tableData.items.find((x) => x.id == id));
    const dispatch = useAppDispatch();
    const methods = useForm<GroupInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: group?.name
        }
    });

    const onSubmit = ({name}) => {
        if(id){
            dispatch(updateGroup({ id, name }));
        }
    };

    useEffect(() => {
        methods.setValue(nameOf('name'), group?.name);
    }, [group]);

    return { methods, onSubmit };
};

export default useSubmitForm;
