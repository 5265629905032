import { useContext } from 'react';
import {
    ListSubheader,
    Box,
    List,
    styled,
    Button,
    ListItem,
    Tooltip,
    TooltipProps,
    tooltipClasses,
    useTheme
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { DrawerContext } from 'src/contexts/DrawerContext';
import GroupsIcon from '@mui/icons-material/Groups';
import CampaignIcon from '@mui/icons-material/Campaign';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import _ from 'lodash';
import AccountMenu from './AccountMenu';
import LocaleToggle from 'src/locales/LocaleToggle';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';

const MenuWrapper = styled(Box)(
    ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(0)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
    ({ theme, dir }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          justify-content: flex-start;
          padding-left: ${theme.spacing(dir == 'ltr' ? 2 : 2)};
          margin-left: ${theme.spacing(dir == 'ltr' ? 1 : 0)};
          padding-right: ${theme.spacing(dir == 'ltr' ? 0.5 : 0.5)};
          margin-right: ${theme.spacing(dir == 'ltr' ? 0 : 1)};
          margin-top: ${theme.spacing(0.5)};
          min-width: auto;
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &:hover {
            background-color: ${theme.colors.secondary.lighter};
            color: ${theme.colors.alpha.trueWhite};

            .MuiButton-startIcon {
                .MuiSvgIcon-root {
                }
            },
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }

          &.active {
            background-color: ${theme.colors.primary.main};
            color: ${theme.colors.alpha.trueWhite};

            .MuiButton-startIcon {
                .MuiSvgIcon-root {
                  color: ${theme.colors.alpha.trueWhite[100]};
                }
            },
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create(['transform', 'opacity'])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        margin: 5
    }
}));

function UserOptions() {
    const { closeDrawer: closeSidebar } = useContext(DrawerContext);
    const { t } = useTranslation();
    const direction = useAppSelector((x) => x.account.direction);
    const theme = useTheme();

    const menu = [
        {
            to: '',
            text: 'layout.sidebar.messaging.account_menu',
            icon: <AccountMenu />
        },
        {
            to: '/bot',
            text: 'layout.sidebar.messaging.chat_bot',
            icon: <SmartToyIcon />
        },
        {
            to: '/campaigns',
            text: 'layout.sidebar.messaging.campaigns',
            icon: <CampaignIcon />
        },
        {
            to: '/conversations',
            text: 'layout.sidebar.messaging.conversations',
            icon: <WhatsAppIcon />
        },
        {
            to: '/catalog',
            text: 'layout.sidebar.business_management.catalog',
            icon: <ProductionQuantityLimitsOutlinedIcon />
        },
        {
            to: '/groups',
            text: 'layout.sidebar.business_management.contacts',
            icon: <GroupsIcon />
        },
        {
            to: '/templates',
            text: 'layout.sidebar.business_management.templates',
            icon: <ListAltIcon />
        },
        {
            to: '/email-campaigns',
            text: 'layout.sidebar.emails.campaigns',
            icon: <EmailIcon />
        },
        {
            to: 'https://sms-client.info-sender.com',
            text: 'layout.sidebar.sms',
            icon: <SmsOutlinedIcon />
        }
    ];

    return (
        <>
            <MenuWrapper dir={direction}>
                {menu.map((x, index) => (
                    <List
                        key={index}
                        component="div"
                        subheader={<ListSubheader component="div" disableSticky></ListSubheader>}
                    >
                        <SubMenuWrapper dir={direction}>
                            <List component="div">
                                <ListItem component="div" key={x.text}>
                                    {x.to == '' ? (
                                        <>{x.icon}</>
                                    ) : (
                                        <LightTooltip
                                            sx={{
                                                '& .MuiTooltip-tooltip': {
                                                    marginLeft: 2,
                                                    marginRight: 2
                                                }
                                            }}
                                            title={t(x.text)}
                                            placement="right"
                                        >
                                            {x.to == 'https://sms-client.info-sender.com' ? (
                                                <a
                                                    href="https://sms-client.info-sender.com"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                >
                                                    <Button
                                                        size="large"
                                                        disableRipple
                                                        onClick={closeSidebar}
                                                        startIcon={x.icon}
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                color: theme.colors.secondary.dark
                                                            }
                                                        }}
                                                    ></Button>
                                                </a>
                                            ) : (
                                                <Button
                                                    size="large"
                                                    disableRipple
                                                    component={RouterLink}
                                                    onClick={closeSidebar}
                                                    to={x.to}
                                                    startIcon={x.icon}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            color: theme.colors.secondary.dark
                                                        }
                                                    }}
                                                ></Button>
                                            )}
                                        </LightTooltip>
                                    )}
                                </ListItem>
                            </List>
                        </SubMenuWrapper>
                    </List>
                ))}

                <LocaleToggle />
            </MenuWrapper>
        </>
    );
}

export default UserOptions;
