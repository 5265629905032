import React, { createContext, useEffect, useState } from 'react';
import * as signalR from '@microsoft/signalr';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import LocalTokenService from 'src/services/localToken.service';
import { conversationArrived, chatRequestRecieved, markMessageForwarded } from 'src/screens/conversations/slice';
import { CampaignInstanceStatus, ChatRequest, Conversation } from 'src/types';
import { updateCampaignInstanceStatus } from 'src/screens/campaigns/slice';
import { setContactsFileProcessed } from 'src/screens/groups/slice';

// Define the context type
interface SignalrContextType {
    connection: any;
}

// Create the SignalR context
export const SignalrContext = createContext<SignalrContextType | undefined>(undefined);

// Create the SignalR provider component
export const SignalrProvider: React.FC = ({ children }) => {
    const [connection, setConnection] = useState<any>(null);
    const user = useAppSelector((x) => x.account);
    const dispatch = useAppDispatch();
    const token = LocalTokenService.getTokens()?.accessToken;

    useEffect(() => {
        if (connection && !token) {
            connection
                .stop()
                .then(() => console.log('SignalR Disconnected'))
                .catch((err) => {});
        }

        // Create a new SignalR connection
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_API_URL}/hubs/chat`, {
                accessTokenFactory: () => token
            })
            .withAutomaticReconnect()
            .build();

        newConnection.on('ChatRequestRecieved', (message: ChatRequest) => {
            dispatch(chatRequestRecieved(message));
        });

        newConnection.on('ConversationRecieved', (message: Conversation) => {
            dispatch(conversationArrived(message));
        });

        newConnection.on('MessageForwarded', (id: string, correlationId: number) => {
            dispatch(markMessageForwarded({ id, correlationId }));
        });

        newConnection.on(
            'CampaignInstanceStatusRecieved',
            (instanceId: string, campaignId: string, status: CampaignInstanceStatus) => {
                dispatch(updateCampaignInstanceStatus({ instanceId, campaignId, status }));
            }
        );

        newConnection.on('ContactsFileProcessed', (groupId: string) => {
            dispatch(setContactsFileProcessed(groupId));
        });

        // Start the connection
        newConnection
            .start()
            .then(() => {
                console.log('SignalR connection established.');
            })
            .catch((error: any) => {
                console.log('SignalR connection error:', error);
            });

        // Set the connection in state
        setConnection(newConnection);

        // Clean up the connection when the component unmounts
        return () => {
            newConnection.stop();
        };
    }, [user, token]);

    return <SignalrContext.Provider value={{ connection }}>{children}</SignalrContext.Provider>;
};
