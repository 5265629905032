import { Box } from '@mui/material';
import ActionButton from './ActionButton';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { EditorInputs } from '../../schema';
import DeleteIcon from '@mui/icons-material/Delete';

const RemoveMedia = () => {
    const { watch, setValue } = useFormContext<EditorInputs>();
    const media = watch(nameOf<EditorInputs>('media'));

    const onActionClick = () => {
        setValue(nameOf<EditorInputs>('media'), null, {
            shouldDirty: true
        });
    };

    return media ? (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <ActionButton
                startIcon={<DeleteIcon />}
                disabled={false}
                onActionClick={() => onActionClick()}
                text="Remove media"
            ></ActionButton>
        </Box>
    ) : (
        <></>
    );
};

export default RemoveMedia;
