import { OutlinedInput, InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import { SubmitCampaignInputs } from '../schema';
import { nameOf } from 'src/utils/utils.shared';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { LanguageMappingsEn, Template } from 'src/types';
import _ from 'lodash';

export default function LanguageCode({ templates }: { templates: Template[] }) {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitCampaignInputs>();

    return (
        <FormControl fullWidth sx={{ maxWidth: '80%', m: 2 }}>
            <InputLabel>{t('campaigns.list.head.language_code')}</InputLabel>
            <Select
                defaultValue={defaultValues?.languageCode}
                error={errors?.templateId && true}
                {...register(nameOf<SubmitCampaignInputs>('languageCode'))}
                id={nameOf<SubmitCampaignInputs>('languageCode')}
                size="medium"
                input={<OutlinedInput label={t('campaigns.list.head.language_code')} />}
            >
                {_.chain(templates)
                    .map((x) => x.language)
                    .uniq()
                    .map((x) => ({
                        code: x,
                        language: LanguageMappingsEn[x]
                    }))
                    .unshift({
                        code: undefined,
                        language: undefined
                    })
                    .value()
                    .map((x, i) =>
                        x.code == undefined ? (
                            <MenuItem value={x.code} key={i}>
                                <em>{t('common.drop_downs.select')}</em>
                            </MenuItem>
                        ) : (
                            <MenuItem key={i} value={x.code} selected={x.code == defaultValues?.languageCode}>
                                {x.language}
                            </MenuItem>
                        )
                    )}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors?.templateId?.message}</FormHelperText>
        </FormControl>
    );
}
