import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { Group, SampleTemplate, Template } from 'src/types';
import { ActionState } from 'src/types/types.shared';
import i18next from 'i18next';
import { WhatsAppService } from 'src/services/whatsApp.service';

const service = new WhatsAppService();

export interface SliceState {
    list: {
        state: ActionState;
        items: Template[];
        page: number;
    };
    submit: {
        state: ActionState;
    };
    samples: {
        state: ActionState;
        items: SampleTemplate[];
    };
}

const initialState: SliceState = {
    list: {
        state: undefined,
        items: [],
        page: 1
    },
    submit: {
        state: undefined
    },
    samples: {
        state: undefined,
        items: []
    }
};

export const getTemplates = createAsyncThunk('GET_TEMPLATES', async (pageUrl: string, thunkAPI) => {
    const response = await service.getTemplates(pageUrl);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addTemplate = createAsyncThunk('ADD_TEMPLATE', async (template: Template, thunkAPI) => {
    const response = await service.addTemplate(template);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateTemplate = createAsyncThunk('UPDATE_TEMPLATE', async (template: Template, thunkAPI) => {
    const response = await service.updateTemplate(template);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getSampleTemplates = createAsyncThunk('GET_SAMPLES', async (_, thunkAPI) => {
    const response = await service.getSampleTemplates();
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addSampleTemplate = createAsyncThunk('ADD_SAMPLE', async (sampleTemplate: SampleTemplate, thunkAPI) => {
    const response = await service.addSampleTemplate(sampleTemplate);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateSampleTemplate = createAsyncThunk(
    'UPDATE_SAMPLE',
    async (sampleTemplate: SampleTemplate, thunkAPI) => {
        const response = await service.updateSampleTemplate(sampleTemplate);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const deleteSampleTemplate = createAsyncThunk('DELETE_SAMPLE', async (id: string, thunkAPI) => {
    const response = await service.deleteSampleTemplate(id);
    if (response.status === 'Successful') return id;
    return thunkAPI.rejectWithValue(response.message);
});

export const deleteTemplate = createAsyncThunk(
    'DELETE_TEMPLATE',
    async ({ id, name }: { id: string; name: string }, thunkAPI) => {
        const response = await service.deleteTemplate(id, name);
        if (response.status === 'Successful') return id;
        return thunkAPI.rejectWithValue(response.message);
    }
);

const slice = createSlice({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getTemplates.pending, (state) => {
            state.list.state = 'inProgress';
            state.submit.state = undefined;
        });
        builder.addCase(getTemplates.fulfilled, (state, action) => {
            state.list.items = action.payload;
            state.list.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getTemplates.rejected, (state, action) => {
            state.list.state = 'failed';
            state.submit.state = undefined;
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('templates.slice.errors.fetch_templates'));
        });

        builder.addCase(addTemplate.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(addTemplate.fulfilled, (state) => {
            state.submit.state = 'successful';
            SnackbarUtils.success(i18next.t('templates.slice.success.add_template'));
        });
        builder.addCase(addTemplate.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('templates.slice.errors.add_template'));
        });

        builder.addCase(updateTemplate.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(updateTemplate.fulfilled, (state) => {
            state.submit.state = 'successful';
            SnackbarUtils.success(i18next.t('templates.slice.success.update_template'));
        });
        builder.addCase(updateTemplate.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('templates.slice.errors.update_template'));
        });

        builder.addCase(getSampleTemplates.pending, (state) => {
            state.samples.state = 'inProgress';
        });
        builder.addCase(getSampleTemplates.fulfilled, (state, action) => {
            state.samples.state = 'successful';
            state.samples.items = action.payload;
        });
        builder.addCase(getSampleTemplates.rejected, (state, action) => {
            state.samples.state = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('templates.slice.errors.fetch_sample_templates')
            );
        });

        builder.addCase(addSampleTemplate.pending, (state) => {
            state.samples.state = 'inProgress';
        });
        builder.addCase(addSampleTemplate.fulfilled, (state, action) => {
            state.samples.state = 'successful';
            SnackbarUtils.success(i18next.t('templates.slice.success.add_sample_template'));
        });
        builder.addCase(addSampleTemplate.rejected, (state, action) => {
            state.samples.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('templates.slice.errors.add_sample_template'));
        });

        builder.addCase(updateSampleTemplate.pending, (state) => {
            state.samples.state = 'inProgress';
        });
        builder.addCase(updateSampleTemplate.fulfilled, (state) => {
            state.samples.state = 'successful';
            SnackbarUtils.success(i18next.t('templates.slice.success.update_sample_template'));
        });
        builder.addCase(updateSampleTemplate.rejected, (state, action) => {
            state.samples.state = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('templates.slice.errors.update_sample_template')
            );
        });

        builder.addCase(deleteSampleTemplate.pending, (state) => {
            state.samples.state = 'inProgress';
        });
        builder.addCase(deleteSampleTemplate.fulfilled, (state, action) => {
            state.samples.state = 'successful';
            state.samples.items = state.samples.items.filter((sample) => sample.id !== action.payload);
            SnackbarUtils.success(i18next.t('templates.slice.success.delete_sample_template'));
        });
        builder.addCase(deleteSampleTemplate.rejected, (state, action) => {
            state.samples.state = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('templates.slice.errors.delete_sample_template')
            );
        });

        builder.addCase(deleteTemplate.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(deleteTemplate.fulfilled, (state, action) => {
            state.list.state = 'successful';
            state.list.items = state.list.items.filter((t) => t.id !== action.payload);
            SnackbarUtils.success(i18next.t('templates.slice.success.delete_template'));
        });
        builder.addCase(deleteTemplate.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('templates.slice.errors.delete_template'));
        });
    }
});

export const {} = slice.actions;

export default slice.reducer;
