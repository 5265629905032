import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDeviceInfo } from 'src/components';

const LiveSupport = () => {
    const { t } = useTranslation();
    const device = useDeviceInfo();

    const link = device.isSmallDevice
        ? 'https://wa.me/966565479016'
        : 'https://web.whatsapp.com/send?phone=966565479016';

    return (
        <Tooltip title={t('layout.sidebar.sidebar_bottom.live_support')} placement="right">
            <a href={link} target="_blank" rel="noopener noreferrer">
                <IconButton sx={{ mr: 1, ml: 1 }}>
                    <img style={{ height: '25px' }} src="/static/images/whatsapp-icon.png" />
                </IconButton>
            </a>
        </Tooltip>
    );
};

export default LiveSupport;
