import { XComponentContainer, XPageContainer } from 'src/components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import TopAction from './TopAction';
import { useParams } from 'react-router';
import { getCampaign } from '../slice';
import SubmitContainer from './SubmitContainer';
import { getTemplates } from 'src/screens/templates/slice';

const Submit = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { value, state } = useAppSelector((x) => x.campaigns.current);
    const templates = useAppSelector((x) => x.templates.list);
    const currentTemplate = templates?.items?.find((x) => x.id == value?.templateId);
    const show = templates.state == 'successful' && (!id || state == 'successful');

    useEffect(() => {
        if (id) {
            dispatch(getCampaign(id));
        }
    }, [id]);

    useEffect(() => {
        if (templates.items.length == 0) {
            dispatch(getTemplates(''));
        }
    }, []);

    return (
        <XComponentContainer>
            <XPageContainer
                loading={templates.state == 'inProgress' || state == 'inProgress'}
                topBar={{
                    title: value ? t('campaigns.submit.edit_title') : t('campaigns.submit.add_title'),
                    backLink: '/campaigns',
                    action: <TopAction campaign={value} template={currentTemplate} />
                }}
            >
                {show && <SubmitContainer />}
            </XPageContainer>
        </XComponentContainer>
    );
};

export default Submit;
