import { RouteObject } from 'react-router';
import SignIn from 'src/screens/account/signIn';
import SetPassword from 'src/screens/account/setPassword';
import Register from 'src/screens/businesses/register';
import ForgotPassword from 'src/screens/account/ForgotPassword';

const index: RouteObject[] = [
    {
        path: '/',
        element: <SignIn />
    },
    {
        path: '/account/:userId/set-password',
        element: <SetPassword reset={false} />
    },
    {
        path: '/account/:userId/reset-password',
        element: <SetPassword reset={true} />
    },
    {
        path: '/businesses/register',
        element: <Register />
    },
    {
        path: '/forgot-password/',
        element: <ForgotPassword />
    },
    {
        path: '*',
        element: <SignIn />
    }
];

export default index;
