import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useAppSelector } from './store/hooks';
import { SnackbarProvider } from 'notistack';
import { SnackbarUtilsConfigurator } from './components/Snackbar';
import Content from 'src/routes';
import { ConfirmDialogProvider } from './contexts/ConfirmationContext';
import { SignalrProvider } from './contexts/SignalRContext';
import { AlertProvider } from './contexts/AlertContext';
import { CloseSnackbarAction } from './components';

function App() {
    const { i18n } = useTranslation();
    const { locale } = useAppSelector((x) => x.account.user);

    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [locale]);

    useEffect(() => {}, []);

    return (
        <ThemeProvider>
            <ConfirmDialogProvider>
                <AlertProvider>
                    <SnackbarProvider
                        maxSnack={3}
                        preventDuplicate
                        autoHideDuration={3000}
                        anchorOrigin={{
                            horizontal: locale == 'ar' ? 'left' : 'right',
                            vertical: 'bottom'
                        }}
                        action={(key) => <CloseSnackbarAction id={key} />}
                    >
                        <SnackbarUtilsConfigurator />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CssBaseline />
                            <SignalrProvider>
                                <Content />
                            </SignalrProvider>
                        </LocalizationProvider>
                    </SnackbarProvider>
                </AlertProvider>
            </ConfirmDialogProvider>
        </ThemeProvider>
    );
}
export default App;
