import { Box, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from './../../schema';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'src/components';

const WebsiteSample = ({ url, index, inputColor }: { url: string; index: number; inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();
    const { borderRounded } = useStyles();

    return (
        <Box sx={{ ...borderRounded, p: 2, mt: 2 }}>
            <Typography variant="h5">{t('templates.submit.buttons.call_to_action.sample_url_title')}</Typography>
            <Typography variant="body1" sx={{ fontSize: 11 }}>
                {t('templates.submit.buttons.call_to_action.sample_url_description')}
            </Typography>
            <Box sx={{ pt: 1, display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ mx: 1 }} variant="subtitle2">
                    {'{{1}}'}
                </Typography>
                <TextField
                    fullWidth
                    defaultValue={defaultValues?.name}
                    error={errors?.buttons?.callToactions[index]?.websiteSample && true}
                    {...register(`buttons.callToactions.${index}.websiteSample`)}
                    placeholder={`${t('templates.submit.buttons.call_to_action.sample_url')} ${url}{{1}}`}
                    size="small"
                    type="text"
                    sx={{
                        '&.MuiFormControl-root': {
                            '.MuiOutlinedInput-root': {
                                background: inputColor
                            }
                        }
                    }}
                    helperText={errors?.buttons?.callToactions[index]?.websiteSample?.message}
                />
            </Box>
        </Box>
    );
};

export default WebsiteSample;
