import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { WelcomeMessageInputs } from './schema';

const BodyArabic = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<WelcomeMessageInputs>();

    return (
        <>
            <TextField
                fullWidth
                error={errors?.bodyArabic && true}
                {...register(nameOf<WelcomeMessageInputs>('bodyArabic'))}
                id={nameOf<WelcomeMessageInputs>('bodyArabic')}
                placeholder={t('bot.settings.welcomeMessage.bodyArabic')}
                size="small"
                type="text"
                helperText={errors?.bodyArabic?.message}
                sx={{
                    p: 1
                }}
                multiline
                rows={3}
            ></TextField>
        </>
    );
};

export default BodyArabic;
