import { Box, FormControlLabel, IconButton, Paper, Radio, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SubmitTemplateInputs } from '../schema';
import { MediaType } from 'src/types';

const MediaTypeItem = ({
    thumbnail,
    mediaType,
    inputColor
}: {
    thumbnail: string;
    mediaType: MediaType;
    inputColor: string;
}) => {
    const {
        watch,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const selectedType = watch('header.mediaType');

    return (
        <Box sx={{ display: 'flex' }}>
            <Paper
                sx={{
                    mr: 2,
                    mt: 1,
                    backgroundColor: inputColor,
                    border: selectedType == mediaType ? 1 : 0
                }}
            >
                <IconButton>
                    <FormControlLabel
                        control={<Radio value={mediaType} />}
                        label={
                            <Box textAlign="center">
                                <img style={{ width: '100px', height: '100px' }} src={thumbnail} alt="Image" />
                                <Typography variant="body2" align="center">
                                    {mediaType.toString()}
                                </Typography>
                            </Box>
                        }
                    />
                </IconButton>
            </Paper>
        </Box>
    );
};

export default MediaTypeItem;
