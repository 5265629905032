import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { XTableContainer, useAlignment } from 'src/components';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Head from './Head';
import { useTranslation } from 'react-i18next';
import Actions from './Actions';
import { getQuickreplies, setQuickRepliesPaging } from 'src/screens/conversations/slice';
import useAfterDelete from './useAfterDelete';

function List() {
    useAfterDelete();
    const { t } = useTranslation();
    const { list, state } = useAppSelector((x) => x.conversations.quickReplies);
    const dispatch = useAppDispatch();
    const align = useAlignment();

    useEffect(() => {
        dispatch(getQuickreplies());
    }, []);

    const onPaging = (page: number, limit: number) => {
        dispatch(
            setQuickRepliesPaging({
                page,
                limit
            })
        );
        dispatch(getQuickreplies());
    };

    return (
        <XTableContainer
            totalItems={list.totalItems}
            page={list.page}
            limit={list.limit}
            onPaging={onPaging}
            loading={state === 'inProgress'}
        >
            <Table size="small">
                <Head />
                <TableBody>
                    {list.items.map((x) => (
                        <TableRow hover key={x.id}>
                            <TableCell align={align('left')} padding="normal">
                                {x.title}
                            </TableCell>
                            <TableCell align={align('left')}>{x.message}</TableCell>
                            <TableCell align={align('right')}>
                                <Actions quickRely={x}></Actions>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </XTableContainer>
    );
}

export default List;
