import { useFormContext } from 'react-hook-form';
import { Template } from 'src/types';
import { SubmitSallaTemplateInputs } from './schema';
import { Box, Typography, CardContent } from '@mui/material';
import { t } from 'i18next';
import { XTemplatePreview } from 'src/components';

const Preview = ({ templates }: { templates: Template[] }) => {
    const { watch } = useFormContext<SubmitSallaTemplateInputs>();
    const templateId = watch('metaTemplateId');
    const template = templates.find((x) => x.id == templateId);

    return (
        <>
            {template && (
                <Box sx={{ height: '100%', m: 1, mt: 3 }}>
                    <Typography variant="body1">{t('campaigns.submit.preview_title')}</Typography>
                    <CardContent sx={{ mt: 2, background: 'url(/static/images/whatsapp-background.png)' }}>
                        <Box sx={{ background: 'rgb(203 255 217)' }}>
                            {template && <XTemplatePreview template={template} />}
                        </Box>
                    </CardContent>
                </Box>
            )}
        </>
    );
};

export default Preview;
