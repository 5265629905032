import { Avatar } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function getAvatarLetters(name: string) {
    const words = name
        .trim()
        .split(' ')
        .filter((x) => x);

    let avatarLetters = words
        .slice(0, 2)
        .map((word) => word.charAt(0).toUpperCase())
        .join('');

    if (words.length === 1) {
        avatarLetters = words[0].slice(0, 2).toUpperCase();
    }

    return avatarLetters;
}

const XAvatar = ({ color, name }: { color: string; name: string }) => {
    return name ? (
        <Avatar sx={{ bgcolor: color }}>{getAvatarLetters(name)}</Avatar>
    ) : (
        <Avatar sx={{ bgcolor: color }}>{<UserIcon color={color} />}</Avatar>
    );
};

const UserIcon = ({ color }: { color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="10" fill={color} />
            <path
                fill="#fff"
                d="M12 2C6.48 2 2 6.48 2 12c0 2.985 1.372 5.695 3.507 7.486C6.512 20.883 8.659 22 12 22s5.488-1.117 6.493-2.514C20.628 17.695 22 14.985 22 12c0-5.52-4.48-10-10-10zm0 2c2.206 0 4 1.794 4 4 0 2.206-1.794 4-4 4-2.206 0-4-1.794-4-4 0-2.206 1.794-4 4-4zm0 16c-2.67 0-4.935-1.43-6.207-3.566C6.345 14.716 8.45 14 12 14c3.55 0 5.655.716 6.207 1.434C16.935 18.57 14.67 20 12 20z"
            />
        </svg>
    );
};

export default XAvatar;
