import { XSidebarListItem } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import { getTeams } from '../slice';
import ItemActions from './ItemActions';
import _ from 'lodash';
import useTeamChanged from './useTeamChanged';

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const { tableData } = useAppSelector((x) => x.teams.list);

    useEffect(() => {
        dispatch(getTeams());
    }, [dispatch]);

    useTeamChanged(() => {
        dispatch(getTeams());
    });

    return (
        <>
            {tableData.items.map((x) => (
                <XSidebarListItem
                    key={x.id}
                    title={`${x.name}(${x.userCount})`}
                    to={`${x.id}/users`}
                    actions={<ItemActions teamId={x.id}></ItemActions>}
                />
            ))}
        </>
    );
};

export default Sidebar;
