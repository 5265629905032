import { FormHelperText, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAppSelector } from 'src/store/hooks';
import { UserLocale } from 'src/types';
import { useFormContext } from 'react-hook-form';
import { CampaignInterfaceInputs } from '../schema';

const BotOptions = ({ locale }: { locale: UserLocale }) => {
    const { t } = useTranslation();
    const bot = useAppSelector((x) => x.businessBot.bot);
    const {
        setValue,
        formState: { errors }
    } = useFormContext<CampaignInterfaceInputs>();

    const options = bot?.botInterfaces?.find((x) => x.interfaceType == 'InteractiveList' && x.locale == locale)
        ?.interactiveList?.options;

    const onOptionSelect = (option: string | number) => {
        const targetInterfaceId = options.find((x) => x.id == option).targetInterfaceId;
        setValue('campaignInterface.interfaceId', targetInterfaceId);
    };

    return (
        <>
            <Select defaultValue={0} size="small" onChange={(e) => onOptionSelect(e.target.value)}>
                {_.chain(options?.length ? options : [])
                    .map((x) => ({
                        id: x.id,
                        title: x.title
                    }))
                    .unshift({
                        id: '',
                        title: ''
                    })
                    .value()
                    .map((x) =>
                        x.id == '' ? (
                            <MenuItem value={0} key={0}>
                                <em>{t('common.drop_downs.select')}</em>
                            </MenuItem>
                        ) : (
                            <MenuItem key={x.id} value={x.id}>
                                {x.title}
                            </MenuItem>
                        )
                    )}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors?.campaignInterface?.interfaceId?.message}</FormHelperText>
        </>
    );
};

export default BotOptions;
