import TopBarContent from '../TopBarContent';
import Scrollbar from 'src/components/Scrollbar';
import { Box, styled } from '@mui/material';
import SuspenseLoader from '../../SuspenseLoader';
import { PageContainerProps } from 'src/types';
import { useContext } from 'react';
import { DrawerContext } from 'src/contexts/DrawerContext';

const ContentWrapper = styled(Box)(
    ({ theme }) => `
        padding: ${theme.spacing(0)};
  `
);

const MainWindow = styled(Box)(
    ({ theme }) => `
        width: 100%;
        height: 100vh;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        flex: 1;
        background: ${theme.colors.alpha.white[100]};
`
);

const XContentContainer = ({ props }: { props: PageContainerProps }) => {
    const { openDrawer } = useContext(DrawerContext);
    return (
        <MainWindow>
            <TopBarContent>{props}</TopBarContent>
            <Box flex={1}>
                {props.loading ? (
                    <SuspenseLoader />
                ) : (
                    <>
                        {props.disableContentScroll ? (
                            <ContentWrapper>{props.children} </ContentWrapper>
                        ) : (
                            <Scrollbar>
                                <ContentWrapper>{props.children} </ContentWrapper>
                            </Scrollbar>
                        )}
                    </>
                )}
            </Box>
            {props.bottom && props.bottom}
        </MainWindow>
    );
};

export default XContentContainer;
