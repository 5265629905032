import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { CampaignsService } from 'src/services/campaigns.service';
import { RootState } from 'src/store';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import i18next from 'i18next';
import { Campaign, CampaignInstance, CampaignInstanceStatus, CampaignReport } from 'src/types/types.campaigns';
import { SubmitCampaignInputs } from './submit/schema';
import { CampaignInterfaceInputs } from './customise/schema';
import { getBusinessesBot } from '../bot/slice';
import { Group } from 'src/types';

const service = new CampaignsService();

export interface SliceState {
    list: {
        state: ActionState;
        tableData: TableData<Campaign>;
    };
    current: {
        state: ActionState;
        customiseState: ActionState;
        instanceState: ActionState;
        completedJustNow: boolean;
        value: Campaign | undefined;
        reports: {
            state: ActionState;
            campaignReports: Array<CampaignReport>;
        };
        campaignTest: {
            state: ActionState;
        };
        schedule: {
            state: ActionState;
        };
    };
    groups: {
        items: Group[];
        state: ActionState;
    };
}

const initialState: SliceState = {
    list: {
        state: undefined,
        tableData: DefaultTableData()
    },
    current: {
        state: undefined,
        customiseState: undefined,
        instanceState: undefined,
        completedJustNow: undefined,
        value: undefined,
        reports: {
            state: undefined,
            campaignReports: []
        },
        campaignTest: {
            state: undefined
        },
        schedule: {
            state: undefined
        }
    },
    groups: {
        items: [],
        state: undefined
    }
};

export const getCampaigns = createAsyncThunk('GET_CAMPAIGNS', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.campaigns.list.tableData;
    const response = await service.getCampaigns(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getCampaign = createAsyncThunk('GET_CAMPAIGN', async (id: string, thunkAPI) => {
    const response = await service.getCampaign(id);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addCampaign = createAsyncThunk('ADD_CAMPAIGN', async (inputs: SubmitCampaignInputs, thunkAPI) => {
    const response = await service.addCampaign(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateCampaign = createAsyncThunk('ADD_CAMPAIGN', async (inputs: SubmitCampaignInputs, thunkAPI) => {
    const response = await service.addCampaign(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addCampaignInstance = createAsyncThunk(
    'ADD_CAMPAIGN_INSTANCE',
    async (
        {
            groupId,
            campaignId,
            scheduledFor,
            acceptTerms
        }: { groupId: string; campaignId: string; scheduledFor: string; acceptTerms: boolean },
        thunkAPI
    ) => {
        const response = await service.addCampaignInstance(groupId, campaignId, scheduledFor, acceptTerms);
        if (response.status === 'Successful') {
            const campaignResponse = await service.getRecentCampaignInstance(campaignId);
            if (campaignResponse.status === 'Successful') {
                return campaignResponse.data;
            }
            return thunkAPI.rejectWithValue(campaignResponse.message);
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const addCampaignAction = createAsyncThunk(
    'ADD_CAMPAIGN_INTERFACE',
    async (inputs: CampaignInterfaceInputs, thunkAPI) => {
        const response = await service.addCampaignAction(inputs);
        if (response.status === 'Successful') return response.data;
        thunkAPI.dispatch(getBusinessesBot());
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const publishInstance = createAsyncThunk(
    'PUBLISH_INSTANCE',
    async (
        {
            id,
            campaignId,
            scheduledFor,
            acceptTerms
        }: { id: string; campaignId: string; scheduledFor: string; acceptTerms: boolean },
        thunkAPI
    ) => {
        const response = await service.publishInstance(id, scheduledFor, acceptTerms);
        if (response.status === 'Successful') {
            const campaignResponse = await service.getRecentCampaignInstance(campaignId);
            if (campaignResponse.status === 'Successful') {
                return campaignResponse.data;
            }
            return thunkAPI.rejectWithValue(campaignResponse.message);
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const downloadCsv = createAsyncThunk(
    'DOWNLOAD_CSV',
    async (
        { campaignName, groupId, campaignId }: { campaignName: string; groupId: string; campaignId: string },
        thunkAPI
    ) => {
        const response = await service.downloadCsv(campaignName, groupId, campaignId);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const uploadCsv = createAsyncThunk(
    'UPLOAD_CSV',
    async ({ fileLink, groupId, campaignId }: { fileLink: string; groupId: string; campaignId: string }, thunkAPI) => {
        const response = await service.uploadCsv(fileLink, groupId, campaignId);
        if (response.status === 'Successful') {
            const campaignResponse = await service.getRecentCampaignInstance(campaignId);
            if (campaignResponse.status === 'Successful') {
                return campaignResponse.data;
            }
            return thunkAPI.rejectWithValue(campaignResponse.message);
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const getReports = createAsyncThunk('GET_REPORTS', async (id: string, thunkAPI) => {
    const response = await service.getReports(id);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const sendTestCampaign = createAsyncThunk(
    'SEND_TEST_CAMPAIGN',
    async ({ campaignId, phoneNumber }: { campaignId: string; phoneNumber: string }, thunkAPI) => {
        const response = await service.sendTestCampaign({ campaignId, phoneNumber });
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const getAllGroups = createAsyncThunk('SEARCH_GROUPS', async (_, thunkAPI) => {
    const response = await service.getAllGroups();
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const deleteCampaign = createAsyncThunk('DELETE_CAMPAIGN', async (id: string, thunkAPI) => {
    const response = await service.deleteCampaign(id);
    if (response.status === 'Successful') {
        thunkAPI.dispatch(getCampaigns());
        return response.data;
    }
    return thunkAPI.rejectWithValue(response.message);
});

const slice = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.list.tableData.page = action.payload.page;
            state.list.tableData.limit = action.payload.limit;
        },
        updateCampaignInstanceStatus: (
            state,
            action: PayloadAction<{ instanceId: string; campaignId: string; status: CampaignInstanceStatus }>
        ) => {
            state.current.value.recentInstance.status = action.payload.status;
            if (action.payload.status == 'Failed') {
                SnackbarUtils.error(i18next.t('campaigns.slice.errors.campaign_failed'));
            } else if (action.payload.status == 'Completed') {
                SnackbarUtils.success(i18next.t('campaigns.slice.success.campaign_completed'));
            }
            state.current.completedJustNow = true;
        },
        resetTestCampaign: (state) => {
            state.current.campaignTest.state = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getCampaigns.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(getCampaigns.fulfilled, (state, action) => {
            state.list.tableData = action.payload;
            state.list.state = 'successful';
            state.current.state = undefined;
            state.current.customiseState = undefined;
            state.current.instanceState = undefined;
            state.current.value = undefined;
            state.current.completedJustNow = undefined;
            state.current.campaignTest.state = undefined;
            state.current.schedule.state = undefined;
        });
        builder.addCase(getCampaigns.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.fetch_campaigns'));
        });

        builder.addCase(getAllGroups.pending, (state) => {
            state.groups.state = 'inProgress';
            state.groups.state = undefined;
        });
        builder.addCase(getAllGroups.fulfilled, (state, action) => {
            state.groups.items = action.payload.items;
            state.groups.state = 'successful';
        });
        builder.addCase(getAllGroups.rejected, (state, action) => {
            state.groups.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('templates.slice.errors.search_groups'));
        });

        builder.addCase(addCampaign.pending, (state) => {
            state.current.state = 'inProgress';
        });
        builder.addCase(addCampaign.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('campaigns.slice.success.add_campaign'));
            state.current.state = 'successful';
            state.current.value = {
                id: action.payload.id,
                ...state.current.value
            };
        });
        builder.addCase(addCampaign.rejected, (state, action) => {
            state.current.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.add_campaign'));
        });

        builder.addCase(getCampaign.pending, (state) => {
            state.current.state = 'inProgress';
        });
        builder.addCase(getCampaign.fulfilled, (state, action) => {
            state.current.state = 'successful';
            state.current.value = action.payload;
        });
        builder.addCase(getCampaign.rejected, (state, action) => {
            state.current.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.get_campaign'));
        });

        builder.addCase(addCampaignInstance.pending, (state, action) => {
            if (action.meta.arg.scheduledFor) {
                state.current.schedule.state = 'inProgress';
            } else {
                state.current.instanceState = 'inProgress';
            }
        });
        builder.addCase(addCampaignInstance.fulfilled, (state, action) => {
            state.current.value.recentInstance = action.payload;
            if (action.payload.status == 'Scheduled') {
                state.current.schedule.state = 'successful';
                SnackbarUtils.success(i18next.t('campaigns.slice.success.schedule'));
            } else {
                state.current.instanceState = 'successful';
                SnackbarUtils.success(i18next.t('campaigns.slice.success.add_instance'));
            }
        });
        builder.addCase(addCampaignInstance.rejected, (state, action) => {
            if (action.meta.arg.scheduledFor) {
                state.current.schedule.state = 'failed';
                SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.schedule'));
            } else {
                state.current.instanceState = 'failed';
                SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.add_instance'));
            }
        });

        builder.addCase(downloadCsv.pending, () => {});
        builder.addCase(downloadCsv.fulfilled, () => {});
        builder.addCase(downloadCsv.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.download_csv'));
        });

        builder.addCase(uploadCsv.pending, (state) => {
            state.current.instanceState = 'inProgress';
        });
        builder.addCase(uploadCsv.fulfilled, (state, action) => {
            state.current.instanceState = 'successful';
            state.current.value.recentInstance = action.payload;
            SnackbarUtils.success(i18next.t('campaigns.slice.success.csv_upload'));
        });
        builder.addCase(uploadCsv.rejected, (state, action) => {
            state.current.instanceState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.csv_upload'));
        });

        builder.addCase(publishInstance.pending, (state, action) => {
            if (action.meta.arg.scheduledFor) {
                state.current.schedule.state = 'inProgress';
            } else {
                state.current.instanceState = 'inProgress';
            }
        });
        builder.addCase(publishInstance.fulfilled, (state, action) => {
            state.current.value.recentInstance = action.payload;
            if (action.payload.status == 'Scheduled') {
                state.current.schedule.state = 'successful';
                SnackbarUtils.success(i18next.t('campaigns.slice.success.schedule'));
            } else {
                state.current.instanceState = 'successful';
                SnackbarUtils.success(i18next.t('campaigns.slice.success.publish_instance'));
            }
        });
        builder.addCase(publishInstance.rejected, (state, action) => {
            if (action.meta.arg.scheduledFor) {
                state.current.schedule.state = 'failed';
                SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.schedule'));
            } else {
                state.current.instanceState = 'failed';
                SnackbarUtils.error(
                    action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.publish_instance')
                );
            }
        });

        builder.addCase(addCampaignAction.pending, (state) => {
            state.current.customiseState = 'inProgress';
        });
        builder.addCase(addCampaignAction.fulfilled, (state) => {
            state.current.customiseState = 'successful';
            SnackbarUtils.success(i18next.t('campaigns.slice.success.add_campaign_action'));
        });
        builder.addCase(addCampaignAction.rejected, (state, action) => {
            state.current.customiseState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.add_campaign_action'));
        });

        builder.addCase(getReports.pending, (state) => {
            state.current.reports.state = 'inProgress';
        });
        builder.addCase(getReports.fulfilled, (state, action) => {
            state.current.reports.campaignReports = action.payload;
            state.current.reports.state = 'successful';
        });
        builder.addCase(getReports.rejected, (state, action) => {
            state.current.reports.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.fetch_reports'));
        });

        builder.addCase(sendTestCampaign.pending, (state) => {
            state.current.campaignTest.state = 'inProgress';
        });
        builder.addCase(sendTestCampaign.fulfilled, (state) => {
            state.current.campaignTest.state = 'successful';
        });
        builder.addCase(sendTestCampaign.rejected, (state, action) => {
            state.current.campaignTest.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.campaign_failed'));
        });

        builder.addCase(deleteCampaign.pending, (state) => {
            // state.current.campaignTest.state = 'inProgress';
        });
        builder.addCase(deleteCampaign.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('campaigns.slice.success.delete_campaign'));
            // state.current.campaignTest.state = 'successful';
        });
        builder.addCase(deleteCampaign.rejected, (state, action) => {
            // state.current.campaignTest.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('campaigns.slice.errors.delete_campaign'));
        });
    }
});

export const { setPaging, updateCampaignInstanceStatus, resetTestCampaign } = slice.actions;

export default slice.reducer;
