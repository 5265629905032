import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { SetPasswordInputs } from './schema';

const Password = () => {
    const {
        register,
        formState: { errors }
    } = useFormContext<SetPasswordInputs>();

    return (
        <TextField
            error={errors?.password && true}
            {...register(nameOf<SetPasswordInputs>('password'))}
            id={nameOf<SetPasswordInputs>('password')}
            placeholder="Password"
            size="medium"
            type="password"
            helperText={errors?.password?.message}
        />
    );
};

export default Password;
