import { Box, Checkbox, FormControlLabel, Link } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { GroupInputs } from './schema';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';

const TermsAndConditions = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<GroupInputs>();

    return (
        <>
            <Box>
                <FormControlLabel
                    sx={{
                        '& .MuiFormControlLabel-label': {
                            fontSize: 12
                        }
                    }}
                    label={t('groups.submit.terms_and_conditions.description')}
                    control={<Checkbox {...register(nameOf<GroupInputs>('acceptTermsAndConditions'))} />}
                />
            </Box>
            {errors?.acceptTermsAndConditions && (
                <Box>
                    <FormHelperText error>{t('groups.submit.terms_and_conditions.error')}</FormHelperText>
                </Box>
            )}
        </>
    );
};

export default TermsAndConditions;
