import { useTranslation } from 'react-i18next';
import { XIconButton } from 'src/components';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import { removeGroup } from '../slice';

const Item = ({ groupId }: { groupId: string }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const confirm = useConfirm();

    const onDeleteClick = async () => {
        const ok = await confirm(t('groups.list.data.delete_confirm_title'), t('groups.list.data.delete_confirm_body'));
        if (ok) {
            dispatch(removeGroup(groupId));
        }
    };

    return (
        <XIconButton onClick={() => onDeleteClick()} tooltip={t('common.lists.delete')}>
            <DeleteTwoToneIcon color={'error'} fontSize="small"></DeleteTwoToneIcon>
        </XIconButton>
    );
};

export default Item;
