import { Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';

const DrawerTopbar = ({ title, onCloseClick }: { title: string; onCloseClick: () => void }) => {
    const direction = useAppSelector((x) => x.account.direction);
    const { t } = useTranslation();

    return (
        <Grid container sx={{ p: 1 }} dir={direction}>
            <Grid xs={6} item sx={{ p: 1 }}>
                <Typography color="rgb(230, 26, 128)" variant="h4">
                    {title}
                </Typography>
            </Grid>
            <Grid xs={6} item sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton onClick={() => onCloseClick()}>
                    <CloseIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export default DrawerTopbar;
