import yupx from 'src/locales/yupLocale';
import { ComponentType, UserLocale, VariableType } from 'src/types';

export interface SallaTemplatePlaceholder {
    index: number;
    sallaEventCategoryFieldId: string;
    componentType: ComponentType;
}

export interface SubmitSallaTemplateInputs {
    id: string;
    metaTemplateId: string;
    sallaEventId: string;
    locale: UserLocale;
    placeholders: SallaTemplatePlaceholder[];
}

export const schema = yupx.object().shape({
    metaTemplateId: yupx.string().required().trim().label('campaigns.yup.submit.template_name'),
    locale: yupx.string().required(),
    placeholders: yupx.array().of(
        yupx.object().shape({
            index: yupx.string().required(),
            sallaEventCategoryFieldId: yupx.string().required().label('salla.yup.placeholder'),
            componentType: yupx.string()
        })
    )
});
