import { ListItemButton, ListItemText, styled } from '@mui/material';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { DrawerContext } from 'src/contexts/DrawerContext';

interface Props {
    title: string;
    icon?: ReactNode;
    actions?: ReactNode | ReactNode[];
    to: string;
    onClick?: () => void;
    disabled?: boolean;
}

export function XSidebarListItem(props: Props) {
    const [active, setActive] = useState(false);
    const location = useLocation();
    const { closeDrawer } = useContext(DrawerContext);

    useEffect(() => {
        setActive(window.location.pathname.lastIndexOf(props.to) > -1);
    }, [location]);

    return (
        <ListItemButton
            sx={{ mt: 1 }}
            component={NavLink}
            onClick={() => {
                closeDrawer();
                if (props.onClick) props.onClick();
            }}
            to={props.to}
            selected={active}
            disabled={props.disabled}
        >
            {props.icon}
            <ListItemText
                sx={{
                    mr: 1,
                    ml: 1
                }}
                primaryTypographyProps={{
                    variant: 'subtitle1',
                    noWrap: true,
                    fontWeight: 'bold'
                }}
                primary={props.title}
            />
            {props.actions}
        </ListItemButton>
    );
}
