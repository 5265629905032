import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useNavigate } from 'react-router';
import { getContacts } from '../../slice';

const useAfterSubmit = () => {
    const { groupId } = useAppSelector((x) => x.groups.processed);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (groupId) {
            // dispatch(getContacts(groupId));
            navigate(`/groups/${groupId}/contacts`);
        }
    }, [groupId]);
};

export default useAfterSubmit;
