import { XSidebarListItem } from 'src/components';
import _ from 'lodash';
import { CampaignReport } from 'src/types';
import { format } from 'date-fns';

const Sidebar = ({ campaignReports }: { campaignReports: CampaignReport[] }) => {
    return (
        <>
            {campaignReports.map((x) => (
                <XSidebarListItem
                    key={x.campaignInstanceId}
                    title={format(new Date(x.publishedDate), 'PPpp')}
                    to={`${x.campaignInstanceId}`}
                />
            ))}
        </>
    );
};

export default Sidebar;
