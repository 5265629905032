import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { addBusiness, registerBusiness, updateBusiness } from '../slice';
import { AddBusinessInputs, registarationSchema, schema } from './schema';

const useSubmitForm = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const business = useAppSelector((x) => x.businesses.list.tableData.items.find((x) => x.id == id));
    const state = useAppSelector((x) => x.businesses.list.state);
    const userLoggedIn = useAppSelector((x) => x.account?.user?.email);
    const locale = useAppSelector((x) => x.account.user.locale);
    const navigate = useNavigate();

    useEffect(() => {
        if (id && state == undefined) navigate('/businesses');
    }, []);

    const methods = useForm<AddBusinessInputs>({
        resolver: yupResolver(userLoggedIn? schema: registarationSchema),
        defaultValues: business
            ? {
                  ...business,
                  adminFirstName: business.adminFirstName,
                  adminLastName: business.adminLastName,
                  businessName: business.name,
                  language: business.language,
                  whatsAppPhoneNumberId: business.whatsAppPhoneNumberId,
                  whatsAppBusinessAccountId: business.whatsappBusinessId,
                  whatsAppApplicationId: business.whatsAppApplicationId,
                  timeZone: 'Arab Standard Time',
                  whatsAppToken:
                      'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
              }
            : {
                  language: locale,
                  timeZone: 'Arab Standard Time'
              }
    });

    const onSubmit = (inputs: AddBusinessInputs) => {
        if (id) {
            dispatch(updateBusiness({ id, inputs }));
        }
        else if(!userLoggedIn)
        {
            dispatch(registerBusiness(inputs));
        }
        else dispatch(addBusiness(inputs));
    };

    return { userLoggedIn, editMode: id && true, methods, onSubmit };
};

export default useSubmitForm;
