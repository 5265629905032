import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { BotInterface } from 'src/types';
import { schema, FeedbackMessageInputs } from './schema';
import { updateFeedbackInterfaces } from 'src/screens/bot/slice';

const useSubmitForm = ({
    provideFeedbackInterface,
    feedbackProvidedInterface
}: {
    provideFeedbackInterface: BotInterface[];
    feedbackProvidedInterface: BotInterface[];
}) => {
    const dispatch = useAppDispatch();
    const methods = useForm<FeedbackMessageInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            provideFeedbackEnglish: provideFeedbackInterface.find((x) => x.locale == 'en')?.interactiveButtons?.text,
            provideFeedbackArabic: provideFeedbackInterface?.find((x) => x.locale == 'ar')?.interactiveButtons?.text,
            feedbackProvidedEnglish: feedbackProvidedInterface?.find((x) => x.locale == 'en')?.text,
            feedbackProvidedArabic: feedbackProvidedInterface?.find((x) => x.locale == 'ar')?.text
        }
    });

    const onSubmit = (inputs: FeedbackMessageInputs) => {
        dispatch(updateFeedbackInterfaces(inputs));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
