import { Alert, Box, Link, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { MediaPicker, SnackbarUtils } from 'src/components';
import { EmailCampaignInputs } from './schema';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { getRows } from 'src/utils/csvHelper';

const CsvFilePicker = () => {
    const dir = useAppSelector((x) => x.account.direction);
    const { t } = useTranslation();
    const {
        watch,
        setValue,
        formState: { errors }
    } = useFormContext<EmailCampaignInputs>();

    const val: any = watch(nameOf<EmailCampaignInputs>('csvFile'));
    const { items } = useAppSelector((x) => x.groups.list.tableData);

    const downloadSampleCSV = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        fetch('/static/sample-csv-emails.csv')
            .then((response) => response.text())
            .then((text) => {
                const url = window.URL.createObjectURL(new Blob([text], { type: 'text/csv' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'sample-csv-emails.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    const validateCsvData = (totalEmails: number, limit: number) => {
        if (totalEmails > limit) {
            return {
                isValid: false,
                message: t('groups.submit.limit_exceeds').replace('{}', limit.toString())
            };
        }
        return {
            isValid: true,
            message: null
        };
    };

    const handleMediaChange = async (link: any, type: string, name: string, file: File) => {
        try {
            const limit = process.env.REACT_APP_EMAIL_CAMPAIGN_MAX_RECIPIENTS;
            const lines = await getRows(file);
            const { isValid, message } = validateCsvData(lines.length, parseInt(limit));
            if (!isValid) {
                setValue(nameOf<EmailCampaignInputs>('csvFile'), undefined, {
                    shouldDirty: true,
                    shouldValidate: true
                });
                setValue(nameOf<EmailCampaignInputs>('recipients'), undefined, {
                    shouldDirty: true,
                    shouldValidate: true
                });
                return SnackbarUtils.error(message);
            }
            setValue(
                nameOf<EmailCampaignInputs>('csvFile'),
                { link, type, name },
                {
                    shouldDirty: true,
                    shouldValidate: true
                }
            );
            setValue(nameOf<EmailCampaignInputs>('recipients'), lines, {
                shouldDirty: true,
                shouldValidate: true
            });
        } catch (error) {
            return SnackbarUtils.error(error?.message || 'Error processing file:');
        }
    };
    return (
        <Box sx={{ pt: 1 }}>
            <Box sx={{ display: 'flex' }}>
                <Typography sx={{ py: 1 }} variant="subtitle1">
                    {t('emailCampaigns.compose.targetEmails')}
                </Typography>
                <Box sx={{ mr: dir == 'ltr' ? '' : 'auto', ml: dir == 'ltr' ? 'auto' : '' }}>
                    <Link
                        component="button"
                        variant="body2"
                        sx={{ fontSize: 'smaller', mb: '-25px' }}
                        onClick={downloadSampleCSV}
                    >
                        {t('groups.submit.download_sample')}
                    </Link>
                </Box>
            </Box>
            <Alert severity={val ? 'success' : 'info'}>
                <MediaPicker
                    label={t('emailCampaigns.compose.selectTargetsfile')}
                    accept=".csv"
                    onMediaSelected={handleMediaChange}
                    uniqueId="email-targets-file-picker"
                ></MediaPicker>
                <FormHelperText sx={{ color: 'primary.main' }}>{val?.name}</FormHelperText>
            </Alert>
            <FormHelperText error>{errors?.csvFile?.message}</FormHelperText>
        </Box>
    );
};

export default CsvFilePicker;
