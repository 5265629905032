import { useTranslation } from 'react-i18next';
import { Box, Card } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { XComponentContainer, XPageContainer, XSidebarListItem } from 'src/components';
import LockIcon from '@mui/icons-material/Lock';
import _ from 'lodash';
import { Outlet } from 'react-router';
import { useState } from 'react';

function Account() {
    const { t } = useTranslation();
    const [title, setTitle] = useState('');

    const menu = [
        {
            to: 'profile',
            icon: <AccountCircleOutlinedIcon fontSize="medium" color="secondary" />,
            text: t('layout.header.user_dropdown.profile')
        },
        {
            to: 'password',
            icon: <LockIcon fontSize="medium" color="secondary" />,
            text: t('account.password.title')
        }
    ];

    return (
        <XPageContainer
            sideBar={{
                content: (
                    <>
                        {menu.map((x) => {
                            return (
                                <XSidebarListItem
                                    onClick={() => setTitle(x.text)}
                                    key={x.text}
                                    title={x.text}
                                    icon={x.icon}
                                    to={x.to}
                                />
                            );
                        })}
                    </>
                ),
                title: 'Settings'
            }}
            topBar={{
                action: <></>,
                title: title
            }}
        >
            <XComponentContainer>
                <Box sx={{ p: 1, height: '100%' }}>
                    <Outlet />
                </Box>
            </XComponentContainer>
        </XPageContainer>
    );
}

export default Account;
