import { useEffect } from 'react';
import { resetActionState } from 'src/screens/bot/slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

const useAfterSubmit = () => {
    const dispatch = useAppDispatch();
    const { submitState } = useAppSelector((x) => x.businessBot);
    useEffect(() => {
        if (submitState === 'successful') {
            dispatch(resetActionState());
        }
    }, [submitState, dispatch, resetActionState]);
};

export default useAfterSubmit;
