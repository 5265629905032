import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { BotInterface, Action } from 'src/types';
import { schema, EditorInputs } from './schema';
import { addOption, updateOption } from '../../slice';
import { useEffect } from 'react';
import { nameOf } from 'src/utils/utils.shared';

const useSubmitForm = (botInterface: BotInterface, option: Action) => {
    const dispatch = useAppDispatch();
    const methods = useForm<EditorInputs>({
        resolver: yupResolver(schema),
        defaultValues: getDefaultValues(botInterface, option)
    });
    const onSubmit = (inputs: EditorInputs) => {
        if (inputs.optionId) {
            dispatch(updateOption(inputs));
        } else {
            dispatch(addOption(inputs));
        }
    };

    useEffect(() => {
        const defaultValues = getDefaultValues(botInterface, option);
        methods.setValue(nameOf('actions'), defaultValues.actions);
        methods.setValue(nameOf('body'), defaultValues.body);
        methods.setValue(nameOf('flag'), defaultValues.flag);
        methods.setValue(nameOf('header'), defaultValues.header);
        methods.setValue(nameOf('locale'), defaultValues.locale);
        methods.setValue(nameOf('media'), defaultValues.media);
        methods.setValue(nameOf('optionId'), defaultValues.optionId);
        methods.setValue(nameOf('products'), defaultValues.products);
        methods.setValue(nameOf('title'), defaultValues.title);
    }, [botInterface, option]);

    return { methods, onSubmit, getDefaultValues };
};

function getDefaultValues(botInterface: BotInterface, option: Action) {
    let defaultValues: EditorInputs = {
        optionId: option?.id,
        header: '',
        actions: [],
        products: [],
        body: botInterface?.text,
        title: option?.title,
        locale: botInterface?.locale,
        flag: 'nonProduct'
    };

    if (botInterface.interfaceType == 'InteractiveButtons') {
        defaultValues.body = botInterface.interactiveButtons.text;
        defaultValues.actions = botInterface.interactiveButtons?.replyButtons;
        defaultValues.media = botInterface.interactiveButtons.media
            ? {
                  link: botInterface.interactiveButtons.media.link,
                  name: '',
                  type: botInterface.interactiveButtons.media.type
              }
            : undefined;
    } else if (botInterface.interfaceType == 'MediaWithCaption') {
        defaultValues.body = botInterface.mediaWithCaption.caption;
        defaultValues.media = {
            link: botInterface.mediaWithCaption.media.link,
            name: '',
            type: botInterface.mediaWithCaption.media.type
        };
    } else if (botInterface.interfaceType == 'SingleProduct') {
        defaultValues.body = botInterface.singleProduct.body;
        defaultValues.products = [botInterface.singleProduct.product];
    } else if (botInterface.interfaceType == 'MultiProduct') {
        defaultValues.body = botInterface.multiProduct.body;
        defaultValues.header = botInterface.multiProduct.header;
        defaultValues.products = botInterface.multiProduct.products;
    }

    return defaultValues;
}

export default useSubmitForm;
