import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { DrawerContext } from 'src/contexts/DrawerContext';
import { useAppSelector } from 'src/store/hooks';

import { Box, Drawer, alpha, styled, useTheme, lighten, darken } from '@mui/material';

import UserOptions from './UserOptions';
import SidebarBottom from './SidebarBottom';

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
`
);

function Sidebar() {
    const { direction } = useAppSelector((state) => state.account);
    const { currentDrawer, closeDrawer } = useContext(DrawerContext);
    const theme = useTheme();

    return (
        <>
            <SidebarWrapper
                sx={{
                    display: {
                        xs: 'none',
                        lg: 'inline-block'
                    },
                    position: 'fixed',
                    left: direction === 'ltr' ? 0 : 'auto',
                    right: direction === 'ltr' ? 'auto' : 0,
                    top: 0,
                    borderRight: `${theme.colors.secondary.light} 1px solid`,
                    borderLeft: `${theme.colors.secondary.light} 1px solid`,
                    background: alpha(lighten(theme.header.background, 0.1), 0.5),
                    boxShadow: theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
                }}
            >
                <Scrollbar>
                    <UserOptions />
                    <SidebarBottom />
                </Scrollbar>
            </SidebarWrapper>
            <Drawer
                sx={{
                    boxShadow: `${theme.sidebar.boxShadow}`
                }}
                anchor={direction === 'rtl' ? 'right' : 'left'}
                open={currentDrawer == 'Drawer1'}
                onClose={closeDrawer}
                variant="temporary"
                elevation={9}
            >
                <SidebarWrapper
                    sx={{
                        background:
                            theme.palette.mode === 'dark'
                                ? theme.colors.alpha.white[100]
                                : darken(theme.colors.alpha.black[100], 0.5)
                    }}
                >
                    <Scrollbar>
                        <UserOptions />
                        <SidebarBottom />
                    </Scrollbar>
                </SidebarWrapper>
            </Drawer>
        </>
    );
}

export default Sidebar;
