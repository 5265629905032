import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Alert, Box, Button } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import Products from 'src/screens/bot/products';
import { EditorInputs } from '../../schema';
import Body from '../../Body';
import { useTranslation } from 'react-i18next';
import StorefrontIcon from '@mui/icons-material/AssignmentTurnedIn';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SingleProduct() {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const {
        watch,
        formState: { errors }
    } = useFormContext<EditorInputs>();
    const products = watch(nameOf<EditorInputs>('products')) as { retailerId: string; name: string }[];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box>
            <Alert severity="success" sx={{ mb: 1 }}>
                <Typography component={'div'}>{t('bot.options.editor.share_entire_catalog')}</Typography>
                <Button variant="outlined" onClick={handleClickOpen} size="small" sx={{ mt: 1 }}>
                    {t('bot.options.editor.select_product')}
                </Button>
                <Typography color={'error'}>{t(errors?.products?.message)}</Typography>
                {products?.length == 1 && (
                    <Typography sx={{ display: 'flex', mt: 2 }} component={'div'} variant="caption">
                        <StorefrontIcon color="warning"></StorefrontIcon>
                        {products[0].name}
                    </Typography>
                )}
            </Alert>
            <Body></Body>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative', p: 1 }} color="default">
                    <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
                            Select one or more products
                        </Typography>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Products></Products>
            </Dialog>
        </Box>
    );
}
