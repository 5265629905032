import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { BusinessService } from 'src/services/business.service';
import { RootState } from 'src/store';
import { Business } from 'src/types';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import { AddBusinessInputs } from './submit/schema';
import i18next from 'i18next';
import { WhatsaapInfoInputs } from './whatsapInfo/schema';

const service = new BusinessService();

export interface SliceState {
    list: {
        state: ActionState;
        tableData: TableData<Business>;
    };
    submit: {
        state: ActionState;
    };
    register: {
        state: ActionState;
    };
}

const initialState: SliceState = {
    list: {
        state: undefined,
        tableData: DefaultTableData()
    },
    submit: {
        state: undefined
    },
    register: {
        state: undefined
    }
};

export const getBusinesses = createAsyncThunk('GET_BUSINESSES', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.businesses.list.tableData;
    const response = await service.getBusinesses(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addBusiness = createAsyncThunk('ADD_BUSINESSES', async (inputs: AddBusinessInputs, thunkAPI) => {
    const response = await service.addBusiness(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateBusiness = createAsyncThunk(
    'UPDATE_BUSINESSES',
    async ({ id, inputs }: { id: string; inputs: AddBusinessInputs }, thunkAPI) => {
        const response = await service.updateBusiness(id, inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateWhatsAppInfo = createAsyncThunk(
    'UPDATE_WHATSAPP_INFO',
    async (inputs: WhatsaapInfoInputs, thunkAPI) => {
        const response = await service.updateWhatsAppInfo(inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const registerBusiness = createAsyncThunk(
    'REGISTER_BUSINESS',
    async (inputs: AddBusinessInputs, thunkAPI) => {
        const response = await service.registerBusiness(inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const resendEmail = createAsyncThunk('RESEND_BUSINESS_EMAIL', async (id: string, thunkAPI) => {
    const response = await service.resendEmail(id);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

const slice = createSlice({
    name: 'busineses',
    initialState,
    reducers: {
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.list.tableData.page = action.payload.page;
            state.list.tableData.limit = action.payload.limit;
        },
        resetRegistration: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.register.state = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getBusinesses.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(getBusinesses.fulfilled, (state, action) => {
            state.list.tableData = action.payload;
            state.list.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getBusinesses.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('business.slice.errors.fetch_businesses'));
        });

        builder.addCase(addBusiness.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(addBusiness.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('business.slice.success.business_added'));
            state.submit.state = 'successful';
        });
        builder.addCase(addBusiness.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('business.slice.errors.add_business'));
        });

        builder.addCase(updateBusiness.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(updateBusiness.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('business.slice.success.business_updated'));
            state.submit.state = 'successful';
        });
        builder.addCase(updateBusiness.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('business.slice.errors.update_business'));
        });

        builder.addCase(updateWhatsAppInfo.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(updateWhatsAppInfo.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('business.slice.success.whatsapp_info'));
            state.submit.state = 'successful';
        });
        builder.addCase(updateWhatsAppInfo.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('business.slice.errors.whatsapp_info'));
        });

        builder.addCase(registerBusiness.pending, (state) => {
            state.register.state = 'inProgress';
        });
        builder.addCase(registerBusiness.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('business.slice.success.register'));
            state.register.state = 'successful';
        });
        builder.addCase(registerBusiness.rejected, (state, action) => {
            state.register.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('business.slice.errors.register'));
        });

        builder.addCase(resendEmail.pending, () => {});
        builder.addCase(resendEmail.fulfilled, () => {
            SnackbarUtils.success(i18next.t('business.slice.success.resend_email'));
        });
        builder.addCase(resendEmail.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('business.slice.errors.resend_email'));
        });
    }
});

export const { setPaging, resetRegistration } = slice.actions;

export default slice.reducer;
