import { Product } from 'src/types';
import { TableCell, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Check from './Check';

const Item = ({ product }: { product: Product }) => {
    const { t } = useTranslation();
    return (
        <TableRow hover>
            <TableCell padding="normal">
                <a rel="noopener noreferrer" target="_blank" href={product.url}>
                    <img style={{ height: '50px' }} src={product.imageUrl}></img>
                </a>
            </TableCell>
            <TableCell padding="normal">{product.name}</TableCell>
            <TableCell padding="normal">{product.inventory}</TableCell>
            <TableCell padding="normal">{product.price}</TableCell>
            <TableCell padding="normal" align="right">
                <Check retailerId={product.retailerId} name={product.name}></Check>
            </TableCell>
        </TableRow>
    );
};
export default Item;
