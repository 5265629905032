import { ApiResponse, App } from 'src/types';
import apiClient from './apiClient';

const appId = process.env.REACT_APP_SALLA_APP_ID;

export class AppService {
    async getApps(): Promise<ApiResponse<App[]>> {
        const { data } = await apiClient.get<ApiResponse<App[]>>('/apps');
        return data;
    }

    async installSalla(email: string): Promise<ApiResponse<App>> {
        const { data } = await apiClient.put<ApiResponse<App>>('/apps/salla', {
            email
        });
        return data;
    }

    redirectToSalla() {
        const newWindow = window.open(`https://s.salla.sa/apps/install/${appId}`, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }
}
