import { RouteObject } from 'react-router';
import Groups from 'src/screens/groups';
import Contacts from 'src/screens/groups/content/contacts';
import SubmitGroup from 'src/screens/groups/content/submitGroup';
import Rename from 'src/screens/groups/content/rename';
import SidebarLayout from 'src/layouts/SidebarLayout';
import SubmitContact from 'src/screens/groups/content/submitContact';

const groupRoutes: RouteObject = {
    path: 'groups',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <Groups />
        },
        {
            path: '',
            element: <Groups />,
            children: [
                {
                    path: 'new',
                    element: <SubmitGroup />
                },
                {
                    path: ':id',
                    element: <SubmitGroup />
                },
                {
                    path: ':id/contacts',
                    element: <Contacts />
                },
                {
                    path: ':id/contacts/add',
                    element: <SubmitContact />
                },
                {
                    path: ':id/rename',
                    element: <Rename />
                }
            ]
        }
    ]
};

export default groupRoutes;
