import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CampaignInterfaceInputs } from './schema';
import { XToolbar } from 'src/components';
import { useRef } from 'react';
import Teams from './Teams';

const CustomInterface = () => {
    const { t } = useTranslation();
    const {
        register,
        watch,
        setValue,
        formState: { errors }
    } = useFormContext<CampaignInterfaceInputs>();

    const inputRef = useRef<HTMLInputElement>(null);
    const error = errors?.campaignInterface?.message;
    const isBotInterface = watch('campaignInterface.isBotInterface') as boolean;

    return (
        <>
            {!isBotInterface && (
                <>
                    <TextField
                        inputRef={inputRef}
                        sx={{ pb: 1, mt: 2 }}
                        fullWidth
                        error={error && error['message']}
                        name={`campaignInterface.message`}
                        {...register(`campaignInterface.message`)}
                        placeholder={t('conversations.quickReplies.list.head.message')}
                        size="medium"
                        type="text"
                        multiline
                        rows={5}
                        helperText={error && error['message']}
                    />
                    <XToolbar
                        emoji={{
                            placement: 'bottom',
                            onEmojiSelect: (newMsg: string) => {
                                setValue('campaignInterface.message', newMsg, {
                                    shouldDirty: true
                                });
                            }
                        }}
                        placeHolder={{
                            onPlaceHolderSelect: (newMsg: string) => {
                                setValue('campaignInterface.message', newMsg, {
                                    shouldDirty: true
                                });
                            }
                        }}
                        inputRef={inputRef}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                        <Teams />
                    </Box>
                </>
            )}
        </>
    );
};

export default CustomInterface;
