import yupx from 'src/locales/yupLocale';
import { MediaType, TemplateCategory } from 'src/types';

export type ButtonGroupType = 'NONE' | 'CALL_TO_ACTION_GROUP' | 'QUICK_REPLY_GROUP';
export type CallToActionType = 'CALL_PHONE_NUMBER' | 'VISIT_WEBSITE';
export type CallToActionUrlType = 'STATIC' | 'DYNAMIC';
export type QuickReplyType = 'CUSTOM' | 'MARKETING_OPT_OUT';
export type HeaderType = 'NONE' | 'TEXT' | 'MEDIA';

export interface SubmitTemplateInputs {
    id: string;
    name: string;
    templateType: TemplateCategory;
    languageCode: string;
    header: {
        type: HeaderType;
        text: string;
        textSample: string;
        mediaType: MediaType;
        mediaSample: string;
    };
    body: {
        text: string;
        variables: { index: number; sample: string }[];
    };
    footer: string;
    buttons: {
        buttonGroupType: ButtonGroupType;
        callToactions: {
            type: CallToActionType;
            buttonText: string;
            countryCode: string;
            phoneNumber: string;
            urlType: CallToActionUrlType;
            website: string;
            websiteSample: string;
        }[];
        quickReplies: {
            type: QuickReplyType;
            buttonText: string;
            footer: string;
        }[];
    };
}

const headerSchema = yupx.object().shape({
    text: yupx.string().when('type', ([type], schema) => {
        if ((type as HeaderType) == 'TEXT') {
            return schema.required().max(1024).label('templates.yup.submit.header.text');
        }
        return schema.notRequired();
    }),
    textSample: yupx.string().when(['text', 'type'], ([text, type], schema) => {
        if ((type as HeaderType) == 'TEXT' && text?.toString()?.indexOf('{{1}}') != -1) {
            return schema.required().label('templates.yup.submit.header.text_sample');
        }
        return schema.notRequired();
    }),
    mediaType: yupx.string().when('type', ([type], schema) => {
        if ((type as HeaderType) == 'MEDIA') {
            return schema.required().label('templates.yup.submit.header.media_type');
        }
        return schema.notRequired();
    }),
    mediaSample: yupx.string().when('type', ([type], schema) => {
        if ((type as HeaderType) == 'MEDIA') {
            return schema.required().label('templates.yup.submit.header.media_sample');
        }
        return schema.notRequired();
    })
});

const bodySchema = yupx.object().shape({
    text: yupx.string().required().max(1024).label('templates.yup.submit.body.text'),
    variables: yupx.array().of(
        yupx.object().shape({
            index: yupx.number().required().label('templates.yup.submit.body.variables.index'),
            sample: yupx.string().required().label('templates.yup.submit.body.variables.sample')
        })
    )
});

const buttonsSchema = yupx.object().shape({
    quickReplies: yupx.array().of(
        yupx.object().shape({
            type: yupx.string().required().label('templates.yup.submit.buttons.quick_replies.type'),
            buttonText: yupx
                .string()
                .max(25)
                .required()
                .label('templates.yup.submit.buttons.quick_replies.button_text'),
            footer: yupx.string().when('type', ([type], schema) => {
                if ((type as QuickReplyType) == 'MARKETING_OPT_OUT') {
                    return schema.required().label('templates.yup.submit.buttons.quick_replies.footer');
                }
                return schema.notRequired();
            })
        })
    ),
    callToactions: yupx.array().of(
        yupx.object().shape({
            type: yupx.string().required().label('templates.yup.submit.buttons.call_to_actions.type'),
            buttonText: yupx
                .string()
                .max(25)
                .required()
                .label('templates.yup.submit.buttons.call_to_actions.button_text'),
            phoneNumber: yupx.string().when('type', ([type], schema) => {
                if ((type as CallToActionType) == 'CALL_PHONE_NUMBER') {
                    return schema.required().label('templates.yup.submit.buttons.call_to_actions.phone_number');
                }
                return schema.notRequired();
            }),
            website: yupx.string().when('type', ([type], schema) => {
                if ((type as CallToActionType) == 'VISIT_WEBSITE') {
                    return schema.required().label('templates.yup.submit.buttons.call_to_actions.website');
                }
                return schema.notRequired();
            }),
            websiteSample: yupx.string().when('urlType', ([type], schema) => {
                if ((type as CallToActionUrlType) == 'DYNAMIC') {
                    return schema.required().label('templates.yup.submit.buttons.call_to_actions.website_sample');
                }
                return schema.notRequired();
            })
        })
    )
});

export const schema = yupx.object().shape({
    templateType: yupx.string().required().label('templates.yup.submit.template_type'),
    name: yupx
        .string()
        .required()
        .max(512)
        .label('templates.yup.submit.name')
        .matches(/^[a-z0-9]+$/)
        .label('templates.yup.submit.name'),
    languageCode: yupx.string().required().label('templates.yup.submit.language_code'),
    header: headerSchema,
    body: bodySchema,
    footer: yupx.string().notRequired().max(1024),
    buttons: buttonsSchema
});
