import yupx from 'src/locales/yupLocale';

export interface CommonInputs {
    english: string;
    arabic: string;
    api: string;
}

export const schema = yupx.object().shape({
    english: yupx.string().required().trim().label('bot.yup.settings.common_form.english'),
    arabic: yupx.string().required().trim().label('bot.yup.settings.common_form.arabic'),
    api: yupx.string().required().trim().label('bot.yup.settings.common_form.api')
});
