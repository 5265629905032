import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { TeamsService } from 'src/services/teams.service';
import { RootState } from 'src/store';
import { Team, User } from 'src/types';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import { AddTeamInputs } from './content/submit/schema';
import i18next from 'i18next';
import { xor } from 'lodash';

const service = new TeamsService();

export interface SliceState {
    list: {
        state: ActionState;
        tableData: TableData<Team>;
    };
    userlist: {
        state: ActionState;
        tableData: TableData<User>;
    };
    submit: {
        state: ActionState;
    };
}

const initialState: SliceState = {
    list: {
        state: undefined,
        tableData: DefaultTableData()
    },
    userlist: {
        state: undefined,
        tableData: DefaultTableData()
    },
    submit: {
        state: undefined
    }
};

export const getTeams = createAsyncThunk('GET_TEAMS', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.teams.list.tableData;
    const response = await service.getTeams(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getTeam = createAsyncThunk('GET_TEAM', async (id: string, thunkAPI) => {
    const response = await service.getTeam(id);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addTeam = createAsyncThunk('ADD_TEAM', async (inputs: AddTeamInputs, thunkAPI) => {
    const response = await service.addTeam(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateTeam = createAsyncThunk(
    'UPDATE_TEAM',
    async ({ id, inputs }: { id: string; inputs: AddTeamInputs }, thunkAPI) => {
        const response = await service.updateTeam(id, inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const addUserToTeam = createAsyncThunk(
    'ADD_USER_TO_TEAM',
    async ({ userId, teamId }: { userId: string; teamId: string }, thunkAPI) => {
        const response = await service.addUsersToTeam(teamId, [userId]);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const removeUserFromTeam = createAsyncThunk(
    'REMOVE_USER_FROM_TEAM',
    async ({ userId, teamId }: { userId: string; teamId: string }, thunkAPI) => {
        const response = await service.removeUserFromTeam(teamId, userId);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const deleteTeam = createAsyncThunk('DELETE_TEAM', async (teamId: string, thunkAPI) => {
    const response = await service.deleteTeam(teamId);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getUsers = createAsyncThunk(
    'GET_USERS',
    async ({ teamId, isMember }: { teamId: string; isMember: boolean }, thunkAPI) => {
        const state = thunkAPI.getState() as RootState;
        const { page, limit } = state.users.list.tableData;
        const response = await service.getUsers(teamId, isMember, page, limit);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

const slice = createSlice({
    name: 'teams',
    initialState,
    reducers: {
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.list.tableData.page = action.payload.page;
            state.list.tableData.limit = action.payload.limit;
        },

        setUsersPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.userlist.tableData.page = action.payload.page;
            state.userlist.tableData.limit = action.payload.limit;
        }
    },
    extraReducers(builder) {
        builder.addCase(getTeams.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(getTeams.fulfilled, (state, action) => {
            state.list.tableData = action.payload;
            state.list.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getTeams.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.fetch_teams'));
        });

        builder.addCase(getTeam.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(getTeam.fulfilled, (state, action) => {
            state.list.tableData.items = state.list.tableData.items.map((x) =>
                x.id == action.payload.id ? { ...action.payload, userCount: x.userCount } : x
            );
            state.list.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getTeam.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.fetch_team'));
        });

        builder.addCase(getUsers.pending, (state) => {
            state.userlist.state = 'inProgress';
        });
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.userlist.tableData = action.payload;
            state.userlist.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getUsers.rejected, (state, action) => {
            state.userlist.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.fetch_teams'));
        });

        builder.addCase(addTeam.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(addTeam.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('team.slice.success.team_added'));
            state.submit.state = 'successful';
        });
        builder.addCase(addTeam.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.add_team'));
        });

        builder.addCase(updateTeam.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(updateTeam.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('team.slice.success.team_updated'));
            state.submit.state = 'successful';
        });
        builder.addCase(updateTeam.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.update_team'));
        });

        builder.addCase(addUserToTeam.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(addUserToTeam.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('team.slice.success.user_added'));
            state.submit.state = 'successful';
        });
        builder.addCase(addUserToTeam.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.add_user'));
        });

        builder.addCase(removeUserFromTeam.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(removeUserFromTeam.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('team.slice.success.user_removed'));
            state.submit.state = 'successful';
        });
        builder.addCase(removeUserFromTeam.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.delete_user'));
        });

        builder.addCase(deleteTeam.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(deleteTeam.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('team.slice.success.team_deleted'));
            state.submit.state = 'successful';
        });
        builder.addCase(deleteTeam.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.delete_team'));
        });
    }
});

export const { setPaging, setUsersPaging } = slice.actions;

export default slice.reducer;
