import { Checkbox } from '@mui/material';
import { nameOf } from 'src/utils/utils.shared';
import { useFormContext } from 'react-hook-form';
import _ from 'lodash';
import { EditorInputs } from '../../options/content/schema';

const Check = ({ retailerId, name }: { retailerId: string; name: string }) => {
    const { watch, setValue } = useFormContext<EditorInputs>();

    const products = watch(nameOf<EditorInputs>('products')) as { retailerId: string; name: string }[];
    return (
        <Checkbox
            defaultChecked={_.some(products, (x) => x.retailerId == retailerId)}
            value={retailerId}
            onChange={(e, checked) => {
                if (checked) {
                    setValue(nameOf<EditorInputs>('products'), [...products, { retailerId, name }], {
                        shouldDirty: true,
                        shouldValidate: true
                    });
                } else {
                    setValue(nameOf<EditorInputs>('products'), _.without(products, { retailerId, name }), {
                        shouldDirty: true,
                        shouldValidate: true
                    });
                }
            }}
        />
    );
};

export default Check;
