import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { SnackbarUtils } from 'src/components';
import { AppService } from 'src/services/app.service';
import { ActionState, App } from 'src/types';

const service = new AppService();

export interface SliceState {
    state: ActionState;
    apps: App[];
}

const initialState: SliceState = {
    state: undefined,
    apps: []
};

export const getApps = createAsyncThunk('GET_APPS', async (_, thunkAPI) => {
    const response = await service.getApps();
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const installSalla = createAsyncThunk('ADD_SALLA', async ({ email }: { email: string }, thunkAPI) => {
    const response = await service.installSalla(email);
    if (response.status === 'Successful') {
        return {
            email: email
        };
    }
    return thunkAPI.rejectWithValue(response.message);
});

const slice = createSlice({
    name: 'apps',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getApps.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(getApps.fulfilled, (state, action) => {
            state.apps = action.payload;
            state.state = 'successful';
        });
        builder.addCase(getApps.rejected, (state) => {
            state.state = 'successful';
        });

        builder.addCase(installSalla.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(installSalla.fulfilled, (state, action) => {
            let app = state.apps.find((x) => x.app == 'Salla');
            if (app) {
                app.email = action.payload.email;
            }
            state.state = 'successful';
            SnackbarUtils.success(t('apps.salla.slice.success.add_email'));
        });
        builder.addCase(installSalla.rejected, (state, action) => {
            state.state = 'successful';
            SnackbarUtils.error(action?.payload?.toString() ?? t('apps.salla.slice.error.add_email'));
        });
    }
});
export default slice.reducer;
