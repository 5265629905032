import { OutlinedInput, InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import { SubmitTemplateInputs } from './schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function TemplateType() {
    const { t } = useTranslation();
    const {
        register,
        watch,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const types = ['', 'MARKETING', 'AUTHENTICATION', 'UTILITY'];
    const templateType = watch('templateType');

    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>{t('templates.submit.templateType')}</InputLabel>
            <Select
                defaultValue={defaultValues?.templateType ?? ''}
                error={errors?.templateType && true}
                {...register('templateType')}
                id="templateType"
                size="medium"
                input={<OutlinedInput label={t('templates.submit.templateType')} />}
            >
                {types.map((x, i) =>
                    x == '' ? (
                        <MenuItem value={x} key={i}>
                            <em>{t('common.drop_downs.select')}</em>
                        </MenuItem>
                    ) : (
                        <MenuItem key={i} value={x}>
                            {x}
                        </MenuItem>
                    )
                )}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors?.templateType?.message}</FormHelperText>
        </FormControl>
    );
}
