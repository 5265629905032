import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { AddBusinessInputs } from './schema';
import { useState } from 'react';
import HelperIcon from '../whatsapInfo/HelperIcon';

const WhatsAppSecret = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<AddBusinessInputs>();

    return (
        <Box sx={{ display: 'flex' }}>
            <TextField
                fullWidth
                error={errors?.whatsAppSecret && true}
                {...register(nameOf<AddBusinessInputs>('whatsAppSecret'))}
                id={nameOf<AddBusinessInputs>('whatsAppSecret')}
                placeholder={t('business.whatsApp_info.new_app_secret')}
                label={t('business.whatsApp_info.new_app_secret')}
                size="medium"
                type="text"
                helperText={errors?.whatsAppSecret?.message}
            />
            <HelperIcon imageName="secret" />
        </Box>
    );
};

export default WhatsAppSecret;
