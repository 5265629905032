import * as yup from 'yup';

export interface SetPasswordInputs {
    userId: string;
    token: string;
    password: string;
    reset: boolean;
}

export const schema = yup.object().shape({
    userId: yup.string().required().label('account.yup.verify.user_id'),
    token: yup.string().required().label('account.yup.verify.token'),
    password: yup.string().required().label('account.yup.verify.password')
});
