import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { MultiProduct } from 'src/types';
import Thumbnail from './Thumbnail';
import { t } from 'i18next';

const MultiProductPreview = ({ message }: { message: MultiProduct }) => {
    return (
        <>
            <Grid container spacing={0.2}>
                <Grid item xs={4}>
                    <Thumbnail content={message?.products[0]?.imageUrl} type="Image" />
                </Grid>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Box>
                        {t('conversations.chatWindow.preview.multi_product.body').replace(
                            '{0}',
                            message?.products?.length?.toString()
                        )}
                    </Box>
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'end' }}>
                    {message?.header}
                </Grid>
                <Grid item>
                    <Typography color="primary">{message?.body}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                    <Box sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
                        <Button size="small">{t('conversations.chatWindow.preview.multi_product.footer')}</Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default MultiProductPreview;
