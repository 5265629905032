import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';
import { SnackbarUtils } from 'src/components';
import { SallaService } from 'src/services/salla.service';
import { ActionState, SallaEventCategory, SallaEvent, SallaEventCategoryField, SallaEventTemplate } from 'src/types';
import { SubmitSallaTemplateInputs } from './event-setup/schema';

const service = new SallaService();

export interface SliceState {
    state: ActionState;
    sallaEventCategories: SallaEventCategory[];
    sallaEvents: SallaEvent[];
    sallaEventCategoryFields: SallaEventCategoryField[];
    sallaEventTemplates: SallaEventTemplate[];
}

const initialState: SliceState = {
    state: undefined,
    sallaEventCategories: [],
    sallaEvents: [],
    sallaEventCategoryFields: [],
    sallaEventTemplates: []
};

export const getEventCategories = createAsyncThunk('GET_CATEGORIES', async (_, thunkAPI) => {
    const response = await service.getCategories();
    if (response.status == 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getCategoryFields = createAsyncThunk('GET_CATEGORY_FIELDS', async (_, thunkAPI) => {
    const response = await service.getCategoryFields();
    if (response.status == 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getEvents = createAsyncThunk('GET_EVENTS', async (_, thunkAPI) => {
    const response = await service.getEvents();
    if (response.status == 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getEventsTemplates = createAsyncThunk('GET_EVENT_TEMPLATES', async (_, thunkAPI) => {
    const response = await service.getEventsTemplates();
    if (response.status == 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const setTemplate = createAsyncThunk(
    'SET_SALLA_TEMPLATE',
    async (inputs: SubmitSallaTemplateInputs, thunkAPI) => {
        const response = await service.setTemplate(inputs);
        if (response.status === 'Successful') {
            thunkAPI.dispatch(getEventCategories());
            thunkAPI.dispatch(getEvents());
            thunkAPI.dispatch(getEventsTemplates());
            return response.data;
        }
        return thunkAPI.rejectWithValue(response.message);
    }
);

const slice = createSlice({
    name: 'salla',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getEventCategories.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(getEventCategories.fulfilled, (state, action) => {
            state.state = 'successful';
            state.sallaEventCategories = action.payload;
        });
        builder.addCase(getEventCategories.rejected, (state, action) => {
            state.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? t('salla.slice.error.get_categories'));
        });

        builder.addCase(getEvents.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(getEvents.fulfilled, (state, action) => {
            state.state = 'successful';
            state.sallaEvents = action.payload;
        });
        builder.addCase(getEvents.rejected, (state, action) => {
            state.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? t('salla.slice.error.get_events'));
        });

        builder.addCase(getEventsTemplates.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(getEventsTemplates.fulfilled, (state, action) => {
            state.state = 'successful';
            state.sallaEventTemplates = action.payload;
        });
        builder.addCase(getEventsTemplates.rejected, (state, action) => {
            state.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? t('salla.slice.error.get_event_templates'));
        });

        builder.addCase(getCategoryFields.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(getCategoryFields.fulfilled, (state, action) => {
            state.state = 'successful';
            state.sallaEventCategoryFields = action.payload;
        });
        builder.addCase(getCategoryFields.rejected, (state, action) => {
            state.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? t('salla.slice.error.get_category_fields'));
        });

        builder.addCase(setTemplate.pending, (state) => {});
        builder.addCase(setTemplate.fulfilled, (state, action) => {
            SnackbarUtils.success(action?.payload?.toString() ?? t('salla.slice.success.set_template'));
        });
        builder.addCase(setTemplate.rejected, (state, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? t('salla.slice.error.set_template'));
        });
    }
});
export default slice.reducer;
