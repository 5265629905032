import { Product } from 'src/types';
import { TableCell, TableRow } from '@mui/material';

const Item = ({ product }: { product: Product }) => {
    return (
        <TableRow hover>
            <TableCell padding="normal" align="justify">
                <a rel="noopener noreferrer" target="_blank" href={product.url}>
                    <img style={{ height: '50px' }} src={product.imageUrl}></img>
                </a>
            </TableCell>
            <TableCell align="justify" padding="normal">
                {product.name}
            </TableCell>
            <TableCell align="justify" padding="normal">
                {product.inventory}
            </TableCell>
            <TableCell align="justify" padding="normal">
                {product.price}
            </TableCell>
        </TableRow>
    );
};
export default Item;
