import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { CampaignInterfaceInputs, schema } from './schema';
import { addCampaignAction } from '../slice';
import { TemplateAction } from 'src/types';

const useSubmitForm = (buttonName: string, templateAction: TemplateAction) => {
    const dispatch = useAppDispatch();

    const campaign = useAppSelector((x) => x.campaigns.current.value);
    const locale = useAppSelector((x) => x.account.user.locale);

    const methods = useForm<CampaignInterfaceInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            campaignId: campaign?.id,
            locale,
            buttonName: buttonName,
            campaignInterface: {
                interfaceId: templateAction?.targetInterfaceId,
                isBotInterface: templateAction?.targetInterfaceId && true,
                message: ''
            }
        }
    });

    const onSubmit = (inputs: CampaignInterfaceInputs) => {
        if (templateAction?.id) {
            alert('Coming soon');
        } else dispatch(addCampaignAction(inputs));
    };

    return { campaign, methods, onSubmit };
};

export default useSubmitForm;
