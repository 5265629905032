import MenuPreview from './MenuPreview';
import BodyPreview from './BodyPreview';
import { useState } from 'react';
import { SimpleFragment } from '../Fragments';

const ListPreview = ({
    title,
    body,
    options
}: {
    title: string;
    body: string;
    options: { id: string; title: string }[];
}) => {
    const [showList, setShowList] = useState(true);
    return (
        <SimpleFragment>
            {showList ? (
                <MenuPreview onCloseListClick={() => setShowList(false)} title={title} options={options}></MenuPreview>
            ) : (
                <BodyPreview onOpenListClick={() => setShowList(true)} title={title} body={body}></BodyPreview>
            )}
        </SimpleFragment>
    );
};

export default ListPreview;
