import { UserLocale } from 'src/types';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { useState } from 'react';

const radioStyle = {
    '&.MuiRadio-root': {
        py: 0
    }
};

export default function XLanguageSwitch({
    onLocaleChange,
    value,
    disabled
}: {
    disabled?: boolean;
    value: UserLocale;
    onLocaleChange: (value: UserLocale) => void;
}) {
    // const userLocale = useAppSelector((x) => x.account.user.locale);
    // const [value, setValue] = useState(userLocale.toString());
    const { t } = useTranslation();

    const onChange = (locale: UserLocale) => {
        // setValue(locale);
        onLocaleChange(locale);
    };
    return (
        <RadioGroup row name="locale">
            <FormControlLabel
                checked={value == 'en'}
                control={<Radio sx={radioStyle} disabled={disabled} onChange={() => onChange('en')} />}
                label={t('components.languageSwitch.english')}
            />
            <FormControlLabel
                checked={value == 'ar'}
                control={<Radio sx={radioStyle} disabled={disabled} onChange={() => onChange('ar')} />}
                label={t('components.languageSwitch.arabic')}
            />
        </RadioGroup>
    );
}
