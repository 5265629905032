import { OutlinedInput, InputLabel, MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import { SubmitTemplateInputs } from './schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { LanguageMappingsEn } from 'src/types';

export default function TemplateType() {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const languages: { label: string; value: string }[] = [
        { label: 'common.drop_downs.select', value: '' },
        { label: 'components.languageSwitch.english', value: 'en' },
        { label: 'components.languageSwitch.arabic', value: 'ar' }
    ];

    if (
        !_.find(languages, {
            value: defaultValues.languageCode
        })
    ) {
        languages.push({
            label: LanguageMappingsEn[defaultValues.languageCode],
            value: defaultValues.languageCode
        });
    }

    return (
        <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>{t('campaigns.list.head.language_code')}</InputLabel>
            <Select
                defaultValue={defaultValues?.languageCode ?? ''}
                error={errors?.languageCode && true}
                {...register('languageCode')}
                id="languageCode"
                size="medium"
                input={<OutlinedInput {...register('languageCode')} label={t('campaigns.list.head.language_code')} />}
            >
                {languages.map((x, i) =>
                    x.value == '' ? (
                        <MenuItem value={x.value} key={i}>
                            <em>{t('common.drop_downs.select')}</em>
                        </MenuItem>
                    ) : (
                        <MenuItem key={i} value={x.value}>
                            {t(x.label)}
                        </MenuItem>
                    )
                )}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors?.languageCode?.message}</FormHelperText>
        </FormControl>
    );
}
