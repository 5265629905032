import { Tokens, User } from 'src/types';
const key: string = 'tokens';
const maxRetryValue = 2;

class LocalTokenService {
    getTokens() {
        return JSON.parse(localStorage.getItem(key));
    }

    setTokens(tokens: Tokens) {
        tokens.retries = 0;
        localStorage.setItem(key, JSON.stringify(tokens));
    }

    addRetry() {
        let tokens = JSON.parse(localStorage.getItem(key));
        tokens.retries += 1;
        localStorage.setItem(key, JSON.stringify(tokens));
    }

    canRetry() {
        let tokens = JSON.parse(localStorage.getItem(key));
        if (!tokens) return false;
        return tokens.retries <= maxRetryValue;
    }

    deleteTokens() {
        localStorage.removeItem(key);
    }

    RefreshTokenExpired() {
        const tokens = JSON.parse(localStorage.getItem(key));
        if (!tokens || !tokens?.accessToken) return true;
        const parsed = this.parseAccessToken(tokens.accessToken);
        if (!parsed?.exp || !parsed.refreshTokenLifeTime) return true;
        const expDate = this.GetExpiryDate(parsed);
        return new Date() > expDate;
    }

    parseAccessToken(token: string): User {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        const parsed = JSON.parse(jsonPayload);
        return {
            id: parsed.nameid,
            firstName: parsed.firs_tname,
            lastName: parsed.last_name,
            email: parsed.email,
            businessId: parsed.business_id,
            businessName: parsed.business_name,
            locale: parsed.locale,
            role: parsed.role,
            exp: parsed.exp,
            refreshTokenLifeTime: parsed.refresh_token_lifetime,
            teams: parsed.team_ids.split(',')
        };
    }

    GetExpiryDate(parsedToken: any) {
        const expDate = new Date(parsedToken.exp * 1000);
        let newDateObj = new Date();
        newDateObj.setMinutes(expDate.getMinutes() + parsedToken.refreshTokenLifeTime);
        return newDateObj;
    }
}

export default new LocalTokenService();
