import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import { useTranslation } from 'react-i18next';
import SectionHeading from '../SectionHeading';
import { Box, Button, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const Text = ({ inputColor }: { inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        watch,
        setValue,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const body = watch('body');

    const onVariableAdd = () => {
        let count = 0;
        const variables = body.text.match(/{{(\d+)}}/g);
        if (variables) count = variables?.length;

        setValue(
            'body',
            {
                ...body,
                text: `${body.text} {{${count + 1}}}`
            },
            {
                shouldDirty: true
            }
        );
    };

    return (
        <>
            <SectionHeading
                title={t('templates.submit.body.title')}
                required={true}
                description={t('templates.submit.body.description')}
            />
            <TextField
                fullWidth
                defaultValue={defaultValues?.body?.text}
                error={errors?.body?.text?.message && true}
                {...register('body.text')}
                id={'body.text'}
                placeholder={t('templates.submit.body.title')}
                size="medium"
                type="text"
                multiline
                rows={3}
                sx={{
                    '&.MuiFormControl-root': {
                        '.MuiOutlinedInput-root': {
                            background: inputColor
                        }
                    }
                }}
                helperText={errors?.body?.text?.message}
            />

            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button sx={{ mt: 1 }} startIcon={<AddIcon />} size="small" onClick={onVariableAdd}>
                    {t('templates.submit.add_variable')}
                </Button>
            </Box>
        </>
    );
};

export default Text;
