import SidebarLayout from 'src/layouts/SidebarLayout';
import SignIn from 'src/screens/account/signIn';
import Account from 'src/screens/account';
import Profile from 'src/screens/account/profile';
import Password from 'src/screens/account/password';
import SetPassword from 'src/screens/account/setPassword';

export const accountRoutes = [
    {
        path: 'account',
        element: <SidebarLayout />,
        children: [
            {
                path: '',
                element: <Account />,
                children: [
                    {
                        path: 'profile',
                        element: <Profile />
                    },
                    {
                        path: 'password',
                        element: <Password />
                    },
                    {
                        path: '/account/:userId/set-password',
                        element: <SetPassword reset={false} />
                    },
                    {
                        path: '/account/:userId/reset-password',
                        element: <SetPassword reset={true} />
                    }
                ]
            }
        ]
    }
    // {
    //     path: 'sign-in',
    //     element: <SignIn />
    // }
];
