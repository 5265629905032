import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { QuickReply, User } from 'src/types';
import { useTranslation } from 'react-i18next';
import { XIconButton, useAuthPolicy } from 'src/components';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import { deleteQuickreply } from 'src/screens/conversations/slice';

const Actions = ({ quickRely }: { quickRely: QuickReply }) => {
    const confirm = useConfirm();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const enabled = useAuthPolicy('Supervision');

    const onDeleteClick = async () => {
        const ok = await confirm(
            t('conversations.quickReplies.list.data.delete_confirm_title'),
            t('conversations.quickReplies.list.data.delete_confirm_body')
        );
        if (ok) {
            dispatch(deleteQuickreply(quickRely.id));
        }
    };

    return (
        <>
            <XIconButton disabled={!enabled} to={`${quickRely.id}`} tooltip={t('common.lists.edit')}>
                <EditTwoToneIcon fontSize="small"></EditTwoToneIcon>
            </XIconButton>
            <XIconButton disabled={!enabled} onClick={() => onDeleteClick()} tooltip={t('common.lists.delete')}>
                <DeleteTwoToneIcon color={'error'} fontSize="small"></DeleteTwoToneIcon>
            </XIconButton>
        </>
    );
};

export default Actions;
