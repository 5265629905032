import { useTranslation } from 'react-i18next';
import { XIconButton, useAuthPolicy } from 'src/components';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import { deleteTeam } from '../slice';

const ItemActions = ({ teamId }: { teamId: string }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const confirm = useConfirm();
    const onDeleteClick = async () => {
        const ok = await confirm(t('team.list.data.delete_confirm_title'), t('team.list.data.delete_confirm_body'));
        if (ok) {
            dispatch(deleteTeam(teamId));
        }
    };

    const enabled = useAuthPolicy('Supervision');

    return (
        <>
            <XIconButton disabled={!enabled} onClick={() => onDeleteClick()} tooltip={t('common.lists.delete')}>
                <DeleteTwoToneIcon color={'error'} fontSize="small"></DeleteTwoToneIcon>
            </XIconButton>
        </>
    );
};

export default ItemActions;
