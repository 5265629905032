import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { signIn } from '../slice';
import { Inputs, schema } from './schema';
import LocalTokenService from './../../../services/localToken.service';

const useSignInForm = () => {
    const dispatch = useAppDispatch();
    const methods = useForm<Inputs>({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data: Inputs) => {
        LocalTokenService.deleteTokens();
        dispatch(signIn(data));
    };

    return { methods, onSubmit };
};

export default useSignInForm;
