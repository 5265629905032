import { useAppSelector } from 'src/store/hooks';
import { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@mui/material';
import { XComponentContainer } from 'src/components';
import { useParams } from 'react-router';
import SendIcon from '@mui/icons-material/Send';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HeaderItem from '../HeaderItem';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import DoneIcon from '@mui/icons-material/Done';
import { DATE_TIME_DISPLAY_FORMAT } from 'src/utils/constants';
import { format } from 'date-fns';

const toPercent = (value: number, total: number) => {
    if (total === 0) {
        return '0%';
    }

    const percent = (value / total) * 100;
    const formattedPercent = percent % 1 === 0 ? percent.toFixed(0) : percent.toFixed(2);
    return `${formattedPercent}%`;
};

const Reports = () => {
    const { t } = useTranslation();
    const { campaignReports } = useAppSelector((x) => x.campaigns.current.reports);
    const { instanceId } = useParams();
    const instanceReport = campaignReports?.find((report) => report.campaignInstanceId === instanceId);

    const [reportData, setReportData] = useState({
        data: [],
        sent: 0,
        delivered: 0,
        read: 0,
        rejected: 0,
        failed: 0,
        totalContacts: 0,
        targetGroup: undefined,
        templateName: undefined,
        initiatedBy: undefined,
        sentToAll: false
    });

    useEffect(() => {
        if (instanceReport) {
            const {
                sent,
                sentToAll,
                delivered,
                read,
                failed,
                rejected,
                totalContacts,
                templateName,
                initiatedBy,
                targetGroup
            } = instanceReport;

            const data = {
                data: [
                    { id: 'sent', data: [sent], label: t('campaigns.reports.labels.sent'), color: '#ba68c8' },
                    {
                        id: 'rejected',
                        data: [rejected],
                        label: t('campaigns.reports.labels.rejected'),
                        color: '#ef5350'
                    },
                    {
                        id: 'delivered',
                        data: [delivered],
                        label: t('campaigns.reports.labels.delivered'),
                        color: '#4caf50'
                    },
                    { id: 'read', data: [read], label: t('campaigns.reports.labels.read'), color: '#42a5f5' },
                    { id: 'failed', data: [failed], label: t('campaigns.reports.labels.failed'), color: '#ef5350' }
                ],
                sent,
                delivered,
                read,
                rejected,
                failed,
                totalContacts,
                targetGroup,
                templateName,
                initiatedBy,
                sentToAll
            };
            setReportData(data);
        }
    }, [instanceReport]);

    const sentBy = () => {
        if (instanceReport?.sentToAll) {
            return t('campaigns.reports.body.all_contacts');
        } else if (reportData?.targetGroup) {
            return reportData.targetGroup;
        }
        return <Typography color={'error'}>{t('campaigns.reports.body.group_deleted')}</Typography>;
    };

    return (
        <XComponentContainer>
            <Grid container sx={{ color: 'grey', p: 1 }}>
                <Grid container spacing={2} sx={{ textAlign: 'center', m: 1 }}>
                    {/* <Grid item xs={4} md={2} sx={{ borderBottom: '2px solid blue', pb: 1, color: 'rgb(230, 26, 128)' }}>
                        <Typography variant="h5">{t('campaigns.reports.header.overview.title')}</Typography>
                        <Typography variant="h5">{t('campaigns.reports.header.overview.stats')}</Typography>
                    </Grid> */}

                    <Grid item xs={4} md={2} sx={{ borderBottom: '1px solid #f0f0f0', pb: 1 }}>
                        <Typography variant="h5">{reportData.totalContacts}</Typography>
                        <HeaderItem
                            count={reportData.totalContacts}
                            title={t('campaigns.reports.header.contacts.title')}
                            message={t('campaigns.reports.header.contacts.title')}
                            description={t('campaigns.reports.header.contacts.description')}
                            ratio=""
                            color={'#000'}
                            icon={<PeopleAltIcon fontSize="small" />}
                        />
                    </Grid>

                    <Grid item xs={4} md={2} sx={{ borderBottom: '1px solid #f0f0f0', pb: 1 }}>
                        <Typography color={'#ba68c8'} variant="h5">
                            {toPercent(reportData.sent, reportData.totalContacts)}
                        </Typography>
                        <HeaderItem
                            count={reportData.sent}
                            title={t('campaigns.reports.header.sent.title')}
                            message={t('campaigns.reports.header.sent.message')}
                            description={t('campaigns.reports.header.sent.description')}
                            ratio={t('campaigns.reports.header.sent.ratio')}
                            color={'#ba68c8'}
                            icon={<SendIcon sx={{ color: '#ba68c8' }} fontSize="small" />}
                        />
                    </Grid>

                    <Grid item xs={4} md={2} sx={{ borderBottom: '1px solid #f0f0f0', pb: 1 }}>
                        <Typography color={'#ef5350'} variant="h5">
                            {toPercent(reportData.rejected, reportData.totalContacts)}
                        </Typography>
                        <HeaderItem
                            count={reportData.rejected}
                            title={t('campaigns.reports.header.rejected.title')}
                            message={t('campaigns.reports.header.rejected.message')}
                            description={t('campaigns.reports.header.rejected.description')}
                            ratio={t('campaigns.reports.header.rejected.ratio')}
                            color={'#ef5350'}
                            icon={<ReportGmailerrorredIcon sx={{ color: '#ef5350' }} fontSize="small" />}
                        />
                    </Grid>

                    <Grid item xs={4} md={2} sx={{ borderBottom: '1px solid #f0f0f0', pb: 1 }}>
                        <Typography color={'#4caf50'} variant="h5">
                            {toPercent(reportData.delivered, reportData.sent)}
                        </Typography>
                        <HeaderItem
                            count={reportData.delivered}
                            title={t('campaigns.reports.header.delivered.title')}
                            message={t('campaigns.reports.header.delivered.message')}
                            description={t('campaigns.reports.header.delivered.description')}
                            ratio={t('campaigns.reports.header.delivered.ratio')}
                            color={'#4caf50'}
                            icon={<DoneIcon sx={{ color: '#4caf50' }} fontSize="small" />}
                        />
                    </Grid>

                    <Grid item xs={4} md={2} sx={{ borderBottom: '1px solid #f0f0f0', pb: 1 }}>
                        <Typography color={'#42a5f5'} variant="h5">
                            {toPercent(reportData.read, reportData.delivered)}
                        </Typography>
                        <HeaderItem
                            count={reportData.read}
                            title={t('campaigns.reports.header.read.title')}
                            message={t('campaigns.reports.header.read.message')}
                            description={t('campaigns.reports.header.read.description')}
                            ratio={t('campaigns.reports.header.read.ratio')}
                            color={'#42a5f5'}
                            icon={<DoneAllIcon sx={{ color: '#42a5f5' }} fontSize="small" />}
                        />
                    </Grid>

                    <Grid item xs={4} md={2} sx={{ borderBottom: '1px solid #f0f0f0', pb: 1 }}>
                        <Typography color={'#ef5350'} variant="h5">
                            {toPercent(reportData.failed, reportData.sent)}
                        </Typography>
                        <HeaderItem
                            count={reportData.failed}
                            title={t('campaigns.reports.header.failed.title')}
                            message={t('campaigns.reports.header.failed.message')}
                            description={t('campaigns.reports.header.failed.description')}
                            ratio={t('campaigns.reports.header.failed.ratio')}
                            color={'#ef5350'}
                            icon={<ReportGmailerrorredIcon sx={{ color: '#ef5350' }} fontSize="small" />}
                        />
                    </Grid>
                </Grid>

                <Grid container alignItems={'center'}>
                    <Grid item sm={12} md={6}>
                        <Typography color={'primary'} variant="h6" sx={{ py: 2, color: 'rgb(230, 26, 128)' }}>
                            {instanceReport?.publishedDate &&
                                format(new Date(instanceReport.publishedDate), DATE_TIME_DISPLAY_FORMAT)}
                        </Typography>
                        <Typography color={'primary'} variant="h5">
                            {t('campaigns.reports.body.target_group')}:
                        </Typography>
                        <Typography variant="subtitle1" fontSize={'small'}>
                            {sentBy()}
                        </Typography>
                        <Typography color={'primary'} variant="h5" sx={{ pt: 2 }}>
                            {t('campaigns.reports.body.initiated_by')}:
                        </Typography>
                        <Typography variant="subtitle1" fontSize={'small'}>
                            {reportData.initiatedBy}
                        </Typography>
                        <Typography color={'primary'} variant="h5" sx={{ pt: 2 }}>
                            {t('campaigns.reports.body.template_used')}:
                        </Typography>
                        <Typography variant="subtitle1" fontSize={'small'}>
                            {reportData.templateName}
                        </Typography>
                    </Grid>
                    <Grid item sm={12} md={6} sx={{ direction: 'ltr' }}>
                        {(reportData.totalContacts && (
                            <BarChart
                                sx={{
                                    '.MuiChartsLegend-root': {
                                        display: 'none'
                                    }
                                }}
                                xAxis={[
                                    {
                                        data: [''],
                                        scaleType: 'band',
                                        label: ''
                                    }
                                ]}
                                series={reportData.data}
                                width={400}
                                height={400}
                            />
                        )) || <Typography variant="h3">{t('campaigns.reports.empty_list')}</Typography>}
                    </Grid>
                </Grid>
            </Grid>
        </XComponentContainer>
    );
};

export default Reports;
