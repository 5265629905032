import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateSelectLanguage } from 'src/screens/bot/slice';
import { useAppDispatch } from 'src/store/hooks';
import { BotInterface } from 'src/types';
import { schema, SelectLanguageInputs } from './schema';

const useSubmitForm = (selectLanguageInterface: BotInterface) => {
    const dispatch = useAppDispatch();
    const methods = useForm<SelectLanguageInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            message: selectLanguageInterface?.interactiveButtons?.text
        }
    });

    const onSubmit = (inputs: SelectLanguageInputs) => {
        dispatch(updateSelectLanguage({ inputs }));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
