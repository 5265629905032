import { useTranslation } from 'react-i18next';
import { Box, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { EditorInputs } from './schema';
import { nameOf } from 'src/utils/utils.shared';

export type MessageType = 'nonProductMessage' | 'productMessage';

const messageTypes: { messageType: MessageType; label: string }[] = [
    {
        messageType: 'nonProductMessage',
        label: 'bot.options.editor.message'
    },
    {
        messageType: 'productMessage',
        label: 'bot.options.editor.products'
    }
];

export default function MessageTypes({
    messageType,
    onChange
}: {
    messageType: MessageType;
    onChange: (value: MessageType) => void;
}) {
    const { t } = useTranslation();
    const { setValue } = useFormContext<EditorInputs>();

    return (
        <Box sx={{ pl: 1, pb: 1 }}>
            <RadioGroup row name="messageTypes">
                {messageTypes.map((x) => (
                    <FormControlLabel
                        key={x.messageType}
                        checked={messageType == x.messageType}
                        control={
                            <Radio
                                autoFocus
                                onChange={() => {
                                    setValue(nameOf<EditorInputs>('flag'), 'nonProduct', {
                                        shouldDirty: true
                                    });
                                    onChange(x.messageType);
                                }}
                            />
                        }
                        label={t(x.label)}
                    />
                ))}
            </RadioGroup>
        </Box>
    );
}
