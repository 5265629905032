import { ApiResponse } from 'src/types';
import apiClient from './apiClient';
import { EmailCampaignInputs } from 'src/screens/emailCampaigns/compose/schema';
import { MediaService } from './media.service';

const html = `<html>
<head>
    <title>Dynamic HTML Creation</title>
</head>
<body>
   <div>
        <p>{text}</p>
        <img src='{imageUrl}' alt='missing-image' />
   </div>
</body>
</html>`;

const mediaService = new MediaService();

export class EmailService {
    async sendEmailCampaign(inputs: EmailCampaignInputs) {
        let body = html.replace('{text}', inputs.body);
        if (inputs.image) {
            const url = await mediaService.saveMedia(inputs.image);
            body = body.replace('{imageUrl}', url);
        } else {
            body = body.replace("<img src='{imageUrl}' alt='missing-image' />", '');
        }
        const { data } = await apiClient.post<ApiResponse<string>>('/email-campaigns', {
            subject: inputs.subject,
            body,
            recipients: inputs.recipients
        });
        return data;
    }
}

export default new EmailService();
