import { Chip, Typography } from '@mui/material';
import { SallaApp } from 'src/types';
import { useTranslation } from 'react-i18next';

const Salla = ({ app }: { app: SallaApp }) => {
    const { t } = useTranslation();
    return (
        <>
            <Typography variant="h3" sx={{ textDecoration: 'none', my: 1, color: 'rgb(230, 26, 128)' }}>
                {t(`apps.${app.app.toLocaleLowerCase()}.app_name`)}
            </Typography>
            <img src="/static/images/apps/salla.svg" alt={app.app} style={{ width: '40%', height: '40%' }} />
            {!app.installed && (
                <Typography variant="body2" sx={{ textDecoration: 'none', mt: 2 }}>
                    {t('apps.install_now')}
                </Typography>
            )}
            {app.installed && (
                <Typography
                    variant="subtitle1"
                    sx={{
                        textDecoration: 'none',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    <Chip color="success" size="small" label={t('apps.installed')} />
                    {t('salla.events_available').replace('{1}', app.metaData.availableEvents.toString())}
                    <div> {t('salla.events_configured').replace('{1}', app.metaData.configuredEvents.toString())}</div>
                </Typography>
            )}
        </>
    );
};

export default Salla;
