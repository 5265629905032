import { XIconButton } from 'src/components';
import { Campaign } from 'src/types';
import BarChartIcon from '@mui/icons-material/BarChart';

const Report = ({ campaign }: { campaign: Campaign }) => {
    return (
        <>
            {campaign?.recentInstance?.status == 'Completed' && (
                <XIconButton
                    tooltip="View Reports"
                    to={`/campaigns/${campaign.id}/reports/${campaign?.recentInstance?.id}`}
                >
                    <BarChartIcon />
                </XIconButton>
            )}
        </>
    );
};

export default Report;
