import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { forgotPassword } from '../slice';
import { Input, schema } from './schema';

const useForgotPassword = () => {
    const dispatch = useAppDispatch();
    const methods = useForm<Input>({
        resolver: yupResolver(schema)
    });

    const onSubmit = (data: Input) => {
        dispatch(forgotPassword(data));
    };

    return { methods, onSubmit };
};

export default useForgotPassword;
