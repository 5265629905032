import { Box } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { useFormContext } from 'react-hook-form';
import { CampaignInterfaceInputs } from './schema';
import { XPreview } from 'src/components';

const ExistingBotInterface = () => {
    const { watch } = useFormContext<CampaignInterfaceInputs>();

    const interfaceId = watch(`campaignInterface.interfaceId`);
    const isBotInterface = watch('campaignInterface.isBotInterface') as boolean;
    const botInterfaces = useAppSelector((x) => x.businessBot.bot.botInterfaces);
    const botInterface = botInterfaces.find((x) => x.id == interfaceId);

    return (
        <>
            {isBotInterface && botInterface && (
                <Box sx={{ p: 1, background: 'url(/static/images/whatsapp-background.png)' }}>
                    <Box sx={{ m: 1, p: 1, background: 'rgb(203 255 217)' }}>
                        <XPreview botInterface={botInterface}></XPreview>
                    </Box>
                </Box>
            )}
        </>
    );
};

export default ExistingBotInterface;
