import { Typography, Box, TextField } from '@mui/material';
import { t } from 'i18next';
import { FormProvider } from 'react-hook-form';
import XFormButtons from 'src/components/Forms/XFormButton';
import useSubmitForm from './useSubmit';

const FormContainer = () => {
    const { methods, onSubmit } = useSubmitForm();

    return (
        <>
            <Typography sx={{ p: 2 }} variant="subtitle1">
                {t('apps.salla.provide_email_message')}
            </Typography>
            <Box sx={{ p: 1, mx: 1 }}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <TextField
                            fullWidth
                            error={methods.formState.errors?.email && true}
                            {...methods.register('email')}
                            id={'email'}
                            size="medium"
                            sx={{ width: { sm: '100%', md: '40%' }, mb: 2 }}
                            helperText={methods.formState.errors?.email?.message}
                        />
                        <XFormButtons
                            authPolicy="Regular"
                            isDirty={methods.formState.isDirty}
                            onResetClick={methods.reset}
                            returnTo="./../../"
                        ></XFormButtons>
                    </form>
                </FormProvider>
            </Box>
        </>
    );
};

export default FormContainer;
