import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';

export default function XConfirmModal({
    isOpen,
    onClose,
    onConfirm,
    title,
    body
}: {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title: string;
    body: string;
}) {
    const dir = useAppSelector((x) => x.account.direction);
    const { t } = useTranslation();

    return (
        <Dialog
            dir={dir}
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button sx={{ ml: 1 }} size="small" variant="outlined" onClick={onClose}>
                    {t('common.modals.confirm_no')}
                </Button>
                <Button size="small" variant="contained" onClick={onConfirm} autoFocus>
                    {t('common.modals.confirm_yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}
