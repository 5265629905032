import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Campaign } from 'src/types';
import { useTranslation } from 'react-i18next';
import { XIconButton, useAuthPolicy } from 'src/components';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import { deleteCampaign } from '../../slice';

const Actions = ({ campaign }: { campaign: Campaign }) => {
    const confirm = useConfirm();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const enabled = useAuthPolicy('Supervision');

    const onDeleteClick = async () => {
        const ok = await confirm(
            t('campaigns.list.data.delete_confirm_title'),
            t('campaigns.list.data.delete_confirm_body')
        );

        if (ok) {
            dispatch(deleteCampaign(campaign.id));
        }
    };

    return (
        <XIconButton disabled={!enabled} onClick={() => onDeleteClick()} tooltip={t('common.lists.delete')}>
            <DeleteTwoToneIcon color={'error'} fontSize="small"></DeleteTwoToneIcon>
        </XIconButton>
    );
};

export default Actions;
