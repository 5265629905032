import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { Contact } from 'src/types';
import { useTranslation } from 'react-i18next';
import { XIconButton } from 'src/components';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import CloseIcon from '@mui/icons-material/Close';
import { removeContactFromGroup } from 'src/screens/groups/slice';
import { useParams } from 'react-router';

const Actions = ({ contact }: {  contact: Contact }) => {
    const confirm = useConfirm();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { t } = useTranslation();

    const onDeleteClick = async () => {
        const ok = await confirm(
            t('groups.contacts.list.data.delete_confirm_title'),
            t('groups.contacts.list.data.delete_confirm_body')
        );
        if(ok){
            dispatch(removeContactFromGroup({ id, cid: contact.id, deleteFromAllGroups: true}))
        }
    };

    const onRemoveClick = async () => {
        const ok = await confirm(
            t('groups.contacts.list.data.remove_confirm_title'),
            t('groups.contacts.list.data.remove_confirm_body')
        );
        if(ok){
            dispatch(removeContactFromGroup({ id, cid: contact.id,  deleteFromAllGroups: false}))
        }

    };
    return (
        <>
            <XIconButton onClick={onRemoveClick} tooltip={t('groups.contacts.list.data.remove_from_group')}>
                <CloseIcon color={'error'} fontSize="small" />
            </XIconButton>
            <XIconButton onClick={() => onDeleteClick()} tooltip={t('common.lists.delete')}>
                <DeleteTwoToneIcon color={'error'} fontSize="small"></DeleteTwoToneIcon>
            </XIconButton>
        </>
    );
};

export default Actions;
