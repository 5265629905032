import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Users from 'src/screens/teams/content/users';
import Teams from 'src/screens/teams';
import Submit from 'src/screens/teams/content/submit';

const teamRoutes: RouteObject = {
    path: 'teams',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <Teams />
        },
        {
            path: '',
            element: <Teams />,
            children: [
                {
                    path: 'new',
                    element: <Submit />
                },
                {
                    path: ':id',
                    element: <Submit />
                },
                {
                    path: ':id/users',
                    element: <Users showMembers={true} />
                },
                {
                    path: ':id/users/add',
                    element: <Users showMembers={false} />
                }
            ]
        }
    ]
};

export default teamRoutes;
