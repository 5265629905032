import _ from 'lodash';
import Box from '@mui/material/Box';
import LiveSupport from './LiveSupport';
import Notifications from './Notifications';

export default function SidebarBottom() {
    return (
        <Box sx={{ position: 'absolute', bottom: 0 }}>
            {/* <Notifications /> */}
            <LiveSupport />
        </Box>
    );
}
