import { ColumnLabel, XTableHead } from 'src/components';

function Head() {
    const columns: ColumnLabel[] = [
        {
            align: 'left',
            labelKey: 'catalog.products.list.head.image'
        },
        {
            align: 'left',
            labelKey: 'catalog.products.list.head.name'
        },
        {
            align: 'left',
            labelKey: 'catalog.products.list.head.inventory'
        },
        {
            align: 'left',
            labelKey: 'catalog.products.list.head.price'
        },
        {
            align: 'right',
            labelKey: 'catalog.products.list.head.check'
        }
    ];
    return <XTableHead columns={columns} />;
}

export default Head;
