import { Contact } from 'src/types';
import { TableCell, TableRow } from '@mui/material';
import Actions from './Actions';
import { useTranslation } from 'react-i18next';
import { useAlignment } from 'src/components';

const Item = ({ contact }: { contact: Contact }) => {
    const { t } = useTranslation();
    const align = useAlignment();
    return (
        <TableRow hover key={2}>
            <TableCell align={align('left')} padding="normal">
                {contact.phoneNumber}
            </TableCell>
            <TableCell align={align('left')}>{contact.name}</TableCell>
            <TableCell align={align('left')}>
                {contact.locale == 'ar'
                    ? t('components.languageSwitch.arabic')
                    : t('components.languageSwitch.english')}
            </TableCell>
            <TableCell align={align('right')}>
                <Actions contact={contact}></Actions>
            </TableCell>
        </TableRow>
    );
};
export default Item;
