import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { AddBusinessInputs } from './schema';
import { useTranslation } from 'react-i18next';

const AdminLastName = ({disabled}: {disabled: boolean}) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<AddBusinessInputs>();

    return (
        <TextField
            fullWidth
            disabled={disabled}
            error={errors?.adminLastName && true}
            {...register(nameOf<AddBusinessInputs>('adminLastName'))}
            id={nameOf<AddBusinessInputs>('businessName')}
            placeholder={t('business.submit.admin_last_name')}
            label={t('business.submit.admin_last_name')}
            size="medium"
            type="text"
            helperText={errors?.adminLastName?.message}
        />
    );
};

export default AdminLastName;
