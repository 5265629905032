import { Alert, Box, Grid, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XLanguageSwitch, XLinkButton, XPageContainer, XPreview, XSwitch, useStyles } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { UserLocale } from 'src/types';
import { getBusinessesBot, updateBotStatus } from './slice';
import _ from 'lodash';

const Bot = () => {
    const { t } = useTranslation();
    const userLocale = useAppSelector((x) => x.account.user.locale);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getBusinessesBot());
    }, [dispatch]);
    const { borderRB } = useStyles();

    const [locale, setLocale] = useState<UserLocale>(userLocale);
    const { loadState, bot } = useAppSelector((x) => x.businessBot);
    const mainMenu = bot?.botInterfaces?.find((x) => x.interfaceType == 'InteractiveList' && x.locale == locale);
    var firstOption = _.head(mainMenu?.interactiveList?.options)?.id || '';

    return (
        <XPageContainer
            loading={loadState == 'inProgress'}
            topBar={{
                title: t('bot.page_title')
            }}
        >
            {loadState === 'successful' && (
                <Box sx={{}}>
                    <Grid container>
                        <Grid item md={7} sx={{ ...borderRB, p: 2, pt: 3 }}>
                            <Alert variant="outlined" sx={{ p: 1 }}>
                                <Typography variant="body1">{t('bot.preview.customise_description_1')}</Typography>
                                <Typography variant="body2">{t('bot.preview.customise_description_2')}</Typography>
                                <Box sx={{ display: 'flex', mt: 2 }}>
                                    <Box sx={{ pl: 1, pr: 1 }}>
                                        <XLinkButton
                                            to={`/bot/options/${firstOption}`}
                                            text={t('bot.preview.customize')}
                                        />
                                    </Box>
                                </Box>
                            </Alert>

                            <Alert variant="outlined" sx={{ mt: 2, p: 2 }}>
                                <Typography variant="body2">{t('bot.preview.settings_description')}</Typography>
                                <Box sx={{ display: 'flex', mt: 2 }}>
                                    <XLinkButton to="/bot/settings/welcome-message" text={t('bot.preview.settings')} />
                                </Box>
                            </Alert>
                        </Grid>
                        <Grid item md={5} sx={{ ...borderRB, px: 3, pt: 3 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <XSwitch
                                    label={t('bot.preview.bot_status')}
                                    checked={bot.isActive}
                                    onChange={(e) => {
                                        dispatch(updateBotStatus(!bot.isActive));
                                    }}
                                />
                            </Box>
                            <Box sx={{ display: 'flex', mb: 2 }}>
                                <XLanguageSwitch onLocaleChange={(x) => setLocale(x)} value={locale} />
                            </Box>
                            <Box sx={{ p: 1, background: 'url(/static/images/whatsapp-background.png)' }}>
                                <Box sx={{ background: '#fffffe', p: 1 }}>
                                    <XPreview
                                        botInterface={mainMenu}
                                        showListIcon={true}
                                        editUrl="/bot/settings"
                                    ></XPreview>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </XPageContainer>
    );
};

export default Bot;
