import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import useSubmitForm from './useSubmitForm';
import XFormButtons from 'src/components/Forms/XFormButton';
import { SallaEventTemplate, Template } from 'src/types';
import TemplatesDropdown from './TemplateSelection';
import Placeholders from './Placeholders';
import Locale from './Locale';
import Preview from './Preview';

const SubmitContainer = ({
    templates,
    categoryId,
    sallaEventId,
    sallaEventTemplate
}: {
    templates: Template[];
    categoryId: string;
    sallaEventId: string;
    sallaEventTemplate: SallaEventTemplate;
}) => {
    const { methods, onSubmit } = useSubmitForm(categoryId, sallaEventId, sallaEventTemplate);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12} md={6} sx={{}} gap={3}>
                        <TemplatesDropdown templates={templates} />
                        <Locale />
                        <Placeholders />
                    </Grid>
                    <Grid item xs={12} md={6} sx={{}}>
                        <Preview templates={templates} />
                    </Grid>
                    <Grid item sx={{ mt: 2 }}>
                        <XFormButtons
                            authPolicy="Supervision"
                            isDirty={methods.formState.isDirty}
                            onResetClick={methods.reset}
                            returnTo={`/apps/salla/categories/${categoryId}/events`}
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default SubmitContainer;
