import KeyboardDoubleArrowDownOutlined from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { getGroups } from '../slice';

const LoadMoreGroups = () => {
    const [loading, setLoading] = useState(false);
    const { tableData, state } = useAppSelector((x) => x.groups.list);
    const dispatch = useAppDispatch();
    const debouncedOnClick = debounce(() => {
        dispatch(getGroups({ nextPage: true }));
    }, 2000);

    const onMoreMessagesClick = () => {
        setLoading(true);
        debouncedOnClick();
    };

    useEffect(() => {
        setLoading(state == 'inProgress');
    }, [state]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
            {tableData.items.length < tableData.totalItems &&
                (loading ? (
                    <CircularProgress />
                ) : (
                    <IconButton onClick={() => onMoreMessagesClick()}>
                        <KeyboardDoubleArrowDownOutlined />
                    </IconButton>
                ))}
        </Box>
    );
};

export default LoadMoreGroups;
