import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

const RegistrationSuccessful = () => {
    return (
        <Grid item xs={12} md={6} sx={{ px: 1, my: 10 }}>
            <Alert icon={<></>}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Typography variant="h3">Your details have been submitted successfully</Typography>
                    <Typography dir="rtl" variant="h3">
                        {'لقد تم إرسال التفاصيل الخاصة بك بنجاح'}
                    </Typography>
                    <Button sx={{ mt: 5 }} type="submit" variant="outlined" to={'/sign-in'} component={NavLink}>
                        Login (صفحة الدخول)
                    </Button>
                </Box>
            </Alert>
        </Grid>
    );
};

export default RegistrationSuccessful;
