import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button/Button';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

const XAddButton = ({ text, to }: { text: string; to: string }) => {
    return (
        <Button
            to={to}
            component={NavLink}
            sx={{ mt: { xs: 2, md: 0 } }}
            variant="outlined"
            size="small"
            startIcon={<AddTwoToneIcon fontSize="small" />}
        >
            {text}
        </Button>
    );
};

export default XAddButton;
