import { BotInterface } from 'src/types';
import InteractiveButtonMessagePreview from '../InteractiveButtonsPreview';
import ListPreview from '../ListPreview';
import LocationPreview from '../LocationPreview';
import MediaWithCaptionPreview from '../MediaWithCaptionPreview';
import SimpleTextPreview from '../TextPreview';
import { Box } from '@mui/material';

const XPreview = ({
    botInterface,
    showListIcon,
    editUrl
}: {
    botInterface: BotInterface;
    showListIcon?: boolean;
    editUrl?: string;
}) => {
    return (
        <Box>
            {botInterface.interfaceType == 'InteractiveList' && (
                <ListPreview
                    options={botInterface.interactiveList.options.map((x) => ({ id: x.id, title: x.title }))}
                    body={botInterface.interactiveList.body}
                    title={botInterface.interactiveList.title}
                ></ListPreview>
            )}
            {botInterface.interfaceType == 'Text' && (
                <SimpleTextPreview message={botInterface.text}></SimpleTextPreview>
            )}
            {botInterface.interfaceType == 'Location' && (
                <LocationPreview message={botInterface.location}></LocationPreview>
            )}
            {botInterface.interfaceType == 'MediaWithCaption' && (
                <MediaWithCaptionPreview message={botInterface.mediaWithCaption}></MediaWithCaptionPreview>
            )}
            {botInterface.interfaceType == 'InteractiveButtons' && (
                <InteractiveButtonMessagePreview
                    message={botInterface.interactiveButtons}
                ></InteractiveButtonMessagePreview>
            )}
        </Box>
    );
};

export default XPreview;
