import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { AddTeamInputs } from './schema';

const Name = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<AddTeamInputs>();

    return (
        <TextField
            fullWidth
            error={errors?.name && true}
            {...register(nameOf<AddTeamInputs>('name'))}
            id={nameOf<AddTeamInputs>('name')}
            placeholder={t('team.submit.name')}
            label={t('team.submit.name')}
            size="small"
            type="text"
            helperText={errors?.name?.message}
        />
    );
};

export default Name;
