import { useEffect } from 'react';
import { XSidebarListItem } from 'src/components';
import { getGroups } from '../slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import Item from './Item';
import LoadMoreGroups from './LoadMoreGroups';

const Sidebar = () => {
    const dispatch = useAppDispatch();
    const { items } = useAppSelector((x) => x.groups.list.tableData);

    useEffect(() => {
        dispatch(getGroups({ nextPage: false }));
    }, [dispatch]);

    return (
        <>
            {items.map((x) => (
                <XSidebarListItem
                    key={x.id}
                    title={x.name}
                    to={`/groups/${x.id}/contacts`}
                    actions={<Item groupId={x.id}></Item>}
                />
            ))}
            <LoadMoreGroups />
        </>
    );
};

export default Sidebar;
