import { Switch, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { AddTeamInputs } from './schema';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Head from './Head';

const TimeTable = () => {
    const { t } = useTranslation();
    const {
        control,
        setValue,
        register,
        formState: { errors }
    } = useFormContext<AddTeamInputs>();

    const { fields } = useFieldArray({ name: 'schedule', control });
    return (
        <>
            <Typography sx={{ p: 1 }} variant={'h4'} color={'primary'}>
                {t('team.submit.working_hours')}
            </Typography>
            <Table size="small">
                <Head />
                <TableBody>
                    {fields.map((x, i) => {
                        const startTimeError = Array.isArray(errors.schedule)
                            ? errors.schedule[i]?.shiftStart?.message
                            : '';

                        const endTimeError = Array.isArray(errors.schedule)
                            ? errors.schedule[i]?.shiftEnd?.message
                            : '';

                        return (
                            <TableRow hover key={x.id}>
                                <TableCell align="justify" padding="normal">
                                    {x.dayOfWeek}
                                    <TextField
                                        fullWidth
                                        disabled
                                        sx={{ display: 'none' }}
                                        name={`schedule[${i}]dayOfWeek`}
                                        {...register(`schedule.${i}.dayOfWeek`)}
                                        placeholder={t('team.submit.day_of_week')}
                                        size="small"
                                        type="hidden"
                                    />
                                </TableCell>
                                <TableCell align="center" padding="normal">
                                    <Switch
                                        defaultChecked={x.availability}
                                        onChange={(_, checked) => {
                                            setValue(`schedule.${i}.availability`, checked, {
                                                shouldDirty: true,
                                                shouldValidate: true
                                            });
                                            setValue(`schedule.${i}.shiftStart`, '', {
                                                shouldDirty: true,
                                                shouldValidate: true
                                            });
                                            setValue(`schedule.${i}.shiftEnd`, '', {
                                                shouldDirty: true,
                                                shouldValidate: true
                                            });
                                        }}
                                    />
                                </TableCell>
                                <TableCell align="justify" padding="normal">
                                    <TextField
                                        fullWidth
                                        error={startTimeError ? true : false}
                                        name={`schedule[${i}]shiftStart`}
                                        {...register(`schedule.${i}.shiftStart`)}
                                        placeholder={t('team.submit.shift_start')}
                                        size="small"
                                        type="time"
                                        helperText={startTimeError}
                                    />
                                </TableCell>
                                <TableCell align="justify" padding="normal">
                                    <TextField
                                        fullWidth
                                        name={`schedule[${i}]shiftEnd`}
                                        error={endTimeError ? true : false}
                                        {...register(`schedule.${i}.shiftEnd`)}
                                        placeholder={t('team.submit.shift_end')}
                                        size="small"
                                        type="time"
                                        helperText={endTimeError}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </>
    );
};

export default TimeTable;
