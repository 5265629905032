import { XLinkButton, XTemplatePreview } from 'src/components';
import { SubmitTemplateInputs } from './schema';
import { SampleTemplate, Template, TemplateButtonType } from 'src/types';
import { Alert, Box, Button, Grid } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { addTemplate, updateTemplate, addSampleTemplate, updateSampleTemplate } from '../slice';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import _ from 'lodash';
import { useLocation, useParams } from 'react-router';

const toTemplate = (inputs: SubmitTemplateInputs): Template => {
    const template: Template = {
        category: inputs.templateType,
        id: inputs.id,
        language: inputs.languageCode,
        name: inputs.name,
        status: 'PENDING',
        components: {
            body: {
                text: inputs.body.text,
                examples: _.chain(inputs.body.variables)
                    .sortBy('index')
                    .map((x) => x.sample)
                    .value()
            },
            header: {
                text: inputs.header?.text,
                example: inputs.header.textSample,
                fileUrl: inputs.header?.mediaSample,
                mediaType: inputs.header?.mediaType
            },
            footer: inputs.footer,
            buttons: []
        }
    };
    if (inputs.buttons.buttonGroupType == 'CALL_TO_ACTION_GROUP') {
        template.components.buttons.push(
            ...inputs.buttons.callToactions.map((x) => ({
                type: (x.type == 'CALL_PHONE_NUMBER' ? 'PHONE_NUMBER' : 'URL') as TemplateButtonType,
                text: x.buttonText,
                countryCode: x.countryCode,
                phoneNumber: x.phoneNumber,
                url: x.website,
                example: x.websiteSample
            }))
        );
    } else if (inputs.buttons.buttonGroupType == 'QUICK_REPLY_GROUP') {
        template.components.buttons.push(
            ...inputs.buttons.quickReplies.map((x) => ({
                type: 'QUICK_REPLY' as TemplateButtonType,
                text: x.buttonText,
                phoneNumber: '',
                url: '',
                example: ''
            }))
        );
    }
    return template;
};

const TemplatePreview = ({ inputs }: { inputs: SubmitTemplateInputs }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { id, sampleTemplateId } = useParams();
    const dispatch = useAppDispatch();
    const submitState = useAppSelector((x) => x.templates.submit.state);
    const isSampleActive = pathname.split('/').includes('samples');
    const template = toTemplate(inputs);

    const onSaveClick = () => {
        if (isSampleActive) {
            if (id) {
                dispatch(updateSampleTemplate({ id, template }));
            } else {
                dispatch(addSampleTemplate({ template }));
            }
        } else {
            if (template.id && !sampleTemplateId) {
                dispatch(updateTemplate(template));
            } else {
                dispatch(addTemplate(template));
            }
        }
    };
    return (
        <>
            <Grid container sx={{ mt: 5 }}>
                <Grid item md={4}></Grid>
                <Grid item xs={12} md={4} sx={{ p: 2, background: 'url(/static/images/whatsapp-background.png)' }}>
                    <Box sx={{ background: 'rgb(203 255 217)', p: 1, borderRadius: '5px' }}>
                        <XTemplatePreview template={template} />
                    </Box>
                </Grid>
                <Grid item md={4}></Grid>
            </Grid>

            <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
                {submitState == 'successful' && <Alert variant="outlined">{t('templates.submit.submitted')}</Alert>}
                {submitState == 'inProgress' && <CircularProgress />}

                {(submitState == undefined || submitState == 'failed') && (
                    <>
                        <Button
                            sx={{ mr: 1 }}
                            type="button"
                            size="small"
                            variant="contained"
                            onClick={(e) => {
                                onSaveClick();
                                e.preventDefault();
                            }}
                        >
                            {t('components.forms.formButtons.save')}
                        </Button>
                        <XLinkButton
                            text={t('templates.submit.back_to_templates')}
                            to={isSampleActive ? '/templates/samples' : '/templates'}
                        ></XLinkButton>
                    </>
                )}
            </Box>
            <Box sx={{ m: 2, display: 'flex', justifyContent: 'center' }}>
                {submitState == 'successful' && (
                    <XLinkButton
                        text={t('templates.submit.back_to_templates')}
                        to={isSampleActive ? '/templates/samples' : '/templates'}
                    ></XLinkButton>
                )}
            </Box>
        </>
    );
};

export default TemplatePreview;
