import { ReactNode } from 'react';

export type UserLocale = 'en' | 'ar';
export type MenuDirection = 'rtl' | 'ltr';

export type ActionState = undefined | 'inProgress' | 'failed' | 'successful';

export interface TableData<T> {
    page: number;
    limit: number;
    totalItems: number;
    items: T[];
}

export function DefaultTableData<T>(): TableData<T> {
    const initialState: TableData<T> = {
        page: 1,
        limit: 10,
        totalItems: 10,
        items: [] as T[]
    };
    return initialState;
}

export type SidebarMode = 'list' | 'submit';

export class Constants {
    public static MaxPageSize: 100;
}

export type MessageType = 'open' | 'closed';

export interface PageContainerProps {
    children: ReactNode | ReactNode[];
    sideBar?: PageSidebarProps;
    bottom?: ReactNode;
    topBar: PageTopbarProps;
    loading?: boolean;
    dir?: string;
    disableContentScroll?: boolean;
}

export interface PageSidebarProps {
    title: ReactNode | string;
    content: ReactNode;
    showDrawer3?: boolean;
}

export interface PageTopbarProps {
    hideOnDesktop?: boolean;
    title?: ReactNode | string;
    action?: ReactNode | ReactNode[];
    backLink?: string;
}
