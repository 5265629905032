import { useFormContext } from 'react-hook-form';
import { QuickReplyType, SubmitTemplateInputs } from '../../schema';
import { Box, Button, Grid, IconButton, Paper, Typography } from '@mui/material';
import ButtonText from './ButtonText';
import QuickReplyTypes from './QuickReplyTypes';
import FooterText from './FooterText';
import { XIconButton } from 'src/components';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'src/components';

const QuickReply = ({ inputColor }: { inputColor: string }) => {
    const { t } = useTranslation();
    const { watch, setValue } = useFormContext<SubmitTemplateInputs>();
    const quickReplies = watch('buttons.quickReplies');
    const { borderRounded } = useStyles();

    const onAddButtonClick = () => {
        quickReplies.push({
            buttonText: '',
            type: 'CUSTOM',
            footer: ''
        });

        setValue(`buttons.quickReplies`, quickReplies, {
            shouldDirty: true
        });
    };

    const onRemoveCallToActionClick = (type: QuickReplyType, buttonText: string) => {
        const newquickReplies = _.without(
            quickReplies,
            _.find(quickReplies, {
                type,
                buttonText
            })
        );

        setValue(`buttons.quickReplies`, newquickReplies, {
            shouldDirty: true
        });
    };

    return (
        <>
            {quickReplies &&
                quickReplies.map((x, index) => (
                    <Box key={index} sx={{ ...borderRounded, p: 1, mt: 1 }}>
                        <Grid container>
                            <Grid item xs={3} sx={{}}>
                                <QuickReplyTypes index={index} inputColor={inputColor} />
                            </Grid>
                            <Grid item xs={3} sx={{ pl: 1 }}>
                                <ButtonText index={index} inputColor={inputColor} />
                            </Grid>
                            <Grid item xs={5} sx={{ pl: 1 }}>
                                {x.type == 'MARKETING_OPT_OUT' && <FooterText index={index} inputColor={inputColor} />}
                            </Grid>
                            <Grid item xs={1} sx={{ pl: 1 }} style={{ display: 'flex', alignItems: 'end' }}>
                                <IconButton
                                    disabled={quickReplies?.length == 1}
                                    onClick={() => onRemoveCallToActionClick(x.type, x.buttonText)}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Grid>
                        </Grid>
                        {x.type == 'MARKETING_OPT_OUT' && (
                            <Typography variant="body1" sx={{ fontSize: 11, mt: 1 }} color={'warning.main'}>
                                {t('templates.submit.buttons.quick_reply.opt_out_description')}
                            </Typography>
                        )}
                    </Box>
                ))}
            <Button size="small" sx={{ mt: 1 }} disabled={quickReplies?.length == 3} onClick={onAddButtonClick}>
                {t('templates.submit.buttons.add')}
            </Button>
        </>
    );
};

export default QuickReply;
