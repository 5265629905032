import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Popper, PopperPlacementType } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { useState } from 'react';
import { addValueToInput } from '../Toolbar/PlaceholderMaker';

const XEmojiPicker = ({
    onInpuChange,
    inputRef,
    placement
}: {
    onInpuChange: (value: string) => void;
    inputRef: React.MutableRefObject<HTMLInputElement>;
    placement: PopperPlacementType;
}) => {
    const { locale } = useAppSelector((x) => x.account.user);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleEmojiPicker = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        event.preventDefault();
        event.stopPropagation();
    };

    const onEmojiSelect = (value: any) => {
        addValueToInput(value.native, inputRef, onInpuChange, false);
    };

    return (
        <>
            <span onClick={handleEmojiPicker}>🙂</span>
            <Popper
                sx={{ p: 1 }}
                placement={placement}
                id={'emoji-picker'}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
            >
                {Boolean(anchorEl) && (
                    <Picker
                        locale={locale}
                        onClickOutside={() => {
                            setAnchorEl(null);
                        }}
                        autoFocus
                        data={data}
                        onEmojiSelect={onEmojiSelect}
                    />
                )}
            </Popper>
        </>
    );
};

export default XEmojiPicker;
