import { Card, CardActionArea, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { t } from 'i18next';
import { useEffect } from 'react';
import { getEventCategories } from '../slice';
import { Link } from 'react-router-dom';
import { XPageContainer } from 'src/components';
import Uninstall from '../install/Uninstall';

export default function SallaCategories() {
    const { sallaEventCategories, state } = useAppSelector((state) => state.salla);
    const dispatch = useAppDispatch();
    const direction = useAppSelector((state) => state.account.direction);

    useEffect(() => {
        if (!sallaEventCategories.length) {
            dispatch(getEventCategories());
        }
    }, []);

    return (
        <XPageContainer
            loading={state == 'inProgress'}
            topBar={{
                title: 'Salla',
                backLink: `/apps`,
                action: <Uninstall />
            }}
        >
            <div dir={direction}>
                <Typography variant="subtitle2" sx={{ m: 2, fontWeight: 'bold' }}>
                    {t('salla.categories.heading')}
                    <Typography sx={{ pb: 1 }} variant="subtitle1">
                        {t('salla.categories.example_heading')}
                    </Typography>
                    <Typography sx={{ pb: 1 }} variant="subtitle1">
                        {t('salla.categories.sub_heading')}
                    </Typography>
                </Typography>

                <Grid container spacing={2}>
                    {sallaEventCategories.map((category, index) => (
                        <Grid item xs={12} md={6} lg={3} key={index}>
                            <Card key={index} sx={{ m: 2, backgroundColor: 'rgb(236 237 245)' }}>
                                <Link
                                    to={`/apps/salla/categories/${category.id}/events`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <CardActionArea
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            p: 1,
                                            pb: 3,
                                            height: '200px'
                                        }}
                                    >
                                        <Typography
                                            variant="h3"
                                            sx={{ textDecoration: 'none', color: 'rgb(230, 26, 128)' }}
                                        >
                                            {category.name}
                                        </Typography>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                textDecoration: 'none',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {t('salla.events_available').replace(
                                                '{1}',
                                                category.totalEvents.toString()
                                            )}
                                            <div>
                                                {t('salla.events_configured').replace(
                                                    '{1}',
                                                    category.configuredEvents.toString()
                                                )}
                                            </div>
                                        </Typography>
                                    </CardActionArea>
                                </Link>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </XPageContainer>
    );
}
