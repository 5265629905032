import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import ComposeEmail from 'src/screens/emailCampaigns/compose';

const emailRoutes: RouteObject = {
    path: 'email-campaigns',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <ComposeEmail />
        }
    ]
};

export default emailRoutes;
