import {
    InteractiveButtons,
    InteractiveList,
    InterfaceType,
    MediaWithCaption,
    Location,
    SharedContact,
    MediaWithoutCaption,
    MultiProduct
} from './types.bot';
import { formatDistanceToNow, formatRelative, isToday } from 'date-fns';
import { MenuDirection } from './types.shared';
import arLocale from 'date-fns/locale/ar';
import enGBLocale from 'date-fns/locale/en-GB';
import { Template } from './types.whatsApp';

export interface ChatNote {
    id: string;
    contactId: string;
    note: string;
    createdBy: string;
    createdOn: string;
}

export interface RecentContact {
    id: string;
    name: string;
    color: string;
    phoneNumber: string;
    unread: number;
    hasNotes: boolean;
    isBlocked: boolean;
    feedback: 'Satisfied' | 'Not Satisfied' | null;
}

export interface ChatRequest extends RecentContact {
    teamId: string;
}

export interface Conversation {
    id: string;
    contactId: string;
    contactName: string;
    phoneNumber: string;
    outGoingMessage?: OutGoingMessage;
    incomingMessage?: IncomingMessage;
    correlationId?: number;
    createdOn: string;
    sentOn: string;
    deliveredOn: string;
    readOn: string;
}

export interface IncomingMessage {
    location: Location;
    contacts: SharedContact[];
    text: string;
    interfaceType: InterfaceType;
    mediaWithCaption: MediaWithCaption;
}

export type MessageStatus = undefined | 'loading' | 'sent' | 'delivered' | 'read';

export interface OutGoingMessage {
    agentId: string;
    agentName: string;
    interfaceType: InterfaceType;
    text: string;
    location?: Location;
    interactiveList?: InteractiveList;
    interactiveButtons?: InteractiveButtons;
    mediaWithCaption?: MediaWithCaption;
    mediaWithoutCaption?: MediaWithoutCaption;
    singleProduct?: string;
    multiProduct?: MultiProduct;
    template?: Template;
}

export function toMessageTime(date: Date, direction: MenuDirection) {
    if (!date || date.toString() == 'Invalid Date') {
        return '';
    }
    let distance = formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: direction == 'rtl' ? arLocale : enGBLocale
    });
    if (isToday(new Date(date))) distance = formatRelative(new Date(date), new Date());
    return distance;
}

export interface QuickReply {
    id: string;
    title: string;
    message: string;
}

export interface TemplateActionResponse {
    buttonText: string;
    templateId: string;
    responseText: string;
}
