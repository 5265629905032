import { Box } from '@mui/material';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import XMultiButton from 'src/components/Buttons/XMultiButton';

const Top = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <XMultiButton
                buttons={[
                    {
                        to: `/groups/${id}`,
                        text: t('groups.list.data.add_contacts')
                    },
                    {
                        to: `/groups/${id}/rename`,
                        text: t('groups.list.data.rename')
                    }
                ]}
            />
        </Box>
    );
};

export default Top;
