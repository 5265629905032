import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Loader from '../Loader';
import { lazy } from 'react';
import Submit from 'src/screens/templates/submit';
import Samples from 'src/screens/templates/samples';
import ChooseSample from 'src/screens/templates/submit/chooseSample';

const List = Loader(lazy(() => import('src/screens/templates/list')));

const productRoutes: RouteObject = {
    path: 'templates',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <List />
        },
        {
            path: 'new',
            element: <Submit />
        },
        {
            path: 'new/:sampleTemplateId',
            element: <Submit />
        },
        {
            path: 'samples',
            element: <Samples />
        },
        {
            path: 'samples/choose',
            element: <ChooseSample />
        },
        {
            path: 'samples/new',
            element: <Submit />
        },
        ,
        {
            path: 'samples/:id',
            element: <Submit />
        },
        {
            path: ':id',
            element: <Submit />
        }
    ]
};

export default productRoutes;
