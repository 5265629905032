import { IncomingMessage } from 'src/types';
import { Box, Grid, IconButton } from '@mui/material';
import MediaWithCaptionPreview from '../MediaWithCaptionPreview';
import { ButtonFragment } from '../Fragments';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

const XIncomingChatPreview = ({ message }: { message: IncomingMessage }) => {
    return (
        <Box>
            {message.interfaceType == 'Text' && <> {message.text}</>}
            {message.interfaceType == 'InteractiveButtons' && (
                <Box
                    sx={{
                        color: '#000',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'box-shadow 0.3s ease',
                        cursor: 'pointer'
                    }}
                >
                    <ButtonFragment>{message.text}</ButtonFragment>
                </Box>
            )}
            {message.interfaceType == 'InteractiveList' && (
                <Box
                    sx={{
                        color: '#000',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'box-shadow 0.3s ease',
                        cursor: 'pointer'
                    }}
                >
                    <ButtonFragment>{message.text}</ButtonFragment>
                </Box>
            )}
            {message.interfaceType == 'MediaWithCaption' && (
                <MediaWithCaptionPreview message={message.mediaWithCaption}></MediaWithCaptionPreview>
            )}
            {message.interfaceType == 'Location' && (
                <Box sx={{}}>
                    <>{message.location?.address}</>
                    <Box>
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${message.location.latitude},${message.location.longitude}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <IconButton>
                                <LocationOnIcon />
                            </IconButton>

                            {message.location?.name || 'Location'}
                        </a>
                    </Box>
                </Box>
            )}
            {message.interfaceType == 'Contact' && (
                <Box sx={{}}>
                    <>
                        {message.contacts?.map((x) => {
                            return (
                                <Grid
                                    sx={{ mx: 1 }}
                                    key={x.name}
                                    container
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                >
                                    <Grid item>
                                        <ContactPhoneIcon sx={{ mt: 1 }} fontSize="large" />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <div>
                                            <div>{x.name}</div>
                                            <div>{x.phoneNumbers.map((y) => y.number).join(', ')}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </>
                </Box>
            )}
        </Box>
    );
};

export default XIncomingChatPreview;
