export const getRows = (file: File): Promise<string[]> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const contents: ArrayBuffer = reader.result as ArrayBuffer;
            const textDecoder = new TextDecoder();
            const decodedContents = textDecoder.decode(contents);
            const lines = decodedContents.split('\n').filter((x) => x);
            resolve(lines);
        };
        reader.onerror = (error) => {
            console.error('Error processing file:', error);
            reject(error);
        };
        reader.readAsArrayBuffer(file);
    });
};
