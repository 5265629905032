import { Alert, Box, Button, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { MediaPicker } from 'src/components';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { Campaign, CampaignInstance, CampaignStep } from 'src/types';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { uploadCsv } from '../slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

const showUpload = (campaign: Campaign, instance: CampaignInstance, touched: boolean, groupId: string) => {
    if (campaign.templateVariables.filter((x) => x.variableType == 'Custom').length > 0) {
        if (instance) {
            return groupId && !touched && (instance.status == 'Failed' || instance.status == 'Completed');
        }
        return !touched && groupId;
    }
    return false;
};

const CsvUpload = ({ nextStep }: { nextStep: CampaignStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [val, setVal] = useState<{
        link: string;
        type: string;
        name: string;
    }>();

    const touched = useAppSelector((x) => x.campaigns.current.completedJustNow);

    // useEffect(() => {
    //     setVal(undefined);
    // }, [groupId]);

    const onCsvSelected = (link: string, type: string, name: string) => {
        setVal({
            link,
            type,
            name
        });
    };

    const onMediaRemoved = () => {
        setVal(undefined);
    };

    const onUploadClick = () => {
        dispatch(uploadCsv({ fileLink: val.link, groupId: nextStep.groupId, campaignId: nextStep.campaign?.id }));
    };

    return (
        <Box sx={{ pt: 1 }}>
            {nextStep.showUpload && (
                <Alert severity={val ? 'success' : 'info'}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <FormHelperText sx={{ color: 'primary.main' }}>{val?.name}</FormHelperText>
                        {!val && (
                            <MediaPicker
                                uniqueId="campaign-contacts-picker"
                                label=""
                                accept=".csv"
                                onMediaSelected={onCsvSelected}
                            ></MediaPicker>
                        )}
                        {val && (
                            <Tooltip title={t('campaigns.instance.upload.remove')}>
                                <IconButton sx={{ mx: 1 }} color="primary" component="span" onClick={onMediaRemoved}>
                                    <DeleteForeverIcon fontSize="small" color="error" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    {!val && <Typography color={'GrayText'}>{t('campaigns.instance.upload.message')}</Typography>}

                    {val && (
                        <Button sx={{ mt: 1 }} variant="contained" size="small" onClick={() => onUploadClick()}>
                            {t('components.forms.formButtons.upload')}
                        </Button>
                    )}
                </Alert>
            )}
        </Box>
    );
};

export default CsvUpload;
