import axios from 'axios';
import LocalTokenService from '../localToken.service';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL + '/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(
    (config) => {
        const token = LocalTokenService.getTokens()?.accessToken;
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if (err.response.status == 401) {
            {
                LocalTokenService.deleteTokens();
                window.location.href = '/sign-in';
            }
        } else return err.response;
    }
);

function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export default apiClient;
