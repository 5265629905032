import { Avatar, Box, Grid, Hidden, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAppSelector } from 'src/store/hooks';

const UserBoxLabel = styled(Typography)(
    ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.secondary.main};
`
);

const UserItem = () => {
    const { firstName, lastName, email } = useAppSelector((x) => x.account.user);
    const user = {
        name: `${firstName} ${lastName}`,
        avatar: (
            <Avatar sx={{ mt: 0.5, bgcolor: 'rgb(230, 26, 128)' }}>{(firstName[0] + lastName[0]).toUpperCase()}</Avatar>
        ),
        email: email
    };

    return (
        <Grid container>
            <Grid item xs={3} sx={{ pl: 2 }}>
                {user.avatar}
            </Grid>
            <Grid item xs={9} dir="ltr">
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <UserBoxLabel> {user.name}</UserBoxLabel>
                    <Typography color="primary"> {user.email}</Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default UserItem;
