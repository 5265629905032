import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { updateLocaleSettings } from 'src/services/account.services';
import { AuthService } from 'src/services/auth.service';
import { User } from 'src/types';
import { ActionState, MenuDirection, UserLocale } from 'src/types/types.shared';
import { UpdateProfileInputs } from './profile/schema';
import i18next from 'i18next';
import { UpdatePasswordInputs } from './password/schema';
import { SetPasswordInputs } from './setPassword/schema';

const authService = new AuthService();

const user = authService.getCurrentUser();
export interface SliceState {
    state: ActionState;
    user: User;
    direction: MenuDirection;
}
const defaultUser: User = {
    email: '',
    firstName: '',
    id: '',
    lastName: '',
    businessId: '',
    businessName: '',
    locale: 'en',
    role: 'Staff',
    teams: [],
    exp: 0,
    refreshTokenLifeTime: 0
};

const initialState: SliceState = {
    state: undefined,
    direction: user?.locale == 'ar' ? 'rtl' : 'ltr',
    user: user ? user : defaultUser
};

export const modifyLocaleSettings = createAsyncThunk(
    'ACCOUNT_SETTINGS_LOCALE',
    async ({ locale, direction }: { locale: UserLocale; direction: MenuDirection }) => {
        await updateLocaleSettings(locale);
        return { locale, direction };
    }
);

export const signIn = createAsyncThunk(
    'ACCOUNT_SIGNIN',
    async ({ userName, password }: { userName: string; password: string }, thunkAPI) => {
        const response = await authService.signIn(userName, password);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const setPassword = createAsyncThunk('ACCOUNT_VERIFY', async (inputs: SetPasswordInputs, thunkAPI) => {
    const response = await authService.setPassword(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updatePassword = createAsyncThunk(
    'ACCOUNT_RESET_PASSWORD',
    async (inputs: UpdatePasswordInputs, thunkAPI) => {
        const response = await authService.updatePassword(inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateProfile = createAsyncThunk(
    'ACCOUNT_UPDATE_PROFILE',
    async (inputs: UpdateProfileInputs, thunkAPI) => {
        const response = await authService.updateProfile(inputs);
        if (response.status === 'Successful') return inputs;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const signOut = createAsyncThunk('ACCOUNT_SIGNOUT', async (_, thunkAPI) => {
    await authService.signOut();
});

export const forgotPassword = createAsyncThunk(
    'ACCOUNT_FORGOT_PASSWORD',
    async (inputs: { email: string }, thunkAPI) => {
        const response = await authService.forgotPassword(inputs.email);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

const slice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        resetState: (state) => {
            state.state = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(modifyLocaleSettings.pending, () => {});
        builder.addCase(modifyLocaleSettings.fulfilled, (state, action) => {
            state.user.locale = action.payload.locale;
            state.direction = action.payload.direction;
        });
        builder.addCase(modifyLocaleSettings.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('account.slice.errors.update_settings'));
        });

        builder.addCase(signIn.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.user = action.payload;
            state.direction = action.payload.locale == 'en' ? 'ltr' : 'rtl';
            window.location.href = '/conversations/bot-conversations';
        });
        builder.addCase(signIn.rejected, (state, action) => {
            state.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('account.slice.errors.sign_in'));
        });

        builder.addCase(updateProfile.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(updateProfile.fulfilled, () => {
            SnackbarUtils.success(i18next.t('account.slice.success.profile_updated'));
            window.location.href = window.location.href;
        });
        builder.addCase(updateProfile.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('account.slice.errors.update_profile'));
        });

        builder.addCase(signOut.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(signOut.fulfilled, (state) => {
            state.user = defaultUser;
            window.location.href = window.location.href;
        });
        builder.addCase(signOut.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('account.slice.errors.sign_out'));
        });

        builder.addCase(updatePassword.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(updatePassword.fulfilled, () => {
            SnackbarUtils.success(i18next.t('account.slice.success.password_changed'));
            window.location.href = window.location.href;
        });
        builder.addCase(updatePassword.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('account.slice.errors.password_change'));
        });

        builder.addCase(setPassword.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(setPassword.fulfilled, (state) => {
            state.state = 'successful';
            SnackbarUtils.success('Your account has been verified successfully.');
        });
        builder.addCase(setPassword.rejected, (state, action) => {
            state.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? 'An error occured.');
        });

        builder.addCase(forgotPassword.pending, (state) => {
            state.state = 'inProgress';
        });
        builder.addCase(forgotPassword.fulfilled, (state) => {
            state.state = 'successful';
            SnackbarUtils.success(i18next.t('Mail sent successfully'));
        });
        builder.addCase(forgotPassword.rejected, (state, action) => {
            state.state = 'failed';
            SnackbarUtils.error(`${action?.payload?.toString()}, check if your email is correct`);
        });
    }
});

export const { resetState } = slice.actions;

export default slice.reducer;
