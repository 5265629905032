import * as yup from 'yup';

export interface UpdatePasswordInputs {
    currentPassword: string;
    newPassword: string;
}

export const schema = yup.object().shape({
    currentPassword: yup.string().required().trim().label('account.yup.password.current_password'),
    newPassword: yup.string().required().trim().label('account.yup.password.new_password')
});
