import { Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Select,
    MenuItem,
    FormHelperText,
    TableHead,
    Typography
} from '@mui/material';
import { SubmitSallaTemplateInputs } from './schema';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'src/store/hooks';
import { useParams } from 'react-router';

const Placeholders = () => {
    const { t } = useTranslation();
    const { sallaEventCategoryFields, sallaEvents } = useAppSelector((x) => x.salla);
    const sallaEventCategoryId = sallaEvents?.find((x) => x.id == useParams().id!)?.sallaEventCategoryId!;

    const {
        register,
        control,
        watch,
        formState: { errors }
    } = useFormContext<SubmitSallaTemplateInputs>();

    const templateVariables = useFieldArray({
        name: 'placeholders',
        control
    }).fields;

    const watched = watch('placeholders') as {
        index: number;
        sallaEventCategoryFieldId: string;
        componentType: string;
    }[];

    return (
        <Box sx={{ height: '100%', mt: 3 }}>
            {templateVariables?.length > 0 && (
                <>
                    <Alert severity="warning">
                        <Typography variant="body2">{t('salla.event_setup.placeholders.description')}</Typography>
                    </Alert>
                    <Table size="small" sx={{ mt: 2 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'30%'}>
                                    <Typography sx={{ textTransform: 'none' }} variant="subtitle1"></Typography>
                                </TableCell>
                                <TableCell width={'70%'}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templateVariables.map((x, i) => {
                                const typeError = Array.isArray(errors.placeholders)
                                    ? errors.placeholders[i]?.sallaEventCategoryFieldId?.message
                                    : '';

                                const fieldName = watched.find(
                                    (a) => a.index == x.index && a.componentType == x.componentType
                                ).sallaEventCategoryFieldId;

                                return (
                                    <TableRow hover key={i}>
                                        <TableCell width={'30%'} align="justify" padding="normal">
                                            <Typography variant="caption" sx={{ fontSize: 'smaller' }}>
                                                {`{\{${x.index}\}}`}{' '}
                                                {x.componentType == 'Body'
                                                    ? t('campaigns.submit.placeholders.list.body')
                                                    : t('campaigns.submit.placeholders.list.header')}
                                            </Typography>
                                        </TableCell>
                                        <TableCell width={'70%'}>
                                            <Select
                                                defaultValue={fieldName}
                                                fullWidth
                                                size="small"
                                                name={`templateVariables[${i}]sallaEventCategoryFieldId`}
                                                {...register(`placeholders.${i}.sallaEventCategoryFieldId`)}
                                                error={typeError ? true : false}
                                            >
                                                <MenuItem value={undefined}>
                                                    <em>{t('common.drop_downs.select')}</em>
                                                </MenuItem>
                                                {sallaEventCategoryFields
                                                    .filter((x) => x.sallaEventCategoryId == sallaEventCategoryId)
                                                    ?.map((y) => (
                                                        <MenuItem key={y.id} value={y.id}>
                                                            {y.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            <FormHelperText style={{ color: 'red' }}>{typeError}</FormHelperText>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
};

export default Placeholders;
