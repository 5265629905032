import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import { getSampleTemplates } from '../../slice';
import { XComponentContainer, XPageContainer } from 'src/components';
import Sample from './Sample';
import XMultiButton from 'src/components/Buttons/XMultiButton';

const ChooseSample = () => {
    const { t } = useTranslation();
    const direction = useAppSelector((x) => x.account.direction);

    const { items, state } = useAppSelector((x) => x.templates.samples);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (items.length == 0) {
            dispatch(getSampleTemplates());
        }
    }, [items]);

    return (
        <XComponentContainer>
            <XPageContainer
                loading={state == 'inProgress'}
                topBar={{
                    title: t('templates.samples.choose_sample.title'),
                    action: (
                        <XMultiButton
                            buttons={[
                                {
                                    text: t('templates.submit.back_to_templates'),
                                    to: '/templates'
                                },
                                {
                                    text: t('templates.submit.previous'),
                                    to: '/templates/new'
                                }
                            ]}
                        />
                    )
                }}
            >
                <Box dir={direction}>
                    <Grid container spacing={4} sx={{ p: 2 }}>
                        {items.map((x) => (
                            <Sample key={x.id} sampleTemplate={x} />
                        ))}
                    </Grid>
                </Box>
            </XPageContainer>
        </XComponentContainer>
    );
};

export default ChooseSample;
