import {  Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import Email from './Email';
import { FormProvider } from 'react-hook-form';
import useForgotPassword from './useSubmitForm';
import { NavLink } from 'react-router-dom';
import { useAppSelector } from 'src/store/hooks';
import HomeAnimation from '../signIn/HomeAnimation';

export default function ForgotPassword() {
    const { methods, onSubmit } = useForgotPassword();
    const state = useAppSelector((state) => state.account.state);
   
    return (
        <Grid container sx={{ backgroundColor: '#ffffff' }}>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <a href="https://info-sender.com">
                    <Box
                        sx={{
                            my: {
                                md: 2,
                                xs: 10
                            },
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <img src="/static/images/logos/logo-light.png" style={{ width: '70%' }} />
                    </Box>
                </a>
                <Typography sx={{pb:2}} variant='body2'>Please enter your email to receive password reset link</Typography>
                {state == 'inProgress' && <CircularProgress />}
                <Box
                    sx={{
                        mt:1,
                        mb:10,
                        px: {
                            md: 15,
                            xs: 2
                        }
                    }}
                >
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Email />
                            <Grid item >
                                    <>
                              
                                    <Button sx={{my:2}} type="submit" fullWidth variant="contained">
                                        Send Reset Password Link
                                    </Button>
                                    <Button sx={{width:'100%'}} component={NavLink} to='/=' variant="outlined">
                                        Back to Login
                                    </Button>
                                </>
                            </Grid>
                        </form>
                    </FormProvider>
                </Box>                
            </Grid>
            <HomeAnimation />
        </Grid>
    );
}
