import { Button, Grid, Box } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import useSignInForm from './useSubmitForm';
import UserName from './UserName';
import Password from './Password';
import useAfterSignin from './useAfterSignin';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from 'src/store/hooks';
import { NavLink } from 'react-router-dom';
import HomeAnimation from './HomeAnimation';

const SignIn = () => {
    const { methods, onSubmit } = useSignInForm();
    useAfterSignin();
    const state = useAppSelector((x) => x.account.state);

    return (
        <Grid container sx={{ backgroundColor: '#ffffff' }}>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <a href="https://info-sender.com">
                    <Box
                        sx={{
                            my: {
                                md: 2,
                                xs: 10
                            },
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <img src="/static/images/logos/logo-light.png" style={{ width: '70%' }} />
                    </Box>
                </a>
                <Box
                    sx={{
                        px: {
                            md: 15,
                            xs: 2
                        }
                    }}
                >
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <UserName />
                            <Password />
                            <Grid item sx={{ px: 1 }}>
                                {state != 'inProgress' && (
                                    <>
                                        <Button type="submit" fullWidth variant="contained">
                                            Login
                                        </Button>
                                    </>
                                )}
                            </Grid>
                        </form>
                    </FormProvider>
                    <Box sx={{ px: 1, display: 'flex', pt: 3, justifyContent: 'space-evenly', gap: 1 }}>
                        <Button sx={{ width: '50%' }} component={NavLink} to="/forgot-password" variant="outlined">
                            Forgot Password
                        </Button>
                        <Button
                            sx={{ width: '50%' }}
                            variant="outlined"
                            to={'/businesses/register'}
                            component={NavLink}
                        >
                            Register Business
                        </Button>
                    </Box>
                    <Box sx={{ p: 1, pt: 3 }}>
                        <a href="https://sms-client.info-sender.com">
                            <Button type="button" fullWidth variant="outlined">
                                SMS Platform
                            </Button>
                        </a>
                    </Box>
                </Box>
                {state == 'inProgress' && <CircularProgress />}
            </Grid>
            <HomeAnimation />
        </Grid>
    );
};

export default SignIn;
