import apiClient from "src/services/apiClient";
import { ApiResponse } from "src/types";

export function nameOf<T>(key: keyof T, instance?: T): keyof T {
    return key;
}

export const downloadSampleCSV = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    fetch('/static/sample-csv.csv')
        .then((response) => response.text())
        .then((text) => {
            const url = window.URL.createObjectURL(new Blob([text], { type: 'text/csv' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sample-csv.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
};

export const countLinesInCsvFile = (file: File): Promise<any> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = ({ target }) => {
            const contactList: { phoneNumber: string; name: string }[] = [];
            const text = target.result as string;
            const lines = text.replaceAll('\n', '').split('\r');
            let count: number = 0;
            lines.forEach((line) => {
                if (line.trim() !== '' && line.replaceAll(',', '')) {
                    const contact = line.split(',');
                    if (contact[0] !== 'PhoneNumber') {
                        contactList.push({
                            phoneNumber: contact[0],
                            name: contact[1]
                        });
                    }
                    count++;
                }
            });
            resolve({
                contactList,
                count
            });
        };
        reader.onerror = (error) => {
            console.error('Error processing file:', error);
            reject(error);
        };
        reader.readAsText(file);
    });
};


export const getMediaUrl = async ({ link, name }: { link: string, name: string }) => {
    if (link?.startsWith('blob:')) {
        let blob_file = await fetch(link).then((r) => r.blob());
        let formData = new FormData();
        let file = new File([blob_file], name, {
            type: blob_file.type
        });
        formData.append('file', file, name);
        const { data } = await apiClient.post<ApiResponse<{ url: string }>>(`/media`, formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        return data;
    }
    return {
        data: undefined,
        status: 'Failed',
        message: 'Invalid file'
    };
}

export const deleteMediaByFilename = async (fileId: string) => {
    if (fileId) {
        const { data } = await apiClient.delete<ApiResponse<{ url: string }>>(`/media/${fileId}`);
        return data;
    }
    return {
        data: undefined,
        status: 'Failed',
        message: 'Invalid filename'
    };
}

