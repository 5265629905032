import { Box } from '@mui/material';
import { XPageContainer } from 'src/components';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store/hooks';
import GoToSalla from './GoToSalla';
import FormContainer from './FormContainer';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';

export default function SallaInstall() {
    const { apps, state } = useAppSelector((x) => x.apps);
    const direction = useAppSelector((state) => state.account.direction);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const sallaApp = apps.find((x) => x.app == 'Salla');
    useEffect(() => {
        if (sallaApp?.installed) {
            navigate('/apps');
        }
    }, [sallaApp]);

    return (
        <XPageContainer
            loading={state == 'inProgress'}
            topBar={{
                title: t('apps.salla.installation_page_title'),
                backLink: '/apps'
            }}
        >
            <Box dir={direction}>{sallaApp?.email ? <GoToSalla /> : <FormContainer />}</Box>
        </XPageContainer>
    );
}
