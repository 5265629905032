import { Box } from '@mui/material';
import { MediaType } from 'src/types';

function Thumbnail({ type, content }: { type: MediaType; content: string }) {
    return (
        <Box sx={{}}>
            {type == 'Image' && <img style={{ width: '99%', maxHeight: '380px' }} src={content} />}
            {type == 'Document' && (
                <a href={content} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '30%', maxHeight: '200px' }} src="/static/images/thumbnail-document.png" />
                </a>
            )}
            {type == 'Video' && (
                <video src={content} controls style={{ width: '99%', maxHeight: '400px' }}>
                    Your browser does not support the video tag.
                </video>
            )}
            {type == 'Audio' && (
                <audio controls>
                    <source src={content} style={{ width: '99%' }} />
                    Your browser does not support the audio element.
                </audio>
            )}
            {type == 'Sticker' && <img style={{ width: '99%', maxHeight: '240px' }} src={content} />}
        </Box>
    );
}

export default Thumbnail;
