import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import XFormButtons from 'src/components/Forms/XFormButton';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import FirstName from './FirstName';
import LastName from './LastName';
import Email from './Email';
import Language from './Language';

const Profile = () => {
    const { methods, onSubmit } = useSubmitForm();
    useAfterSubmit();
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid
                    container
                    sx={{
                        mx: 2
                    }}
                >
                    <Grid item xs={12} md={4}>
                        <Grid item md={12} sx={{ py: 1 }}>
                            <FirstName />
                        </Grid>
                        <Grid item md={12} sx={{ py: 1 }}>
                            <LastName />
                        </Grid>
                        <Grid item md={12} sx={{ py: 1 }}>
                            <Email />
                        </Grid>
                        <Grid item md={12} sx={{ py: 1 }}>
                            <Language />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <XFormButtons
                                authPolicy="Regular"
                                isDirty={methods.formState.isDirty}
                                onResetClick={methods.reset}
                                returnTo="/"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
export default Profile;
