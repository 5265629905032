import account from './account.json';
import components from './components.json';
import business from './business.json';
import bot from './bot.json';
import user from './user.json';
import layout from './layout.json';
import common from './common.json';
import teams from './team.json';
import groups from './groups.json';
import catalog from './catalog.json';
import conversations from './conversations.json';
import templates from './templates.json';
import campaigns from './campaigns.json';
import emailCampaigns from './email-campaigns.json';
import sms from './sms.json';
import apps from './apps.json';
import salla from './salla.json';

export default {
    ...account,
    ...components,
    ...business,
    ...bot,
    ...user,
    ...layout,
    ...common,
    ...teams,
    ...groups,
    ...catalog,
    ...conversations,
    ...templates,
    ...campaigns,
    ...emailCampaigns,
    ...sms,
    ...apps,
    ...salla
};
