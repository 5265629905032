import { FormHelperText, MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useFormContext } from 'react-hook-form';
import { CampaignInterfaceInputs } from './schema';
import { useEffect } from 'react';
import { getTeams } from 'src/screens/teams/slice';

const Teams = () => {
    const dispatch = useAppDispatch();
    const {
        setValue,
        formState: { errors }
    } = useFormContext<CampaignInterfaceInputs>();
    const { t } = useTranslation();

    const onOptionSelect = (option: string | number) => {
        setValue('teamId', option.toString(), {
            shouldDirty: true
        });
    };

    const { tableData, state } = useAppSelector((x) => x.teams.list);

    useEffect(() => {
        if (state == undefined) {
            dispatch(getTeams());
        }
    }, [state]);

    return (
        <>
            {tableData?.items?.length > 0 && (
                <>
                    <Select defaultValue={0} size="small" onChange={(e) => onOptionSelect(e.target.value)}>
                        <MenuItem value={0}>{t('bot.options.editor.actions.transfer_to_team')}</MenuItem>
                        {tableData.items.map((x) => (
                            <MenuItem key={x.id} value={x.id}>
                                {x.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: 'red' }}>
                        {errors?.campaignInterface?.interfaceId?.message}
                    </FormHelperText>
                </>
            )}
        </>
    );
};

export default Teams;
