import { Box, Button, Card, Grid, styled } from '@mui/material';
import { XComponentContainer, useStyles } from 'src/components';
import TemplateType from './TemplateType';
import Name from './Name';
import Language from './Language';
import Body from './body';
import Footer from './footer';
import Header from './header';
import Section from './Section';
import Buttons from './buttons';
import ChooseTemplateButton from './chooseSample/ChooseTemplateButton';

const SubmitContainer = () => {
    const { borderRB } = useStyles();
    const inputColor = '#fafcff !important';

    return (
        <XComponentContainer>
            <Grid container>
                <Grid item md={3} sm={6} xs={12} sx={borderRB}>
                    <Box sx={{ p: 2 }}>
                        <ChooseTemplateButton />
                        <Name />
                        <Language />
                        <TemplateType />
                    </Box>
                </Grid>
                <Grid item md={9} sm={6} xs={12} sx={{ ...borderRB, mt: 2 }}>
                    <Section bgColor="#e7e7e65c">
                        <Header inputColor={inputColor} />
                    </Section>
                    <Section bgColor="rgb(115 145 248 / 12%)">
                        <Body inputColor={inputColor} />
                    </Section>
                    <Section bgColor="#e7e7e65c">
                        <Footer />
                    </Section>
                    <Section bgColor="#3f91eb1c">
                        <Buttons inputColor={inputColor} />
                    </Section>
                </Grid>
            </Grid>
        </XComponentContainer>
    );
};

export default SubmitContainer;
