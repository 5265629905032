import { Box, Typography } from '@mui/material';
import { XSwitch } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { updateBlockUrls } from '../../slice';
import { useTranslation } from 'react-i18next';

export default function BlockUrls({}) {
    const { bot, loadState } = useAppSelector((x) => x.businessBot);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    return (
        <Box>
            {loadState == 'successful' && (
                <>
                    <Typography variant="h6" sx={{ pt: 1, pb: 2 }}>
                        {t('bot.settings.block_urls.description')}
                    </Typography>
                    <XSwitch
                        label={
                            bot.urlsBlocked
                                ? t('bot.settings.block_urls.urls_blocked')
                                : t('bot.settings.block_urls.urls_allowed')
                        }
                        checked={!bot.urlsBlocked}
                        onChange={(e) => dispatch(updateBlockUrls(!bot.urlsBlocked))}
                    />
                </>
            )}
        </Box>
    );
}
