import { XComponentContainer, XPageContainer, useStyles } from 'src/components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { getCampaign } from '../slice';
import Groups from './Groups';
import { Grid } from '@mui/material';
import Variables from './Variables';
import CsvUpload from './CsvUpload';
import InstanceStatus from './InstanceStatus';
import SendCampaign from './SendCampaign';
import _ from 'lodash';
import { getNextStep } from 'src/types';

const CampaignInstance = () => {
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { borderRB } = useStyles();

    const { value: campaign, completedJustNow } = useAppSelector((x) => x.campaigns.current);

    useEffect(() => {
        if (!campaignId) {
            navigate('/campaigns');
        }
    }, [campaignId]);

    useEffect(() => {
        if (campaignId) {
            dispatch(getCampaign(campaignId));
        }
    }, [campaignId]);

    const [groupId, setGroupId] = useState('');
    const nextStep = getNextStep(campaign, groupId, completedJustNow);

    return (
        <XComponentContainer>
            <XPageContainer
                topBar={{
                    title: t('campaigns.submit.send'),
                    backLink: '/campaigns'
                }}
            >
                {nextStep && (
                    <Grid container>
                        <Grid item md={6} sx={{ ...borderRB, p: 2 }}>
                            <Groups nextStep={nextStep} onGroupSelected={setGroupId} />
                            <CsvUpload nextStep={nextStep} />
                            <InstanceStatus nextStep={nextStep} />
                            <SendCampaign nextStep={nextStep} />
                        </Grid>
                        <Grid item md={6} sx={{ ...borderRB, p: 2 }}>
                            <Variables nextStep={nextStep} />
                        </Grid>
                    </Grid>
                )}
            </XPageContainer>
        </XComponentContainer>
    );
};

export default CampaignInstance;
