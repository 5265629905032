import { Box, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import { useTranslation } from 'react-i18next';

const TextSample = ({ inputColor }: { inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    return (
        <Box sx={{ p: 2, mt: 2, background: '#f7f7f7' }}>
            <Typography variant="h5">{t('templates.submit.header.text.sample_header')}</Typography>
            <Typography variant="body1" sx={{ fontSize: 11 }}>
                {t('templates.submit.header.text.sample_description')}
            </Typography>
            <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2">{'{{1}}'}</Typography>
                <TextField
                    fullWidth
                    defaultValue={defaultValues?.name}
                    error={errors?.header?.textSample && true}
                    {...register('header.textSample')}
                    id={'header.sample'}
                    placeholder={t('templates.submit.header.text.sample_placeholder')}
                    size="small"
                    type="text"
                    sx={{
                        ml: 1,
                        mr: 1,
                        '&.MuiFormControl-root': {
                            '.MuiOutlinedInput-root': {
                                background: inputColor
                            },
                            '.MuiOutlinedInput-input': {
                                background: inputColor
                            }
                        }
                    }}
                    helperText={errors?.header?.textSample?.message}
                />
            </Box>
        </Box>
    );
};

export default TextSample;
