import { MenuItem, FormControl, Select } from '@mui/material';
import { ButtonGroupType, SubmitTemplateInputs } from './../schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DefaultCountryCode } from 'src/types';

export default function ButtonTypes({ inputColor }: { inputColor: string }) {
    const { t } = useTranslation();
    const {
        setValue,
        watch,
        formState: { defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const buttons = watch('buttons');

    const onButtonTypeChange = (buttonGroupType: ButtonGroupType) => {
        let newButtons = {
            ...buttons,
            buttonGroupType
        };
        if (buttonGroupType == 'CALL_TO_ACTION_GROUP' && newButtons.callToactions.length == 0) {
            newButtons.callToactions = [
                {
                    type: 'CALL_PHONE_NUMBER',
                    buttonText: '',
                    countryCode: DefaultCountryCode,
                    phoneNumber: '',
                    urlType: 'STATIC',
                    website: '',
                    websiteSample: ''
                }
            ];
        } else if (buttonGroupType == 'QUICK_REPLY_GROUP') {
            const custom = newButtons.quickReplies.find((x) => x.type == 'CUSTOM');
            const optOut = newButtons.quickReplies.find((x) => x.type == 'MARKETING_OPT_OUT');

            if (!custom && !optOut) {
                newButtons.quickReplies = [
                    {
                        type: 'CUSTOM',
                        buttonText: '',
                        footer: ''
                    }
                ];
            }
        }

        setValue('buttons', newButtons, {
            shouldDirty: true
        });
    };

    const items: { label: string; value: ButtonGroupType }[] = [
        {
            label: 'templates.submit.buttons.types.none',
            value: 'NONE'
        },
        {
            label: 'templates.submit.buttons.types.call_to_action',
            value: 'CALL_TO_ACTION_GROUP'
        },
        {
            label: 'templates.submit.buttons.types.quick_reply',
            value: 'QUICK_REPLY_GROUP'
        }
    ];

    return (
        <>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                    sx={{ bgcolor: inputColor }}
                    defaultValue={defaultValues.buttons.buttonGroupType}
                    onChange={(e) => onButtonTypeChange(e.target.value as ButtonGroupType)}
                    id="buttons.type"
                    size="small"
                >
                    {items.map((x, i) => (
                        <MenuItem key={i} value={x.value}>
                            {t(x.label)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
}
