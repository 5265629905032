import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Action } from 'src/types';
import ActionButton from './ActionButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppSelector } from 'src/store/hooks';
import { useFormContext } from 'react-hook-form';
import { EditorInputs } from '../../schema';
import { nameOf } from 'src/utils/utils.shared';
import { useTranslation } from 'react-i18next';

export default function TransferToTeamAction() {
    const { teams } = useAppSelector((x) => x.businessBot);
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { watch, setValue } = useFormContext<EditorInputs>();
    const actions = watch(nameOf<EditorInputs>('actions')) as Action[];

    const disabled = actions.length == 3 && actions.filter((x) => x.actionType == 'TransferToTeam').length == 0;

    const onCheckChange = (teamId: string, name: string, added: boolean) => {
        let updatedActions = actions.filter((x) => x.supportTeamId != teamId);
        if (added) {
            updatedActions.push({
                id: '',
                title: name,
                actionType: 'TransferToTeam',
                supportTeamId: teamId
            });
        }
        setValue(nameOf<EditorInputs>('actions'), updatedActions, {
            shouldDirty: true
        });
    };

    var used = actions.filter((x) => x.actionType == 'TransferToTeam').length > 0;
    return (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <ActionButton
                used={used}
                startIcon={<KeyboardArrowDownIcon />}
                disabled={disabled}
                onActionClick={handleClick}
                text={t('bot.options.editor.actions.transfer_to_team')}
            ></ActionButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {teams.map((x) => {
                    const checked = actions.find((y) => y.supportTeamId == x.id) && true;
                    const disabled = actions.length == 3 && !checked;
                    return (
                        <Box key={x.name}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        disabled={disabled}
                                        onChange={(_, checked) => {
                                            onCheckChange(x.id, x.name, checked);
                                        }}
                                    />
                                }
                                label={x.name}
                            />
                        </Box>
                    );
                })}
            </Menu>
        </Box>
    );
}
