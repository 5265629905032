import { Button, Box, Typography, Alert } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import useSubmitForm from './useSubmitForm';
import Password from './Password';
import { Container } from '@mui/system';
import { useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import LocalTokenService from './../../../services/localToken.service';

const SetPassword = ({ reset }: { reset: boolean }) => {
    const { methods, onSubmit } = useSubmitForm(reset);
    const { state } = useAppSelector((x) => x.account);

    useEffect(() => {
        LocalTokenService.deleteTokens();
    }, []);

    return state != 'successful' ? (
        <>
            <Typography component="h1" variant="h3" sx={{ display: 'flex', justifyContent: 'center', m: 10, mb: 0 }}>
                {reset ? 'Hi, there!' : 'Welcome to Info Sender!'}
            </Typography>
            <Typography component="h1" variant="body2" sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
                {reset ? 'Enter your new password' : 'To continue please setup a pasword for your account'}
            </Typography>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        marginTop: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& .MuiTextField-root': { m: 1, width: '100%' }
                    }}
                >
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <Password />
                            <Button type="submit" fullWidth variant="contained" sx={{ ml: 1, mt: 1 }}>
                                Submit
                            </Button>
                        </form>
                    </FormProvider>
                </Box>
            </Container>
        </>
    ) : (
        <Container
            sx={{
                marginTop: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& .MuiTextField-root': { m: 1, width: '100%' }
            }}
            maxWidth="xs"
        >
            <Box>
                <Alert variant="outlined" severity="success" sx={{ p: 5 }}>
                    Your account has been verified.
                </Alert>
            </Box>
            <Box sx={{ mt: 1 }}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                        window.location.href = '/';
                    }}
                >
                    Sign in now
                </Button>
            </Box>
        </Container>
    );
};

export default SetPassword;
