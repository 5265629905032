import { useAppSelector } from 'src/store/hooks';
import { Alignment } from '../Tables/XTableHead';

const useAlignment = () => {
    const direction = useAppSelector((x) => x.account.direction);

    const align = (alignmnet: Alignment): Alignment => {
        if (direction == 'ltr') return alignmnet;
        if (alignmnet == 'left') return 'right';
        if (alignmnet == 'right') return 'left';
        return alignmnet;
    };

    return align;
};

export default useAlignment;
