import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppSelector } from 'src/store/hooks';
import { nameOf } from 'src/utils/utils.shared';
import { WhatsaapInfoInputs } from './schema';
import HelperIcon from './HelperIcon';

const WhatsAppPhoneNumberId = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const business = useAppSelector((x) => x.businesses.list.tableData.items.find((x) => x.id == id));
    const {
        register,
        formState: { errors }
    } = useFormContext<WhatsaapInfoInputs>();

    return (
        <Box sx={{ display: 'flex' }}>
            <TextField
                fullWidth
                error={errors?.whatsAppPhoneNumberId && true}
                {...register(nameOf<WhatsaapInfoInputs>('whatsAppPhoneNumberId'))}
                id={nameOf<WhatsaapInfoInputs>('whatsAppPhoneNumberId')}
                placeholder={t('business.submit.whatsapp_phone_number_id')}
                label={t('business.submit.whatsapp_phone_number_id')}
                size="medium"
                type="text"
                helperText={errors?.whatsAppPhoneNumberId?.message}
            />
            <HelperIcon imageName="phone-number-id" />
        </Box>
    );
};

export default WhatsAppPhoneNumberId;
