import { Button } from '@mui/material';
import { AppService } from 'src/services/app.service';
import { t } from 'i18next';

const service = new AppService();

const Uninstall = () => {
    return (
        <Button size="small" variant="outlined" onClick={service.redirectToSalla}>
            {t('salla.uninstall')}
        </Button>
    );
};

export default Uninstall;
