import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { EditorInputs } from './schema';
import { useRef } from 'react';
import Menubar from './Menubar';

const Body = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<EditorInputs>();

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <TextField
                fullWidth
                inputRef={inputRef}
                error={errors?.body && true}
                {...register(nameOf<EditorInputs>('body'))}
                id={nameOf<EditorInputs>('body')}
                placeholder={t('bot.options.editor.body')}
                size="medium"
                type="text"
                multiline
                rows={3}
                helperText={errors?.body?.message}
            />
            <Menubar inputRef={inputRef} />
        </>
    );
};

export default Body;
