import { Box } from '@mui/material';
import List from './list';
import { XAddButton, XComponentContainer, XPageContainer } from 'src/components';
import { t } from 'i18next';

const QuickReplies = () => {
    return (
        <XComponentContainer>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                <Box sx={{ width: '40%', padding: '1rem' }}>
                    <XAddButton text={t('conversations.quickReplies.list.head.add_label')} to="new"></XAddButton>
                </Box>
                <List />
            </Box>
        </XComponentContainer>
    );
};

export default QuickReplies;
