import { BotInterface } from 'src/types';
import EntireCatalog from './entireCatalog';
import MultiProduct from './multiProduct';
import SingleProduct from './singleProduct';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { EditorInputs, ProductFlag } from '../schema';
import { nameOf } from 'src/utils/utils.shared';

const values: { value: ProductFlag; label: string }[] = [
    {
        value: 'catalog',
        label: 'bot.options.editor.catalog'
    },
    {
        value: 'singleProduct',
        label: 'bot.options.editor.single_product'
    },
    {
        value: 'multiProduct',
        label: 'bot.options.editor.multi_product'
    }
];

const getProductMessageType = (botInterface: BotInterface) => {
    if (botInterface.interfaceSubType == 'Text_Catalog') return 'catalog';
    if (botInterface.interfaceType == 'MultiProduct') return 'multiProduct';
    if (botInterface.interfaceType == 'SingleProduct') return 'singleProduct';
    return '';
};

const ProductMessage = ({ botInterface }: { botInterface: BotInterface }) => {
    const [interfaceType, setInterfaceType] = useState<ProductFlag | ''>(getProductMessageType(botInterface));
    const { setValue } = useFormContext<EditorInputs>();
    const { t } = useTranslation();

    useEffect(() => {
        setInterfaceType(getProductMessageType(botInterface));
    }, [botInterface]);

    return (
        <>
            <Box sx={{ pl: 1, pb: 1 }}>
                <RadioGroup row name="messageTypes">
                    {values.map((x) => (
                        <FormControlLabel
                            key={x.value}
                            checked={interfaceType == x.value}
                            control={
                                <Radio
                                    autoFocus
                                    onChange={() => {
                                        setInterfaceType(x.value);
                                        setValue(nameOf<EditorInputs>('flag'), x.value, {
                                            shouldDirty: true
                                        });
                                    }}
                                />
                            }
                            label={t(x.label)}
                        />
                    ))}
                </RadioGroup>
            </Box>

            {interfaceType == 'catalog' && <EntireCatalog></EntireCatalog>}
            {interfaceType == 'singleProduct' && <SingleProduct></SingleProduct>}
            {interfaceType == 'multiProduct' && <MultiProduct></MultiProduct>}
        </>
    );
};

export default ProductMessage;
