import { Box, Button, Grid, Switch, Tooltip } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CampaignInterfaceInputs } from '../schema';
import BotOptions from './BotOptions';
import { XLanguageSwitch } from 'src/components';
import { useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { UserLocale } from 'src/types';

const Head = ({ button }: { button: string }) => {
    const { setValue, watch } = useFormContext<CampaignInterfaceInputs>();
    const [locale, setLocale] = useState(useAppSelector((x) => x.account.user.locale));

    const isBotInterface = watch('campaignInterface.isBotInterface') as boolean;

    const onLocaleChange = (userLocale: UserLocale) => {
        setLocale(userLocale);
        setValue(`locale`, userLocale, {
            shouldDirty: true
        });
    };

    return (
        <Grid container>
            <Grid item xs={11}>
                <Box>
                    <Button sx={{ mr: 1, color: 'rgb(230, 26, 128)' }} variant="outlined" size="small">
                        {button}
                    </Button>
                </Box>
            </Grid>
            <Grid xs={1} item sx={{ display: 'flex', justifyContent: 'end' }}>
                <Tooltip
                    sx={{ position: 'relative', right: 0 }}
                    title={isBotInterface ? 'Customise message' : 'Use message from bot'}
                >
                    <Switch
                        onChange={(_, checked) => {
                            setValue(`campaignInterface.isBotInterface`, checked, {
                                shouldDirty: true
                            });
                        }}
                    />
                </Tooltip>
            </Grid>
            <Grid xs={12} item sx={{ my: 2 }}>
                <Grid container>
                    <Grid item>
                        <XLanguageSwitch onLocaleChange={onLocaleChange} value={locale} />
                    </Grid>
                    <Grid item> {isBotInterface && <BotOptions locale={locale} />}</Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Head;
