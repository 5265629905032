import { ApiResponse } from 'src/types';
import apiClient from './apiClient';

export class MediaService {
    async saveMedia(inputs: { link: string; name: string }) {
        let url = '';
        if (inputs?.link?.startsWith('blob:')) {
            let blob_file = await fetch(inputs.link).then((r) => r.blob());
            let formData = new FormData();
            let file = new File([blob_file], inputs.name, {
                type: blob_file.type
            });
            formData.append('file', file, inputs.name);

            var { data } = await apiClient.post<ApiResponse<{ url: string }>>(`/media`, formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
            if (data.status == 'Successful') {
                url = data.data.url;
            } else {
                alert('Error saving file');
            }
        }
        return url;
    }
}

export default new MediaService();
