import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { XAddButton, XComponentContainer, XPageContainer } from 'src/components';
import { getTeams } from './slice';
import { useTranslation } from 'react-i18next';
import Sidebar from './sidebar';
import { Outlet, useNavigate, useParams } from 'react-router';

function Teams() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const teams = useAppSelector((x) => x.teams.list.tableData.items);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        dispatch(getTeams());
    }, [dispatch]);

    useEffect(() => {
        if (!id && teams.length > 0) {
            navigate(`${teams[0].id}/users`);
        }
    }, [teams]);

    const getTitle = () => {
        let title = '';
        if (location.pathname.indexOf('users/add') > 0) {
            title = 'team.list.data.add_users';
        } else if (location.pathname.indexOf('new') > 0) {
            title = 'team.submit.add_title';
        } else if (location.pathname.indexOf('users') > 0) {
            title = 'team.users.title';
        } else if (id) {
            title = 'team.submit.edit_tile';
        }
        const group = useAppSelector((x) => x.teams.list.tableData.items).find((x) => x.id == id)?.name;
        return t(title).replace('{team_name}', group);
    };

    return (
        <XPageContainer
            sideBar={{
                content: <Sidebar />,
                title: t('team.list.title')
            }}
            topBar={{
                action: <XAddButton to={`/teams/new`} text={t('team.submit.add_title')}></XAddButton>,
                title: getTitle()
            }}
        >
            <XComponentContainer>
                <Outlet></Outlet>
            </XComponentContainer>
        </XPageContainer>
    );
}

export default Teams;
