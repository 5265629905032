import { SubmitUserInputs } from 'src/screens/users/submit/schema';
import { ApiListResponse, ApiResponse, User } from 'src/types';
import apiClient from './apiClient';

export class UsersService {
    async getUsers(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<User>>('/users', {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async addUser(inputs: SubmitUserInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>('/users', inputs);
        return data;
    }

    async deleteUser(userId: string) {
        const { data } = await apiClient.delete<ApiListResponse<User>>(`/users/${userId}`);
        return data;
    }

    async updateUser(id: string, inputs: SubmitUserInputs) {
        const { data } = await apiClient.put<ApiResponse<string>>(`/users/${id}`, inputs);
        return data;
    }
}

export default new UsersService();
