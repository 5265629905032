import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getProducts, setPaging } from '../slice';
import { XTableContainer } from 'src/components';
import { Table, TableBody } from '@mui/material';
import Head from './Head';
import Item from './item';
import { useParams } from 'react-router';

function Products() {
    const dispatch = useAppDispatch();
    const { setId } = useParams();
    const { tableData, state } = useAppSelector((x) => x.catalog.products);

    useEffect(() => {
        dispatch(getProducts(setId));
    }, [dispatch, setId]);

    const onPaging = (page: number, limit: number) => {
        dispatch(
            setPaging({
                page,
                limit
            })
        );
        dispatch(getProducts(setId));
    };
    return (
        <XTableContainer
            totalItems={tableData.totalItems}
            page={tableData.page}
            limit={tableData.limit}
            onPaging={onPaging}
            loading={state === 'inProgress'}
            createAction={null}
        >
            <Table size="small">
                <Head />
                <TableBody>
                    {tableData.items.map((x) => (
                        <Item key={x.retailerId} product={x} />
                    ))}
                </TableBody>
            </Table>
        </XTableContainer>
    );
}

export default Products;
