import { ApiListResponse, ApiResponse, Campaign, CampaignInstance, CampaignReport, Group } from 'src/types';
import apiClient from './apiClient';
import { SubmitCampaignInputs } from 'src/screens/campaigns/submit/schema';
import { CampaignInterfaceInputs } from 'src/screens/campaigns/customise/schema';

export class CampaignsService {
    async getCampaigns(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<Campaign>>('/campaigns', {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async getCampaignInstances(id: string, page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<CampaignInstance>>(`/campaigns/${id}/CampaignInstances`, {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async getCampaign(id: string) {
        const { data } = await apiClient.get<ApiResponse<Campaign>>(`/campaigns/${id}`);
        return data;
    }

    async getRecentCampaignInstance(id: string) {
        const { data } = await apiClient.get<ApiResponse<CampaignInstance>>(`/campaigns/${id}/recent-instance`);
        return data;
    }

    async addCampaign(inputs: SubmitCampaignInputs) {
        const { data } = await apiClient.post<ApiResponse<{ id: string }>>('/campaigns', inputs);
        return data;
    }

    async updateCampaign(inputs: SubmitCampaignInputs) {
        const { data } = await apiClient.put<ApiResponse<string>>(`/campaigns/${inputs.id}`, inputs);
        return data;
    }

    async addCampaignInstance(groupId: string, campaignId: string, scheduledFor: string, acceptTerms: boolean) {
        const formData = new FormData();
        if (groupId && groupId != 'all') formData.append('groupId', groupId);
        if (scheduledFor) formData.append('scheduledFor', scheduledFor);
        formData.append('acceptTermsAndConditions', String(acceptTerms));

        const { data } = await apiClient.post<ApiResponse<{ id: string }>>(
            `/campaigns/${campaignId}/CampaignInstances`,
            formData,
            {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            }
        );
        return data;
    }

    async addCampaignAction(inputs: CampaignInterfaceInputs) {
        var payload = inputs.campaignInterface.isBotInterface
            ? {
                  buttonName: inputs.buttonName,
                  locale: inputs.locale,
                  interfaceRequest: {
                      botInterfaceId: inputs.campaignInterface.interfaceId
                  }
              }
            : {
                  buttonName: inputs.buttonName,
                  locale: inputs.locale,
                  interfaceRequest: {
                      text: inputs.campaignInterface.message,
                      teamId: inputs.teamId ? inputs.teamId : null
                  }
              };

        const { data } = await apiClient.post<ApiResponse<string>>(
            `/campaigns/${inputs.campaignId}/TemplateActions`,
            payload
        );
        return data;
    }

    async publishInstance(id: string, scheduledFor: string, acceptTerms: boolean) {
        const formData = new FormData();
        formData.append('acceptTermsAndConditions', String(acceptTerms));
        if (scheduledFor) formData.append('scheduledFor', scheduledFor);

        const { data } = await apiClient.put<ApiResponse<string>>(
            `/campaigns/CampaignInstances/${id}/Publish`,

            formData,
            {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            }
        );
        return data;
    }

    async downloadCsv(campaignName: string, groupId: string, campaignId: string): Promise<ApiResponse<string>> {
        try {
            let downloadUrl = `/campaigns/${campaignId}/CSV`;
            if (groupId && groupId != 'all') {
                downloadUrl += `?groupId=${groupId}`;
            }

            const { data } = await apiClient.get<ApiResponse<string>>(downloadUrl);

            if (data.status == 'Failed') {
                return data;
            }

            const url = window.URL.createObjectURL(new Blob([data.toString()]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${campaignName}.csv`);
            link.click();
            window.URL.revokeObjectURL(url);
            return {
                data: '',
                status: 'Successful'
            };
        } catch (error) {
            return {
                data: '',
                status: 'Failed'
            };
        }
    }

    async uploadCsv(fileLink: string, groupId: string, campaignId: string): Promise<ApiResponse<string>> {
        let blob_file = await fetch(fileLink).then((r) => r.blob());
        let formData = new FormData();
        let file = new File([blob_file], 'data', {
            type: blob_file.type
        });
        formData.append('file', file, 'data');
        formData.append('groupId', groupId);

        var { data } = await apiClient.post<ApiResponse<string>>(`/campaigns/${campaignId}/upload`, formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        return data;
    }

    async getReports(id: string) {
        const { data } = await apiClient.get<ApiListResponse<CampaignReport>>(`/campaigns/${id}/reports`);
        const {
            message,
            status,
            data: { items }
        } = data;
        return {
            data: items,
            message,
            status
        };
    }

    async getAllGroups() {
        const { data } = await apiClient.get<ApiListResponse<Group>>(`groups?limit=100`);
        return data;
    }

    async sendTestCampaign({ campaignId, phoneNumber }: { campaignId: string; phoneNumber: string }) {
        let formData = new FormData();
        formData.append('phoneNumber', phoneNumber);

        const { data } = await apiClient.post<ApiListResponse<string>>(`/campaigns/${campaignId}/test`, formData, {
            headers: {
                'Content-Type': `multipart/form-data`
            }
        });
        return data;
    }

    async deleteCampaign(id: string) {
        const { data } = await apiClient.delete<ApiResponse<string>>(`/campaigns/${id}`);
        return data;
    }
}

export default new CampaignsService();
