import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button/Button';

const XLinkButton = ({ text, to, disabled }: { text: string; to: string; disabled?: boolean }) => {
    return (
        <Button disabled={disabled} to={to} component={NavLink} variant="outlined" size="small">
            {text}
        </Button>
    );
};

export default XLinkButton;
