import { Box, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { MediaPicker, SnackbarUtils } from 'src/components';
import { EmailCampaignInputs } from './schema';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';

const ImagePicker = () => {
    const { t } = useTranslation();
    const {
        watch,
        setValue,
        formState: { errors }
    } = useFormContext<EmailCampaignInputs>();

    const val: any = watch(nameOf<EmailCampaignInputs>('image'));

    const handleMediaChange = async (link: any, type: string, name: string, file: File) => {
        try {
            setValue(
                nameOf<EmailCampaignInputs>('image'),
                { link, type, name },
                {
                    shouldDirty: true,
                    shouldValidate: true
                }
            );
        } catch (error) {
            return SnackbarUtils.error(error?.message || 'Error processing file:');
        }
    };
    return (
        <Box sx={{ pt: 1 }}>
            <Typography sx={{ pt: 1, display: 'flex' }} variant="subtitle1">
                {t('emailCampaigns.compose.image')}
                <Box>
                    <MediaPicker
                        label={''}
                        accept="image/*"
                        onMediaSelected={handleMediaChange}
                        uniqueId="email-image-file-picker"
                    ></MediaPicker>
                </Box>
            </Typography>
            <FormHelperText sx={{ color: 'primary.main' }}>{val?.name}</FormHelperText>
            <FormHelperText error>{errors?.image?.message}</FormHelperText>
        </Box>
    );
};

export default ImagePicker;
