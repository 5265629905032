import { ColumnLabel, XTableHead } from 'src/components';

function Head() {
    const columns: ColumnLabel[] = [
        {
            align: 'left',
            labelKey: 'user.list.head.first_name'
        },
        {
            align: 'left',
            labelKey: 'user.list.head.last_name'
        },
        {
            align: 'left',
            labelKey: 'user.list.head.email'
        },
        {
            align: 'right',
            labelKey: 'common.lists.actions'
        }
    ];
    return <XTableHead columns={columns} />;
}

export default Head;
