import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { EditorInputs } from './schema';

const Title = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<EditorInputs>();
    return (
        <TextField
            sx={{ pb: 1, width: '50%' }}
            fullWidth
            error={errors?.title && true}
            {...register(nameOf<EditorInputs>('title'))}
            id={nameOf<EditorInputs>('title')}
            placeholder={t('bot.options.editor.title')}
            size="small"
            type="text"
            helperText={errors?.title?.message}
        />
    );
};

export default Title;
