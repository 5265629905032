import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { Contact, Group } from 'src/types';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import i18next from 'i18next';
import { RootState } from 'src/store';
import { EmailCampaignInputs } from './compose/schema';
import { EmailService } from 'src/services/email.service';

const service = new EmailService();

export interface SliceState {
    submit: {
        state: ActionState;
    };
}

const initialState: SliceState = {
    submit: {
        state: undefined
    }
};

export const sendEmailCampaign = createAsyncThunk(
    'SEND_EMAIL_CAMPAIGN',
    async (inputs: EmailCampaignInputs, thunkAPI) => {
        const response = await service.sendEmailCampaign(inputs);
        if (response.status === 'Successful') return;
        return thunkAPI.rejectWithValue(response.message);
    }
);

const slice = createSlice({
    name: 'emailCampaigns',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(sendEmailCampaign.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(sendEmailCampaign.fulfilled, (state, action) => {
            state.submit.state = 'successful';
            SnackbarUtils.success(i18next.t('emailCampaigns.slice.success.send'));
        });
        builder.addCase(sendEmailCampaign.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('emailCampaigns.slice.errors.send'));
        });
    }
});

export const {} = slice.actions;

export default slice.reducer;
