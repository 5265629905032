import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { Inputs } from './schema';

const UserName = () => {
    const {
        register,
        formState: { errors }
    } = useFormContext<Inputs>();

    return (
        <TextField
            sx={{ p: 1 }}
            fullWidth
            error={errors?.userName && true}
            {...register(nameOf<Inputs>('userName'))}
            id={nameOf<Inputs>('userName')}
            placeholder="Email"
            size="medium"
            helperText={errors?.userName?.message}
        />
    );
};

export default UserName;
