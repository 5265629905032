import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { nameOf } from 'src/utils/utils.shared';
import { WhatsaapInfoInputs } from './schema';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useState } from 'react';
import HelperIcon from './HelperIcon';

const WhatsAppSecret = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<WhatsaapInfoInputs>();

    const [show, setShow] = useState(false);
    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <FormControlLabel
                    value="start"
                    control={<Switch color="primary" onChange={(_, checked) => setShow(checked)} />}
                    label={t('business.whatsApp_info.secret_switch_label')}
                    labelPlacement="start"
                    sx={{ mb: 2, ml: 0, mr: 1 }}
                />
                <HelperIcon imageName="secret" margin="0" />
            </Box>
            {show && (
                <TextField
                    fullWidth
                    error={errors?.whatsAppSecret && true}
                    {...register(nameOf<WhatsaapInfoInputs>('whatsAppSecret'))}
                    id={nameOf<WhatsaapInfoInputs>('whatsAppSecret')}
                    placeholder={t('business.whatsApp_info.new_app_secret')}
                    label={t('business.whatsApp_info.new_app_secret')}
                    size="medium"
                    type="text"
                    helperText={errors?.whatsAppSecret?.message}
                />
            )}
        </>
    );
};

export default WhatsAppSecret;
