import { Box, CardContent, CardHeader, Paper, Typography } from '@mui/material';
import { SubmitCampaignInputs } from '../schema';
import { nameOf } from 'src/utils/utils.shared';
import { useFormContext } from 'react-hook-form';
import { Template } from 'src/types';
import { XTemplatePreview } from 'src/components';
import { useTranslation } from 'react-i18next';

const MiddleSection = ({ templates }: { templates: Template[] }) => {
    const { watch } = useFormContext<SubmitCampaignInputs>();
    const { t } = useTranslation();

    const templateId = watch(nameOf<SubmitCampaignInputs>('templateId')) as string;

    const template = templates.find((x) => x.id == templateId);
    return (
        <Box sx={{ height: '100%', p: 2, m: 1 }}>
            <Typography variant="caption">{t('campaigns.submit.preview_title')}</Typography>
            {template && (
                <CardContent sx={{ mt: 2, background: 'url(/static/images/whatsapp-background.png)' }}>
                    <Box sx={{ background: 'rgb(203 255 217)' }}>
                        {template && <XTemplatePreview template={template} />}
                    </Box>
                </CardContent>
            )}
        </Box>
    );
};

export default MiddleSection;
