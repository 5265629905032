import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router';
import { t } from 'i18next';

type CampaignTab = 'WhatsApp' | 'Email' | 'SMS';

const CampaignTabs = ({ activeTab }: { activeTab: CampaignTab }) => {
    const navigateTo = useNavigate();
    const onTabClick = (path: string) => {
        navigateTo(path);
    };

    return (
        <Box sx={{ px: 2, display: 'flex' }}>
            {activeTab != 'WhatsApp' && (
                <Button color="success" size="small" onClick={() => onTabClick('/campaigns')}>
                    {t('campaigns.list.title')}
                </Button>
            )}
            {activeTab != 'SMS' && (
                <Button color="success" size="small" onClick={() => onTabClick('/sms/campaigns')}>
                    {t('sms.campaigns.list.title')}
                </Button>
            )}
            {activeTab != 'Email' && (
                <Button color="success" size="small" onClick={() => onTabClick('/email-campaigns')}>
                    {t('emailCampaigns.compose.title')}
                </Button>
            )}
        </Box>
    );
};

export default CampaignTabs;
