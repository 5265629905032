import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { Contact, Group } from 'src/types';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import i18next from 'i18next';
import { GroupsService } from 'src/services/group.service';
import { GroupInputs } from './content/submitGroup/schema';
import { RootState } from 'src/store';

const service = new GroupsService();

export interface SliceState {
    list: {
        state: ActionState;
        tableData: TableData<Group>;
    };
    submit: {
        state: ActionState;
    };
    processed: {
        groupId: string;
    };
    contacts: {
        state: ActionState;
        tableData: TableData<Contact>;
    };
}

const initialState: SliceState = {
    list: {
        state: undefined,
        tableData: DefaultTableData()
    },
    submit: {
        state: undefined
    },
    processed: {
        groupId: undefined
    },
    contacts: {
        state: undefined,
        tableData: DefaultTableData()
    }
};

export const getGroups = createAsyncThunk('GET_GROUPS', async ({ nextPage }: { nextPage: boolean }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.groups.list.tableData;
    const response = await service.getGroups(nextPage ? page + 1 : 1, limit);
    if (response.status === 'Successful') return { data: response.data, nextPage };
    return thunkAPI.rejectWithValue(response.message);
});

export const addGroup = createAsyncThunk('ADD_GROUP', async (inputs: GroupInputs, thunkAPI) => {
    const response = await service.addGroup(inputs);
    if (response.status === 'Successful')
        return {
            id: response.data.groupId,
            name: inputs.name
        };
    return thunkAPI.rejectWithValue(response.message);
});

export const updateGroup = createAsyncThunk(
    'UPDATE_GROUP',
    async ({ id, name }: { id: string; name: string }, thunkAPI) => {
        const response = await service.updateGroup(id, name);
        if (response.status === 'Successful') return { id, name };
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const addContactsToGroup = createAsyncThunk(
    'ADD_CONTACTS_TO_GROUP',
    async ({ id, inputs }: { id: string; inputs: GroupInputs }, thunkAPI) => {
        const response = await service.addContactsToGroup(id, inputs);
        if (response.status === 'Successful')
            return {
                groupId: id
            };
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const getContacts = createAsyncThunk('GET_CONTACTS_IN_GROUP', async (groupId: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.groups.contacts.tableData;
    const response = await service.getGroupContacts(page, limit, groupId);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const removeContactFromGroup = createAsyncThunk(
    'REMOVE_CONTACT_FROM_GROUP',
    async ({ id, cid, deleteFromAllGroups }: { id: string; cid: string; deleteFromAllGroups: boolean }, thunkAPI) => {
        const response = await service.removeContactFromGroup(id, cid, deleteFromAllGroups);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const removeGroup = createAsyncThunk('REMOVE_GROUP', async (id: string, thunkAPI) => {
    const response = await service.removeGroup(id);
    if (response.status === 'Successful') return id;
    return thunkAPI.rejectWithValue(response.message);
});

const slice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.contacts.tableData.page = action.payload.page;
            state.contacts.tableData.limit = action.payload.limit;
        },
        setContactsFileProcessed: (state, action: PayloadAction<string>) => {
            state.list.tableData.items = state.list.tableData.items.map((x) =>
                x.id == action.payload ? { ...x, processing: false } : x
            );
            state.processed.groupId = action.payload;
        }
    },
    extraReducers(builder) {
        builder.addCase(getGroups.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(getGroups.fulfilled, (state, action) => {
            const { nextPage, data } = action.payload;
            if (nextPage) {
                state.list.tableData = {
                    ...data,
                    items: [...state.list.tableData.items, ...data.items]
                };
            } else {
                state.list.tableData = data;
            }
            state.list.state = 'successful';
            state.submit.state = undefined;
            state.processed.groupId = undefined;
        });
        builder.addCase(getGroups.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('groups.slice.errors.fetch_groups'));
        });

        builder.addCase(addGroup.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(addGroup.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('groups.slice.success.add_group'));
            state.list.tableData.items.unshift({
                id: action.payload.id,
                name: action.payload.name,
                contactsCount: 0,
                processing: true
            });
            state.submit.state = 'successful';
            state.processed.groupId = undefined;
        });
        builder.addCase(addGroup.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('groups.slice.errors.add_group'));
        });

        builder.addCase(addContactsToGroup.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(addContactsToGroup.fulfilled, (state, action) => {
            SnackbarUtils.success(i18next.t('groups.slice.success.add_contacts_to_group'));
            state.list.tableData.items = state.list.tableData.items.map((x) =>
                x.id == action.payload.groupId ? { ...x, processing: true } : x
            );
            state.submit.state = 'successful';
            state.processed.groupId = undefined;
        });
        builder.addCase(addContactsToGroup.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('groups.slice.errors.add_contacts_to_group'));
        });

        builder.addCase(getContacts.pending, (state) => {
            state.contacts.state = 'inProgress';
        });
        builder.addCase(getContacts.fulfilled, (state, action) => {
            state.contacts.tableData = action.payload;
            state.contacts.state = 'successful';
            state.submit.state = undefined;
            state.processed.groupId = undefined;
        });
        builder.addCase(getContacts.rejected, (state, action) => {
            state.contacts.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.fetch_teams'));
        });

        builder.addCase(removeContactFromGroup.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(removeContactFromGroup.fulfilled, (state) => {
            state.list.state = 'successful';
            SnackbarUtils.success(i18next.t('groups.slice.success.remove_contact'));
        });
        builder.addCase(removeContactFromGroup.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('groups.slice.errors.remove_contact'));
        });

        builder.addCase(removeGroup.pending, (state) => {
            state.list.state = 'inProgress';
        });
        builder.addCase(removeGroup.fulfilled, (state, action) => {
            state.list.state = 'successful';
            state.list.tableData.items = state.list.tableData.items.filter((item) => item.id !== action.payload);
            SnackbarUtils.success(i18next.t('groups.slice.success.remove_group'));
        });
        builder.addCase(removeGroup.rejected, (state, action) => {
            state.list.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('groups.slice.errors.remove_group'));
        });

        builder.addCase(updateGroup.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(updateGroup.fulfilled, (state, action) => {
            state.submit.state = 'successful';
            SnackbarUtils.success(i18next.t('groups.slice.success.update_group'));
        });
        builder.addCase(updateGroup.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('groups.slice.errors.update_group'));
        });
    }
});

export const { setPaging, setContactsFileProcessed } = slice.actions;

export default slice.reducer;
