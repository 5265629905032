import { ColumnLabel, XTableHead } from 'src/components';

function Head() {
    const columns: ColumnLabel[] = [
        {
            align: 'left',
            labelKey: 'team.submit.day_of_week'
        },
        {
            align: 'center',
            labelKey: 'team.submit.availability'
        },
        {
            align: 'left',
            labelKey: 'team.submit.shift_start'
        },
        {
            align: 'left',
            labelKey: 'team.submit.shift_end'
        }
    ];
    return <XTableHead columns={columns} />;
}

export default Head;
