import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { SubmitCampaignInputs } from './../schema';
import { useTranslation } from 'react-i18next';

const Name = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitCampaignInputs>();

    return (
        <TextField
            fullWidth
            defaultValue={defaultValues?.name}
            sx={{ maxWidth: '80%', m: 2 }}
            error={errors?.name && true}
            {...register(nameOf<SubmitCampaignInputs>('name'))}
            id={nameOf<SubmitCampaignInputs>('name')}
            placeholder={t('campaigns.submit.name')}
            label={t('campaigns.submit.name')}
            size="medium"
            type="text"
            helperText={errors?.name?.message}
        />
    );
};

export default Name;
