import { Stack, Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { ReactElement } from 'react';

const CustumTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        border: '2px solid #f0f0f0',
        fontSize: 8,
        maxWidth: 500
    }
}));

const HeaderItem = ({
    count,
    title,
    message,
    description,
    ratio,
    color,
    icon
}: {
    count: number;
    title: string;
    message: string;
    description: string;
    ratio: string;
    color: string;
    icon: ReactElement;
}) => {
    return (
        <Stack spacing={1} direction={'row'} justifyContent={'center'}>
            <Typography variant="subtitle1">{title}</Typography>
            <CustumTooltip
                title={
                    <>
                        <Stack spacing={1} direction={'row'}>
                            {icon}
                            <Typography variant="body1" color={color}>
                                {message}({count})
                            </Typography>
                        </Stack>
                        <Typography fontSize={'1.5em'} sx={{ py: 1 }}>
                            {description}
                        </Typography>
                        {ratio && (
                            <Stack direction={'row'} sx={{ mb: 1 }} color={'primary'}>
                                <Typography sx={{ p: 1, backgroundColor: 'rgba(85, 105, 255, 0.1)' }}>
                                    {ratio}
                                </Typography>
                            </Stack>
                        )}
                    </>
                }
                arrow
            >
                <InfoIcon color="action" fontSize="small" />
            </CustumTooltip>
        </Stack>
    );
};

export default HeaderItem;
