import { ApiListResponse, ApiResponse, SmsCampaign, SmsTemplate } from 'src/types';
import apiClient from './apiClient';
import { SubmitSmsCampaignInputs } from 'src/screens/sms/campaigns/newCampaign/schema';

export class SmsService {
    async getSmsTemplates(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<SmsTemplate>>('/sms-templates', {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async addTemplate(template: { title: string; text: string }) {
        const { data } = await apiClient.post<ApiResponse<string>>('/sms-templates', template);
        return data;
    }

    async updateTemplate(template: SmsTemplate) {
        const { data } = await apiClient.put<ApiResponse<string>>(`/sms-templates/${template.id}`, template);
        return data;
    }

    async deleteTemplate(id: string) {
        const { data } = await apiClient.delete<ApiResponse<SmsTemplate>>(`/sms-templates/${id}`);
        return data;
    }

    async getSmsBalance(businessId: string) {
        const { data } = await apiClient.get<ApiResponse<{ balance: number }>>(`businesses/${businessId}/sms-balance`);
        return data;
    }

    async getSmsCampaigns(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<SmsCampaign>>(`/sms-campaigns`, {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async sendSmsCampaign(smsCampaign: SubmitSmsCampaignInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>(`/sms-campaigns`, smsCampaign);
        return data;
    }

    async testSmsCampaign(smsCampaign: SubmitSmsCampaignInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>(`/sms-campaigns/test`, smsCampaign);
        return data;
    }
}

export default new SmsService();
