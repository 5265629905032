import { Grid, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { XComponentContainer, XPageContainer } from 'src/components';
import XFormButtons from 'src/components/Forms/XFormButton';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import WhatsappToken from './WhatsappToken';
import { useTranslation } from 'react-i18next';
import WhatsAppPhoneNumberId from './WhatsAppPhoneNumberId';
import WhatsAppBusinessAccountId from './WhatsAppBusinessAccountId';
import WebhookConfigurationUrl from './WebhookConfigurationUrl';
import WebhookConfigurationToken from './WebhookConfigurationToken';
import WhatsAppBusinessPhoneNumber from './WhatsAppBusinessPhoneNumber';
import WhatsAppApplicationId from './WhatsAppApplicationId';
import WhatsAppSecret from './WhatsAppSecret';
import HelperIcon from './HelperIcon';

const WhatsAppInfo = () => {
    const { t } = useTranslation();
    const { id, methods, onSubmit } = useSubmitForm();
    useAfterSubmit();
    return (
        <XPageContainer
            topBar={{
                title: t('business.whatsApp_info.title'),
                backLink: '/businesses'
            }}
        >
            <XComponentContainer>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ p: 2, mr: 2 }}>
                            <Grid item xs={12} sx={{ display: 'flex' }}>
                                <Typography variant="h6" sx={{ my: 1, color: 'rgb(230, 26, 128)' }}>
                                    {t('business.whatsApp_info.web_hook_details')}
                                </Typography>
                                <HelperIcon imageName="webhook" margin="3px" />
                            </Grid>
                            <Grid item md={8} xs={12}>
                                <WebhookConfigurationUrl id={id}></WebhookConfigurationUrl>
                            </Grid>
                            <Grid item md={5} xs={12}>
                                <WebhookConfigurationToken />
                            </Grid>
                            <Grid item xs={12}></Grid>

                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ my: 1, color: 'rgb(230, 26, 128)' }}>
                                    {t('business.whatsApp_info.fields_details')}
                                </Typography>
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppBusinessPhoneNumber></WhatsAppBusinessPhoneNumber>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppApplicationId />
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppBusinessAccountId></WhatsAppBusinessAccountId>
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppPhoneNumberId></WhatsAppPhoneNumberId>
                            </Grid>

                            <Grid item xs={12}></Grid>
                            <Grid item md={4}>
                                <WhatsAppSecret></WhatsAppSecret>
                            </Grid>
                            <Grid item xs={12}></Grid>

                            <Grid item sm={6} xs={12}>
                                <WhatsappToken></WhatsappToken>
                            </Grid>
                            <Grid item xs={12}>
                                <XFormButtons
                                    authPolicy="Administration"
                                    isDirty={methods.formState.isDirty}
                                    onResetClick={methods.reset}
                                    returnTo="/businesses"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </XComponentContainer>
        </XPageContainer>
    );
};

export default WhatsAppInfo;
