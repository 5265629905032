import { Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import XMultiButton from 'src/components/Buttons/XMultiButton';

const Top = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    return (
        <Box sx={{ m: 1, display: 'flex', justifyContent: 'end' }}>
            <XMultiButton
                buttons={[
                    {
                        to: `/teams/${id}/users/add`,
                        text: t('team.list.data.add_users')
                    },
                    {
                        to: `/teams/${id}`,
                        text: t('team.submit.team_availability')
                    }
                ]}
            />
        </Box>
    );
};

export default Top;
