import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';

const TermsAndConditions = ({ error, onChange }: { error: string; onChange: (accepted: boolean) => void }) => {
    const { t } = useTranslation();
    return (
        <>
            <Box>
                <FormControlLabel
                    sx={{
                        '& .MuiFormControlLabel-label': {
                            fontSize: 12
                        }
                    }}
                    label={t('campaigns.instance.terms_and_conditions.description')}
                    control={<Checkbox onChange={(_, checked) => onChange(checked)} />}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
            </Box>
        </>
    );
};

export default TermsAndConditions;
