import { Box } from '@mui/material';
import Thumbnail from './Thumbnail';
import { MediaWithoutCaption } from 'src/types';
import _ from 'lodash';

const MediaWithoutCaptionPreview = ({ message }: { message: MediaWithoutCaption }) => {
    return (
        <Box sx={{}}>
            <Thumbnail content={message?.media?.link} type={message?.media?.type}></Thumbnail>
        </Box>
    );
};

export default MediaWithoutCaptionPreview;
