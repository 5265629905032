import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'src/store/hooks';

const useAfterSubmit = () => {
    const { state } = useAppSelector((x) => x.campaigns.current);
    const id = useAppSelector((x) => x.campaigns.current.value?.id);
    const navigateTo = useNavigate();

    useEffect(() => {
        if (state === 'successful') navigateTo(`/campaigns/${id}`);
    }, [state]);
};

export default useAfterSubmit;
