import yupx from 'src/locales/yupLocale';

export interface WelcomeMessageInputs {
    titleEnglish: string;
    bodyEnglish: string;
    titleArabic: string;
    bodyArabic: string;
}

export const schema = yupx.object().shape({
    titleEnglish: yupx.string().required().trim().label('bot.yup.settings.welcome_message.title_english'),
    bodyEnglish: yupx.string().required().trim().label('bot.yup.settings.welcome_message.body_english'),
    titleArabic: yupx.string().required().trim().label('bot.yup.settings.welcome_message.title_arabic'),
    bodyArabic: yupx.string().required().trim().label('bot.yup.settings.welcome_message.body_arabic')
});
