import yupx from 'src/locales/yupLocale';

export interface WhatsaapInfoInputs {
    businessId: string;
    whatsAppToken: string;
    whatsAppSecret: string;
    whatsAppBusinessPhoneNumber: string;
    webhookConfigurationToken: string;
    whatsAppPhoneNumberId: string;
    whatsAppBusinessAccountId: string;
    whatsAppApplicationId: string;
}

export const schema = yupx.object().shape({
    businessId: yupx.string().required().trim().label('business.yup.whatsApp_info.business_id'),
    whatsAppBusinessPhoneNumber: yupx.string().required().trim().label('business.yup.whatsApp_info.phone_number'),
    whatsAppPhoneNumberId: yupx.string().required().trim().label('business.yup.whatsApp_info.whatsapp_phone_number_id'),
    whatsAppBusinessAccountId: yupx
        .string()
        .required()
        .trim()
        .label('business.yup.whatsApp_info.whatsapp_business_account_id'),
    whatsAppToken: yupx.string(),
    whatsAppSecret: yupx.string(),
    whatsAppApplicationId: yupx.string().required().trim().label('business.yup.whatsApp_info.whatsapp_application_id')
});
