import { Alert, Box, FormHelperText, Grid, Input, Typography } from '@mui/material';
import { MediaType, parseMediaType } from 'src/types';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import { useTranslation } from 'react-i18next';
import { MediaPicker, useStyles } from 'src/components';
import Thumbnail from 'src/components/Preview/Thumbnail';

const MediaTypeSample = ({ type }: { type: MediaType }) => {
    const { t } = useTranslation();
    const {
        register,
        setValue,
        watch,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();
    const { borderRounded } = useStyles();

    const header = watch('header');

    const onMediaSelected = (link: string, type: string) => {
        setValue(
            'header',
            {
                ...header,
                mediaSample: link,
                mediaType: parseMediaType(type)
            },
            {
                shouldDirty: true,
                shouldValidate: true
            }
        );
    };

    const acceptedType = {
        Image: 'image/jpeg, image/jpg, image/png',
        Document: '.pdf',
        Video: 'video/mp4'
    };

    return (
        <Box sx={{ ...borderRounded, p: 2, mt: 2, background: '#f7f7f7' }}>
            <Typography variant="h5">{t('templates.submit.header.text.sample_header')}</Typography>
            <Typography variant="body1" sx={{ fontSize: 11 }}>
                {t('templates.submit.header.text.sample_description')}
            </Typography>

            <Grid container>
                <Grid item md={6}>
                    <Alert
                        variant="outlined"
                        severity="info"
                        sx={{
                            mt: 2,
                            '.MuiAlert-message': {
                                width: '100%'
                            }
                        }}
                    >
                        <MediaPicker
                            uniqueId="templates-header-media-picker"
                            maxSizeInBytes={5242880}
                            accept={acceptedType[header.mediaType]}
                            onMediaSelected={(link, type) => {
                                onMediaSelected(link, type);
                            }}
                            label={type}
                        />
                    </Alert>
                </Grid>
                <Grid item md={6} sx={{ px: 1 }}>
                    <Input {...register('header.mediaSample')} type="hidden" />
                    <Thumbnail
                        content={header.mediaSample ? header.mediaSample : defaultValues?.header?.mediaSample}
                        type={header.mediaType ? header.mediaType : defaultValues?.header?.mediaType}
                    />
                </Grid>
            </Grid>
            <FormHelperText style={{ color: 'red' }}>{errors?.header?.mediaSample?.message}</FormHelperText>
        </Box>
    );
};

export default MediaTypeSample;
