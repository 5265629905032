import { FormProvider } from 'react-hook-form';
import useSubmitForm from './useSubmitForm';
import XFormButtons from 'src/components/Forms/XFormButton';
import BodyArabic from './BodyArabic';
import BodyEnglish from './BodyEnglish';
import { Box, Grid } from '@mui/material';
import TitleArabic from './TitleArabic';
import TitleEnglish from './TitleEnglish';
import { useEffect } from 'react';
import { getBusinessesBot } from 'src/screens/bot/slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import useAfterSubmit from './useAfterSubmit';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

const DesignWelcomeMessage = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { bot, loadState } = useAppSelector((x) => x.businessBot);
    useEffect(() => {
        if (loadState == 'inProgress') dispatch(getBusinessesBot());
    }, [dispatch, getBusinessesBot, loadState]);

    const welcomeInterfaces = bot.botInterfaces.filter((x) => x.interfaceType == 'InteractiveList');
    const { methods, onSubmit } = useSubmitForm(welcomeInterfaces);
    useAfterSubmit();
    return (
        <>
            <Typography variant="h6" sx={{ pt: 1, pb: 2 }}>
                {t('bot.settings.welcomeMessage.description')}
            </Typography>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Grid container alignItems="stretch" spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TitleEnglish></TitleEnglish>
                            <BodyEnglish></BodyEnglish>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TitleArabic></TitleArabic>
                            <BodyArabic></BodyArabic>
                        </Grid>
                    </Grid>
                    <Box sx={{ pl: 1 }}>
                        <XFormButtons
                            authPolicy="Supervision"
                            isDirty={methods.formState.isDirty}
                            onResetClick={methods.reset}
                            returnTo="/bot"
                        />
                    </Box>
                </form>
            </FormProvider>
        </>
    );
};

export default DesignWelcomeMessage;
