import { useAppSelector } from 'src/store/hooks';

export type AuthPolicy = 'Regular' | 'BusinessAdministartion' | 'Administration' | 'Supervision';

const useAuthPolicy = (policy: AuthPolicy) => {
    const { role } = useAppSelector((x) => x.account.user);
    let enabled = true;
    if (policy == 'Supervision') {
        enabled = role == 'BusinessAdmin' || role == 'SuperAdmin' || role == 'Supervisor';
    } else if (policy == 'BusinessAdministartion') {
        enabled = role == 'BusinessAdmin' || role == 'SuperAdmin';
    } else if (policy == 'Administration') {
        enabled = role == 'SuperAdmin';
    }
    return enabled;
};

export default useAuthPolicy;
