import { Box, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CopyIcon from './CopyIcon';

const WebhookConfigurationUrl = ({ id }: { id: string }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex' }}>
            <TextField
                fullWidth
                value={`${process.env.REACT_APP_API_URL}/webhook/${id}`}
                label={t('business.whatsApp_info.webhook_hook_url')}
                size="medium"
                disabled
                type="text"
            />
            <CopyIcon content={`${process.env.REACT_APP_API_URL}/webhook/${id}`} />
        </Box>
    );
};

export default WebhookConfigurationUrl;
