import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { SubmitCampaignInputs, schema } from './schema';
import { addCampaign, updateCampaign } from '../slice';
import { useEffect } from 'react';

const useSubmitForm = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const campaign = useAppSelector((x) => x.campaigns.current.value);

    useEffect(() => {
        if (id && !campaign) {
            navigate('/campaigns');
        }
    }, [id, campaign]);

    const methods = useForm<SubmitCampaignInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            templateVariables: campaign?.templateVariables ?? [],
            languageCode: campaign?.languageCode,
            name: campaign?.name,
            templateId: campaign?.templateId
        }
    });

    const onSubmit = (inputs: SubmitCampaignInputs) => {
        if (campaign?.id) dispatch(updateCampaign(inputs));
        else dispatch(addCampaign(inputs));
    };

    return { campaign, methods, onSubmit };
};

export default useSubmitForm;
