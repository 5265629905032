import { TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { CommonInputs } from './schema';

const BodyEnglish = ({ placeholder }: { placeholder: string }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<CommonInputs>();
    return (
        <>
            <Typography
                variant="caption"
                sx={{
                    pt: 1,
                    pl: 1,
                    display: 'flex',
                    justifyContent: 'start',
                    color: 'rgb(230, 26, 128)'
                }}
            >
                {t('components.languageSwitch.english')}
            </Typography>
            <TextField
                fullWidth
                error={errors?.english && true}
                {...register(nameOf<CommonInputs>('english'))}
                id={nameOf<CommonInputs>('english')}
                placeholder={placeholder}
                size="small"
                type="text"
                helperText={errors?.english?.message}
                sx={{
                    p: 1
                }}
                multiline
                rows={3}
            ></TextField>
        </>
    );
};

export default BodyEnglish;
