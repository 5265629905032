import BodySamples from './BodySamples';
import Text from './Text';

const Body = ({ inputColor }: { inputColor: string }) => {
    return (
        <>
            <Text inputColor={inputColor}/>
            <BodySamples inputColor={inputColor}/>
        </>
    );
};

export default Body;
