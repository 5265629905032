import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { User } from 'src/types';
import { useTranslation } from 'react-i18next';
import { XIconButton, useAuthPolicy } from 'src/components';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import { addUserToTeam, removeUserFromTeam } from '../../slice';
import CloseIcon from '@mui/icons-material/Close';

const Actions = ({ user, teamId, isMember }: { user: User; teamId: string; isMember: boolean }) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const dispatch = useAppDispatch();
    const enabled = useAuthPolicy('Supervision');

    const onRemoveClick = async () => {
        const ok = await confirm(t('user.list.data.remove_confirm_title'), t('user.list.data.remove_confirm_body'));
        if (ok) dispatch(removeUserFromTeam({ teamId: teamId, userId: user.id }));
    };

    const onAddClick = async () => {
        const ok = await confirm(t('user.list.data.add_confirm_title'), t('user.list.data.add_confirm_body'));
        if (ok) dispatch(addUserToTeam({ teamId: teamId, userId: user.id }));
    };
    return !isMember ? (
        <XIconButton disabled={!enabled} onClick={onRemoveClick} tooltip={t('user.list.data.remove_confirm_title')}>
            <CloseIcon color={'error'} fontSize="small" />
        </XIconButton>
    ) : (
        <XIconButton disabled={!enabled} onClick={onAddClick} tooltip={t('user.list.data.add_confirm_title')}>
            <AddCircleOutlineIcon fontSize="small" />
        </XIconButton>
    );
};

export default Actions;
