import { Box, Chip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SectionHeading = ({
    title,
    required,
    description
}: {
    title: string;
    required: boolean;
    description: string;
}) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">{t(title)}</Typography>
                <Chip
                    color={required ? 'error' : 'success'}
                    sx={{ mr: 1, ml: 1, height: 18, fontSize: 'smaller' }}
                    size="small"
                    label={required ? t('templates.submit.required') : t('templates.submit.optional')}
                />
            </Box>
            <Typography variant="body1" sx={{ mb: 2 }}>
                {t(description)}
            </Typography>
        </Box>
    );
};

export default SectionHeading;
