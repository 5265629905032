import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import RegistrationSuccessful from './RegistrationSuccessful';
import { signOut } from 'src/screens/account/slice';
import Submit from '../submit';
import { Box } from '@mui/material';

const Register = () => {
    const dispatch = useAppDispatch();
    const state = useAppSelector((x) => x.businesses.register.state);

    useEffect(() => {
        return () => {
            dispatch(signOut());
        };
    }, []);

    return (
        <Box sx={{px:2, overflow: 'hidden'}}>
            {state == 'successful' ? <RegistrationSuccessful /> : <Submit />}
        </Box>
    );
};

export default Register;
