import { Box } from '@mui/material';
import ActionButton from './ActionButton';
import { Action } from 'src/types';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { EditorInputs } from '../../schema';
import { useTranslation } from 'react-i18next';

const EndConversationAction = () => {
    const { watch, setValue } = useFormContext<EditorInputs>();
    const actions = watch(nameOf<EditorInputs>('actions')) as Action[];
    const { t } = useTranslation();

    const used = actions.find((x) => x.actionType == 'EndConversation') && true;
    const disabled = actions.length == 3 && !used;

    const onActionClick = (added: boolean) => {
        let updatedActions = actions.filter((x) => x.actionType != 'EndConversation');
        if (added) {
            updatedActions.push({
                id: '',
                title: t('bot.options.editor.actions.end_conversation'),
                actionType: 'EndConversation'
            });
        }
        setValue(nameOf<EditorInputs>('actions'), updatedActions, {
            shouldDirty: true
        });
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <ActionButton
                used={used}
                disabled={disabled}
                onActionClick={() => onActionClick(!used)}
                text={t('bot.options.editor.actions.end_conversation')}
            ></ActionButton>
        </Box>
    );
};

export default EndConversationAction;
