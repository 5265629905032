import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getSampleTemplates } from '../slice';
import { XAddButton, XComponentContainer, XLinkButton, XPageContainer } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import Sample from './Sample';
import { SampleTemplate } from 'src/types';

function Samples() {
    const { t } = useTranslation();
    const direction = useAppSelector((x) => x.account.direction);
    const { items, state } = useAppSelector((x) => x.templates.samples);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getSampleTemplates());
    }, []);

    return (
        <XComponentContainer>
            <XPageContainer
                loading={state == 'inProgress'}
                topBar={{
                    title: t('templates.samples.title'),
                    action: (
                        <>
                            <Box sx={{ ml: 1, mr: 1 }}>
                                <XLinkButton to={'/templates'} text={t('templates.submit.back_to_templates')} />
                            </Box>
                            <XAddButton to={'/templates/samples/new'} text={t('templates.samples.create_sample')} />
                        </>
                    )
                }}
            >
                <Box dir={direction}>
                    <Grid container spacing={4} sx={{ p: 2 }}>
                        {items.map(({ id, template }: SampleTemplate) => (
                            <Sample id={id} template={template} key={id} />
                        ))}
                    </Grid>
                </Box>
            </XPageContainer>
        </XComponentContainer>
    );
}

export default Samples;
