import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getGroups } from './slice';
import { Box } from '@mui/material';
import { XAddButton, XComponentContainer, XPageContainer } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import Sidebar from './sidebar';

function Groups() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const groups = useAppSelector((x) => x.groups.list.tableData.items);
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getGroups({ nextPage: false }));
    }, [dispatch, getGroups]);

    const getTitle = () => {
        let title = '';
        if (location.pathname.indexOf('rename') > 0) {
            title = 'groups.list.data.rename_title';
        } else if (location.pathname.indexOf('new') > 0) {
            title = 'groups.submit.add_title';
        } else if (location.pathname.indexOf('contacts') > 0) {
            title = 'groups.list.data.contacts_title';
        } else if (id) {
            title = 'groups.list.data.add_contacts_title';
        }
        const group = useAppSelector((x) => x.groups.list.tableData.items).find((x) => x.id == id)?.name;
        return t(title).replace('{group_name}', group);
    };

    useEffect(() => {
        if (!id && groups.length > 0) {
            navigate(`${groups[0].id}/contacts`);
        }
    }, [groups]);

    return (
        <XPageContainer
            sideBar={{
                content: <Sidebar></Sidebar>,
                title: t('groups.title')
            }}
            topBar={{
                action: <XAddButton to="/groups/new" text={t('groups.submit.add_title')}></XAddButton>,
                title: getTitle()
            }}
        >
            <XComponentContainer>
                <Box sx={{ p: 1, height: '100%' }}>
                    <Outlet />
                </Box>
            </XComponentContainer>
        </XPageContainer>
    );
}

export default Groups;
