import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { AddBusinessInputs } from './schema';
import { useTranslation } from 'react-i18next';

const Email = ({ disabled }: { disabled: boolean }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<AddBusinessInputs>();
    return (
        <TextField
            fullWidth
            disabled={disabled}
            error={errors?.email && true}
            {...register(nameOf<AddBusinessInputs>('email'))}
            id={nameOf<AddBusinessInputs>('email')}
            placeholder={t('business.submit.email')}
            label={t('business.submit.email')}
            size="medium"
            type="text"
            helperText={errors?.email?.message}
        />
    );
};

export default Email;
