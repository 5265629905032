import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useAuthPolicy } from 'src/components';
import { AuthPolicy } from '../Authentication/useAuthPolicy';

type Props = {
    submitText?: string;
    isDirty: boolean;
    onResetClick: () => void;
    onCloseClick?: () => void;
    returnTo?: string;
    authPolicy: AuthPolicy;
};

function XFormButtons({ submitText, isDirty, returnTo, authPolicy, onResetClick, onCloseClick }: Props) {
    const { t } = useTranslation();
    let enabled = useAuthPolicy(authPolicy);

    const getCloseButton = () => {
        if (onCloseClick)
            return (
                <Button onClick={() => onCloseClick()} sx={{ m: 1, ml: 0 }} variant="outlined" color="primary">
                    {t('components.forms.formButtons.close')}
                </Button>
            );

        return (
            <Button component={NavLink} to={returnTo} sx={{ m: 1, ml: 0 }} variant="outlined" color="primary">
                {t('components.forms.formButtons.close')}
            </Button>
        );
    };
    return (
        <Box>
            <Button
                type="submit"
                disabled={!isDirty || !enabled}
                sx={{ m: 1, ml: 0 }}
                variant="contained"
                color="primary"
            >
                {submitText ? submitText : t('components.forms.formButtons.save')}
            </Button>
            {isDirty ? (
                <Button sx={{ m: 1, ml: 0 }} variant="outlined" color="primary" onClick={() => onResetClick()}>
                    {t('components.forms.formButtons.reset')}
                </Button>
            ) : (
                getCloseButton()
            )}
        </Box>
    );
}

XFormButtons.defaultProps = {
    onCloseClick: null,
    submitText: '',
    userError: ''
};
export default XFormButtons;
