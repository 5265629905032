import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import XSidebarContainer from './sidebarContainer';
import XContentContainer from './contantContainer';
import { PageContainerProps } from 'src/types';

function XPageContainer(props: PageContainerProps) {
    const direction = useAppSelector((x) => x.account.direction);

    return (
        <div>
            <Helmet>
                <title>{props.sideBar?.title || props.topBar?.title}</title>
            </Helmet>

            {direction == 'ltr' && (
                <Grid container>
                    <Grid item xs={0} md={props.sideBar ? 3 : 0}>
                        {props.sideBar && <XSidebarContainer direction={direction} sidebarProps={props.sideBar} />}
                    </Grid>
                    <Grid xs={12} item md={props.sideBar ? 9 : 12}>
                        <XContentContainer props={props} />
                    </Grid>
                </Grid>
            )}

            {direction == 'rtl' && (
                <Grid container>
                    <Grid xs={12} item md={props.sideBar ? 9 : 12}>
                        <XContentContainer props={props} />
                    </Grid>
                    <Grid item xs={0} md={props.sideBar ? 3 : 0}>
                        {props.sideBar && <XSidebarContainer direction={direction} sidebarProps={props.sideBar} />}
                    </Grid>
                </Grid>
            )}
        </div>
    );
}

export default XPageContainer;
