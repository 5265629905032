import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { UpdateProfileInputs } from './schema';

const LastName = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<UpdateProfileInputs>();
    return (
        <TextField
            fullWidth
            error={errors?.lastName && true}
            {...register(nameOf<UpdateProfileInputs>('lastName'))}
            id={nameOf<UpdateProfileInputs>('lastName')}
            placeholder={t('user.submit.last_name')}
            label={t('user.submit.last_name')}
            size="medium"
            type="text"
            helperText={errors?.lastName?.message}
        />
    );
};

export default LastName;
