import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { XTableContainer, useAlignment } from 'src/components';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import Head from './Head';
import { getUsers, setUsersPaging } from '../../slice';
import Actions from './Actions';
import { useParams } from 'react-router';
import useAfterMemberChanged from './useAfterMemberAdded';
import Top from './Top';

function Users({ showMembers }: { showMembers: boolean }) {
    const { id } = useParams();
    useAfterMemberChanged(id, showMembers);
    const { tableData, state } = useAppSelector((x) => x.teams.userlist);
    const dispatch = useAppDispatch();
    const align = useAlignment();

    useEffect(() => {
        dispatch(
            getUsers({
                teamId: id,
                isMember: showMembers
            })
        );
    }, [id, showMembers]);

    const onPaging = (page: number, limit: number) => {
        dispatch(
            setUsersPaging({
                page,
                limit
            })
        );
        dispatch(
            getUsers({
                teamId: id,
                isMember: showMembers
            })
        );
    };

    return (
        <>
            <Top />
            <XTableContainer
                totalItems={tableData.totalItems}
                page={tableData.page}
                limit={tableData.limit}
                onPaging={onPaging}
                loading={state === 'inProgress'}
            >
                <Table size="small">
                    <Head />
                    <TableBody>
                        {tableData.items.map((x) => (
                            <TableRow hover key={x.id}>
                                <TableCell align={align('left')} padding="normal">
                                    {x.firstName}
                                </TableCell>
                                <TableCell align={align('left')}>{x.lastName}</TableCell>
                                <TableCell align={align('left')}>{x.email}</TableCell>
                                <TableCell align={align('right')}>
                                    <Actions teamId={id} user={x} isMember={!showMembers}></Actions>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </XTableContainer>
        </>
    );
}

export default Users;
