import Head from './head/Head';
import ExistingBotInterface from './ExistingBotInterface';
import CustomInterface from './CustomInterface';
import { FormProvider } from 'react-hook-form';
import useSubmitForm from './useSubmitForm';
import XFormButtons from 'src/components/Forms/XFormButton';
import { useAppSelector } from 'src/store/hooks';
import useAfterSubmit from './useAfterSubmit';

const SubmitContainer = ({ button }: { button: string }) => {
    const templateActopn = useAppSelector((x) =>
        x.campaigns.current.value.templateActions.find((x) => x.buttonName == button)
    );
    const { methods, onSubmit } = useSubmitForm(button, templateActopn);
    useAfterSubmit();
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Head button={button} />
                <ExistingBotInterface />
                <CustomInterface />
                {!templateActopn && (
                    <XFormButtons
                        authPolicy="Supervision"
                        isDirty={methods.formState.isDirty}
                        onResetClick={methods.reset}
                        returnTo="/campaigns"
                    />
                )}
            </form>
        </FormProvider>
    );
};

export default SubmitContainer;
