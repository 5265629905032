import CommonForm from './commonForm';

const EndConversation = () => {
    return (
        <CommonForm
            interfaceSubType="Text_ConversationEnded"
            api="EndConversation"
            descriptionKey="bot.settings.end_conversation.description"
            bodyEnglishKey="bot.settings.end_conversation.bodyEnglish"
            bodyArabicKey="bot.settings.end_conversation.bodyArabic"
        ></CommonForm>
    );
};

export default EndConversation;
