import { Card, CardActionArea, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import { t } from 'i18next';
import { getEventCategories, getEvents, getEventsTemplates } from 'src/screens/salla/slice';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import AddTaskIcon from '@mui/icons-material/AddTask';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import { XPageContainer } from 'src/components';
import Uninstall from '../install/Uninstall';
import { getTemplates } from 'src/screens/templates/slice';

export default function SallaEvents() {
    const dispatch = useAppDispatch();
    const categoryId = useParams().id;

    const category = useAppSelector((x) => x.salla.sallaEventCategories).find((c) => c.id == categoryId);
    const { sallaEvents, sallaEventTemplates, state } = useAppSelector((x) => x.salla);
    const events = sallaEvents.filter((e) => e.sallaEventCategoryId == categoryId);
    const { items: templates, state: templatesState } = useAppSelector((x) => x.templates.list);
    const direction = useAppSelector((state) => state.account.direction);

    useEffect(() => {
        if (!templates?.length) {
            dispatch(getTemplates(''));
        }

        if (!category) {
            dispatch(getEventCategories());
        }

        if (!sallaEventTemplates?.length) {
            dispatch(getEventsTemplates());
        }

        if (!sallaEvents?.length) {
            dispatch(getEvents());
        }
    }, []);

    const getTemplateName = (eventId: string) => {
        const templateId = sallaEventTemplates.find((x) => x.sallaEventId == eventId)?.metaTemplateId;
        const template = templates.find((x) => x.id == templateId);
        return template?.name;
    };

    return (
        <XPageContainer
            loading={state == 'inProgress' || templatesState == 'inProgress'}
            topBar={{
                title: category?.name,
                backLink: './../../',
                action: <Uninstall />
            }}
        >
            <div dir={direction}>
                <Typography variant="subtitle2" sx={{ m: 2, fontWeight: 'bold' }}>
                    {t('salla.events.heading.part_1')}{' '}
                    <span style={{ color: 'rgb(230, 26, 128)' }}>{category?.name}</span>
                    {t('salla.events.heading.part_2')}
                    <Typography sx={{ pb: 1 }} variant="subtitle1">
                        {t('salla.events.sub_heading')}
                    </Typography>
                </Typography>
                <Grid container spacing={2}>
                    {events.map((event, index) => (
                        <Grid item xs={12} md={6} lg={3} key={index}>
                            <Card key={index} sx={{ m: 2, backgroundColor: 'rgb(236 237 245)' }}>
                                <Link
                                    to={`/apps/salla/events/${event.id}/event-setup`}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <CardActionArea
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            p: 1,
                                            pb: 3,
                                            height: '200px'
                                        }}
                                    >
                                        <Typography variant="h4" sx={{ color: 'rgb(230, 26, 128)' }}>
                                            {event.friendlyName}
                                        </Typography>
                                        {event.isConfigured ? (
                                            <>
                                                <AddTaskIcon sx={{ color: 'success.main', fontSize: '75px' }} />
                                                <Typography sx={{ color: 'success.main' }} variant="subtitle1">
                                                    {getTemplateName(event.id)}
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <SettingsSuggestIcon sx={{ fontSize: '75px' }} />
                                                <Typography color="primary" variant="subtitle1">
                                                    {t('salla.events.configure_now')}
                                                </Typography>
                                            </>
                                        )}
                                    </CardActionArea>
                                </Link>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </XPageContainer>
    );
}
