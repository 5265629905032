import yupx from 'src/locales/yupLocale';

export interface EmailCampaignInputs {
    subject: string;
    body: string;
    recipients: string[];
    csvFile: {
        link: string;
        type: string;
        name: string;
    };
    image: {
        link: string;
        type: string;
        name: string;
    };
    acceptTermsAndConditions: boolean;
}

export const schema = yupx.object().shape({
    subject: yupx.string().required().trim().label('emailCampaigns.compose.yup.name'),
    body: yupx.string().required().trim().label('emailCampaigns.compose.yup.body'),
    csvFile: yupx.object().required().label('emailCampaigns.compose.yup.csv_file'),
    recipients: yupx.array().required().label('emailCampaigns.compose.yup.csv_file'),
    image: yupx.object().label('emailCampaigns.compose.yup.image'),
    acceptTermsAndConditions: yupx.bool().required().oneOf([true])
});
