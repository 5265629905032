import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { WhatsaapInfoInputs } from './schema';
import HelperIcon from './HelperIcon';

const PhoneNumber = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<WhatsaapInfoInputs>();

    return (
        <TextField
            fullWidth
            error={errors?.whatsAppBusinessPhoneNumber && true}
            {...register(nameOf<WhatsaapInfoInputs>('whatsAppBusinessPhoneNumber'))}
            id={nameOf<WhatsaapInfoInputs>('whatsAppBusinessPhoneNumber')}
            placeholder={t('business.submit.phone_number')}
            label={t('business.submit.phone_number')}
            size="medium"
            type="text"
            helperText={errors?.whatsAppBusinessPhoneNumber?.message}
        />
    );
};

export default PhoneNumber;
