import { Box, TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useStyles } from 'src/components';

const BodySamples = ({ inputColor }: { inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        watch,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();
    const { borderRounded } = useStyles();

    const text = watch('body.text');
    const variables = _.sortBy(text.match(/{{(\d+)}}/g));

    return (
        <>
            {variables?.length > 0 && (
                <Box sx={{ ...borderRounded, p: 2, mt: 2 }}>
                    <Typography variant="h5">{t('templates.submit.body.sample_header')}</Typography>
                    <Typography variant="body1" sx={{ fontSize: 11 }}>
                        {t('templates.submit.body.sample_description')}
                    </Typography>
                    {variables.map((x, i) => (
                        <Box sx={{ p: 1, display: 'flex', alignItems: 'center' }} key={i}>
                            <Typography variant="subtitle2">{`{{${i + 1}}}`}</Typography>
                            <input
                                type="hidden"
                                {...register(`body.variables.${i}.index`)}
                                value={i + 1}
                                id={`body.variables.${i}.index`}
                            />
                            <TextField
                                fullWidth
                                error={errors?.body?.variables[i]?.sample && true}
                                {...register(`body.variables.${i}.sample`)}
                                id={`body.variables.${i}.sample`}
                                placeholder={t('templates.submit.body.sample_placeholder').replace(
                                    '{{}}',
                                    `{{${i + 1}}}`
                                )}
                                size="small"
                                type="text"
                                multiline
                                sx={{
                                    ml: 1,
                                    mr: 1,
                                    '&.MuiFormControl-root': {
                                        '.MuiOutlinedInput-root': {
                                            background: inputColor
                                        },
                                        '.MuiOutlinedInput-input': {
                                            background: inputColor
                                        }
                                    }
                                }}
                                helperText={errors?.body?.variables[i]?.sample?.message}
                            />
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};

export default BodySamples;
