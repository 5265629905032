import { XPageContainer } from 'src/components';
import { useTranslation } from 'react-i18next';
import { Card, CardActionArea, Grid, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { getApps } from './slice';
import { App, SallaApp } from 'src/types';
import Salla from './Salla';

const getApp = (app: App) => {
    if (app.app == 'Salla') {
        return <Salla app={app as SallaApp} />;
    }
};

function Apps() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { apps, state } = useAppSelector((x) => x.apps);
    const direction = useAppSelector((x) => x.account.direction);

    useEffect(() => {
        dispatch(getApps());
    }, []);

    return (
        <XPageContainer
            loading={state == 'inProgress'}
            topBar={{
                title: t('layout.sidebar.business_management.apps')
            }}
        >
            <Grid container spacing={2} dir={direction}>
                {apps.map((app, index) => (
                    <Grid item xs={'auto'} sm={6} md={4} lg={3} key={index}>
                        <Card
                            key={index}
                            sx={{
                                m: 2
                            }}
                        >
                            <Link
                                to={!app.installed ? 'salla/install' : 'salla/categories'}
                                style={{ textDecoration: 'none' }}
                            >
                                <CardActionArea
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                        pb: 2,
                                        height: '250px'
                                    }}
                                >
                                    {getApp(app)}
                                </CardActionArea>
                            </Link>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </XPageContainer>
    );
}

export default Apps;
