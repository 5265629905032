import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import { useTranslation } from 'react-i18next';
import SectionHeading from '../SectionHeading';
import ButtonTypes from './ButtonTypes';
import CallToAction from './callToaction';
import QuickReply from './quickReply';

const Buttons = ({ inputColor }: { inputColor: string }) => {
    const { t } = useTranslation();
    const { watch } = useFormContext<SubmitTemplateInputs>();

    const buttonType = watch('buttons.buttonGroupType');

    return (
        <>
            <SectionHeading
                title={t('templates.submit.buttons.title')}
                required={false}
                description={t('templates.submit.buttons.description')}
            />

            <ButtonTypes inputColor={inputColor} />
            {buttonType == 'CALL_TO_ACTION_GROUP' && <CallToAction inputColor={inputColor} />}
            {buttonType == 'QUICK_REPLY_GROUP' && <QuickReply inputColor={inputColor} />}
        </>
    );
};

export default Buttons;
