import { Box, Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { Bot, BotInterface } from 'src/types';
import Title from './Title';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import XFormButtons from 'src/components/Forms/XFormButton';
import useFormWatch from './useFormWatch';
import { NEW_INTERFACE } from '../../slice';
import { XPreview, useStyles } from 'src/components';
import MessageTypes, { MessageType } from './MessageTypes';
import { useEffect, useState } from 'react';
import NonProductMessage from './nonProductMessage';
import ProductMessage from './productMessage';
import { useOutletContext, useParams } from 'react-router-dom';
import _ from 'lodash';

const getMessageType = (botInterface: BotInterface) => {
    if (
        botInterface.interfaceSubType == 'Text_Catalog' ||
        botInterface.interfaceType == 'SingleProduct' ||
        botInterface.interfaceType == 'MultiProduct'
    )
        return 'productMessage';
    return 'nonProductMessage';
};

const Content = () => {
    const { optionId } = useParams();
    const bot = useOutletContext() as Bot;
    const { borderRB } = useStyles();

    const interactiveList = bot.botInterfaces.find((x) =>
        _.includes(
            _.map(x.interactiveList?.options, (p) => p.id),
            optionId
        )
    )?.interactiveList;

    const option = interactiveList?.options?.find((x) => x.id == optionId);

    const botInterface = bot.botInterfaces.find(
        (x) => x.id == (option?.targetInterfaceId ? option.targetInterfaceId : NEW_INTERFACE)
    );

    useAfterSubmit();
    const { methods, onSubmit } = useSubmitForm(botInterface, option);
    const previewData = useFormWatch(methods.watch, botInterface);

    const [messageType, setMessageType] = useState<MessageType>(getMessageType(botInterface));

    useEffect(() => {
        setMessageType(getMessageType(botInterface));
    }, [botInterface]);
    const onMessageTypeChange = (value: MessageType) => {
        setMessageType(value);
    };
    return (
        <>
            <Grid container>
                <Grid item md={8} xs={12} sx={{ ...borderRB, p: 1 }}>
                    <FormProvider {...methods}>
                        <form onSubmit={methods.handleSubmit(onSubmit)}>
                            <MessageTypes onChange={onMessageTypeChange} messageType={messageType}></MessageTypes>
                            <Title></Title>

                            {messageType == 'nonProductMessage' && (
                                <NonProductMessage title={option?.title}></NonProductMessage>
                            )}
                            {messageType == 'productMessage' && (
                                <ProductMessage botInterface={botInterface}></ProductMessage>
                            )}

                            <Box sx={{ pl: 1, mt: 2 }}>
                                <XFormButtons
                                    authPolicy="Supervision"
                                    isDirty={methods.formState.isDirty}
                                    onResetClick={methods.reset}
                                    returnTo="/bot"
                                />
                            </Box>
                        </form>
                    </FormProvider>
                </Grid>
                <Grid item md={4} sx={borderRB}>
                    <Box
                        sx={{
                            p: 1,
                            mx: 1,
                            background: 'url(/static/images/whatsapp-background.png)'
                        }}
                    >
                        <Box
                            sx={{
                                p: 1,
                                background: 'rgb(203 255 217)'
                            }}
                        >
                            {previewData && <XPreview botInterface={previewData}></XPreview>}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default Content;
