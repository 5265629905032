import { WelcomeMessageInputs } from 'src/screens/bot/settings/content/welcomeMessage/schema';
import { ApiResponse, Bot, parseMediaType } from 'src/types';
import apiClient from './apiClient';
import { EditorInputs } from 'src/screens/bot/options/content/schema';
import { CommonInputs } from 'src/screens/bot/settings/content/commonForm/schema';
import { SelectLanguageInputs } from 'src/screens/bot/settings/content/selectLanguage/schema';
import { FeedbackMessageInputs } from 'src/screens/bot/settings/content/feedback/schema';

export class BotService {
    async getBusinessBot(): Promise<ApiResponse<Bot>> {
        const { data } = await apiClient.get<ApiResponse<Bot>>('/bot');
        return data;
    }

    async deleteOption(id: string): Promise<ApiResponse<string>> {
        const { data } = await apiClient.delete<ApiResponse<string>>(`/bot/options/${id}`);
        return data;
    }

    async updateWelcomeMessage(inputs: WelcomeMessageInputs): Promise<ApiResponse<string>> {
        const { data } = await apiClient.put<ApiResponse<string>>(`/bot/WelcomeMessage`, inputs);
        return data;
    }

    async updateSelectLanguage(inputs: SelectLanguageInputs): Promise<ApiResponse<string>> {
        const { data } = await apiClient.put<ApiResponse<string>>(`/bot/LanguageSelector`, inputs);
        return data;
    }

    async updateSettings(inputs: CommonInputs): Promise<ApiResponse<string>> {
        const { data } = await apiClient.put<ApiResponse<string>>(`/bot/${inputs.api}`, inputs);
        return data;
    }

    async addOption(inputs: EditorInputs): Promise<ApiResponse<string>> {
        const payload = await this.getPayload(inputs);
        const response = await apiClient.post<ApiResponse<string>>(`/bot/options`, payload);
        return response.data;
    }

    async updateOption(inputs: EditorInputs): Promise<ApiResponse<string>> {
        const payload = await this.getPayload(inputs);
        const response = await apiClient.put<ApiResponse<string>>(`/bot/options/${inputs.optionId}`, payload);
        return response.data;
    }

    async getMediaUrl(inputs: EditorInputs) {
        let url = inputs.media?.link;
        if (inputs.media?.link?.startsWith('blob:')) {
            let blob_file = await fetch(inputs.media.link).then((r) => r.blob());
            let formData = new FormData();
            let file = new File([blob_file], inputs.media.name, {
                type: blob_file.type
            });
            formData.append('file', file, inputs.media.name);

            var { data } = await apiClient.post<ApiResponse<{ url: string }>>(`/media`, formData, {
                headers: {
                    'Content-Type': `multipart/form-data`
                }
            });
            if (data.status == 'Successful') {
                url = data.data.url;
            } else {
                return {
                    data: '',
                    status: 'Failed',
                    message: 'Error saving media file'
                };
            }
        }
        return url;
    }

    async getPayload(inputs: EditorInputs) {
        const url = await this.getMediaUrl(inputs);

        let payload: any = {
            title: inputs.title,
            locale: inputs.locale,
            targetInterface: {}
        };

        if (inputs.flag == 'catalog') {
            payload.targetInterface.catalog = true;
        } else if (inputs.flag == 'singleProduct') {
            payload.targetInterface.singleProduct = {
                body: inputs.body,
                productRetailerId: inputs.products[0].retailerId
            };
        } else if (inputs.flag == 'multiProduct') {
            payload.targetInterface.multiProductObject = {
                header: inputs.header,
                body: inputs.body,
                productRetailerIds: inputs.products.map((x) => x.retailerId)
            };
        } else if (inputs.actions && inputs.actions.length > 0) {
            payload.targetInterface.interactiveButtons = {
                text: inputs.body,
                replyButtons: inputs.actions.map((x) => ({
                    title: x.title,
                    type: x.actionType,
                    supportTeamId: x.supportTeamId,
                    targetInterfaceId: x.targetInterfaceId
                })),
                media: url
                    ? {
                          type: parseMediaType(inputs.media.type),
                          link: url
                      }
                    : null
            };
        } else if (url) {
            payload.targetInterface.MediaWithCaption = {
                type: parseMediaType(inputs.media.type),
                link: url,
                caption: inputs.body
            };
        } else if (inputs.body) {
            payload.targetInterface.text = inputs.body;
        }

        return payload;
    }

    async changeBotStatus(isActive: boolean): Promise<ApiResponse<string>> {
        const { data } = await apiClient.put<ApiResponse<string>>('/bot/status', { isEnabled: isActive });
        return data;
    }

    async updateBlockUrls(blockUrls: boolean): Promise<ApiResponse<string>> {
        const { data } = await apiClient.put<ApiResponse<string>>('/bot/blockUrls', { value: blockUrls });
        return data;
    }

    async updateFeedbackStatus(feedbackStatus: boolean): Promise<ApiResponse<string>> {
        const { data } = await apiClient.put<ApiResponse<string>>('/bot/feedbackStatus', { value: feedbackStatus });
        return data;
    }

    async updateFeedbackInterfaces(inputs: FeedbackMessageInputs): Promise<ApiResponse<string>> {
        const response = await apiClient.put<ApiResponse<string>>(`/bot/feedback`, inputs);
        return response.data;
    }

    async updateInvalidActionResponseStatus(invalidActionResponseStatus: boolean): Promise<ApiResponse<string>> {
        const { data } = await apiClient.put<ApiResponse<string>>('/bot/invalidMessageStatus', {
            Status: invalidActionResponseStatus
        });
        return data;
    }
}

export default new BotService();
