import yupx from 'src/locales/yupLocale';

export interface GroupInputs {
    name: string;
    csvFile?: {
        link: string;
        type: string;
        name: string;
    };
}

export const schema = yupx.object().shape({
    name: yupx.string().required().trim().label('groups.yup.rename.name'),
    csvFile: yupx.object().label('groups.yup.rename.csv_file')
});
