import yupx from 'src/locales/yupLocale';

export interface CampaignInterfaceInputs {
    buttonName: string;
    teamId: string;
    locale: string;
    campaignId: string;
    campaignInterface: {
        interfaceId: string;
        isBotInterface: boolean;
        message: string;
    };
}

export const schema = yupx.object().shape({
    campaignId: yupx.string().required().trim().label('campaigns.yup.customise.campaign_id'),
    buttonName: yupx.string().required().trim().label('campaigns.yup.customise.button_name'),
    locale: yupx.string().required().trim().label('campaigns.yup.customise.locale'),
    campaignInterface: yupx.object().shape({
        message: yupx.string().when('isBotInterface', ([isBotInterface], schema) => {
            if (isBotInterface) {
                return schema.notRequired();
            }
            return schema.required().trim().label('campaigns.yup.customise.campaign_interface.message');
        }),
        interfaceId: yupx.string().when('isBotInterface', ([isBotInterface], schema) => {
            if (isBotInterface) {
                return schema.required().trim().label('campaigns.yup.customise.campaign_interface.interface_id');
            }
            return schema.notRequired();
        })
    })
});
