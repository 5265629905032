import { IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const CloseSnackbarAction = ({ id }: any) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton
            style={{ marginTop: '0.1em', color: '#fff' }}
            onClick={() => {
                closeSnackbar(id);
            }}
        >
            <CloseIcon />
        </IconButton>
    );
};

export default CloseSnackbarAction;
