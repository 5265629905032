import { useEffect, useState } from 'react';
import { getBusinessesBot } from 'src/screens/bot/slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useTranslation } from 'react-i18next';
import { Box, Card } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { XComponentContainer, XPageContainer, XSidebarListItem } from 'src/components';
import { Outlet, useNavigate } from 'react-router';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PhonelinkEraseIcon from '@mui/icons-material/PhonelinkErase';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import RateReviewIcon from '@mui/icons-material/RateReview';

function Settings() {
    const navigate = useNavigate();
    const { bot, loadState } = useAppSelector((x) => x.businessBot);
    const { t } = useTranslation();

    useEffect(() => {
        if (loadState != 'successful') {
            navigate('/bot');
        }
    }, []);

    const menu = [
        {
            icon: <HomeIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.welcomeMessage.title'),
            to: 'welcome-message'
        },
        {
            icon: <PhonelinkEraseIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.end_conversation.title'),
            to: 'end-conversation'
        },
        {
            icon: <SupportAgentIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.transfer_to_eam.title'),
            to: 'transfer-to-team'
        },
        {
            icon: <DangerousOutlinedIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.invalid_action.title'),
            to: 'invalid-action'
        },
        {
            icon: <LockClockOutlinedIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.outside_working_hours.title'),
            to: 'outside-working-hours'
        },
        {
            icon: <NotInterestedIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.marketing_opt_out.title'),
            to: 'marketing-opt-out'
        },
        {
            icon: <GTranslateIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.select_language.title'),
            to: 'select-language'
        },
        {
            icon: <LinkOffIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.block_urls.title'),
            to: 'block-urls'
        },
        {
            icon: <RateReviewIcon fontSize="medium" color="primary" />,
            text: t('bot.settings.feedback.title'),
            to: 'feedback'
        }
    ];

    const [title, setTitle] = useState(menu[0].text);

    return (
        <XPageContainer
            sideBar={{
                content: loadState == 'successful' && (
                    <>
                        {menu.map((x) => {
                            return (
                                <XSidebarListItem
                                    onClick={() => setTitle(x.text)}
                                    to={x.to}
                                    key={x.text}
                                    title={x.text}
                                    icon={x.icon}
                                />
                            );
                        })}
                    </>
                ),
                title: t('bot.settings.title')
            }}
            topBar={{
                action: <></>,
                title: title,
                backLink: '/bot'
            }}
        >
            <XComponentContainer>
                <Box sx={{ px: 2, mr: 2, height: '100%' }}>
                    <Outlet context={bot} />
                </Box>
            </XComponentContainer>
        </XPageContainer>
    );
}

export default Settings;
