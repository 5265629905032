import { useRoutes } from 'react-router';
import getAuthorizedRoutes from './authorizedRoutes';
import unAuthorizedRoutes from './unAuthorizedRoutes';
import LocalTokenService from 'src/services/localToken.service';
import { useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import { AuthService } from 'src/services/auth.service';

const TokenRefresher = () => {
    useEffect(() => {
        var interval = setInterval(() => {
            authService.refreshToken();
        }, 300000);

        return () => {
            clearInterval(interval);
        };
    }, []);
    return <></>;
};

const authService = new AuthService();

const Content = () => {
    const { user } = useAppSelector((x) => x.account);
    const tokens = LocalTokenService.getTokens();

    if (tokens) {
        return (
            <>
                <TokenRefresher />
                {useRoutes(getAuthorizedRoutes(user))}
            </>
        );
    }
    return useRoutes(unAuthorizedRoutes);
};

export default Content;
