import { ColumnLabel, XTableHead } from 'src/components';

function Head() {
    const columns: ColumnLabel[] = [
        {
            align: 'left',
            labelKey: 'groups.contacts.list.head.phone_number'
        },
        {
            align: 'left',
            labelKey: 'groups.contacts.list.head.name'
        },
        {
            align: 'left',
            labelKey: 'groups.contacts.list.head.locale'
        },
        {
            align: 'right',
            labelKey: 'common.lists.actions'
        }
    ];
    return <XTableHead columns={columns} />;
}

export default Head;
