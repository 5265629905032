import { useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { BotInterface, Action, parseMediaType } from 'src/types';
import { EditorInputs } from './schema';

const useFormWatch = (watch: UseFormWatch<EditorInputs>, botInterface: BotInterface) => {
    const [previewData, setPreviewData] = useState(botInterface);
    watch((data, { name, type }) => {
        if (data.actions.length > 0) {
            setPreviewData({
                ...previewData,
                interfaceType: 'InteractiveButtons',
                interactiveButtons: {
                    replyButtons: data.actions as Action[],
                    text: data.body,
                    media: data.media
                        ? {
                              type: parseMediaType(data.media.type),
                              link: data.media.link
                          }
                        : null
                }
            });
        } else if (data.media) {
            setPreviewData({
                ...previewData,
                interfaceType: 'MediaWithCaption',
                mediaWithCaption: {
                    caption: data.body,
                    media: {
                        type: parseMediaType(data.media.type),
                        link: data.media.link
                    }
                }
            });
        } else {
            setPreviewData({
                ...previewData,
                interfaceType: 'Text',
                text: data.body
            });
        }
    });

    return previewData;
};

export default useFormWatch;
