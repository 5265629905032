import { Box, PopperPlacementType } from '@mui/material';
import MediaPicker from '../Media/MediaPicker';
import PlaceholderMaker from './PlaceholderMaker';
import XEmojiPicker from '../EmojiPicker';
import { makeStyles } from '@mui/styles';
import ToolbarItem from './ToolbarItem';

interface ToolbarProps {
    inputRef: React.MutableRefObject<HTMLInputElement>;
    media?: {
        acceptedFileTypes?: string;
        onMediaSelect?: (link: string, type: string, name: string) => void;
    };
    emoji?: {
        onEmojiSelect: (value: string) => void;
        placement: PopperPlacementType;
    };
    placeHolder?: {
        onPlaceHolderSelect: (value: string) => void;
    };
}

const XToolbar = (props: ToolbarProps) => {
    return (
        <Box sx={{ display: 'flex', mt: 1 }}>
            <ToolbarItem>
                {props.placeHolder && (
                    <PlaceholderMaker onInpuChange={props.placeHolder.onPlaceHolderSelect} inputRef={props.inputRef} />
                )}
            </ToolbarItem>
            {props.media && (
                <ToolbarItem>
                    <MediaPicker
                        uniqueId="toolbar-media-picker"
                        accept={props.media.acceptedFileTypes}
                        onMediaSelected={props.media.onMediaSelect}
                    />
                </ToolbarItem>
            )}
            {/* <ToolbarItem>
                {props.emoji && (
                    <XEmojiPicker
                        placement={props.emoji.placement}
                        onInpuChange={props.emoji.onEmojiSelect}
                        inputRef={props.inputRef}
                    />
                )}
            </ToolbarItem> */}
        </Box>
    );
};

export default XToolbar;
