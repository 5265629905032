import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Bot from 'src/screens/bot';
import Settings from 'src/screens/bot/settings';
import Options from 'src/screens/bot/options';
import Content from 'src/screens/bot/options/content';
import DesignWelcomeMessage from 'src/screens/bot/settings/content/welcomeMessage';
import SelectLanguage from 'src/screens/bot/settings/content/selectLanguage';
import InvalidAction from 'src/screens/bot/settings/content/InvalidAction';
import OutsideWorkingHours from 'src/screens/bot/settings/content/OutsideWorkingHours';
import EndConversation from 'src/screens/bot/settings/content/EndConversation';
import TransferToTeam from 'src/screens/bot/settings/content/TransferToTeam';
import MarketingOptOut from 'src/screens/bot/settings/content/MarketingOptOut';
import BlockUrls from 'src/screens/bot/settings/content/BlockUrls';
import Feedback from 'src/screens/bot/settings/content/feedback';

const botRoutes: RouteObject = {
    path: 'bot',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <Bot />
        },
        {
            path: 'options',
            element: <Options />,
            children: [
                {
                    path: ':optionId',
                    element: <Content />
                }
            ]
        },
        {
            path: 'settings',
            element: <Settings />,
            children: [
                {
                    path: 'welcome-message',
                    element: <DesignWelcomeMessage />
                },
                {
                    path: 'end-conversation',
                    element: <EndConversation />
                },
                {
                    path: 'transfer-to-team',
                    element: <TransferToTeam />
                },
                {
                    path: 'invalid-action',
                    element: <InvalidAction />
                },
                {
                    path: 'outside-working-hours',
                    element: <OutsideWorkingHours />
                },
                {
                    path: 'marketing-opt-out',
                    element: <MarketingOptOut />
                },
                {
                    path: 'select-language',
                    element: <SelectLanguage />
                },
                {
                    path: 'block-urls',
                    element: <BlockUrls />
                },
                {
                    path: 'feedback',
                    element: <Feedback />
                }
            ]
        }
    ]
};

export default botRoutes;
