import { TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { SelectLanguageInputs } from './schema';

const TitleEnglish = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<SelectLanguageInputs>();

    return (
        <>
            <Typography
                variant="caption"
                sx={{
                    pt: 1,
                    pl: 1,
                    display: 'flex',
                    justifyContent: 'start',
                    color: 'rgb(230, 26, 128)'
                }}
            >
                {t('bot.settings.select_language.bodyTitle')}
            </Typography>
        </>
    );
};

export default TitleEnglish;
