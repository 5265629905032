import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { WhatsaapInfoInputs, schema } from './schema';
import { updateWhatsAppInfo } from '../slice';

const useSubmitForm = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const business = useAppSelector((x) => x.businesses.list.tableData.items.find((x) => x.id == id));
    const state = useAppSelector((x) => x.businesses.list.state);
    const navigate = useNavigate();

    useEffect(() => {
        if (id && state == undefined) navigate('/businesses');
    }, []);
    const methods = useForm<WhatsaapInfoInputs>({
        resolver: yupResolver(schema),
        defaultValues: business
            ? {
                  businessId: business.id,
                  whatsAppBusinessPhoneNumber: business.whatsAppBusinessPhoneNumber,
                  whatsAppPhoneNumberId: business.whatsAppPhoneNumberId,
                  webhookConfigurationToken: business.webhookConfigurationToken,
                  whatsAppBusinessAccountId: business.whatsappBusinessId,
                  whatsAppApplicationId: business.whatsAppApplicationId,
                  whatsAppToken: ''
              }
            : {}
    });

    const onSubmit = (inputs: WhatsaapInfoInputs) => {
        dispatch(updateWhatsAppInfo(inputs));
    };

    return { id, methods, onSubmit };
};

export default useSubmitForm;
