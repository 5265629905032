import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTranslation } from 'react-i18next';

const ContactsPreview = ({
    contactList,
    direction
}: {
    contactList: { phoneNumber: string; name: string }[];
    direction;
}) => {
    const { t } = useTranslation();
    const alignTableData = direction === 'ltr' ? 'left' : 'right';

    return (
        <TableContainer sx={{ maxHeight: 300, border: '1px solid rgba(224, 224, 224, 1)' }}>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell align={alignTableData}>{t('groups.submit.contacts_preview.phone_number')}</TableCell>
                        <TableCell align={alignTableData}>{t('groups.submit.contacts_preview.name')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {contactList.map(({ phoneNumber, name }: { phoneNumber: string; name: string }, index: number) => {
                        return (
                            <TableRow key={index}>
                                <TableCell align={alignTableData}>{phoneNumber}</TableCell>
                                <TableCell align={alignTableData}>{name}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ContactsPreview;
