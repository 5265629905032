import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import { useTranslation } from 'react-i18next';
import SectionHeading from '../SectionHeading';

const Footer = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<SubmitTemplateInputs>();

    return (
        <>
            <SectionHeading
                title={t('templates.submit.footer.title')}
                required={false}
                description={t('templates.submit.footer.description')}
            />

            <TextField
                fullWidth
                error={errors?.footer && true}
                {...register('footer')}
                id={'footer'}
                placeholder={t('templates.submit.footer.title')}
                label={t('templates.submit.footer.title')}
                type="text"
                size="small"
                multiline
                sx={{
                    '&.MuiFormControl-root': {
                        '.MuiOutlinedInput-root': {
                            background: '#fbfbfb !important'
                        }
                    }
                }}
                helperText={errors?.footer?.message}
            />
        </>
    );
};

export default Footer;
