import { Alert, Box, Button, CircularProgress, Collapse, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { useStyles } from 'src/components';

const Schedule = ({
    date,
    onClose,
    onScheduleClick,
    onScheduleChange
}: {
    date: string;
    onScheduleClick: () => void;
    onScheduleChange: (date: string) => void;
    onClose: () => void;
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value && new Date(event.target.value) <= new Date()) {
            setError('campaigns.instance.schedule.schedule_error_past');
            return;
        }
        setError('');
        onScheduleChange(event.target.value);
    };

    const onClick = () => {
        if (!date) {
            setError('campaigns.instance.schedule.schedule_error');
            return;
        }
        setError('');
        onScheduleClick();
    };
    const { state } = useAppSelector((x) => x.campaigns.current.schedule);
    const { borderRoundedSuccess } = useStyles();

    return (
        <Box sx={{ ...borderRoundedSuccess, p: 2 }}>
            <Collapse in={state == 'successful'}>
                <Alert> {t('campaigns.instance.schedule.schedule_success')}</Alert>
            </Collapse>
            {state != 'successful' && (
                <Box>
                    <Typography variant="h5" sx={{ pb: 1, color: 'rgb(230, 26, 128)' }}>
                        {t('campaigns.instance.schedule.title')}
                    </Typography>
                    <Typography variant="body1">{t('campaigns.instance.schedule.heading')}</Typography>
                    <Typography sx={{ mt: 1, fontSize: 'smaller' }} variant="body1">
                        {t('campaigns.instance.schedule.sub_heading')}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                        <TextField
                            size="small"
                            type="datetime-local"
                            value={date}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event)}
                        />

                        {state == 'inProgress' ? (
                            <CircularProgress sx={{ p: 1 }} />
                        ) : (
                            <Box sx={{ mt: 1 }}>
                                <Button onClick={() => onClick()} sx={{ mr: 1 }} variant="outlined" size="small">
                                    {t('campaigns.instance.schedule.schedule_now')}
                                </Button>{' '}
                                <Button onClick={() => onClose()} sx={{ mr: 1 }} variant="outlined" size="small">
                                    {t('components.forms.formButtons.close')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Box>{error && <FormHelperText error>{t(error)}</FormHelperText>}</Box>
                </Box>
            )}
        </Box>
    );
};

export default Schedule;
