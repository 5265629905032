import SettingsEthernetOutlinedIcon from '@mui/icons-material/SettingsEthernetOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const addValueToInput = (
    value: string,
    inputRef: React.MutableRefObject<HTMLInputElement>,
    onInpuChange: (value: string) => void,
    highLight: boolean
) => {
    const inputValue = inputRef.current?.value;
    let position = inputValue.length ?? 0;
    if (inputRef.current) {
        inputRef.current.focus();
        position = inputRef.current.selectionStart;
    }
    const newValue = inputValue.slice(0, position) + value + inputValue.slice(position);

    onInpuChange(newValue);
    inputRef.current.focus();
    if (highLight) {
        inputRef.current.setSelectionRange(position, position + value.length);
    }
};

const PlaceholderMaker = ({
    onInpuChange,
    inputRef
}: {
    onInpuChange: (value: string) => void;
    inputRef: React.MutableRefObject<HTMLInputElement>;
}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onPlaceHolderSelect = (option: string) => {
        setAnchorEl(null);
        if (option) {
            addValueToInput('{{' + option + '}}', inputRef, onInpuChange, true);
        }
    };

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        addValueToInput('', inputRef, onInpuChange, true);
    };

    return (
        <>
            <span onClick={handleMenuOpen} color="primary">
                <SettingsEthernetOutlinedIcon fontSize="small" color="primary" />
            </span>
            <Menu anchorEl={anchorEl} open={open} onClose={() => onPlaceHolderSelect('')}>
                <MenuItem onClick={() => onPlaceHolderSelect('Name')}>
                    {t('components.place_holder_maker.name')}
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        onPlaceHolderSelect('Phone number');
                    }}
                >
                    {t('components.place_holder_maker.phone_number')}
                </MenuItem>
            </Menu>
        </>
    );
};

export default PlaceholderMaker;
