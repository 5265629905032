import { UserLocale } from './types.shared';
import { Product } from './types.whatsApp';

const imageExtensions = ['jpeg', 'jpg', 'png', 'tif', 'tiff', 'bmp', 'gif', 'eps', 'raw', 'svg'];
const audioExtensions = ['flac', 'mp3', 'alac', 'wav', 'wma', 'aac', 'aiff', 'ogg'];
const videoExtensions = ['mp4', 'mov', 'wmv', 'avi', 'flv', 'mkv', 'avchd', 'webm', 'mpeg'];

export function parseMediaType(fileType: string): MediaType {
    if (fileType == 'Document' || fileType == 'Video' || fileType == 'Image') return fileType;
    const parts = fileType.toLocaleLowerCase().split('/');
    const mimeType = parts.length > 0 ? parts[0] : '';
    if (mimeType == 'audio') {
        return 'Audio';
    }

    const extension = parts.length > 0 ? parts.reverse()[0] : '';

    let type: MediaType = 'Document';
    if (imageExtensions.includes(extension)) {
        type = 'Image';
    } else if (videoExtensions.includes(extension)) {
        type = 'Video';
    } else if (audioExtensions.includes(extension)) {
        type = 'Audio';
    } else if (extension === 'pdf') {
        type = 'Document';
    } else if (extension === 'rar') {
        type = 'Document';
    } else if (extension === 'zip') {
        type = 'Document';
    } else type = 'Document';
    return type;
}

export interface Bot {
    id: string;
    name: string;
    isActive: boolean;
    urlsBlocked: boolean;
    enableCustomerFeedback: boolean;
    enableInvalidMessageResponse: boolean;
    botInterfaces: BotInterface[];
}

export interface BotInterface {
    id: string;
    order: number;
    interfaceType: InterfaceType;
    interfaceSubType?: InterfaceSubType;
    locale: UserLocale;
    text?: string;
    location?: Location;
    interactiveList?: InteractiveList;
    interactiveButtons?: InteractiveButtons;
    mediaWithCaption?: MediaWithCaption;
    singleProduct?: {
        product: {
            retailerId: string;
            name: string;
        };
        body: string;
    };
    multiProduct?: {
        products: {
            retailerId: string;
            name: string;
        }[];
        header: string;
        body: string;
    };
}

export interface Text {
    value: string;
}

export type MediaType = 'Image' | 'Video' | 'Document' | 'Audio' | 'Sticker';
export type InterfaceType =
    | 'Text'
    | 'MediaWithCaption'
    | 'MediaWithoutCaption'
    | 'InteractiveList'
    | 'InteractiveButtons'
    | 'Location'
    | 'Contact'
    | 'SingleProduct'
    | 'Template'
    | 'MultiProduct';

export type InterfaceSubType =
    | 'Text_TransferingToTeam'
    | 'Text_ConversationEnded'
    | 'Text_InvalidAction'
    | 'Text_OutsideWorkingHours'
    | 'Text_Catalog'
    | 'InteractiveButtons_LanguageSelector'
    | 'Text_MarketingOptOut'
    | 'InteractiveButtons_ProvideFeedback'
    | 'Text_FeedbackProvided';

export interface Media {
    type: MediaType;
    link: string;
}

export interface MediaWithCaption {
    media: Media;
    caption: string;
}

export interface MediaWithoutCaption {
    media: Media;
}

export interface Location {
    latitude: number;
    longitude: number;
    name?: string;
    address?: string;
}

export interface SharedContact {
    name: string;
    phoneNumbers: {
        type: string;
        number: string;
    }[];
}
export interface Action {
    id: string;
    title: string;
    targetInterfaceId?: string;
    actionType:
        | 'TransferToTeam'
        | 'EndConversation'
        | 'InvokeInterface'
        | 'OpenListOption'
        | 'LanguageSelection'
        | 'GoToMainMenu'
        | 'RemoveMedia';
    supportTeamId?: string;
}

export interface InteractiveButtons {
    text: string;
    media?: Media;
    replyButtons: Action[];
}

export interface InteractiveList {
    body: string;
    title: string;
    options: Action[];
}

export interface SingleProduct {
    productRetailerId: string;
    body: string;
}

export interface MultiProduct {
    productRetailerId: string;
    header: string;
    body: string;
    products: Product[];
}
