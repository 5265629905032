import { Button, Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { XComponentContainer, XPageContainer } from 'src/components';
import XFormButtons from 'src/components/Forms/XFormButton';
import Email from './Email';
import BusinessName from './BusinessName';
import WhatsAppBusinessPhoneNumber from './WhatsAppBusinessPhoneNumber';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import WhatsappToken from './WhatsappToken';
import AdminFirstName from './AdminFirstName';
import AdminLastName from './AdminLastName';
import Language from './Language';
import { useTranslation } from 'react-i18next';
import WhatsAppPhoneNumberId from './WhatsAppPhoneNumberId';
import WhatsAppBusinessAccountId from './WhatsAppBusinessAccountId';
import WhatsAppApplicationId from './WhatsAppApplicationId';
import { NavLink } from 'react-router-dom';
import WhatsAppSecret from './WhatsAppSecret';

const Submit = () => {
    const { t } = useTranslation();
    const { userLoggedIn, editMode, methods, onSubmit } = useSubmitForm();
    useAfterSubmit();

    const getTitle= () => {
        if(!userLoggedIn)
        {
            return "Register your business"
        }
        return editMode ? t('business.submit.view_tile') : t('business.submit.add_tile')
    }

    return (
        <XPageContainer
            topBar={{
                title: getTitle(),
                backLink: '/businesses'
            }}
        >
            <XComponentContainer>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ p: 2, mr: 1 }}>
                            <Grid item md={4} sm={6} xs={12}>
                                <BusinessName disabled={editMode} />
                            </Grid>
                            <Grid item md={4} sm={12} xs={12}>
                                <Email disabled={editMode} />
                            </Grid>

                            <Grid item xs={12}></Grid>

                            <Grid item md={4} sm={6} xs={12}>
                                <AdminFirstName disabled={editMode} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <AdminLastName disabled={editMode} />
                            </Grid>
                            <Grid item xs={12}></Grid>

                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppBusinessPhoneNumber disabled={editMode} />
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppPhoneNumberId disabled={editMode} />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppBusinessAccountId disabled={editMode} />
                            </Grid>

                            <Grid item md={4} sm={6} xs={12}>
                                <WhatsAppApplicationId disabled={editMode} />
                            </Grid>

                            <Grid item xs={12}></Grid>

                            <Grid item md={4}>
                                <WhatsAppSecret></WhatsAppSecret>
                            </Grid>

                            <Grid item xs={12}></Grid>

                            <Grid item md={8} xs={12}>
                                <WhatsappToken></WhatsappToken>
                            </Grid>

                            <Grid item xs={12}>
                                <Language disabled={editMode} />
                            </Grid>
                            {editMode ? (
                                <Button
                                    component={NavLink}
                                    to={'/businesses'}
                                    sx={{ m: 2 }}
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                >
                                    {t('components.forms.formButtons.close')}
                                </Button>
                            ) : (
                                <Grid item>
                                    <XFormButtons
                                        authPolicy={editMode ? "Administration": 'Regular'}
                                        isDirty={methods.formState.isDirty}
                                        onResetClick={methods.reset}
                                        returnTo="/businesses"
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </FormProvider>
            </XComponentContainer>
        </XPageContainer>
    );
};

export default Submit;
