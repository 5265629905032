import { useTheme } from '@mui/material';

const useStyles = () => {
    const theme = useTheme();
    return {
        borderRB: {
            borderRight: `${theme.colors.alpha.black[10]} solid 1px`,
            borderBottom: `${theme.colors.alpha.black[10]} solid 1px`
        },
        borderRounded: {
            border: `${theme.colors.alpha.black[10]} solid 1px`,
            borderRadius: 0.8
        },
        borderRoundedSuccess: {
            border: `${theme.colors.success.main} solid 1px`,
            borderRadius: 0.8
        }
    };
};

export default useStyles;
