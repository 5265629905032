import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import useSubmitForm from './useSubmitForm';
import { useAppSelector } from 'src/store/hooks';
import MiddleSection from './middleSection';
import XFormButtons from 'src/components/Forms/XFormButton';
import LeftSection from './leftSection';
import RightSection from './rightSection';
import useAfterSubmit from './useAfterSubmit';
import { useStyles } from 'src/components';

const SubmitContainer = () => {
    const { campaign, methods, onSubmit } = useSubmitForm();
    useAfterSubmit();
    const templates = useAppSelector((x) => x.templates.list.items).filter((x) => x.status == 'APPROVED');
    const { borderRB } = useStyles();

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid item xs={12} md={3} sx={{ ...borderRB }}>
                        <LeftSection templates={templates} />
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ ...borderRB }}>
                        <MiddleSection templates={templates} />
                    </Grid>
                    <Grid item xs={12} md={5} sx={{ ...borderRB }}>
                        <RightSection />
                    </Grid>
                    <Grid item sx={{ m: 2 }}>
                        {!campaign?.id && (
                            <XFormButtons
                                authPolicy="Supervision"
                                isDirty={methods.formState.isDirty}
                                onResetClick={methods.reset}
                                returnTo="/campaigns"
                            />
                        )}
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default SubmitContainer;
