import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import XFormButtons from 'src/components/Forms/XFormButton';
import Title from './Title';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import { useParams } from 'react-router';
import Message from './Message';
import { XComponentContainer, XPageContainer } from 'src/components';
import { t } from 'i18next';

const Submit = () => {
    const { id } = useParams();
    const { methods, onSubmit } = useSubmitForm(id);
    useAfterSubmit();
    return (
        <XComponentContainer>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Grid container spacing={2} sx={{ p: 2 }}>
                        <Grid item>
                            <Title />
                        </Grid>
                        <Grid item xs={12}>
                            <Message />
                        </Grid>
                        <Grid item lg={12}>
                            <XFormButtons
                                authPolicy="Supervision"
                                isDirty={methods.formState.isDirty}
                                onResetClick={methods.reset}
                                returnTo="/conversations/quick-replies"
                            />
                        </Grid>
                    </Grid>
                </form>
            </FormProvider>
        </XComponentContainer>
    );
};

export default Submit;
