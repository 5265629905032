import yupx from 'src/locales/yupLocale';
import { Action, UserLocale } from 'src/types';

export type ProductFlag = 'nonProduct' | 'catalog' | 'singleProduct' | 'multiProduct';

export interface EditorInputs {
    optionId: string;
    title: string;
    header: string;
    body: string;
    media?: {
        link: string;
        type: string;
        name: string;
    };
    locale: UserLocale;
    actions: Action[];
    products: { retailerId: string; name: string }[];
    flag: ProductFlag;
}

export const schema = yupx.object().shape({
    title: yupx.string().required().max(20, 'bot.yup.options.title.max').trim().label('bot.yup.options.title.title'),
    body: yupx
        .string()
        .trim()
        .when('flag', ([flag], schema) => {
            if (flag == 'catalog') {
                return schema.notRequired();
            }
            return schema.required().max(1024, 'bot.yup.options.body.max').label('bot.yup.options.body.body');
        }),
    header: yupx
        .string()
        .trim()
        .when('flag', ([flag], schema) => {
            if (flag == 'multiProduct') {
                return schema.required().max(24, 'bot.yup.options.header.max').label('bot.yup.options.header.header');
            }
            return schema.notRequired();
        }),
    products: yupx
        .array()
        .of(yupx.object())
        .when('flag', ([flag], schema) => {
            if (flag == 'multiProduct') {
                return schema
                    .required()
                    .min(1, 'bot.yup.options.products.multi_product.min')
                    .max(10, 'bot.yup.options.products.multi_product.max')
                    .label('bot.yup.options.products.products');
            }
            if (flag == 'singleProduct') {
                return schema
                    .required()
                    .min(1, 'bot.yup.options.products.single_product.min')
                    .max(1, 'bot.yup.options.products.single_product.max')
                    .label('bot.yup.options.products.product');
            }
            return schema.notRequired();
        }),
    locale: yupx.string().required().label('bot.yup.options.locale'),
    flag: yupx.string().required().label('bot.yup.options.flag')
});
