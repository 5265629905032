import { Divider, FormControl, Grid, IconButton, Typography } from '@mui/material';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const MenuPreview = ({
    options,
    title,
    onCloseListClick
}: {
    title: string;
    options: { id: string; title: string }[];
    onCloseListClick: () => void;
}) => {
    return (
        <>
            <FormControl component="fieldset" sx={{}}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <IconButton size="small" sx={{ mt: -0.5 }} onClick={onCloseListClick}>
                            <ClearIcon></ClearIcon>
                        </IconButton>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="primary" variant="h6" sx={{ mb: 2 }}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider />
                <RadioGroup
                    sx={{
                        ml: 2,
                        fontSize: 10,
                        '& .MuiSvgIcon-root': { fontSize: 14, mr: 2 }
                    }}
                >
                    {options.map((x) => (
                        <div key={x.id}>
                            <FormControlLabel value={x?.id} control={<Radio />} label={x?.title} />
                            <Divider
                                sx={{
                                    width: '250px'
                                }}
                            />
                        </div>
                    ))}
                </RadioGroup>
            </FormControl>
        </>
    );
};

export default MenuPreview;
