import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import XFormButtons from 'src/components/Forms/XFormButton';
import Name from './Name';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import { useParams } from 'react-router';

const Rename = () => {
    const { id } = useParams();
    const { methods, onSubmit } = useSubmitForm(id);
    useAfterSubmit(id);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={2} sx={{ p: 2 }}>
                    <Grid item>
                        <Name />
                    </Grid>
                    <Grid item lg={12}>
                        <XFormButtons
                            authPolicy="Supervision"
                            isDirty={methods.formState.isDirty}
                            onResetClick={methods.reset}
                            returnTo={id ? `/groups/${id}/contacts` : `/groups`}
                        />
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};

export default Rename;
