import { Typography, Box, Autocomplete, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { CampaignStep, Group } from 'src/types';
import { getAllGroups } from '../slice';
import { XSwitch } from 'src/components';

const Groups = ({
    nextStep,
    onGroupSelected
}: {
    nextStep: CampaignStep;
    onGroupSelected: (groupId: string) => void;
}) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { items, state } = useAppSelector((x) => x.campaigns.groups);

    useEffect(() => {
        if (state != 'successful' || items.length == 0) {
            dispatch(getAllGroups());
        }
    }, [items, state]);

    const options = _.chain(_.clone(items))
        .unshift({
            id: '',
            processing: false,
            contactsCount: 0,
            name: ''
        })
        .value();

    const EnabledGroups = () => {
        if (state == 'successful') {
            return (
                <>
                    <XSwitch
                        label={t('campaigns.instance.send_to_all')}
                        onChange={(checked) => {
                            onGroupSelected(checked ? 'all' : '');
                        }}
                    />
                    {nextStep.groupId != 'all' && (
                        <Autocomplete
                            sx={{ mt: 1, width: '50%' }}
                            disablePortal
                            onChange={(_, value) => onGroupSelected((value as Group).id)}
                            options={options}
                            disableClearable
                            getOptionLabel={(option) => {
                                var val = option as Group;
                                return val?.name ? val.name : '';
                            }}
                            renderInput={(params) => (
                                <TextField {...params} label={t('campaigns.instance.target_group')} />
                            )}
                            renderOption={(props, option) =>
                                option.id ? (
                                    <Box key={option.id} component="li" sx={{ p: 2 }} {...props}>
                                        <Typography>{option.name}</Typography>
                                        <Typography sx={{ ml: 1, mr: 1 }} fontSize={'smaller'} color={'primary'}>
                                            ({option.contactsCount})
                                        </Typography>
                                    </Box>
                                ) : (
                                    <span key={option.id}></span>
                                )
                            }
                        />
                    )}
                </>
            );
        }

        return <></>;
    };

    return (
        <>
            <Typography variant="h4" sx={{ mb: 3, color: 'rgb(230, 26, 128)' }}>
                {t('campaigns.instance.target_group_title')}
            </Typography>
            {!nextStep.showStatus ? (
                EnabledGroups()
            ) : (
                <>
                    {!nextStep.groupId || nextStep.groupId == 'all' ? (
                        <XSwitch
                            checked={true}
                            disabled={true}
                            label={t('campaigns.instance.send_to_all')}
                            onChange={(_) => {}}
                        />
                    ) : (
                        <TextField
                            sx={{ width: '50%' }}
                            disabled
                            value={items.find((x) => x.id == nextStep.groupId)?.name}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Groups;
