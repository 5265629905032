import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Campaigns from 'src/screens/campaigns';
import Submit from 'src/screens/campaigns/submit';
import Customise from 'src/screens/campaigns/customise';
import CampaignInstance from 'src/screens/campaigns/instance';
import Reports from 'src/screens/campaigns/reports/content';
import CampaignReport from 'src/screens/campaigns/reports';

const capmaignRoutes: RouteObject = {
    path: 'campaigns',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <Campaigns />
        },
        {
            path: 'submit',
            element: <Submit />
        },
        {
            path: ':id',
            element: <Submit />
        },
        {
            path: ':id/customise',
            element: <Customise />
        },
        {
            path: ':campaignId/instance',
            element: <CampaignInstance />
        },
        {
            path: ':campaignId/instance/:id',
            element: <CampaignInstance />
        },
        {
            path: ':id/reports',
            element: <CampaignReport />,
            children: [
                {
                    path: ':instanceId',
                    element: <Reports />
                }
            ]
        }
    ]
};

export default capmaignRoutes;
