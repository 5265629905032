import { Alert, Box, Button, Typography } from '@mui/material';
import { t } from 'i18next';
import { getApps } from 'src/screens/apps/slice';
import { AppService } from 'src/services/app.service';
import { useAppDispatch } from 'src/store/hooks';

const service = new AppService();

const GoToSalla = () => {
    const dispatch = useAppDispatch();
    const onRefreshClick = () => {
        dispatch(getApps());
    };

    return (
        <Box sx={{ p: 2 }}>
            <Alert severity="success">
                <Typography sx={{ fontWeight: 'bold' }}>{t('apps.salla.redirect_message')}</Typography>
                <Typography>{t('apps.salla.refresh_message')}</Typography>
                <Button size="small" sx={{ mt: 2 }} variant="outlined" onClick={() => service.redirectToSalla()}>
                    {t('apps.salla.install')}
                </Button>
                <Button onClick={onRefreshClick} size="small" sx={{ mt: 2, mx: 1 }} variant="outlined">
                    {t('apps.salla.verify_installtion')}
                </Button>
            </Alert>
        </Box>
    );
};

export default GoToSalla;
