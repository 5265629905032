import { XPageContainer } from 'src/components';
import { t } from 'i18next';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Box } from '@mui/material';
import { useParams } from 'react-router';
import { useEffect } from 'react';
import { getTemplates } from 'src/screens/templates/slice';
import SubmitContainer from './SubmitContainer';
import { getCategoryFields, getEvents, getEventsTemplates } from '../slice';
import Uninstall from '../install/Uninstall';

const EventSetup = () => {
    const sallaEventId = useParams().id!;
    const dispatch = useAppDispatch();
    const direction = useAppSelector((state) => state.account.direction);
    const { items: templates, state: templatesState } = useAppSelector((x) => x.templates.list);
    const {
        sallaEventCategories,
        sallaEvents,
        sallaEventCategoryFields,
        sallaEventTemplates,
        state: eventsState
    } = useAppSelector((x) => x.salla);

    const categoryId = sallaEvents?.find((x) => x.id == sallaEventId)?.sallaEventCategoryId;
    const sallaEventTemplate = sallaEventTemplates?.find((x) => x.sallaEventId == sallaEventId);

    useEffect(() => {
        if (!templates?.length) {
            dispatch(getTemplates(''));
        }

        if (!sallaEventCategories?.length) {
            dispatch(getEvents());
        }

        if (!sallaEventTemplates?.length) {
            dispatch(getEventsTemplates());
        }

        if (!sallaEventCategoryFields?.length) {
            dispatch(getCategoryFields());
        }
    }, []);

    return (
        <XPageContainer
            loading={templatesState == 'inProgress' || eventsState == 'inProgress'}
            topBar={{
                title: sallaEvents?.find((x) => x.id == sallaEventId)?.friendlyName,
                backLink: `/apps/salla/categories/${categoryId}/events`,
                action: <Uninstall />
            }}
        >
            <Box sx={{ px: 3 }} dir={direction}>
                <SubmitContainer
                    templates={templates.filter((x) => x.status == 'APPROVED')}
                    categoryId={categoryId}
                    sallaEventId={sallaEventId}
                    sallaEventTemplate={sallaEventTemplate}
                />
            </Box>
        </XPageContainer>
    );
};

export default EventSetup;
