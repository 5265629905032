import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Loader from '../Loader';
import { lazy } from 'react';

const NewCampaign = Loader(lazy(() => import('src/screens/sms/campaigns/newCampaign')));
const CampaignsList = Loader(lazy(() => import('src/screens/sms/campaigns/list')));
const List = Loader(lazy(() => import('src/screens/sms/templates/list')));
const Submit = Loader(lazy(() => import('src/screens/sms/templates/submit')));

const templatesSmsRoutes: RouteObject = {
    path: 'sms',
    element: <SidebarLayout />,
    children: [
        {
            path: 'new-campaign',
            element: <NewCampaign />
        },
        {
            path: 'campaigns',
            element: <CampaignsList />
        },
        {
            path: 'templates',
            element: <List />
        },
        {
            path: 'templates/new',
            element: <Submit />
        },
        {
            path: 'templates/:id',
            element: <Submit />
        }
    ]
};

export default templatesSmsRoutes;
