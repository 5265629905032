import { Box, Card, CardContent, Typography } from '@mui/material';
import Name from './Name';
import { Template } from 'src/types';
import CampaignTemplate from './CampaignTemplate';
import LanguageCode from './LanguageCode';
import { useTranslation } from 'react-i18next';

const LeftSection = ({ templates }: { templates: Template[] }) => {
    const { t } = useTranslation();
    return (
        <Box sx={{ height: '100%', m: 1 }}>
            <CardContent>
                <Typography variant="caption">{t('campaigns.submit.select_template_title')}</Typography>
                <Name />
                <br />
                <LanguageCode templates={templates} />
                <br />
                <CampaignTemplate templates={templates} />
                <br />
            </CardContent>
        </Box>
    );
};

export default LeftSection;
