import i18next from 'i18next';
import * as yupx from 'yup';

yupx.setLocale({
    mixed: {
        required: (rquiredFiled: any) => {
            return i18next.t(rquiredFiled.label) ? i18next.t(rquiredFiled.label) : i18next.t('common.yup.required');
        }
    },
    string: {
        max: ({ max }) => {
            return i18next.t('common.yup.max').replace('0', max.toString());
        },
        min: ({ min }) => {
            return i18next.t('common.yup.min').replace('0', min.toString());
        },
        matches: ({ label }) => {
            return i18next.t(`${label}_matches`);
        },
        email: () => {
            return i18next.t('common.yup.email');
        }
    },
    array: {
        max: ({ max }) => {
            return i18next.t('common.yup.max').replace('0', max.toString());
        },
        min: ({ min }) => {
            return i18next.t('common.yup.min').replace('0', min.toString());
        }
    }
});

export default yupx;
