import { ApiListResponse, ApiResponse, Team, User } from 'src/types';
import apiClient from './apiClient';
import { AddTeamInputs } from 'src/screens/teams/content/submit/schema';

export class TeamsService {
    async getTeams(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<Team>>('/teams', {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async getTeam(id: string) {
        const { data } = await apiClient.get<ApiResponse<Team>>(`/teams/${id}`);
        return data;
    }

    async addTeam(inputs: AddTeamInputs) {
        const { data } = await apiClient.post<ApiListResponse<Team>>('/teams', {
            ...inputs,
            schedule: inputs.schedule
                .filter((x) => x.shiftEnd && x.shiftStart && x.dayOfWeek)
                .map((x) => ({ ...x, shiftStartTime: x.shiftStart, shiftEndTime: x.shiftEnd }))
        });
        return data;
    }

    async updateTeam(id: string, inputs: AddTeamInputs) {
        const { data } = await apiClient.put<ApiListResponse<Team>>(`/teams/${id}`, {
            ...inputs,
            schedule: inputs.schedule
                .filter((x) => x.shiftEnd && x.shiftStart && x.dayOfWeek)
                .map((x) => ({ ...x, shiftStartTime: x.shiftStart, shiftEndTime: x.shiftEnd }))
        });
        return data;
    }

    async addUsersToTeam(teamId: string, userIds: string[]) {
        const { data } = await apiClient.post<ApiListResponse<Team>>(`/teams/${teamId}/users`, {
            userIds
        });
        return data;
    }

    async removeUserFromTeam(teamId: string, userId: string) {
        const { data } = await apiClient.delete<ApiListResponse<Team>>(`/teams/${teamId}/users/${userId}`);
        return data;
    }

    async deleteTeam(teamId: string) {
        const { data } = await apiClient.delete<ApiListResponse<Team>>(`/teams/${teamId}`);
        return data;
    }

    async getUsers(teamId: string, isMember: boolean, page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<User>>(`/teams/${teamId}/users`, {
            params: {
                page,
                limit,
                isMember
            }
        });
        return data;
    }
}

export default new TeamsService();
