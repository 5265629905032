import { Tooltip, IconButton, useTheme } from '@mui/material';

import { NavLink } from 'react-router-dom';

type XIconButtonProps = {
    tooltip: string;
    to?: string;
    onClick?: () => void;
    children: React.ReactNode;
    disabled?: boolean;
};

const XIconButton = (props: XIconButtonProps) => {
    const theme = useTheme();
    return (
        <>
            {props.to ? (
                <Tooltip title={props.tooltip} arrow>
                    <IconButton
                        disabled={props.disabled}
                        to={props.to}
                        component={NavLink}
                        onClick={(e) => {
                            e.stopPropagation();
                            if (props.onClick) props.onClick();
                        }}
                        sx={{
                            '&:hover': {
                                background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                    >
                        {props.children}
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title={props.tooltip} arrow>
                    <IconButton
                        disabled={props.disabled}
                        onClick={(e) => {
                            props.onClick();
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        sx={{
                            '&:hover': {
                                background: theme.colors.primary.lighter
                            },
                            color: theme.palette.primary.main
                        }}
                        color="inherit"
                        size="small"
                    >
                        {props.children}
                    </IconButton>
                </Tooltip>
            )}
        </>
    );
};

export default XIconButton;
