import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { RootState } from 'src/store';
import { Set, Product } from 'src/types';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import i18next from 'i18next';
import { WhatsAppService } from 'src/services/whatsApp.service';

const service = new WhatsAppService();

export interface SliceState {
    sets: {
        state: ActionState;
        items: Set[];
    };
    products: {
        state: ActionState;
        tableData: TableData<Product>;
    };
    submit: {
        state: ActionState;
        catalogName: string;
        catalogAvailable: boolean;
    };
}

const initialState: SliceState = {
    sets: {
        state: undefined,
        items: []
    },
    products: {
        state: undefined,
        tableData: DefaultTableData()
    },
    submit: {
        state: undefined,
        catalogName: '',
        catalogAvailable: false
    }
};

export const getSets = createAsyncThunk('GET_SETS', async (_, thunkAPI) => {
    const response = await service.getSets();
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getProducts = createAsyncThunk('GET_PRODUCTS', async (setId: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.catalog.products.tableData;
    const response = await service.getProducts(page, limit, setId);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const linkCatalog = createAsyncThunk('ADD_CATALOG', async (_, thunkAPI) => {
    const response = await service.linkCatalog();
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

const slice = createSlice({
    name: 'catalog',
    initialState,
    reducers: {
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.products.tableData.page = action.payload.page;
            state.products.tableData.limit = action.payload.limit;
        }
    },
    extraReducers(builder) {
        builder.addCase(getSets.pending, (state) => {
            state.sets.state = 'inProgress';
        });
        builder.addCase(getSets.fulfilled, (state, action) => {
            state.sets.items = action.payload.items;
            state.sets.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getSets.rejected, (state, action) => {
            state.sets.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('catalog.slice.errors.fetch_sets'));
        });

        builder.addCase(getProducts.pending, (state) => {
            state.products.state = 'inProgress';
        });
        builder.addCase(getProducts.fulfilled, (state, action) => {
            state.products.tableData = action.payload;
            state.products.state = 'successful';
            state.submit.state = undefined;
        });
        builder.addCase(getProducts.rejected, (state, action) => {
            state.products.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('catalog.slice.errors.fetch_products'));
        });

        builder.addCase(linkCatalog.pending, (state) => {
            state.submit.state = 'inProgress';
        });
        builder.addCase(linkCatalog.fulfilled, (state, action) => {
            state.submit.catalogAvailable = action.payload.catalogAvailable;
            if (action.payload.catalogAvailable) {
                state.submit.state = 'successful';
                state.submit.catalogName = action.payload.catalogName;
                SnackbarUtils.success(i18next.t('catalog.slice.success.link_catalog'));
            } else {
                state.products.state = 'failed';
                SnackbarUtils.error(i18next.t('catalog.slice.errors.catalog_not_available'));
            }
        });
        builder.addCase(linkCatalog.rejected, (state, action) => {
            state.submit.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('catalog.slice.errors.link_catalog'));
        });
    }
});

export const { setPaging } = slice.actions;

export default slice.reducer;
