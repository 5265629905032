import { MenuItem, FormControl, Select, FormHelperText, FormLabel } from '@mui/material';
import { CallToActionUrlType, SubmitTemplateInputs } from '../../schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function UrlTypes({ index, inputColor }: { index: number; inputColor: string }) {
    const { t } = useTranslation();
    const {
        register,
        watch,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const items: { label: string; value: CallToActionUrlType }[] = [
        {
            label: 'templates.submit.buttons.call_to_action.url_types.static',
            value: 'STATIC'
        },
        {
            label: 'templates.submit.buttons.call_to_action.url_types.dynamic',
            value: 'DYNAMIC'
        }
    ];
    const callToactions = watch('buttons.callToactions');

    return (
        <FormControl fullWidth>
            <FormLabel>{t('templates.submit.buttons.call_to_action.url_type')}</FormLabel>
            <Select
                sx={{ bgcolor: inputColor }}
                defaultValue={callToactions[index].urlType}
                error={errors?.buttons?.callToactions[index]?.urlType?.message && true}
                {...register(`buttons.callToactions.${index}.urlType`)}
                id={`buttons.callToactions.${index}.urlType`}
                size="small"
            >
                {items.map((x, i) => (
                    <MenuItem key={i} value={x.value}>
                        {t(x.label)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>
                {errors?.buttons?.callToactions[index]?.urlType?.message}
            </FormHelperText>
        </FormControl>
    );
}
