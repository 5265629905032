import { TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { WelcomeMessageInputs } from './schema';

const BodyEnglish = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<WelcomeMessageInputs>();
    return (
        <>
            <TextField
                fullWidth
                error={errors?.bodyEnglish && true}
                {...register(nameOf<WelcomeMessageInputs>('bodyEnglish'))}
                id={nameOf<WelcomeMessageInputs>('bodyEnglish')}
                placeholder={t('bot.settings.welcomeMessage.bodyEnglish')}
                size="small"
                type="text"
                helperText={errors?.bodyEnglish?.message}
                sx={{
                    p: 1
                }}
                multiline
                rows={3}
            ></TextField>
        </>
    );
};

export default BodyEnglish;
