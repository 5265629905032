import { Box, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { WhatsaapInfoInputs } from './schema';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import CopyIcon from './CopyIcon';

const WebhookConfigurationToken = () => {
    const { t } = useTranslation();
    const { register, getValues } = useFormContext<WhatsaapInfoInputs>();
    const [copied, setCopied] = useState(false);

    return (
        <Box sx={{ display: 'flex' }}>
            <TextField
                fullWidth
                {...register(nameOf<WhatsaapInfoInputs>('webhookConfigurationToken'))}
                id={nameOf<WhatsaapInfoInputs>('webhookConfigurationToken')}
                label={t('business.whatsApp_info.webhook_hook_token')}
                size="medium"
                disabled
                type="text"
            />
            <CopyIcon content={getValues(nameOf<WhatsaapInfoInputs>('webhookConfigurationToken'))} />
        </Box>
    );
};

export default WebhookConfigurationToken;
