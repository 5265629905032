import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getGroups } from '../../slice';

const useAfterSubmit = (id: string) => {
    const { state } = useAppSelector((x) => x.groups.submit);
    const navigateTo = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (state === 'successful') {
            dispatch(getGroups({ nextPage: false }));
            navigateTo(`/groups/${id}/contacts`);
        }
    }, [state, dispatch, getGroups]);
};

export default useAfterSubmit;
