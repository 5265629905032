import CommonForm from './commonForm';

const OutsideWorkingHours = () => {
    return (
        <CommonForm
            interfaceSubType="Text_OutsideWorkingHours"
            api="OutsideWorkingHours"
            descriptionKey="bot.settings.outside_working_hours.description"
            bodyEnglishKey="bot.settings.outside_working_hours.bodyEnglish"
            bodyArabicKey="bot.settings.outside_working_hours.bodyArabic"
        ></CommonForm>
    );
};

export default OutsideWorkingHours;
