import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { XLinkButton } from 'src/components';
import { CampaignStep } from 'src/types';
import { useTranslation } from 'react-i18next';

export const InstanceMessages = {
    InProgress: {
        title: 'Processing',
        message: 'We have started sending the campaign to the users.',
        processing: true,
        icon: <></>
    },
    Published: {
        title: 'Processing',
        message: 'Campaign has been queued. It will be starting in sometime. You can safely leave this screen.',
        processing: true,
        icon: <></>
    },
    Uploaded: {
        title: 'Processing',
        message: 'We are processing the details in the uploaded CSV file.',
        processing: true,
        icon: <></>
    },
    UploadProcessed: {
        title: 'Action required',
        message: 'We have verified the details of the CSV file. You can now start the campaign.',
        processing: false,
        icon: <></>
    },
    Completed: {
        title: 'Completed',
        message: 'Campaign completed. It might take a while for the message to arrive on the target user devices',
        processing: false,
        icon: <></>
    },
    Failed: {
        title: 'Failed',
        message: 'Campaign failed.',
        processing: false,
        icon: <></>
    }
};

const InstanceStatus = ({ nextStep }: { nextStep: CampaignStep }) => {
    const { t } = useTranslation();
    return (
        <>
            {nextStep.showStatus && (
                <Alert variant="outlined" severity={nextStep.status == 'Failed' ? 'error' : 'success'}>
                    {InstanceMessages[nextStep.status].title}
                    <Typography sx={{ mt: 1, mb: 1, fontSize: 'smaller' }} variant="body1">
                        {InstanceMessages[nextStep.status].message}
                    </Typography>
                    {InstanceMessages[nextStep.status].processing && (
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <CircularProgress color="success" disableShrink size={25} />
                        </Box>
                    )}
                    {nextStep?.status == 'Completed' && (
                        <XLinkButton
                            text={t('campaigns.reports.view')}
                            to={`/campaigns/${nextStep.campaign.id}/reports/${nextStep.instance.id}`}
                        />
                    )}
                </Alert>
            )}
        </>
    );
};

export default InstanceStatus;
