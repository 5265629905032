import { Box, Grid, IconButton, Typography } from '@mui/material';

const HomeAnimation = () => {
    return (
        <Grid
            item
            xs={12}
            md={6}
            sx={{
                display: {
                    md: 'block',
                    xs: 'none'
                }
            }}
        >
            <video style={{ height: '99vh', width: '100%' }} autoPlay loop muted>
                <source src="/static/videos/sign-in-2.mp4" type="video/mp4" />
            </video>
            <Box sx={{ m: 1, position: 'absolute', right: 0, bottom: 0 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <a
                        href="https://web.whatsapp.com/send?phone=966565479016 "
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <IconButton>
                            <img
                                style={{ height: '50px', margin: '10px 20px' }}
                                src="/static/images/whatsapp-icon.png"
                            />
                        </IconButton>
                    </a>
                    <Typography>Contact us</Typography>
                    <Typography>تواصل معنا</Typography>
                </Box>
            </Box>
        </Grid>
    );
};

export default HomeAnimation;
