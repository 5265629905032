import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Apps from 'src/screens/apps';
import SallaCategories from 'src/screens/salla/categories';
import EventSetup from 'src/screens/salla/event-setup';
import SallaEvents from 'src/screens/salla/events';
import SallaInstall from 'src/screens/salla/install';

const appsRoutes: RouteObject = {
    path: 'apps',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <Apps />,
            children: []
        },
        {
            path: 'salla/install',
            element: <SallaInstall />
        },
        {
            path: 'salla/categories',
            element: <SallaCategories />
        },
        {
            path: 'salla/categories/:id/events',
            element: <SallaEvents />
        },
        {
            path: 'salla/events/:id/event-setup',
            element: <EventSetup />
        }
    ]
};

export default appsRoutes;
