import Variables from './Variables';
import { Alert, Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const RightSection = () => {
    const [show, setShow] = useState(false);
    const { t } = useTranslation();

    return (
        <Box sx={{ height: '100%', m: 1 }}>
            <CardContent>
                <Box>
                    <Typography variant="caption">{t('campaigns.submit.placeholders.title')}</Typography>
                    <IconButton onClick={() => setShow(!show)} sx={{ ml: 1 }} size="small">
                        <InfoOutlinedIcon color="info" fontSize="small" />
                    </IconButton>
                </Box>
                {show && (
                    <Alert
                        severity="info"
                        variant="outlined"
                        onClose={() => {
                            setShow(false);
                        }}
                    >
                        {t('campaigns.submit.placeholders.alert')}
                    </Alert>
                )}
                <Variables />
            </CardContent>
        </Box>
    );
};

export default RightSection;
