import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import { useAppSelector } from 'src/store/hooks';

const EntireCatalogPreview = ({ message }: { message: string }) => {
    const businessName = useAppSelector((x) => x.account.user.businessName);

    return (
        <>
            <Grid container spacing={0.2}>
                <Grid item xs={2}>
                    <img style={{ width: '100%', height: '100%' }} src={'/static/images/whatsapp-icon-name.png'} />
                </Grid>
                <Grid item xs={10}>
                    <Box sx={{ m: 1 }}>
                        <Typography variant="body1" fontWeight={'bold'}>
                            {t('conversations.chatWindow.preview.entire_catalog.header').replace('{0}', businessName)}
                        </Typography>
                        <Typography variant="subtitle1">
                            {t('conversations.chatWindow.preview.entire_catalog.body')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography color="primary">{message}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Divider></Divider>
                    <Box sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
                        <Button size="small"> {t('conversations.chatWindow.preview.entire_catalog.footer')}</Button>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default EntireCatalogPreview;
