export interface Campaign {
    id: string;
    name: string;
    templateName: string;
    templateId: string;
    languageCode: string;
    templateVariables: TemplateVariable[];
    templateActions: TemplateAction[];
    recentInstance?: CampaignInstance;
    instances: CampaignInstance[];
}

export interface TemplateAction {
    id: string;
    buttonName: string;
    targetInterfaceId: string;
}

export interface TemplateVariable {
    id: string;
    index: number;
    variableType: VariableType;
    fallbackValue: string;
    componentType: ComponentType;
}

export interface CampaignInstance {
    id: string;
    status: CampaignInstanceStatus;
    failiureReason: string;
    date: Date;
    groupId: string;
    scheduledFor: string;
}

export interface CampaignReport {
    campaignInstanceId: string;
    delivered: number;
    failed: number;
    rejected: number;
    publishedDate: string;
    read: number;
    sent: number;
    status: string;
    totalContacts: number;
    templateName: string;
    initiatedBy: string;
    targetGroup: string;
    sentToAll: boolean;
}

export type VariableType = 'Custom' | 'Name' | 'PhoneNumber';
export type ComponentType = 'Body' | 'Header';
export type CampaignInstanceStatus =
    | 'Uploaded'
    | 'UploadProcessed'
    | 'Published'
    | 'Scheduled'
    | 'InProgress'
    | 'Completed'
    | 'Failed';

export function actionRequired(campaign: Campaign, instance: CampaignInstance) {
    if (campaign.templateVariables.filter((x) => x.variableType == 'Custom').length > 0) {
        return !instance?.status || instance.status == 'UploadProcessed';
    }
    return !instance?.status;
}

export interface CampaignStep {
    campaign: Campaign;
    instance: CampaignInstance;
    groupId: string;
    status: CampaignInstanceStatus;
    showUpload: boolean;
    showSend: boolean;
    disableGroups: boolean;
    showStatus: boolean;
    showVariables: boolean;
}

export function getNextStep(campaign: Campaign, groupId: string, completedJustNow: boolean) {
    let instance = campaign?.recentInstance;
    if (!instance) {
        instance = campaign?.instances?.length > 0 ? campaign.instances[0] : undefined;
    }
    let showSend = false;
    let showUpload = false;
    let showStatus = false;

    let nextStep: CampaignStep = {
        campaign,
        instance,
        groupId,
        status: instance?.status,
        disableGroups: false,
        showStatus: false,
        showUpload: showUpload,
        showSend: false,
        showVariables: campaign?.templateVariables?.filter((x) => x.variableType == 'Custom')?.length > 0
    };

    if (!campaign) return nextStep;

    if (nextStep.showVariables) {
        showUpload =
            Boolean(groupId) &&
            instance?.status != 'Published' &&
            instance?.status != 'UploadProcessed' &&
            instance?.status != 'Uploaded' &&
            !completedJustNow;

        showSend = instance?.status == 'UploadProcessed';

        showStatus =
            instance?.status == 'UploadProcessed' ||
            instance?.status == 'Uploaded' ||
            instance?.status == 'Published' ||
            instance?.status == 'InProgress' ||
            completedJustNow;
    } else {
        if ((!instance || !completedJustNow) && instance?.status != 'Published') {
            showSend = Boolean(groupId);
        }
        showStatus = instance?.status == 'Published' || completedJustNow;
    }

    if (showStatus) {
        nextStep.groupId = instance.groupId;
    }

    nextStep.showSend = showSend;
    nextStep.showStatus = showStatus;
    nextStep.showUpload = showUpload;

    return nextStep;
}
