import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button/Button';
import { ReactElement } from 'react';

const XMultiButton = ({
    buttons
}: {
    buttons: {
        to: string;
        text: string;
        icon?: ReactElement;
    }[];
}) => {
    return (
        <>
            {buttons.map(({ to, text, icon }) => {
                return (
                    <Button
                        key={to}
                        to={to}
                        component={NavLink}
                        sx={{ ml: 1, mt: { xs: 2, md: 0 } }}
                        variant="outlined"
                        size="small"
                        startIcon={icon}
                    >
                        {text}
                    </Button>
                );
            })}
        </>
    );
};

export default XMultiButton;
