import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import XFormButtons from 'src/components/Forms/XFormButton';
import Subject from './Subject';
import useSubmitForm from './useSubmitForm';
import CsvFilePicker from './CsvFilePicker';
import { useAppSelector } from 'src/store/hooks';
import { XPageContainer, XComponentContainer, CampaignTabs } from 'src/components';
import { useTranslation } from 'react-i18next';
import Body from './Body';
import ImagePicker from './ImagePicker';
import TermsAndConditions from './TermsAndConditions ';

const SubmitGroup = () => {
    const { t } = useTranslation();
    const { methods, onSubmit } = useSubmitForm();
    const state = useAppSelector((x) => x.emailCampaigns.submit.state);

    return (
        <>
            <XPageContainer
                topBar={{
                    title: t('emailCampaigns.compose.title')
                }}
            >
                <XComponentContainer>
                    <Box
                        sx={{
                            mt: 1,
                            display: {
                                xs: 'flex',
                                md: 'none'
                            },
                            justifyContent: 'end'
                        }}
                    >
                        <CampaignTabs activeTab="Email" />
                    </Box>
                    {state == 'inProgress' ? (
                        <Box
                            sx={{ mt: 10 }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection={'column'}
                        >
                            <CircularProgress size={64} disableShrink thickness={3} />
                        </Box>
                    ) : (
                        <FormProvider {...methods}>
                            <form onSubmit={methods.handleSubmit(onSubmit)}>
                                <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
                                    <Grid item md={6}>
                                        <Grid item>
                                            <CsvFilePicker />
                                        </Grid>
                                        <Grid item sx={{ my: 2 }}>
                                            {<Subject />}
                                        </Grid>
                                        <Grid item>{<Body />}</Grid>
                                        <Grid item>
                                            <ImagePicker />
                                        </Grid>
                                        <Grid item>
                                            <TermsAndConditions />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        sx={{
                                            display: {
                                                xs: 'none',
                                                md: 'block'
                                            }
                                        }}
                                    >
                                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'end' }}>
                                            <CampaignTabs activeTab="Email" />
                                        </Box>
                                    </Grid>

                                    <Grid item lg={12}>
                                        <XFormButtons
                                            authPolicy="Supervision"
                                            isDirty={methods.formState.isDirty}
                                            onResetClick={methods.reset}
                                            returnTo={'/'}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    )}
                </XComponentContainer>
            </XPageContainer>
        </>
    );
};

export default SubmitGroup;
