import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { AddBusinessInputs } from './schema';
import FormControl from '@mui/material/FormControl';
import { XLanguageSwitch } from 'src/components';
import { FormLabel } from '@mui/material';
import { t } from 'i18next';

const Language = ({ disabled }: { disabled: boolean }) => {
    const { setValue, watch } = useFormContext<AddBusinessInputs>();
    const value = watch('language');

    return (
        <FormControl>
            <FormLabel sx={{ my: 1 }}>{t('business.submit.preffered_language')}</FormLabel>
            <XLanguageSwitch
                value={value}
                disabled={disabled}
                onLocaleChange={(x) => setValue(nameOf<AddBusinessInputs>('language'), x, { shouldDirty: true })}
            ></XLanguageSwitch>
        </FormControl>
    );
};

export default Language;
