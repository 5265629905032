import { XTemplatePreview } from 'src/components';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Template } from 'src/types';
import XEditButton from 'src/components/Buttons/XEditButton';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import useConfirm from 'src/contexts/ConfirmationContext';
import { useAppDispatch } from 'src/store/hooks';
import { deleteSampleTemplate } from '../slice';

const Sample = ({ id, template }: { id: string; template: Template }) => {
    const { t } = useTranslation();
    const confirm = useConfirm();
    const dispatch = useAppDispatch();

    const onDeleteClick = async (id: string) => {
        const ok = await confirm(
            t('templates.samples.delete_confirm_title'),
            t('templates.samples.delete_confirm_body')
        );
        if (ok) {
            dispatch(deleteSampleTemplate(id));
        }
    };

    return (
        <Grid item xs={12} sm={6} md={3} key={template.id}>
            <Box
                sx={{
                    background: 'url(/static/images/whatsapp-background.png)',
                    p: 1,
                    borderRadius: 0.3
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: '#673ab7' }}>
                    {template.name}
                </Typography>
                <XTemplatePreview template={template} />
                <Box
                    sx={{
                        mb: 'atto',
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <XEditButton to={`/templates/samples/${id}`} text={t('common.lists.edit')} />
                    <Button
                        sx={{ ml: 1, mr: 1, mt: { xs: 2, md: 0 } }}
                        variant="outlined"
                        color="secondary"
                        size="small"
                        startIcon={<DeleteIcon fontSize="small" />}
                        onClick={() => onDeleteClick(id)}
                    >
                        {t('common.lists.delete')}
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};

export default Sample;
