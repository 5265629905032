import { Box, Grid, IconButton, Typography, styled } from '@mui/material';
import { ReactNode, useContext } from 'react';
import Header from 'src/layouts/SidebarLayout/Header';
import { useAppSelector } from 'src/store/hooks';
import InputIcon from '@mui/icons-material/Input';
import { PageContainerProps } from 'src/types';
import { DrawerContext } from 'src/contexts/DrawerContext';
import useDeviceInfo from '../Hooks/useDeviceInfo';
import XLinkButton from '../Buttons/XLinkButton';
import i18next from 'i18next';

interface TopBarProps {
    title: ReactNode | string;
    children: ReactNode | ReactNode[];
}

const TopBar = styled(Box)(
    ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        border-bottom: ${theme.colors.alpha.black[10]} solid 1px;
        padding: ${theme.spacing(2)};
        align-items: center;
`
);

const IconButtonToggle = styled(IconButton)(
    ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  background: ${theme.colors.alpha.white[100]};
`
);

function TopBarContent({ children }: { children: PageContainerProps }) {
    const dir = useAppSelector((x) => x.account.direction);
    const { openDrawer } = useContext(DrawerContext);
    const deviceInfo = useDeviceInfo();

    return (
        <>
            {!children.topBar.hideOnDesktop && !deviceInfo.isSmallDevice && (
                <TopBar>
                    <Grid container dir={dir}>
                        <Grid display="flex" alignItems={'start'} item xs={6}>
                            <Typography variant="h4" sx={{ fontSize: 20 }} color="rgb(230, 26, 128)">
                                {children.topBar.title}
                            </Typography>
                        </Grid>
                        <Grid item display="flex" justifyContent={'end'} xs={6}>
                            {children.topBar.action}{' '}
                            {children.topBar.backLink && (
                                <Box sx={{ mx: 1 }}>
                                    <XLinkButton
                                        to={children.topBar.backLink}
                                        text={i18next.t('layout.topBar.back')}
                                    ></XLinkButton>
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </TopBar>
            )}
            {deviceInfo.isSmallDevice && (
                <TopBar>
                    <Grid
                        alignItems={'center'}
                        display="flex"
                        justifyContent={'space-between'}
                        xs={12}
                        container
                        dir={i18next.dir(i18next.language)}
                    >
                        <Grid
                            item
                            xs={1}
                            sx={{
                                display: 'flex',
                                justifyContent: 'start'
                            }}
                        >
                            <Header />
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h4" color="primary" sx={{ mx: 1 }}>
                                <span style={{ color: 'rgb(230, 26, 128)' }}> {children.topBar.title}</span>
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            sx={{
                                display: 'flex',
                                justifyContent: 'end'
                            }}
                        >
                            {children.topBar.backLink && (
                                <Box sx={{ mx: 1 }}>
                                    <XLinkButton
                                        to={children.topBar.backLink}
                                        text={i18next.t('layout.topBar.back')}
                                    ></XLinkButton>
                                </Box>
                            )}
                            {children.sideBar && (
                                <IconButtonToggle
                                    color="primary"
                                    onClick={() => openDrawer('Drawer2')}
                                    size="small"
                                    sx={{ mx: 0, p: 0 }}
                                >
                                    <InputIcon />
                                </IconButtonToggle>
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            {children.topBar.action}
                        </Grid>
                    </Grid>
                </TopBar>
            )}
        </>
    );
}

export default TopBarContent;
