import { XLinkButton } from 'src/components';
import { Campaign, Template, getTemplateActions } from 'src/types';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';

const TopAction = ({ campaign, template }: { campaign: Campaign; template: Template }) => {
    const { t } = useTranslation();
    const actions = template ? getTemplateActions(template) : [];
    const canSend = actions.length == 0 || campaign.templateActions.length == actions.length;
    return (
        <>
            {campaign && (
                <>
                    <Box sx={{ mr: 1 }}>
                        <XLinkButton
                            disabled={actions.length == 0}
                            text={t('campaigns.submit.customise')}
                            to="customise"
                        ></XLinkButton>
                    </Box>
                    <XLinkButton disabled={!canSend} text={t('campaigns.submit.send')} to="instance"></XLinkButton>
                </>
            )}
        </>
    );
};

export default TopAction;
