import { Grid } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import XFormButtons from 'src/components/Forms/XFormButton';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import CurrentPassword from './CurrentPassword';
import NewPassword from './NewPassword';
import { useTranslation } from 'react-i18next';

const Password = () => {
    const { t } = useTranslation();
    const { methods, onSubmit } = useSubmitForm();
    useAfterSubmit();
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid
                    container
                    sx={{
                        mx: 2
                    }}
                >
                    <Grid item xs={12} md={4}>
                        <Grid item md={12} sx={{ py: 1 }}>
                            <CurrentPassword />
                        </Grid>
                        <Grid item md={12} sx={{ py: 1 }}>
                            <NewPassword />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <XFormButtons
                                authPolicy="Regular"
                                isDirty={methods.formState.isDirty}
                                onResetClick={methods.reset}
                                returnTo="/"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </FormProvider>
    );
};
export default Password;
