import { useMediaQuery, useTheme } from '@mui/material';

const useDeviceInfo = () => {
    const isSmallDevice = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
    return {
        isSmallDevice: Boolean(isSmallDevice),
        borderColor: `theme.colors.alpha.black[10]} solid 1px`
    };
};

export default useDeviceInfo;
