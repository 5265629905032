import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateWelcomeMessage } from 'src/screens/bot/slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { BotInterface } from 'src/types';
import { schema, WelcomeMessageInputs } from './schema';

const useSubmitForm = (welcomeInterfaces: BotInterface[]) => {
    const dispatch = useAppDispatch();
    const botId = useAppSelector((x) => x.businessBot.bot.id);
    const methods = useForm<WelcomeMessageInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            titleEnglish: welcomeInterfaces.find((x) => x.locale == 'en')?.interactiveList?.title,
            bodyArabic: welcomeInterfaces.find((x) => x.locale == 'ar')?.interactiveList?.body,
            titleArabic: welcomeInterfaces.find((x) => x.locale == 'ar')?.interactiveList?.title,
            bodyEnglish: welcomeInterfaces.find((x) => x.locale == 'en')?.interactiveList?.body
        }
    });

    const onSubmit = (inputs: WelcomeMessageInputs) => {
        dispatch(updateWelcomeMessage({ inputs }));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
