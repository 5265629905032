import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getCampaigns, setPaging } from '../slice';
import { CampaignTabs, XAddButton, XComponentContainer, XPageContainer, XTableContainer } from 'src/components';
import { Box, Grid, Table, TableBody } from '@mui/material';
import Head from './Head';
import Item from './item';
import { useTranslation } from 'react-i18next';
import useAfterDelete from './useAfterDelete';

function List() {
    const { t } = useTranslation();
    useAfterDelete();
    const { tableData, state } = useAppSelector((x) => x.campaigns.list);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getCampaigns());
    }, []);

    const onPaging = (page: number, limit: number) => {
        dispatch(
            setPaging({
                page,
                limit
            })
        );
        dispatch(getCampaigns());
    };

    return (
        <XComponentContainer>
            <XPageContainer
                topBar={{
                    title: t('campaigns.list.title')
                }}
            >
                <Grid container>
                    <Grid item xs={6} sx={{ display: 'flex' }}>
                        <Box sx={{ mt: 2, mx: 3 }}>
                            <XAddButton text={t('campaigns.list.create_label')} to="/campaigns/submit"></XAddButton>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sx={{ pt: 1, display: 'flex', justifyContent: 'end' }}>
                        <CampaignTabs activeTab="WhatsApp" />
                    </Grid>
                </Grid>
                <XTableContainer
                    totalItems={tableData.totalItems}
                    page={tableData.page}
                    limit={tableData.limit}
                    onPaging={onPaging}
                    loading={state === 'inProgress'}
                >
                    <Table sx={{ minWidth: 750, pr: 1 }} size="small">
                        <Head />
                        <TableBody>
                            {tableData.items.map((x) => (
                                <Item key={x.id} campaign={x} />
                            ))}
                        </TableBody>
                    </Table>
                </XTableContainer>
            </XPageContainer>
        </XComponentContainer>
    );
}

export default List;
