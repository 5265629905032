import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { EditorInputs, ProductFlag } from './schema';
import { XToolbar } from 'src/components';

const Menubar = ({ inputRef }: { inputRef: React.MutableRefObject<HTMLInputElement> }) => {
    const { setValue, watch } = useFormContext<EditorInputs>();
    console.log(new Date());
    const onInpuChange = (newMsg: string) => {
        setValue(nameOf<EditorInputs>('body'), newMsg, {
            shouldDirty: false
        });
    };

    const flag = watch(nameOf<EditorInputs>('flag')) as ProductFlag;

    const onMediaSelected = (link, type, name) => {
        setValue(
            nameOf<EditorInputs>('media'),
            {
                link,
                type,
                name
            },
            {
                shouldDirty: true
            }
        );
    };

    return (
        <XToolbar
            inputRef={inputRef}
            emoji={{
                onEmojiSelect: onInpuChange,
                placement: 'bottom'
            }}
            placeHolder={{
                onPlaceHolderSelect: onInpuChange
            }}
            media={
                flag == 'nonProduct'
                    ? {
                          onMediaSelect: onMediaSelected
                      }
                    : null
            }
        />
    );
};

export default Menubar;
