import { Box } from '@mui/material';
import { TextPreview } from './Fragments';
import Thumbnail from './Thumbnail';
import { MediaWithCaption } from 'src/types';
import _ from 'lodash';

const MediaWithCaptionPreview = ({ message }: { message: MediaWithCaption }) => {
    const getCaption = () => {
        if (!message.caption && message.media.type == 'Document') {
            var extension = _.last(message.media.link.split('.'));
            return `.${extension} Document`;
        }
        return message.caption;
    };

    return (
        <Box sx={{}}>
            <Thumbnail content={message.media.link} type={message.media.type}></Thumbnail>
            <TextPreview variant="body2" color={'#000'} sx={{ pt: 1 }}>
                {getCaption()}
            </TextPreview>
        </Box>
    );
};

export default MediaWithCaptionPreview;
