import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getBusinessesBot } from 'src/screens/bot/slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';

const useAfterDelete = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { submitState } = useAppSelector((x) => x.businessBot);
    useEffect(() => {
        if (submitState === 'successful') {
            dispatch(getBusinessesBot());
        }
    }, [submitState, navigate, dispatch, getBusinessesBot]);
};

export default useAfterDelete;
