import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { XPageContainer } from 'src/components';
import useSubmitForm from './useSubmitForm';
import TemplatePreview from './TemplatePreview';
import SubmitContainer from './SubmitContainer';
import { useState } from 'react';
import TopActions from './TopActions';
import { useLocation } from 'react-router';
import { Box, Button } from '@mui/material';

const Submit = () => {
    const { t } = useTranslation();
    const [showPreview, setShowPreview] = useState(false);
    const { id, inputs, methods, onSubmit } = useSubmitForm(setShowPreview);
    const { pathname } = useLocation();
    const isSampleActive = pathname.split('/').includes('samples');

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                <XPageContainer
                    topBar={{
                        title: isSampleActive
                            ? id
                                ? t('templates.samples.edit_sample')
                                : t('templates.samples.create_sample')
                            : id
                            ? t('templates.edit_template')
                            : t('templates.create_template'),
                        backLink: isSampleActive ? '/templates/samples' : '/templates'
                    }}
                >
                    {showPreview ? <TemplatePreview inputs={inputs} /> : <SubmitContainer />}
                    {!showPreview && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="submit" size="small" variant="contained" sx={{ m: 1, p: 1, mr: 4 }}>
                                {t('templates.submit.next')}
                            </Button>
                        </Box>
                    )}
                </XPageContainer>
            </form>
        </FormProvider>
    );
};

export default Submit;
