import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import XFormButtons from 'src/components/Forms/XFormButton';
import Name from './Name';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import CsvFilePicker from './CsvFilePicker';
import { useParams } from 'react-router';
import Instructions from './Instructions';
import TermsAndConditions from './TermsAndConditions ';
import { useAppSelector } from 'src/store/hooks';

const SubmitGroup = () => {
    const { id } = useParams();
    const { methods, onSubmit } = useSubmitForm(id);
    const group = useAppSelector((x) => x.groups.list.tableData.items.find((x) => x.id == id));
    useAfterSubmit();

    return (
        <>
            {group?.processing ? (
                <Box
                    sx={{ mt: 10 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection={'column'}
                >
                    <CircularProgress size={64} disableShrink thickness={3} />
                    <Typography sx={{ my: 2 }}>Processing uploaded file</Typography>
                </Box>
            ) : (
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ pl: 2, pr: 2 }}>
                            <Grid item>{!id && <Name />}</Grid>
                            <Grid item xs={12}>
                                <CsvFilePicker></CsvFilePicker>
                            </Grid>
                            <Grid item xs={12}>
                                <Instructions />
                            </Grid>
                            <Grid item>
                                <TermsAndConditions />
                            </Grid>
                            <Grid item lg={12}>
                                <XFormButtons
                                    authPolicy="Supervision"
                                    isDirty={methods.formState.isDirty}
                                    onResetClick={methods.reset}
                                    returnTo={id ? `/groups/${id}/contacts` : `/groups`}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            )}
        </>
    );
};

export default SubmitGroup;
