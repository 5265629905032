import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { resetState } from '../slice';

const useAfterSubmit = () => {
    const { state } = useAppSelector((x) => x.account);
    const navigateTo = useNavigate();
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (state == 'successful') {
            dispatch(resetState());
            navigateTo('/');
        }
    }, [state, dispatch, resetState]);
};

export default useAfterSubmit;
