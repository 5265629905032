import { Alert, Box, Link } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { downloadSampleCSV, nameOf } from 'src/utils/utils.shared';
import { MediaPicker, SnackbarUtils } from 'src/components';
import { GroupInputs } from './schema';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import { useParams } from 'react-router';
import { Group } from 'src/types';
import ContactsPreview from './ContactsPreview';
import { getRows } from 'src/utils/csvHelper';
import _ from 'lodash';

const CsvFilePicker = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const {
        watch,
        setValue,
        formState: { errors }
    } = useFormContext<GroupInputs>();

    const contacts: any = watch(nameOf<GroupInputs>('contacts'));
    const { items } = useAppSelector((x) => x.groups.list.tableData);
    const { direction } = useAppSelector((x) => x.account);

    const validateCsvData = (linesCount: number, totalContacts: number, limit: number) => {
        if (linesCount < 2) {
            return {
                isValid: false,
                message: t('groups.submit.invalid_csv_file')
            };
        }
        if (totalContacts > limit) {
            return {
                isValid: false,
                message: t('groups.submit.limit_exceeds')
            };
        }

        return {
            isValid: true,
            message: null
        };
    };
    const handleMediaChange = async (link: any, type: string, name: string, file: File) => {
        try {
            const limit = process.env.REACT_APP_GROUP_SIZE_MAX_VALUE;
            const selectedGroup = items.find((item: Group) => item.id === id);
            let contactsCount = 0;
            if (selectedGroup) {
                contactsCount = selectedGroup.contactsCount;
            }
            const lines = await getRows(file);
            const totalContacts: number = contactsCount + lines.length;
            const { isValid, message } = validateCsvData(lines.length, totalContacts, parseInt(limit) + 1);
            if (!isValid) {
                setValue(nameOf<GroupInputs>('contacts'), [], {
                    shouldDirty: true,
                    shouldValidate: true
                });
                return SnackbarUtils.error(message);
            }

            const contacts = parseRows(lines);
            const nonNumericContacts = contacts.filter((x) => isNaN(parseInt(x.phoneNumber)));

            if (nonNumericContacts.length) {
                const error = t('groups.submit.non_numeric_phone_numbers').replace(
                    '0',
                    nonNumericContacts.map((x) => x.phoneNumber).join(', ')
                );
                alert(error);
            } else {
                setValue(nameOf<GroupInputs>('contacts'), contacts, {
                    shouldDirty: true,
                    shouldValidate: true
                });
            }
        } catch (error) {
            return SnackbarUtils.error(error?.message || 'Error processing file:');
        }
    };

    const parseRows = (rows: string[]) => {
        return _.chain(rows)
            .filter((x) => !x.startsWith('Phone'))
            .map((x) => {
                var parts = x.replace(';', ',').split(',');
                if (parts.length > 1) {
                    return { phoneNumber: parts[0], name: parts[1] };
                }
                return null;
            })
            .filter((x) => Boolean(x?.phoneNumber?.trim()))
            .value();
    };

    return (
        <Box sx={{ pt: 1 }}>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Link component="button" variant="body2" sx={{ pb: 1 }} onClick={downloadSampleCSV}>
                    {t('groups.submit.download_sample')}
                </Link>
            </Box>
            <Alert severity={contacts ? 'success' : 'info'} sx={{ my: 1 }}>
                <MediaPicker
                    uniqueId="groups-csv-picker"
                    label={contacts ? t('groups.submit.selected_file') : t('groups.submit.select_file')}
                    accept=".csv"
                    onMediaSelected={handleMediaChange}
                ></MediaPicker>
                <FormHelperText sx={{ color: 'primary.main' }}>{contacts?.name}</FormHelperText>
            </Alert>
            <FormHelperText error>{errors?.contacts?.message}</FormHelperText>
            {contacts?.length ? <ContactsPreview contactList={contacts} direction={direction} /> : ''}
        </Box>
    );
};

export default CsvFilePicker;
