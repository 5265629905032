import {
    SallaEvent,
    ApiResponse,
    App,
    SallaEventCategory,
    SallaEventCategoryField,
    SallaEventTemplate
} from 'src/types';
import apiClient from './apiClient';
import { SubmitSallaTemplateInputs } from 'src/screens/salla/event-setup/schema';

export class SallaService {
    async getCategories(): Promise<ApiResponse<SallaEventCategory[]>> {
        const { data } = await apiClient.get<ApiResponse<SallaEventCategory[]>>('/salla/event-categories');
        return data;
    }

    async getCategoryFields(): Promise<ApiResponse<SallaEventCategoryField[]>> {
        const { data } = await apiClient.get<ApiResponse<SallaEventCategoryField[]>>('/salla/category-fields');
        return data;
    }

    async getEvents(): Promise<ApiResponse<SallaEvent[]>> {
        const { data } = await apiClient.get<ApiResponse<SallaEvent[]>>('/salla/events');
        return data;
    }

    async getEventsTemplates(): Promise<ApiResponse<SallaEventTemplate[]>> {
        const { data } = await apiClient.get<ApiResponse<SallaEventTemplate[]>>('/salla/event-templates');
        return data;
    }

    async setTemplate(inputs: SubmitSallaTemplateInputs): Promise<ApiResponse<App>> {
        const { data } = await apiClient.put<ApiResponse<App>>('/salla/event-templates', {
            ...inputs,
            placeholders: inputs.placeholders.map((x) => ({
                ...x,
                placeholder: `{{${x.index}}}`
            }))
        });
        return data;
    }
}
