import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { XLinkButton } from 'src/components';

const ChooseTemplateButton = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { pathname } = useLocation();
    const isSampleActive = pathname.split('/').includes('samples');
    return (
        <>
            {!id && !isSampleActive && (
                <Box sx={{ mb: 2 }}>
                    {/* <XLinkButton
                        text={t('templates.submit.choose_template')}
                        to={'/templates/samples/choose'}
                    ></XLinkButton> */}
                </Box>
            )}
        </>
    );
};

export default ChooseTemplateButton;
