import { Box } from '@mui/material';
import CommonForm from './commonForm';
import { XSwitch } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { updateInvalidActionResponseStatus } from '../../slice';
import { t } from 'i18next';

const InvalidAction = () => {
    const dispatch = useAppDispatch();
    const invalidActionResponse = useAppSelector((state) => state.businessBot.bot.enableInvalidMessageResponse);

    return (
        <Box>
            <CommonForm
                interfaceSubType="Text_InvalidAction"
                api="invalidaction"
                descriptionKey="bot.settings.invalid_action.description"
                bodyEnglishKey="bot.settings.invalid_action.bodyEnglish"
                bodyArabicKey="bot.settings.invalid_action.bodyArabic"
            ></CommonForm>
            <Box sx={{ pt: 8, display: 'flex', justifyContent: 'start' }}>
                <XSwitch
                    onChange={(e) => dispatch(updateInvalidActionResponseStatus(!invalidActionResponse))}
                    checked={invalidActionResponse}
                    label={
                        invalidActionResponse
                            ? t('bot.settings.invalid_action.invalidActionResponseEnabled')
                            : t('bot.settings.invalid_action.invalidActionResponseDisabled')
                    }
                />
            </Box>
        </Box>
    );
};

export default InvalidAction;
