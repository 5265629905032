import * as yup from 'yup';

export interface QuickReplyInputs {
    id: string;
    title: string;
    message: string;
}

export const schema = yup.object().shape({
    title: yup.string().required().trim().label('conversations.yup.quick_reply.submit.title'),
    message: yup.string().required().trim().label('conversations.yup.quick_reply.submit.message')
});
