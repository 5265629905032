import { useContext } from 'react';
import { Box, IconButton, Tooltip } from '@mui/material';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { DrawerContext } from 'src/contexts/DrawerContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

function Header() {
    const { currentDrawer, openDrawer } = useContext(DrawerContext);
    return (
        <Box
            component="span"
            sx={{
                top: 0,
                display: { lg: 'none', xs: 'inline-block' }
            }}
        >
            <Tooltip arrow title="Toggle Menu">
                <IconButton sx={{ mx: 0, p: 0 }} color="primary" onClick={() => openDrawer('Drawer1')}>
                    {currentDrawer != 'Drawer1' ? (
                        <MenuTwoToneIcon fontSize="small" />
                    ) : (
                        <CloseTwoToneIcon fontSize="small" />
                    )}
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default Header;
