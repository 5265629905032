import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { BotService } from 'src/services/bot.service';
import { TeamsService } from 'src/services/teams.service';
import { Bot, Product, Team } from 'src/types';
import { ActionState, DefaultTableData, TableData, UserLocale } from 'src/types/types.shared';
import { WelcomeMessageInputs } from './settings/content/welcomeMessage/schema';
import i18next from 'i18next';
import { EditorInputs } from './options/content/schema';
import { RootState } from 'src/store';
import { WhatsAppService } from 'src/services/whatsApp.service';
import { CommonInputs as CommonFormInputs } from './settings/content/commonForm/schema';
import _ from 'lodash';
import { SelectLanguageInputs } from './settings/content/selectLanguage/schema';
import { FeedbackMessageInputs } from './settings/content/feedback/schema';

export const NEW_INTERFACE = 'NEW_INTERFACE';

const service = new BotService();
const teamService = new TeamsService();
const catalogService = new WhatsAppService();

export interface SliceState {
    loadState: ActionState;
    submitState: ActionState;
    bot: Bot;
    teams: Team[];
    products: {
        state: ActionState;
        tableData: TableData<Product>;
    };
}

const initialState: SliceState = {
    loadState: undefined,
    submitState: undefined,
    bot: {
        botInterfaces: [],
        id: '',
        urlsBlocked: false,
        isActive: false,
        name: '',
        enableCustomerFeedback: false,
        enableInvalidMessageResponse: true
    },
    products: {
        state: undefined,
        tableData: DefaultTableData()
    },
    teams: []
};

export const getBusinessesBot = createAsyncThunk('GET_BUSINESS_BOT', async (_, thunkAPI) => {
    const response = await service.getBusinessBot();
    if (response.status === 'Successful') {
        return response.data;
    }

    return thunkAPI.rejectWithValue(response.message);
});

export const getAllTeams = createAsyncThunk('GET_ALL_TEAMS', async (_, thunkAPI) => {
    const response = await teamService.getTeams(1, 100);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateWelcomeMessage = createAsyncThunk(
    'BUSINESS_BOT_UPDATE_WELCOME_MESSAGE',
    async ({ inputs }: { inputs: WelcomeMessageInputs }, thunkAPI) => {
        const response = await service.updateWelcomeMessage(inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateSelectLanguage = createAsyncThunk(
    'BUSINESS_BOT_UPDATE_SELECT_LANGUAGE',
    async ({ inputs }: { inputs: SelectLanguageInputs }, thunkAPI) => {
        const response = await service.updateSelectLanguage(inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateSettings = createAsyncThunk(
    'BUSINESS_BOT_UPDATE_SETTINGS',
    async ({ inputs }: { inputs: CommonFormInputs }, thunkAPI) => {
        const response = await service.updateSettings(inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const addOption = createAsyncThunk('BUSINESS_BOT_ADD_LIST_ITEM', async (inputs: EditorInputs, thunkAPI) => {
    const response = await service.addOption(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateOption = createAsyncThunk('BUSINESS_BOT_UPDATE_OPTION', async (inputs: EditorInputs, thunkAPI) => {
    const response = await service.updateOption(inputs);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const deleteOption = createAsyncThunk('BUSINESS_BOT_DELETE_LIST_ITEM', async (id: string, thunkAPI) => {
    const response = await service.deleteOption(id);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const getProducts = createAsyncThunk('BUSINESS_BOT_GET_PRODUCTS', async (setId: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { page, limit } = state.businessBot.products.tableData;
    const response = await catalogService.getProducts(page, limit, setId);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateBotStatus = createAsyncThunk(
    'BUSINESS_BOT_CHANGE_STATUS',
    async (updatedStatus: boolean, thunkAPI) => {
        const response = await service.changeBotStatus(updatedStatus);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateBlockUrls = createAsyncThunk(
    'BUSINESS_BOT_UPDATE_BLOCK_URLS',
    async (blockUrls: boolean, thunkAPI) => {
        const response = await service.updateBlockUrls(blockUrls);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateFeedbackStatus = createAsyncThunk(
    'BUSINESS_BOT_UPDATE_FEEDBACK_STATUS',
    async (status: boolean, thunkAPI) => {
        const response = await service.updateFeedbackStatus(status);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateFeedbackInterfaces = createAsyncThunk(
    'BUSINESS_BOT_UPDATE_FEEDBACK_INTERFACES',
    async (inputs: FeedbackMessageInputs, thunkAPI) => {
        const response = await service.updateFeedbackInterfaces(inputs);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const updateInvalidActionResponseStatus = createAsyncThunk(
    'BUSINESS_BOT_UPDATE_INVALID_ACTION_RESPONSE_STATUS',
    async (status: boolean, thunkAPI) => {
        const response = await service.updateInvalidActionResponseStatus(status);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

const slice = createSlice({
    name: 'businessBot',
    initialState,
    reducers: {
        resetActionState: (state) => {
            state.submitState = undefined;
        },
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.products.tableData.page = action.payload.page;
            state.products.tableData.limit = action.payload.limit;
        },
        removeEmptyListOption: (state) => {
            state.bot.botInterfaces = state.bot.botInterfaces.filter((x) => x.id != NEW_INTERFACE);
        },

        addEmptyListOption: (state, action: PayloadAction<UserLocale>) => {
            if (!_.find(state.bot.botInterfaces, (x) => x.id == NEW_INTERFACE))
                state.bot.botInterfaces.push({
                    id: NEW_INTERFACE,
                    interfaceType: 'Text',
                    locale: action.payload,
                    text: '',
                    order: state.bot.botInterfaces.length + 1
                });
        }
    },
    extraReducers(builder) {
        builder.addCase(getBusinessesBot.pending, (state) => {
            state.loadState = 'inProgress';
        });
        builder.addCase(getBusinessesBot.fulfilled, (state, action) => {
            state.bot = action.payload;
            state.loadState = 'successful';
            state.submitState = undefined;
        });
        builder.addCase(getBusinessesBot.rejected, (state, action) => {
            state.loadState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.fetch_bot_details'));
        });

        builder.addCase(updateWelcomeMessage.pending, (state) => {
            state.submitState = 'inProgress';
        });
        builder.addCase(updateWelcomeMessage.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('bot.slice.success.update_settings'));
            state.submitState = 'successful';
        });
        builder.addCase(updateWelcomeMessage.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_settings'));
        });

        builder.addCase(updateSelectLanguage.pending, (state) => {
            state.submitState = 'inProgress';
        });
        builder.addCase(updateSelectLanguage.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('bot.slice.success.update_settings'));
            state.submitState = 'successful';
        });
        builder.addCase(updateSelectLanguage.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_settings'));
        });

        builder.addCase(updateSettings.pending, (state) => {
            state.submitState = 'inProgress';
        });
        builder.addCase(updateSettings.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('bot.slice.success.update_settings'));
            state.submitState = 'successful';
        });
        builder.addCase(updateSettings.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_settings'));
        });

        builder.addCase(getAllTeams.pending, () => {});
        builder.addCase(getAllTeams.fulfilled, (state, action) => {
            state.teams = action.payload.items;
        });
        builder.addCase(getAllTeams.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.fetch_teams'));
        });

        builder.addCase(addOption.pending, () => {});
        builder.addCase(addOption.fulfilled, (state, action) => {
            state.submitState = 'successful';
            SnackbarUtils.success(i18next.t('bot.slice.success.add_option'));
        });
        builder.addCase(addOption.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.add_option'));
        });

        builder.addCase(updateOption.pending, () => {});
        builder.addCase(updateOption.fulfilled, (state) => {
            state.submitState = 'successful';
            SnackbarUtils.success(i18next.t('bot.slice.success.update_option'));
        });
        builder.addCase(updateOption.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_option'));
        });

        builder.addCase(deleteOption.pending, () => {});
        builder.addCase(deleteOption.fulfilled, (state, action) => {
            state.submitState = 'successful';
            SnackbarUtils.success(i18next.t('bot.slice.success.option_deleted'));
        });
        builder.addCase(deleteOption.rejected, (_, action) => {
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.delete_option'));
        });

        builder.addCase(getProducts.pending, (state) => {
            state.products.state = 'inProgress';
        });
        builder.addCase(getProducts.fulfilled, (state, action) => {
            state.products.tableData = action.payload;
            state.products.state = 'successful';
        });
        builder.addCase(getProducts.rejected, (state, action) => {
            state.products.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('team.slice.errors.fetch_teams'));
        });

        builder.addCase(updateBotStatus.pending, (state) => {
            state.submitState = 'inProgress';
        });

        builder.addCase(updateBotStatus.fulfilled, (state) => {
            state.bot.isActive = !state.bot.isActive;
            if (state.bot.isActive) {
                SnackbarUtils.success(i18next.t('bot.slice.success.change_status_active'));
            } else {
                SnackbarUtils.success(i18next.t('bot.slice.success.change_status_inactive'));
            }
        });

        builder.addCase(updateBotStatus.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.change_status'));
        });

        builder.addCase(updateBlockUrls.pending, (state) => {
            state.submitState = 'inProgress';
        });

        builder.addCase(updateBlockUrls.fulfilled, (state, action) => {
            state.bot.urlsBlocked = !state.bot.urlsBlocked;
            if (state.bot.urlsBlocked) {
                SnackbarUtils.success(i18next.t('bot.slice.success.update_block_urls_blocked'));
            } else {
                SnackbarUtils.success(i18next.t('bot.slice.success.update_block_urls_not_blocked'));
            }
        });

        builder.addCase(updateBlockUrls.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_block_urls'));
        });

        builder.addCase(updateFeedbackStatus.pending, (state) => {
            state.submitState = 'inProgress';
        });

        builder.addCase(updateFeedbackStatus.fulfilled, (state, action) => {
            state.bot.enableCustomerFeedback = !state.bot.enableCustomerFeedback;
            SnackbarUtils.success(
                state.bot.enableCustomerFeedback
                    ? i18next.t('bot.slice.success.update_feedback_status_enabled')
                    : i18next.t('bot.slice.success.update_feedback_status_disabled')
            );
        });

        builder.addCase(updateFeedbackStatus.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_feedback_status'));
        });

        builder.addCase(updateFeedbackInterfaces.pending, (state) => {
            state.submitState = 'inProgress';
        });

        builder.addCase(updateFeedbackInterfaces.fulfilled, (state) => {
            SnackbarUtils.success(i18next.t('bot.slice.success.update_feedback_interfaces'));
            state.submitState = 'successful';
        });

        builder.addCase(updateFeedbackInterfaces.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_feedback_interfaces')
            );
        });

        builder.addCase(updateInvalidActionResponseStatus.pending, (state) => {
            state.submitState = 'inProgress';
        });

        builder.addCase(updateInvalidActionResponseStatus.fulfilled, (state) => {
            state.bot.enableInvalidMessageResponse = !state.bot.enableInvalidMessageResponse;
            if (state.bot.enableInvalidMessageResponse) {
                SnackbarUtils.success(i18next.t('bot.slice.success.update_invalid_action_response_status_enabled'));
            } else {
                SnackbarUtils.success(i18next.t('bot.slice.success.update_invalid_action_response_status_disabled'));
            }
            state.submitState = 'successful';
        });

        builder.addCase(updateInvalidActionResponseStatus.rejected, (state, action) => {
            state.submitState = 'failed';
            SnackbarUtils.error(
                action?.payload?.toString() ?? i18next.t('bot.slice.errors.update_invalid_action_response_status')
            );
        });
    }
});

export const { resetActionState, addEmptyListOption, removeEmptyListOption, setPaging } = slice.actions;

export default slice.reducer;
