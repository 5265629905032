import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { updatePassword } from '../slice';
import { UpdatePasswordInputs, schema } from './schema';

const useSubmitForm = () => {
    const user = useAppSelector((x) => x.account.user);
    const dispatch = useAppDispatch();
    const methods = useForm<UpdatePasswordInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            currentPassword: '',
            newPassword: ''
        }
    });

    const onSubmit = (inputs: UpdatePasswordInputs) => {
        dispatch(updatePassword(inputs));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
