import yupx from 'src/locales/yupLocale';
import { UserLocale } from 'src/types';

export interface AddBusinessInputs {
    businessName: string;
    adminFirstName: string;
    adminLastName: string;
    language: UserLocale;
    email: string;
    whatsAppBusinessPhoneNumber: string;
    whatsAppToken: string;
    whatsAppSecret: string;
    whatsAppPhoneNumberId: string;
    whatsAppBusinessAccountId: string;
    whatsAppApplicationId: string;
    timeZone: string;
}

export const schema = yupx.object().shape({
    businessName: yupx.string().required().trim().max(50).label('business.yup.submit.business_name'),
    adminFirstName: yupx.string().required().trim().label('business.yup.submit.admin_first_name'),
    adminLastName: yupx.string().required().trim().label('business.yup.submit.admin_last_name'),
    language: yupx.string().required().trim().label('business.yup.submit.language'),
    email: yupx.string().email().required().trim().label('business.yup.submit.email'),
    whatsAppBusinessPhoneNumber: yupx.string().required().trim().label('business.yup.submit.phone_number'),
    whatsAppToken: yupx.string().required().trim().label('business.yup.submit.whatsapp_token'),
    whatsAppSecret: yupx.string().required().trim().label('business.yup.submit.whatsapp_secret'),
    whatsAppPhoneNumberId: yupx.string().required().trim().label('business.yup.submit.whatsapp_phone_number_id'),
    whatsAppBusinessAccountId: yupx
        .string()
        .required()
        .trim()
        .label('business.yup.submit.whatsapp_business_account_id'),
    whatsAppApplicationId: yupx.string().required().trim().label('business.yup.submit.whatsapp_application_id')
});



export const registarationSchema = yupx.object().shape({
    businessName: yupx.string().required().trim().max(50).label('business.yup.submit.business_name'),
    adminFirstName: yupx.string().required().trim().label('business.yup.submit.admin_first_name'),
    adminLastName: yupx.string().required().trim().label('business.yup.submit.admin_last_name'),
    language: yupx.string().required().trim().label('business.yup.submit.language'),
    email: yupx.string().email().required().trim().label('business.yup.submit.email'),
});
