import { Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LinkCatalogInitiated = () => {
    const { t } = useTranslation();

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', m: 2 }}>
            <Alert variant="outlined" severity="success">
                {t('catalog.products.link_catalog_initated')}
            </Alert>
        </Box>
    );
};

export default LinkCatalogInitiated;
