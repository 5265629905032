import { Grid } from '@mui/material';
import Body from '../Body';
import Actions from './actions';

const NonProductMessage = ({ title }: { title: string }) => {
    return (
        <>
            <Body></Body>
            <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <Actions title={title}></Actions>
                </Grid>
            </Grid>
        </>
    );
};

export default NonProductMessage;
