import { Box, FormLabel, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../../schema';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const FooterText = ({ index, inputColor }: { index: number; inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        watch,
        formState: { errors }
    } = useFormContext<SubmitTemplateInputs>();

    const type = watch(`buttons.quickReplies.${index}.type`);
    const error = errors?.buttons?.quickReplies.length > 0 ? errors?.buttons?.quickReplies[index]?.footer : undefined;

    return (
        <Box sx={{ mr: 1 }}>
            <FormLabel>{t('templates.submit.buttons.footer_text')}</FormLabel>
            <TextField
                fullWidth
                error={error && true}
                {...register(`buttons.quickReplies.${index}.footer`)}
                placeholder={t('templates.submit.buttons.footer_text')}
                type="text"
                size="small"
                disabled={type == 'MARKETING_OPT_OUT'}
                sx={{
                    '&.MuiFormControl-root': {
                        '.MuiOutlinedInput-root': {
                            background: inputColor
                        }
                    }
                }}
                helperText={error?.message}
            />
        </Box>
    );
};

export default FooterText;
