import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { SubmitSallaTemplateInputs, schema } from './schema';
import { getEventCategories, getEvents, getEventsTemplates, setTemplate } from '../slice';
import { SallaEventTemplate } from 'src/types';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';

const useSubmitForm = (categoryId: string, sallaEventId: string, sallaEventTemplate: SallaEventTemplate) => {
    const dispatch = useAppDispatch();
    const navigateTo = useNavigate();
    const methods = useForm<SubmitSallaTemplateInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            id: sallaEventTemplate?.id,
            sallaEventId: sallaEventId,
            locale: sallaEventTemplate?.contactLocale ?? 'ar',
            metaTemplateId: sallaEventTemplate?.metaTemplateId,
            placeholders:
                sallaEventTemplate?.placeholders?.map((x) => ({
                    ...x,
                    index: +x.placeholder.replace('{{', '').replace('}}', '')
                })) ?? []
        }
    });

    const onSubmit = (inputs: SubmitSallaTemplateInputs) => {
        const response = dispatch(setTemplate(inputs));
        if (!('error' in response)) {
            navigateTo(`/apps/salla/categories/${categoryId}/events`);
        }
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
