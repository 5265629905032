import { XIconButton } from 'src/components';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const HelperIcon = ({ content }: { content: string }) => {
    const [copied, setCopied] = useState(false);
    const { t } = useTranslation();
    const onHelpClick = () => {
        navigator.clipboard.writeText(content);
        setCopied(true);
    };

    return (
        <Box sx={{ m: 1 }}>
            <XIconButton tooltip={t('business.whatsApp_info.click_to_copy')} onClick={onHelpClick}>
                {copied ? (
                    <CheckCircleOutlineIcon color="success"></CheckCircleOutlineIcon>
                ) : (
                    <ContentCopyIcon color="primary"></ContentCopyIcon>
                )}
            </XIconButton>
        </Box>
    );
};

export default HelperIcon;
