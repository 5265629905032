import { useFormContext } from 'react-hook-form';
import { XLanguageSwitch } from 'src/components';
import { nameOf } from 'src/utils/utils.shared';
import { UpdateProfileInputs } from './schema';
import { UserLocale } from 'src/types';

const Language = () => {
    const { setValue, watch } = useFormContext<UpdateProfileInputs>();

    const value = watch('locale') as UserLocale;

    return (
        <XLanguageSwitch
            value={value}
            onLocaleChange={(x) => setValue(nameOf<UpdateProfileInputs>('locale'), x, { shouldDirty: true })}
        ></XLanguageSwitch>
    );
};

export default Language;
