import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppSelector } from 'src/store/hooks';
import { nameOf } from 'src/utils/utils.shared';
import { AddBusinessInputs } from './schema';

const WhatsappToken = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const business = useAppSelector((x) => x.businesses.list.tableData.items.find((x) => x.id == id));
    const {
        register,
        formState: { errors }
    } = useFormContext<AddBusinessInputs>();

    return business ? (
        <></>
    ) : (
        <TextField
            disabled={business && true}
            fullWidth
            error={errors?.whatsAppToken && true}
            {...register(nameOf<AddBusinessInputs>('whatsAppToken'))}
            id={nameOf<AddBusinessInputs>('whatsAppToken')}
            placeholder={t('business.submit.whatsapp_token')}
            label={t('business.submit.whatsapp_token')}
            size="medium"
            type="text"
            multiline
            rows={2}
            helperText={errors?.whatsAppToken?.message}
        />
    );
};

export default WhatsappToken;
