import { Box, Grid } from '@mui/material';
import { XComponentContainer, XPageContainer, useStyles } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useEffect } from 'react';
import { getBusinessesBot } from 'src/screens/bot/slice';
import { useParams } from 'react-router';
import { getCampaign } from '../slice';
import { Paper } from '@mui/material';
import { getTemplates } from 'src/screens/templates/slice';
import { getTemplateActions } from 'src/types';
import SubmitContainer from './SubmitContainer';

const Customise = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const botInterfaces = useAppSelector((x) => x.businessBot.bot.botInterfaces);
    const { value: current, state } = useAppSelector((x) => x.campaigns.current);
    const templates = useAppSelector((x) => x.templates.list);
    const currentTemplate = templates.items.find((x) => x.name == current?.templateName);
    const show = templates.state == 'successful' && (!id || state == 'successful');
    const actions = getTemplateActions(currentTemplate);
    const { borderRB } = useStyles();

    useEffect(() => {
        if (id) {
            dispatch(getCampaign(id));
        }
    }, []);

    useEffect(() => {
        if (botInterfaces.length == 0) {
            dispatch(getBusinessesBot());
        }
    }, [dispatch, botInterfaces]);

    useEffect(() => {
        if (templates.items.length == 0) {
            dispatch(getTemplates(''));
        }
    }, [templates]);

    return (
        <XComponentContainer>
            <XPageContainer
                loading={templates.state == 'inProgress' || state == 'inProgress'}
                topBar={{
                    title: '',
                    action: <></>
                }}
            >
                {show && (
                    <Grid container>
                        {actions.map((x, index) => (
                            <Grid key={index} item md={4} sx={borderRB}>
                                <Box
                                    sx={{
                                        m: 1,
                                        p: 1,
                                        height: '100%'
                                    }}
                                >
                                    <SubmitContainer button={x.text} />
                                </Box>
                            </Grid>
                        ))}
                        <Grid item sx={{ m: 2 }} xs={12}></Grid>
                    </Grid>
                )}
            </XPageContainer>
        </XComponentContainer>
    );
};

export default Customise;
