import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getCampaigns } from '../slice';

const useAfterDelete = () => {
    const { state } = useAppSelector((x) => x.users.submit);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (state === 'successful') {
            dispatch(getCampaigns());
        }
    }, [state, dispatch, getCampaigns]);
};

export default useAfterDelete;
