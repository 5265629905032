import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { EmailCampaignInputs, schema } from './schema';
import { sendEmailCampaign } from '../slice';

const useSubmitForm = () => {
    const dispatch = useAppDispatch();
    const methods = useForm<EmailCampaignInputs>({
        resolver: yupResolver(schema),
        defaultValues: {}
    });
    const onSubmit = (inputs: EmailCampaignInputs) => {
        dispatch(sendEmailCampaign(inputs));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
