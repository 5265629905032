import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarUtils } from 'src/components';
import { SmsService } from 'src/services/sms.service';
import { RootState } from 'src/store';
import { SmsCampaign, SmsTemplate } from 'src/types';
import { ActionState, DefaultTableData, TableData } from 'src/types/types.shared';
import i18next from 'i18next';
import { SubmitSmsCampaignInputs } from './campaigns/newCampaign/schema';

const service = new SmsService();

export interface SliceState {
    templates: {
        state: ActionState;
        tableData: TableData<SmsTemplate>;
    };
    submitTemplate: {
        state: ActionState;
    };
    smsBalance: {
        state: ActionState;
        value: number | undefined;
    };
    smsCampaigns: {
        state: ActionState;
        tableData: TableData<SmsCampaign>;
    };
}

const initialState: SliceState = {
    templates: {
        state: undefined,
        tableData: DefaultTableData()
    },
    submitTemplate: {
        state: undefined
    },
    smsBalance: {
        state: undefined,
        value: undefined
    },
    smsCampaigns: {
        state: undefined,
        tableData: DefaultTableData()
    }
};

export const getSmsTemplates = createAsyncThunk('GET_SMS_TEMPLATES', async (getAll: boolean, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    let { page, limit } = state.users.list.tableData;
    if (getAll) {
        page = 1;
        limit = 500;
    }
    const response = await service.getSmsTemplates(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const addTemplate = createAsyncThunk(
    'ADD_SMS_TEMPLATE',
    async (template: { title: string; text: string }, thunkAPI) => {
        const response = await service.addTemplate(template);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const getBalance = createAsyncThunk('GET_SMS_TEMPLATE', async (businessId: string, thunkAPI) => {
    const response = await service.getSmsBalance(businessId);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const updateTemplate = createAsyncThunk('UPDATE TEMPLATE', async (template: SmsTemplate, thunkAPI) => {
    const response = await service.updateTemplate(template);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const deleteTemplate = createAsyncThunk('DELETE_SMS_TEMPLATE', async (id: string, thunkAPI) => {
    const response = await service.deleteTemplate(id);
    if (response.status === 'Successful') {
        thunkAPI.dispatch(getSmsTemplates(false));
    } else {
        return thunkAPI.rejectWithValue(response.message);
    }
});

export const getSmsCampaigns = createAsyncThunk('GET_SMS_CAMPAIGNS', async (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    let { page, limit } = state.sms.smsCampaigns.tableData;
    const response = await service.getSmsCampaigns(page, limit);
    if (response.status === 'Successful') return response.data;
    return thunkAPI.rejectWithValue(response.message);
});

export const sendCampaign = createAsyncThunk(
    'SEND_SMS_CAMPAIGN',
    async (campaign: SubmitSmsCampaignInputs, thunkAPI) => {
        const response = await service.sendSmsCampaign(campaign);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

export const testCampaign = createAsyncThunk(
    'TEST_SMS_CAMPAIGN',
    async (campaign: SubmitSmsCampaignInputs, thunkAPI) => {
        const response = await service.testSmsCampaign(campaign);
        if (response.status === 'Successful') return response.data;
        return thunkAPI.rejectWithValue(response.message);
    }
);

const slice = createSlice({
    name: 'sms',
    initialState,
    reducers: {
        setPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.templates.tableData.page = action.payload.page;
            state.templates.tableData.limit = action.payload.limit;
        },
        steSmsCampaignsPaging: (state, action: PayloadAction<{ page: number; limit: number }>) => {
            state.smsCampaigns.tableData.page = action.payload.page;
            state.smsCampaigns.tableData.limit = action.payload.limit;
        }
    },
    extraReducers(builder) {
        builder.addCase(getSmsTemplates.pending, (state) => {
            state.templates.state = 'inProgress';
            state.smsBalance.state = undefined;
        });
        builder.addCase(getSmsTemplates.fulfilled, (state, action) => {
            state.templates.tableData = action.payload;
            state.templates.state = 'successful';
            state.submitTemplate.state = undefined;
        });
        builder.addCase(getSmsTemplates.rejected, (state, action) => {
            state.templates.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.fetch_templates'));
        });

        builder.addCase(addTemplate.pending, (state) => {
            state.submitTemplate.state = 'inProgress';
        });
        builder.addCase(addTemplate.fulfilled, (state) => {
            state.submitTemplate.state = 'successful';
            SnackbarUtils.success(i18next.t('sms.slice.success.add_template'));
        });
        builder.addCase(addTemplate.rejected, (state, action) => {
            state.submitTemplate.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.add_template'));
        });

        builder.addCase(updateTemplate.pending, (state) => {
            state.submitTemplate.state = 'inProgress';
        });
        builder.addCase(updateTemplate.fulfilled, (state, action) => {
            state.submitTemplate.state = 'successful';
            SnackbarUtils.success(i18next.t('sms.slice.success.edit_template'));
        });
        builder.addCase(updateTemplate.rejected, (state, action) => {
            state.submitTemplate.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.edit_template'));
        });

        builder.addCase(deleteTemplate.pending, (state) => {
            state.submitTemplate.state = 'inProgress';
        });
        builder.addCase(deleteTemplate.fulfilled, (state) => {
            state.submitTemplate.state = 'successful';
            SnackbarUtils.success(i18next.t('sms.slice.success.delete_template'));
        });

        builder.addCase(deleteTemplate.rejected, (state, action) => {
            state.submitTemplate.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.delete_template'));
        });

        builder.addCase(getBalance.pending, (state) => {
            state.smsBalance.state = 'inProgress';
        });
        builder.addCase(getBalance.fulfilled, (state, action) => {
            state.smsBalance.state = 'successful';
            state.smsBalance.value = action.payload.balance;
        });
        builder.addCase(getBalance.rejected, (state, action) => {
            state.smsBalance.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.get_balance'));
        });
        builder.addCase(getSmsCampaigns.pending, (state) => {
            state.smsCampaigns.state = 'inProgress';
            state.smsBalance.state = undefined;
        });
        builder.addCase(getSmsCampaigns.fulfilled, (state, action) => {
            state.smsCampaigns.tableData = action.payload;
            console.log(action.payload);
            state.smsCampaigns.state = 'successful';
        });
        builder.addCase(getSmsCampaigns.rejected, (state, action) => {
            state.smsCampaigns.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.fetch_templates'));
        });

        builder.addCase(sendCampaign.pending, (state) => {
            state.smsCampaigns.state = 'inProgress';
        });

        builder.addCase(sendCampaign.fulfilled, (state) => {
            state.smsCampaigns.state = 'successful';
            SnackbarUtils.success(i18next.t('sms.slice.success.send_campaign'));
        });

        builder.addCase(sendCampaign.rejected, (state, action) => {
            state.smsCampaigns.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.send_campaign'));
        });

        builder.addCase(testCampaign.pending, (state) => {
            state.smsCampaigns.state = 'inProgress';
        });

        builder.addCase(testCampaign.fulfilled, (state) => {
            state.smsCampaigns.state = 'successful';
            SnackbarUtils.success(i18next.t('sms.slice.success.test_now'));
        });

        builder.addCase(testCampaign.rejected, (state, action) => {
            state.smsCampaigns.state = 'failed';
            SnackbarUtils.error(action?.payload?.toString() ?? i18next.t('sms.slice.errors.test_now'));
        });
    }
});

export const { setPaging, steSmsCampaignsPaging } = slice.actions;

export default slice.reducer;
