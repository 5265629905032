import { MenuItem, FormControl, Select, FormHelperText } from '@mui/material';
import { HeaderType, SubmitTemplateInputs } from '../schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function HeaderTypes({ inputColor }: { inputColor: string }) {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const types: HeaderType[] = ['NONE', 'TEXT', 'MEDIA'];

    return (
        <FormControl fullWidth sx={{ maxWidth: '20%', mr: 1 }}>
            <Select
                sx={{ bgcolor: inputColor }}
                defaultValue={defaultValues?.header?.type}
                error={errors?.header?.type && true}
                {...register('header.type')}
                id={'header.type'}
                size="small"
            >
                {types.map((x, i) => (
                    <MenuItem key={i} value={x}>
                        {t(`templates.submit.header.header_types.${x.toLowerCase()}`)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{errors?.header?.type}</FormHelperText>
        </FormControl>
    );
}
