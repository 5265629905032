import { Box, Button, Divider, Typography } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

const BodyPreview = ({
    title,
    body,
    onOpenListClick
}: {
    title: string;
    body: string;
    onOpenListClick: () => void;
}) => {
    return (
        <>
            <Typography
                sx={{
                    p: 2,
                    fontSize: 12
                }}
            >
                {body}
            </Typography>
            <Divider></Divider>
            <Box sx={{ display: 'flex', justifyContent: 'center', m: 1 }}>
                <Button onClick={onOpenListClick} size="small" startIcon={<FormatListBulletedIcon sx={{ ml: 1 }} />}>
                    {title}
                </Button>
            </Box>
        </>
    );
};

export default BodyPreview;
