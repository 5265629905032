import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { QuickReplyInputs, schema } from './schema';
import { addQuickreply, updateQuickreply } from 'src/screens/conversations/slice';

const useSubmitForm = (id: string) => {
    const quickReply = useAppSelector((x) => x.conversations.quickReplies.list.items.find((x) => x.id == id));
    const dispatch = useAppDispatch();
    const methods = useForm<QuickReplyInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            id: quickReply?.id,
            title: quickReply?.title,
            message: quickReply?.message
        }
    });
    const onSubmit = (inputs: QuickReplyInputs) => {
        if (!id) dispatch(addQuickreply(inputs));
        else dispatch(updateQuickreply(inputs));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
