import { createContext, useContext, useCallback, useRef, useState } from 'react';
import { XConfirmModal } from 'src/components';

const ConfirmDialog = createContext<(title: string, body: string) => Promise<boolean>>((title: string, body: string) =>
    Promise.resolve(true)
);

export function ConfirmDialogProvider({ children }) {
    const fn = useRef<(choice: boolean) => void>();
    const [state, setState] = useState({ isOpen: false, title: '', body: '' });

    const confirm = useCallback(
        (title: string, body: string) => {
            return new Promise<boolean>((resolve) => {
                setState({ title, body, isOpen: true });
                fn.current = (choice) => {
                    resolve(choice);
                    setState({ isOpen: false, title, body: body });
                };
            });
        },
        [setState]
    );

    return (
        <ConfirmDialog.Provider value={confirm}>
            {children}
            <XConfirmModal
                {...state}
                onClose={() => {
                    setState({ isOpen: false, title: state.title, body: state.body });
                    fn.current(false);
                }}
                onConfirm={() => fn.current(true)}
            />
        </ConfirmDialog.Provider>
    );
}

export default function useConfirm() {
    return useContext(ConfirmDialog);
}
