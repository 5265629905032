import yupx from 'src/locales/yupLocale';

export interface FeedbackMessageInputs {
    provideFeedbackEnglish: string;
    provideFeedbackArabic: string;
    feedbackProvidedEnglish: string;
    feedbackProvidedArabic: string;
}

export const schema = yupx.object().shape({
    provideFeedbackEnglish: yupx.string().required().trim().label('bot.yup.settings.feedback.provideFeedbackEnglish'),
    provideFeedbackArabic: yupx.string().required().trim().label('bot.yup.settings.feedback.provideFeedbackArabic'),
    feedbackProvidedEnglish: yupx.string().required().trim().label('bot.yup.settings.feedback.feedbackProvidedEnglish'),
    feedbackProvidedArabic: yupx.string().required().trim().label('bot.yup.settings.feedback.feedbackProvidedArabic')
});
