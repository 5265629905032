import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getUsers } from '../../slice';

const useAfterMemberChanged = (teamId: string, showMembers: boolean) => {
    const { state } = useAppSelector((x) => x.teams.submit);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (state === 'successful') {
            dispatch(
                getUsers({
                    teamId: teamId,
                    isMember: showMembers
                })
            );
        }
    }, [state, dispatch]);
};

export default useAfterMemberChanged;
