import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'src/store/hooks';

const useAfterSubmit = () => {
    const { state } = useAppSelector((x) => x.businesses.submit);
    const navigateTo = useNavigate();
    useEffect(() => {
        if (state === 'successful') navigateTo('/businesses');
    }, [state]);
};

export default useAfterSubmit;
