import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { nameOf } from 'src/utils/utils.shared';
import { WhatsaapInfoInputs } from './schema';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { useState } from 'react';

const WhatsappToken = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<WhatsaapInfoInputs>();

    const [show, setShow] = useState(false);
    return (
        <>
            <FormControlLabel
                value="start"
                control={<Switch color="primary" onChange={(_, checked) => setShow(checked)} />}
                label={t('business.whatsApp_info.token_switch_label')}
                labelPlacement="start"
                sx={{ mb: 2, ml: 0, mr: 1 }}
            />
            {show && (
                <TextField
                    fullWidth
                    error={errors?.whatsAppToken && true}
                    {...register(nameOf<WhatsaapInfoInputs>('whatsAppToken'))}
                    id={nameOf<WhatsaapInfoInputs>('whatsAppToken')}
                    placeholder={t('business.whatsApp_info.new_whatsapp_token')}
                    label={t('business.whatsApp_info.new_whatsapp_token')}
                    size="medium"
                    multiline
                    rows={3}
                    type="text"
                    helperText={errors?.whatsAppToken?.message}
                />
            )}
        </>
    );
};

export default WhatsappToken;
