import { Box, Button, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';

const Text = ({ inputColor }: { inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        setValue,
        watch,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();
    const header = watch('header');
    const hasVariable = header.text?.indexOf('{{1}}') != -1;

    const onVariableAdd = () => {
        setValue(
            'header',
            {
                ...header,
                text: `${header.text} {{1}}`,
                textSample: ''
            },
            {
                shouldDirty: true
            }
        );
    };

    return (
        <Box width={'100%'} sx={{ mr: 1 }}>
            <TextField
                fullWidth
                defaultValue={defaultValues?.name}
                error={errors?.header?.text && true}
                {...register('header.text')}
                id={'header.text'}
                placeholder={t('templates.submit.header.text.placeholder')}
                size="small"
                type="text"
                multiline
                sx={{
                    '&.MuiFormControl-root': {
                        '.MuiOutlinedInput-root': {
                            background: inputColor
                        }
                    }
                }}
                helperText={errors?.header?.text?.message}
            />
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                    disabled={hasVariable}
                    sx={{ mt: 1 }}
                    startIcon={<AddIcon />}
                    size="small"
                    onClick={onVariableAdd}
                >
                    {t('templates.submit.add_variable')}
                </Button>
            </Box>
        </Box>
    );
};

export default Text;
