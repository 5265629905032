import yupx from 'src/locales/yupLocale';
import { WorkHours } from 'src/types';

export interface AddTeamInputs {
    name: string;
    schedule: WorkHours[];
}

export const schema = yupx.object().shape({
    name: yupx.string().required().trim().label('team.yup.submit.name'),
    schedule: yupx.array().of(
        yupx.object().shape({
            dayOfWeek: yupx.string().required().label('team.yup.submit.schedule.day_of_week'),
            shiftStart: yupx.string().when('availability', ([availability], schema) => {
                if (!availability) {
                    return schema.notRequired();
                }
                return schema.required().label('team.yup.submit.schedule.shift_start');
            }),
            shiftEnd: yupx.string().when('availability', ([availability], schema) => {
                if (!availability) {
                    return schema.notRequired();
                }
                return schema.required().label('team.yup.submit.schedule.shift_end');
            })
        })
    )
});
