import { Box, Grid, Typography } from '@mui/material';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useAfterSubmit from './useAfterSubmit';
import useSubmitForm from './useSubmitForm';
import BodyEnglish from './BodyEnglish';
import BodyArabic from './BodyArabic';
import XFormButtons from 'src/components/Forms/XFormButton';
import { Bot, InterfaceSubType } from 'src/types';
import { useOutletContext } from 'react-router-dom';

const CommonForm = ({
    interfaceSubType,
    descriptionKey,
    bodyEnglishKey,
    bodyArabicKey,
    api
}: {
    interfaceSubType: InterfaceSubType;
    descriptionKey: string;
    bodyEnglishKey: string;
    bodyArabicKey: string;
    api: string;
}) => {
    const { t } = useTranslation();
    const bot = useOutletContext() as Bot;

    const interfaces = bot.botInterfaces.filter((x) => x.interfaceSubType == interfaceSubType);
    const { methods, onSubmit } = useSubmitForm(interfaces, api);

    useAfterSubmit();

    return (
        <>
            <Typography variant="h6" sx={{ pt: 1, pb: 2 }}>
                {t(descriptionKey)}
            </Typography>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Grid container alignItems="stretch" spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <BodyEnglish placeholder={t(bodyEnglishKey)}></BodyEnglish>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <BodyArabic placeholder={t(bodyArabicKey)}></BodyArabic>
                        </Grid>
                    </Grid>
                    <Box sx={{ pl: 1 }}>
                        <XFormButtons
                            authPolicy="Supervision"
                            isDirty={methods.formState.isDirty}
                            onResetClick={methods.reset}
                            returnTo="/bot"
                        />
                    </Box>
                </form>
            </FormProvider>
        </>
    );
};

export default CommonForm;
