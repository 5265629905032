import React from 'react';
import { useEffect, useState } from 'react';
import { XComponentContainer, XLanguageSwitch, XPageContainer } from 'src/components';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { UserLocale } from 'src/types';
import { getBusinessesBot, getAllTeams, addEmptyListOption } from '../slice';
import { Box, Button } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import _ from 'lodash';
import { Outlet, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import Sidebar from './sidebar';

export const LocaleContext = React.createContext<UserLocale>('ar');

function Options() {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const user = useAppSelector((x) => x.account.user);
    const [locale, setLocale] = useState<UserLocale>(user.locale);
    const { bot, loadState } = useAppSelector((x) => x.businessBot);
    const navigate = useNavigate();

    useEffect(() => {
        if (loadState == undefined) dispatch(getBusinessesBot());
        dispatch(getAllTeams());
    }, [dispatch, getBusinessesBot, loadState]);

    const interactiveList = bot.botInterfaces.find(
        (x) => x.interfaceType == 'InteractiveList' && x.locale == locale
    )?.interactiveList;

    const addMode = window.location.pathname.indexOf('/bot/options/new') == 0;

    const onAddClick = () => {
        dispatch(addEmptyListOption(locale));
        navigate('/bot/options/new');
    };

    const onLanguageChange = (newLocale: UserLocale) => {
        setLocale(newLocale);

        const interactiveList = bot.botInterfaces.find(
            (x) => x.interfaceType == 'InteractiveList' && x.locale == newLocale
        )?.interactiveList;

        var firstOption = _.head(interactiveList?.options)?.id || '';
        navigate(`/bot/options/${firstOption}`);
    };

    return (
        <LocaleContext.Provider value={locale}>
            {interactiveList && (
                <XPageContainer
                    sideBar={{
                        content: <Sidebar interactiveList={interactiveList} disabled={addMode}></Sidebar>,
                        title: t('bot.options.main_menu')
                    }}
                    topBar={{
                        title: addMode ? t('bot.options.add_option') : t('bot.options.edit_option'),
                        backLink: '/bot'
                    }}
                >
                    <XComponentContainer>
                        <Box sx={{ display: 'flex', justifyContent: 'start', my: 2 }}>
                            <Button
                                sx={{ mx: 2 }}
                                variant="outlined"
                                size="small"
                                onClick={() => onAddClick()}
                                startIcon={<AddTwoToneIcon fontSize="small" />}
                            >
                                {t('bot.options.add_option')}
                            </Button>
                            <XLanguageSwitch disabled={addMode} onLocaleChange={onLanguageChange} value={locale} />
                        </Box>
                        <Box sx={{ height: '100%' }}>
                            <Outlet context={bot} />
                        </Box>
                    </XComponentContainer>
                </XPageContainer>
            )}
        </LocaleContext.Provider>
    );
}

export default Options;
