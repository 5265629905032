import { ComponentType, VariableType } from './types.campaigns';

export const DefaultCountryCode = '966';

export interface Catalog {
    id: string;
    name: string;
}

export interface Set {
    id: string;
    name: string;
    productsCount: number;
}

export interface Product {
    name: string;
    url: string;
    retailerId: string;
    imageUrl: string;
    price: string;
    inventory: number;
}

export interface Template {
    id: string;
    name: string;
    language: string;
    status: TemplateStatus;
    category: TemplateCategory;
    components: TemplateComponent;
}

export interface SampleTemplate {
    id?: string;
    template: Template;
}

export interface TemplateComponent {
    header: TemplateHeader;
    body: TemplateBody;
    footer: string;
    buttons: TemplateButton[];
}

export interface TemplateButton {
    type: TemplateButtonType;
    text: string;
    countryCode?: string;
    phoneNumber: string;
    url: string;
    example: string;
}

export interface TemplateHeader {
    mediaType?: 'Image' | 'Video' | 'Document' | 'Audio' | 'Sticker';
    text: string;
    example: string;
    fileUrl: string;
    format?: TemplateComponentFormat;
}

export interface TemplateBody {
    text: string;
    examples: string[];
}

export type TemplateComponentType = 'HEADER' | 'BODY' | 'FOOTER' | 'BUTTONS';
export type TemplateComponentFormat = 'TEXT' | 'IMAGE' | 'VIDEO' | 'DOCUMENT' | 'LOCATION' | null;
export type TemplateButtonType = 'URL' | 'QUICK_REPLY' | 'PHONE_NUMBER';
export type TemplateCategory = 'UTILITY' | 'MARKETING' | 'AUTHENTICATION';
export type TemplateStatus = 'APPROVED' | 'REJECTED' | 'PENDING' | undefined;

export const LanguageMappingsEn = {
    af: 'Afrikaans',
    sq: 'Albanian',
    ar: 'Arabic',
    az: 'Azerbaijani',
    bn: 'Bengali',
    bg: 'Bulgarian',
    ca: 'Catalan',
    zh_CN: 'Chinese (Simplified)',
    zh_HK: 'Chinese (Hong Kong)',
    zh_TW: 'Chinese (Traditional)',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    en: 'English',
    en_GB: 'English (UK)',
    en_US: 'English (US)',
    et: 'Estonian',
    fil: 'Filipino',
    fi: 'Finnish',
    fr: 'French',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek',
    gu: 'Gujarati',
    ha: 'Hausa',
    he: 'Hebrew',
    hi: 'Hindi',
    hu: 'Hungarian',
    id: 'Indonesian',
    ga: 'Irish',
    it: 'Italian',
    ja: 'Japanese',
    kn: 'Kannada',
    kk: 'Kazakh',
    rw_RW: 'Kinyarwanda',
    ko: 'Korean',
    ky_KG: 'Kyrgyz',
    lo: 'Lao',
    lv: 'Latvian',
    lt: 'Lithuanian',
    mk: 'Macedonian',
    ms: 'Malay',
    ml: 'Malayalam',
    mr: 'Marathi',
    nb: 'Norwegian (Bokmål)',
    fa: 'Persian',
    pl: 'Polish',
    pt_BR: 'Portuguese (Brazil)',
    pt_PT: 'Portuguese (Portugal)',
    pa: 'Punjabi',
    ro: 'Romanian',
    ru: 'Russian',
    sr: 'Serbian',
    sk: 'Slovak',
    sl: 'Slovenian',
    es: 'Spanish',
    es_AR: 'Spanish (Argentina)',
    es_ES: 'Spanish (Spain)',
    es_MX: 'Spanish (Mexico)',
    sw: 'Swahili',
    sv: 'Swedish',
    ta: 'Tamil',
    te: 'Telugu',
    th: 'Thai',
    tr: 'Turkish',
    uk: 'Ukrainian',
    ur: 'Urdu',
    uz: 'Uzbek',
    vi: 'Vietnamese',
    zu: 'Zulu'
};

export function getTemplateActions(template: Template) {
    if (!template?.components?.buttons) return [];
    return template.components.buttons.filter((x) => x.type == 'QUICK_REPLY');
}

export function getTemplateVariables(template: Template) {
    const getPlaceholders = (str: string): string[] => {
        const regex = /{{(\d+)}}/g;
        const placeholders: string[] = [];
        let match;
        while ((match = regex.exec(str)) !== null) {
            placeholders.push(match[1]);
        }
        return placeholders;
    };

    if (template) {
        let bodyPlaceHolders = getPlaceholders(template.components.body?.text);
        let headerPlaceHolders = getPlaceholders(template.components.header?.text);
        var variables = bodyPlaceHolders.map((x) => ({
            id: '',
            index: parseInt(x),
            fallbackValue: '',
            variableType: '' as VariableType,
            componentType: 'Body' as ComponentType
        }));
        var headerVariables = headerPlaceHolders.map((x) => ({
            id: '',
            index: parseInt(x),
            fallbackValue: '',
            variableType: '' as VariableType,
            componentType: 'Header' as ComponentType
        }));
        return [...headerVariables, ...variables];
    }
}

export const getMediaType = (format: TemplateComponentFormat) => {
    switch (format) {
        case 'IMAGE':
            return 'Image';
        case 'VIDEO':
            return 'Video';
        case 'DOCUMENT':
            return 'Document';
        default:
            return 'Image';
    }
};
