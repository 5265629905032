import { useTranslation } from 'react-i18next';
import { MenuDirection, UserLocale } from 'src/types';
import { modifyLocaleSettings } from 'src/screens/account/slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { IconButton } from '@mui/material';
import SwapHorizontalCircleOutlinedIcon from '@mui/icons-material/SwapHorizontalCircleOutlined';

const LocaleToggle = () => {
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const direction = useAppSelector((x) => x.account.direction);

    const toggleLocale = () => {
        const locale: UserLocale = direction == 'ltr' ? 'ar' : 'en';
        const newDirection: MenuDirection = direction == 'ltr' ? 'rtl' : 'ltr';
        i18n.changeLanguage(locale.toString())
            .then((x) => {})
            .catch((x) => {});
        dispatch(modifyLocaleSettings({ locale, direction: newDirection }));
    };

    const devMode =
        process.env.REACT_APP_API_URL.indexOf('localhost') > 0 || process.env.REACT_APP_API_URL.indexOf('sandbox') > 0;

    return (
        <>
            {devMode && (
                <>
                    <IconButton sx={{ pl: 2, pr: 2 }} onClick={() => toggleLocale()} color="primary">
                        <SwapHorizontalCircleOutlinedIcon />
                    </IconButton>
                </>
            )}
        </>
    );
};

export default LocaleToggle;
