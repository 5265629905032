import { Alert, Box, Button, CircularProgress, Collapse, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormHelperText } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { sendTestCampaign } from '../slice';
import { useStyles } from 'src/components';

const TestCampaign = ({ campaignId, onClose }: { campaignId: string; onClose: () => void }) => {
    const { t } = useTranslation();
    const [phoneNumber, setPhoneNumber] = useState('');
    const [error, setError] = useState(false);

    const dispatch = useAppDispatch();
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
        setError(false);
    };

    const onClick = () => {
        const isValid = /^[1-9][0-9]{7,14}$/.test(phoneNumber);
        if (!isValid) {
            setError(true);
            return;
        }
        setError(false);
        dispatch(sendTestCampaign({ campaignId, phoneNumber }));
    };
    const { state } = useAppSelector((x) => x.campaigns.current.campaignTest);
    const { borderRoundedSuccess } = useStyles();

    return (
        <Box sx={{ ...borderRoundedSuccess, p: 2 }}>
            <Collapse in={state == 'successful'}>
                <Alert> {t('campaigns.instance.test.test_success')}</Alert>
                <Button onClick={() => onClose()} sx={{ mt: 2, mr: 1 }} variant="outlined" size="small">
                    {t('components.forms.formButtons.close')}
                </Button>
            </Collapse>
            {state != 'successful' && (
                <>
                    <Typography variant="h5" sx={{ py: 1, color: 'rgb(230, 26, 128)' }}>
                        {t('campaigns.instance.test.title')}
                    </Typography>
                    <Typography variant="body1">{t('campaigns.instance.test.heading')}</Typography>
                    <Typography sx={{ mb: 1, fontSize: 'smaller' }} variant="body1">
                        {t('campaigns.instance.test.sub_heading')}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                        <TextField
                            placeholder={'9661234567890'}
                            size="small"
                            type="text"
                            value={phoneNumber}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event)}
                        />
                        {state == 'inProgress' ? (
                            <CircularProgress sx={{ p: 1 }} />
                        ) : (
                            <Box sx={{ mt: 1 }}>
                                <Button onClick={() => onClick()} sx={{ mr: 1 }} variant="outlined" size="small">
                                    {t('campaigns.instance.test.start_test')}
                                </Button>{' '}
                                <Button onClick={() => onClose()} sx={{ mr: 1 }} variant="outlined" size="small">
                                    {t('components.forms.formButtons.close')}
                                </Button>
                            </Box>
                        )}
                    </Box>
                    <Box>
                        {error && (
                            <FormHelperText error>{t('campaigns.instance.test.test_number_error')}</FormHelperText>
                        )}
                    </Box>
                </>
            )}
        </Box>
    );
};

export default TestCampaign;
