const SubmitContact = () => {
    return (
        <>
            <div>SubmitContact form</div>
            <div>will appear here</div>
        </>
    );
};

export default SubmitContact;
