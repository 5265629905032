import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Loader from '../Loader';
import { lazy } from 'react';

const List = Loader(lazy(() => import('src/screens/users/list')));

const Submit = Loader(lazy(() => import('src/screens/users/submit')));

const usersRoutes: RouteObject = {
    path: 'users',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <List />
        },
        {
            path: 'submit',
            element: <Submit />
        },
        ,
        {
            path: ':id',
            element: <Submit />
        }
    ]
};

export default usersRoutes;
