import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getQuickreplies } from 'src/screens/conversations/slice';

const useAfterDelete = () => {
    const state = useAppSelector((x) => x.conversations.quickReplies.submitState);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (state === 'successful') {
            dispatch(getQuickreplies());
        }
    }, [state, dispatch, getQuickreplies]);
};

export default useAfterDelete;
