import CommonForm from './commonForm';

const TransferToTeam = () => {
    return (
        <CommonForm
            interfaceSubType="Text_TransferingToTeam"
            api="TransferToTeam"
            descriptionKey="bot.settings.transfer_to_eam.description"
            bodyEnglishKey="bot.settings.transfer_to_eam.bodyEnglish"
            bodyArabicKey="bot.settings.transfer_to_eam.bodyArabic"
        ></CommonForm>
    );
};

export default TransferToTeam;
