import { AddBusinessInputs } from 'src/screens/businesses/submit/schema';
import { ApiListResponse, ApiResponse, Business } from 'src/types';
import apiClient from './apiClient';
import { WhatsaapInfoInputs } from 'src/screens/businesses/whatsapInfo/schema';

export class BusinessService {
    async getBusinesses(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<Business>>('/businesses', {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async addBusiness(inputs: AddBusinessInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>('/businesses', inputs);
        return data;
    }

    async updateBusiness(id: string, inputs: AddBusinessInputs) {
        const { data } = await apiClient.put<ApiResponse<string>>(`/businesses/${id}`, inputs);
        return data;
    }

    async updateWhatsAppInfo(inputs: WhatsaapInfoInputs) {
        const { data } = await apiClient.put<ApiResponse<string>>(
            `/businesses/${inputs.businessId}/whatsappinfo`,
            inputs
        );
        return data;
    }

    async registerBusiness(inputs: AddBusinessInputs) {
        const { data } = await apiClient.post<ApiResponse<string>>(`/businesses/Registrations`, inputs);
        return data;
    }

    async resendEmail(id: string) {
        const { data } = await apiClient.post<ApiResponse<string>>(`/businesses/ResendEmail`, {
            id
        });
        return data;
    }
}

export default new BusinessService();
