import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { updateProfile } from '../slice';
import { UpdateProfileInputs, schema } from './schema';

const useSubmitForm = () => {
    const user = useAppSelector((x) => x.account.user);
    const dispatch = useAppDispatch();
    const methods = useForm<UpdateProfileInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            locale: user.locale.toString()
        }
    });

    const onSubmit = (inputs: UpdateProfileInputs) => {
        dispatch(updateProfile(inputs));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
