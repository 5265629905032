import { Campaign } from 'src/types';
import { Chip, CircularProgress, Tooltip, Typography } from '@mui/material';
import { InstanceMessages } from '../../instance/InstanceStatus';
import { useNavigate } from 'react-router';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { DATE_TIME_DISPLAY_FORMAT } from 'src/utils/constants';
import { format } from 'date-fns';

const Status = ({ campaign }: { campaign: Campaign }) => {
    const navigate = useNavigate();

    const processing =
        campaign.recentInstance?.status == 'InProgress' ||
        campaign.recentInstance?.status == 'Published' ||
        campaign.recentInstance?.status == 'Uploaded';

    return (
        <>
            {processing && (
                <Tooltip title={InstanceMessages[campaign.recentInstance.status].message}>
                    <Chip
                        sx={{ pl: 2, pr: 1 }}
                        color="primary"
                        onClick={() => {
                            navigate(`/campaigns/${campaign.id}/instance`);
                        }}
                        onDelete={() => {}}
                        deleteIcon={<CircularProgress size={20} sx={{ color: '#5569ff !important' }} />}
                        size="medium"
                        label={campaign.recentInstance?.status}
                        variant="outlined"
                    />
                </Tooltip>
            )}

            {campaign?.recentInstance?.status == 'UploadProcessed' && (
                <Tooltip title={InstanceMessages[campaign.recentInstance.status]?.message}>
                    <Chip
                        sx={{ pl: 2, pr: 1 }}
                        color="info"
                        onClick={() => {
                            navigate(`/campaigns/${campaign.id}/instance`);
                        }}
                        onDelete={() => {}}
                        deleteIcon={<CloudDoneOutlinedIcon sx={{ color: '#57CA22 !important' }} />}
                        size="medium"
                        label={campaign.recentInstance?.status}
                        variant="outlined"
                    />
                </Tooltip>
            )}

            {campaign?.recentInstance?.status == 'Scheduled' && (
                <>
                    <Chip
                        sx={{ pl: 2, pr: 1 }}
                        color="success"
                        onDelete={() => {}}
                        deleteIcon={<AccessAlarmIcon sx={{ color: '#57CA22 !important' }} />}
                        size="medium"
                        label={campaign.recentInstance?.status}
                        variant="outlined"
                    />
                    <Typography variant="h6" color="primary">
                        {format(new Date(campaign.recentInstance?.scheduledFor), DATE_TIME_DISPLAY_FORMAT)}
                    </Typography>
                </>
            )}

            {campaign?.recentInstance?.status == 'Completed' && (
                <Chip
                    sx={{ pl: 2, pr: 1 }}
                    color="success"
                    onDelete={() => {}}
                    deleteIcon={<CheckCircleOutlineOutlinedIcon sx={{ color: '#57CA22 !important' }} />}
                    size="medium"
                    label={campaign.recentInstance?.status}
                    variant="outlined"
                />
            )}
        </>
    );
};

export default Status;
