import yupx from 'src/locales/yupLocale';
import { TemplateVariable } from 'src/types';

export interface SubmitCampaignInputs {
    id: string;
    name: string;
    templateId: string;
    languageCode: string;
    templateVariables: TemplateVariable[];
}

export const schema = yupx.object().shape({
    name: yupx.string().required().trim().label('campaigns.yup.submit.name'),
    templateId: yupx.string().required().trim().label('campaigns.yup.submit.template_name'),
    languageCode: yupx.string().required().trim().label('campaigns.yup.submit.language_code'),
    templateVariables: yupx.array().of(
        yupx.object().shape({
            index: yupx.string().required().label('campaigns.yup.submit.template_variables.index'),
            variableType: yupx.string().required().label('campaigns.yup.submit.template_variables.variable_type'),
            componentType: yupx.string().required().label('campaigns.yup.submit.template_variables.component_type'),
            fallbackValue: yupx.string().when('variableType', ([variableType], schema) => {
                if (variableType == 'Custom') {
                    return schema.required().label('campaigns.yup.submit.template_variables.fallback_value');
                }
                return schema.notRequired();
            })
        })
    )
});
