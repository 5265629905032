import { RouteObject } from 'react-router';
import { User } from 'src/types';
import { accountRoutes } from './account';
import botRoutes from './bot';
import businessRoutes from './businesses';
import capmaignRoutes from './campaigns';
import dashboardRoutes from './dashboard';
import appsRoutes from './apps';
import teamRoutes from './teams';
import usersRoutes from './users';
import groupRoutes from './groups';
import productRoutes from './catalog';
import conversationRoutes from './conversations';
import templates from './templates';
import emailRoutes from './emails';
import sms from './sms';
import fallback from './fallback';

const getAuthorizedRoutes = (user: User) => {
    var routes: RouteObject[] = [
        dashboardRoutes,
        ...accountRoutes,
        teamRoutes,
        appsRoutes,
        botRoutes,
        usersRoutes,
        capmaignRoutes,
        groupRoutes,
        productRoutes,
        conversationRoutes,
        templates,
        businessRoutes,
        emailRoutes,
        sms,
        fallback // always keep this at last
    ];
    return routes;
};

export default getAuthorizedRoutes;
