import { Box, Divider, Grid } from '@mui/material';
import { Template, TemplateHeader, getMediaType } from 'src/types';
import { PreviewWrapper, ButtonPreview, TextPreview, CallToActionButtonPreview, ButtonFragment } from './Fragments';
import CallIcon from '@mui/icons-material/Call';
import LaunchIcon from '@mui/icons-material/Launch';
import Thumbnail from './Thumbnail';

const XTemplatePreview = ({ template }: { template: Template }) => {
    let header: TemplateHeader = template.components.header;
    if (header) {
        const updatedHeader = { ...header };
        if (updatedHeader?.format) updatedHeader.mediaType = getMediaType(updatedHeader?.format);
        header = updatedHeader;
    }
    const body = template.components.body;
    const footer = template.components.footer;
    const buttons = template.components.buttons;
    const quickReplies = buttons ? buttons?.filter((x) => x.type == 'QUICK_REPLY') : [];
    const callToactions = buttons ? buttons?.filter((x) => x.type == 'URL' || x.type == 'PHONE_NUMBER') : [];
    return (
        <>
            <Box>
                <PreviewWrapper>
                    <>
                        {header?.text && (
                            <TextPreview variant="h5" color={'#000'}>
                                {header.text}
                            </TextPreview>
                        )}
                        {header?.fileUrl && <Thumbnail content={header?.fileUrl} type={header.mediaType}></Thumbnail>}
                        {body.text && (
                            <TextPreview variant="body2" color={'#000'}>
                                {body.text}
                            </TextPreview>
                        )}

                        {footer && <TextPreview sx={{ fontSize: 10 }}>{footer}</TextPreview>}

                        {callToactions?.length > 0 && (
                            <>
                                <Divider sx={{ mt: 1, mb: 1 }} />
                                {callToactions.map((x) => (
                                    <CallToActionButtonPreview
                                        key={x.text}
                                        text={x.text}
                                        icon={
                                            x.type == 'URL' ? (
                                                <LaunchIcon
                                                    sx={{ p: '2px', mr: 1, ml: 1 }}
                                                    color="primary"
                                                    fontSize="small"
                                                />
                                            ) : (
                                                <CallIcon
                                                    sx={{ p: '2px', mr: 1, ml: 1 }}
                                                    color="primary"
                                                    fontSize="small"
                                                />
                                            )
                                        }
                                    />
                                ))}
                            </>
                        )}
                    </>
                </PreviewWrapper>

                {quickReplies?.length > 0 && (
                    <Box sx={{ display: 'flex', flexGrow: 1 }}>
                        {quickReplies.map((x) => (
                            <Grid
                                key={x.text}
                                item
                                xs={12}
                                sx={{
                                    background: '#fff',
                                    borderRadius: '3px',
                                    margin: '5px',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    transition: 'box-shadow 0.3s ease',
                                    cursor: 'pointer'
                                }}
                            >
                                <ButtonFragment>{x.text}</ButtonFragment>
                            </Grid>
                        ))}
                    </Box>
                )}
            </Box>
        </>
    );
};

export default XTemplatePreview;
