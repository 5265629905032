import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Action } from 'src/types';
import ActionButton from './ActionButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAppSelector } from 'src/store/hooks';
import { useFormContext } from 'react-hook-form';
import { EditorInputs } from '../../schema';
import { nameOf } from 'src/utils/utils.shared';
import { LocaleContext } from '../../..';
import { useTranslation } from 'react-i18next';

export default function InvokeListOptionAction({ title }: { title: string }) {
    const locale = React.useContext(LocaleContext);
    const { bot } = useAppSelector((x) => x.businessBot);
    const options = bot.botInterfaces
        .find((x) => x.interfaceType == 'InteractiveList' && x.locale == locale)
        ?.interactiveList.options.filter((x) => x.title != title);
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { watch, setValue } = useFormContext<EditorInputs>();
    const actions = watch(nameOf<EditorInputs>('actions')) as Action[];

    const disabled = actions.length == 3 && actions.filter((x) => x.actionType == 'InvokeInterface').length == 0;

    const onCheckChange = (optionId: string, title: string, added: boolean) => {
        let targetInterfaceId = options.find((x) => x.id == optionId).targetInterfaceId;
        let updatedActions = actions.filter((x) => x.targetInterfaceId != targetInterfaceId);
        if (added) {
            updatedActions.push({
                id: '',
                title: title,
                actionType: 'InvokeInterface',
                targetInterfaceId: targetInterfaceId
            });
        }
        setValue(nameOf<EditorInputs>('actions'), updatedActions, {
            shouldDirty: true
        });
    };

    var used = actions.filter((x) => x.actionType == 'InvokeInterface').length > 0;

    return (
        <Box sx={{ display: 'flex', justifyContent: 'end' }}>
            <ActionButton
                used={used}
                startIcon={<KeyboardArrowDownIcon />}
                disabled={disabled}
                onActionClick={handleClick}
                text={t('bot.options.editor.actions.invoke_action')}
            ></ActionButton>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {options.map((x) => {
                    const checked = actions.find((y) => y.targetInterfaceId == x.targetInterfaceId) && true;
                    const disabled = actions.length == 3 && !checked;
                    return (
                        <Box key={x.title}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        disabled={disabled}
                                        onChange={(_, checked) => {
                                            onCheckChange(x.id, x.title, checked);
                                        }}
                                    />
                                }
                                label={x.title}
                            />
                        </Box>
                    );
                })}
            </Menu>
        </Box>
    );
}
