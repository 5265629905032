import { Table, TableBody, TableCell, TableRow, TableHead, Typography, Box, Link } from '@mui/material';
import { CampaignStep } from 'src/types';
import { useTranslation } from 'react-i18next';
import Head from '../submit/rightSection/Head';
import { useAppDispatch } from 'src/store/hooks';
import { downloadCsv } from '../slice';
import { VariableTypeMap } from '../submit/rightSection/Variables';

const Variables = ({ nextStep }: { nextStep: CampaignStep }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const onDownloadClick = () => {
        dispatch(
            downloadCsv({
                campaignName: nextStep?.campaign?.name,
                campaignId: nextStep.campaign?.id,
                groupId: nextStep.groupId
            })
        );
    };

    return (
        <>
            <Typography variant="h4" sx={{ mb: 2, color: 'rgb(230, 26, 128)' }}>
                {t('campaigns.instance.variables.title')}
            </Typography>
            {nextStep.showVariables && (
                <>
                    <Typography sx={{ fontWeight: 'bold' }} variant="body2">
                        {t('campaigns.instance.variables.instruction_title')}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: 'smaller' }}>
                        {t('campaigns.instance.variables.instruction_body')}
                    </Typography>
                    <Table size="small" sx={{ mt: 2 }}>
                        <TableHead>
                            <Head />
                        </TableHead>
                        <TableBody>
                            {nextStep?.campaign?.templateVariables?.map((x) => (
                                <TableRow hover key={x.index}>
                                    <TableCell width={'15%'} align="justify" padding="normal">
                                        <Typography variant="h5" sx={{ fontSize: 'smaller' }}>
                                            <span style={{ color: 'rgb(230, 26, 128)' }}>{`{\{${x.index}\}}`}</span>{' '}
                                            {x.componentType == 'Body'
                                                ? t('campaigns.submit.placeholders.list.body')
                                                : t('campaigns.submit.placeholders.list.header')}
                                        </Typography>
                                    </TableCell>
                                    <TableCell width={'35%'} padding="normal">
                                        <Typography variant="h5">{t(VariableTypeMap[x.variableType])}</Typography>
                                    </TableCell>
                                    <TableCell width={'50%'} padding="normal">
                                        <Typography variant="h5">{t(x.fallbackValue)}</Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {nextStep.groupId && (
                        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'end' }}>
                            <Link
                                // disabled={nextStep.nextStep == 'Start'}
                                component="button"
                                variant="body2"
                                sx={{ pt: 2 }}
                                onClick={() => onDownloadClick()}
                            >
                                {t('campaigns.instance.variables.download')}
                            </Link>
                        </Box>
                    )}
                </>
            )}
            {!nextStep.showVariables && (
                <Typography variant="body1" sx={{ mt: 1, fontSize: 'smaller' }}>
                    {t('campaigns.instance.variables.no_placeholders')}
                </Typography>
            )}
        </>
    );
};

export default Variables;
