import React, { createContext, useState, ReactNode } from 'react';
import { XAlertModal } from 'src/components';

interface AlertContextProps {
    openAlert: (title: string, content: React.ReactNode) => void;
    closeAlert: () => void;
}

interface AlertProviderProps {
    children: ReactNode;
}

// Create the context
const AlertContext = createContext<AlertContextProps | undefined>(undefined);

// Create the AlertProvider component
const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [alertTitle, setAlertTitle] = useState('');
    const [alertContent, setAlertContent] = useState<React.ReactNode>(<></>);

    const openAlert = (title: string, content: React.ReactNode) => {
        setAlertTitle(title);
        setAlertContent(content);
        setIsOpen(true);
    };

    const closeAlert = () => {
        setIsOpen(false);
    };

    const contextValue: AlertContextProps = {
        openAlert,
        closeAlert
    };

    return (
        <AlertContext.Provider value={contextValue}>
            {children}
            {isOpen && (
                <XAlertModal title={alertTitle} onClose={closeAlert}>
                    {alertContent}
                </XAlertModal>
            )}
        </AlertContext.Provider>
    );
};

export { AlertProvider, AlertContext };
