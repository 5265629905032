import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import './custom.css';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { DrawerProvider } from 'src/contexts/DrawerContext';
import * as serviceWorker from 'src/serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Provider } from 'react-redux';
import { store } from './store';

ReactDOM.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <HelmetProvider>
                <DrawerProvider>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </DrawerProvider>
            </HelmetProvider>
        </I18nextProvider>
    </Provider>,
    document.getElementById('root')
);

serviceWorker.unregister();
