import { TextField, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { SelectLanguageInputs } from './schema';

const BodyEnglish = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<SelectLanguageInputs>();

    return (
        <>
            <TextField
                fullWidth
                error={errors?.message && true}
                {...register(nameOf<SelectLanguageInputs>('message'))}
                id={nameOf<SelectLanguageInputs>('message')}
                placeholder={t('bot.settings.select_language.body')}
                size="small"
                type="text"
                helperText={errors?.message?.message}
                sx={{
                    p: 1
                }}
                multiline
                rows={10}
            ></TextField>
        </>
    );
};

export default BodyEnglish;
