import { MenuItem, FormControl, Select, FormHelperText, FormLabel } from '@mui/material';
import { QuickReplyType, SubmitTemplateInputs } from '../../schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

export default function QuickReplyTypes({ index, inputColor }: { index: number; inputColor: string }) {
    const { t } = useTranslation();
    const {
        register,
        watch,
        setValue,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const quickReplies = watch('buttons.quickReplies');
    const language = watch('languageCode');

    const onQuickReplyButtonTypeChange = (type: QuickReplyType) => {
        const button = language == 'en' ? 'Stop promotions' : '';
        const footer = language == 'en' ? 'Not interested? Tap Stop promotions' : '';
        const quickReply = {
            buttonText: type == 'MARKETING_OPT_OUT' ? button : '',
            footer: type == 'MARKETING_OPT_OUT' ? footer : '',
            type: type
        };

        setValue(`buttons.quickReplies.${index}`, quickReply, {
            shouldDirty: true
        });
    };

    const items: { type: QuickReplyType; label: string; disabled: boolean }[] = [
        {
            label: 'templates.submit.buttons.quick_reply.custom',
            type: 'CUSTOM',
            disabled: false
        },
        {
            label: 'templates.submit.buttons.quick_reply.marketing_opt_out',
            type: 'MARKETING_OPT_OUT',
            disabled: quickReplies.filter((x) => x.type == 'MARKETING_OPT_OUT').length > 0
        }
    ];

    const defaultValue =
        defaultValues.buttons.quickReplies.length > index ? defaultValues.buttons.quickReplies[index]?.type : 'CUSTOM';

    const error = errors?.buttons?.quickReplies?.length ? errors?.buttons?.quickReplies[index]?.type : undefined;

    return (
        <FormControl fullWidth>
            <FormLabel>{t('templates.submit.buttons.action_type')}</FormLabel>
            <Select
                sx={{ bgcolor: inputColor }}
                defaultValue={defaultValue}
                error={error && true}
                {...register(`buttons.quickReplies.${index}.type`)}
                size="small"
                onChange={(e) => onQuickReplyButtonTypeChange(e.target.value as QuickReplyType)}
            >
                {items.map((x, i) => (
                    <MenuItem key={i} value={x.type} disabled={x.disabled}>
                        {t(x.label)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
        </FormControl>
    );
}
