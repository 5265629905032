import { useEffect } from 'react';
import { useAppSelector } from 'src/store/hooks';

const useTeamChanged = (onChane: () => void) => {
    const { state } = useAppSelector((x) => x.teams.submit);
    useEffect(() => {
        if (state === 'successful') {
            onChane();
        }
    }, [state, onChane]);
};

export default useTeamChanged;
