import { Box, FormHelperText, RadioGroup } from '@mui/material';
import MediaTypeItem from './MediaTypeItem';
import { MediaType } from 'src/types';
import { Controller, useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import MediaTypeSample from './MediaTypeSample';
import { useStyles } from 'src/components';

const MediaTypes = ({ inputColor }: { inputColor: string }) => {
    const {
        control,
        watch,
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();
    const header = watch('header');

    const items = [
        {
            type: 'Image',
            thumbnail: '../static/images/thumbnail-image.png'
        },
        {
            type: 'Video',
            thumbnail: '../static/images/thumbnail-video.png'
        },
        {
            type: 'Document',
            thumbnail: '../static/images/thumbnail-document.png'
        }
    ] as {
        type: MediaType;
        thumbnail: string;
    }[];

    return (
        <>
            {header.type == 'MEDIA' && (
                <>
                    <Box sx={{ display: 'flex' }}>
                        <Controller
                            control={control}
                            {...register('header.mediaType')}
                            render={({ field }) => (
                                <RadioGroup row {...field}>
                                    {items.map((x) => (
                                        <MediaTypeItem
                                            inputColor={inputColor}
                                            thumbnail={x.thumbnail}
                                            key={x.type}
                                            mediaType={x.type}
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    </Box>
                    <FormHelperText style={{ color: 'red' }}>{errors?.header?.mediaType?.message}</FormHelperText>
                    {header.mediaType && <MediaTypeSample type={header.mediaType} />}
                </>
            )}
        </>
    );
};

export default MediaTypes;
