import { Box, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { EmailCampaignInputs } from './schema';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

const Subject = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const {
        register,
        formState: { errors }
    } = useFormContext<EmailCampaignInputs>();

    return (
        <Box sx={{ pt: 2 }}>
            <TextField
                fullWidth
                disabled={id && true}
                error={errors?.subject && true}
                {...register(nameOf<EmailCampaignInputs>('subject'))}
                id={nameOf<EmailCampaignInputs>('subject')}
                placeholder={t('emailCampaigns.compose.subject')}
                label={t('emailCampaigns.compose.subject')}
                type="text"
                helperText={errors?.subject?.message}
            />
        </Box>
    );
};

export default Subject;
