import { MenuItem, FormControl, Select, FormHelperText, FormLabel } from '@mui/material';
import { CallToActionType, SubmitTemplateInputs } from './../../schema';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { DefaultCountryCode } from 'src/types';

export default function CallToActionTypes({ index, inputColor }: { index: number; inputColor: string }) {
    const { t } = useTranslation();
    const {
        register,
        watch,
        setValue,
        formState: { errors }
    } = useFormContext<SubmitTemplateInputs>();

    const items: {
        label: string;
        value: CallToActionType;
    }[] = [
        {
            label: 'templates.submit.buttons.call_to_action.call_phone_number',
            value: 'CALL_PHONE_NUMBER'
        },
        {
            label: 'templates.submit.buttons.call_to_action.visit_website',
            value: 'VISIT_WEBSITE'
        }
    ];
    const callToactions = watch('buttons.callToactions');

    const onCallToActionButtonTypeChange = (type: CallToActionType) => {
        let callToaction = { ...callToactions[index] };
        callToaction.type = type;

        if (type == 'CALL_PHONE_NUMBER') {
            callToaction.countryCode = DefaultCountryCode;
            callToaction.phoneNumber = '';
        } else {
            callToaction.websiteSample = '';
            callToaction.urlType = 'STATIC';
            callToaction.website = '';
        }

        setValue(`buttons.callToactions.${index}`, callToaction, {
            shouldDirty: true
        });
    };
    const error = errors?.buttons?.callToactions?.length > 0 ? errors?.buttons?.callToactions[index]?.type : undefined;

    return (
        <FormControl fullWidth>
            <FormLabel>{t('templates.submit.buttons.action_type')}</FormLabel>
            <Select
                sx={{ bgcolor: inputColor }}
                defaultValue={callToactions[index].type}
                error={error && true}
                {...register(`buttons.callToactions.${index}.type`)}
                id={`buttons.callToactions.${index}.type`}
                size="small"
                onChange={(e) => onCallToActionButtonTypeChange(e.target.value as CallToActionType)}
            >
                {items.map((x, i) => (
                    <MenuItem key={i} value={x.value} disabled={callToactions.find((y) => y.type == x.value) && true}>
                        {t(x.label)}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>
        </FormControl>
    );
}
