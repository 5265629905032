import * as yup from 'yup';

export interface GroupInputs {
    name: string;
    contacts: {
        phoneNumber: string;
        name: string;
    }[];
    acceptTermsAndConditions: boolean;
}

export const schema = yup.object().shape({
    name: yup.string().required().trim().label('groups.yup.submit_group.name'),
    contacts: yup.array().required().label('groups.yup.submit_group.csv_file'),
    acceptTermsAndConditions: yup.bool().required().oneOf([true])
});
