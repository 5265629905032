import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getContacts } from '../../slice';

const useAfterDelete = (id: string) => {
    const { state } = useAppSelector((x) => x.groups.list);
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (state === 'successful') {
            dispatch(getContacts(id));
        }
    }, [state, dispatch, getContacts]);
};

export default useAfterDelete;
