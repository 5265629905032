import { FormLabel, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../../schema';
import { useTranslation } from 'react-i18next';

const CountryCode = ({ index, inputColor }: { index: number; inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<SubmitTemplateInputs>();

    return (
        <>
            <FormLabel>{t('templates.submit.buttons.call_to_action.country_code')}</FormLabel>
            <TextField
                fullWidth
                error={errors?.buttons?.callToactions[index]?.countryCode?.message && true}
                {...register(`buttons.callToactions.${index}.countryCode`)}
                id={`buttons.callToactions.${index}.countryCode`}
                placeholder={t('templates.submit.buttons.call_to_action.country_code')}
                type="text"
                disabled
                size="small"
                sx={{
                    '&.MuiFormControl-root': {
                        '.MuiOutlinedInput-root': {
                            background: inputColor
                        }
                    }
                }}
                helperText={errors?.buttons?.callToactions[index]?.countryCode?.message}
            />
        </>
    );
};

export default CountryCode;
