import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Instructions = () => {
    const { t } = useTranslation();
    return (
        <Alert variant="standard" severity="warning">
            <ul>
                <li>{t('groups.submit.csv_instructions.instruction_1')}</li>
                <li>{t('groups.submit.csv_instructions.instruction_2')}</li>
                <li>{t('groups.submit.csv_instructions.instruction_3')}</li>
                <li>{t('groups.submit.csv_instructions.instruction_4')}</li>
            </ul>
        </Alert>
    );
};

export default Instructions;
