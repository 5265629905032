import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Bot from 'src/screens/bot';

const fallback: RouteObject = {
    path: '/',
    element: <SidebarLayout />,
    children: [
        {
            path: '*',
            element: <Bot></Bot>
        }
    ]
};

export default fallback;
