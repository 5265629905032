import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { nameOf } from 'src/utils/utils.shared';
import { QuickReplyInputs } from './schema';
import { useTranslation } from 'react-i18next';

const Title = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<QuickReplyInputs>();

    return (
        <TextField
            fullWidth
            error={errors?.title && true}
            {...register(nameOf<QuickReplyInputs>('title'))}
            id={nameOf<QuickReplyInputs>('title')}
            placeholder={t('conversations.quickReplies.list.head.title')}
            label={t('conversations.quickReplies.list.head.title')}
            size="small"
            type="text"
            helperText={errors?.title?.message}
        />
    );
};

export default Title;
