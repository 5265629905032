import { XIconButton } from 'src/components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Box } from '@mui/material';

const HelperIcon = ({ imageName, margin }: { imageName: string; margin?: string }) => {
    const onHelpClick = () => {
        window.open(`/static/images/KeyHelpers/${imageName}.png`, '_blank');
    };

    return (
        <Box sx={{ m: margin ? margin : 1 }}>
            <XIconButton tooltip="" onClick={onHelpClick}>
                <HelpOutlineIcon />
            </XIconButton>
        </Box>
    );
};

export default HelperIcon;
