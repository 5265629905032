import { Box } from '@mui/material';
import HeaderTypes from './HeaderTypes';
import Text from './Text';
import SectionHeading from '../SectionHeading';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../schema';
import TextSample from './TextSample';
import MediaTypes from './MediaTypes';

const Header = ({ inputColor }: { inputColor: string }) => {
    const { watch } = useFormContext<SubmitTemplateInputs>();
    const header = watch('header');

    const showSample = header.text?.indexOf('{{1}}') != -1;

    return (
        <Box>
            <SectionHeading
                title="templates.submit.header.title"
                required={false}
                description="templates.submit.header.description"
            />
            <Box>
                <Box sx={{ display: 'flex' }}>
                    <HeaderTypes inputColor={inputColor} />
                    {header.type == 'TEXT' && <Text inputColor={inputColor} />}
                </Box>
                {header.type == 'TEXT' && showSample && <TextSample inputColor={inputColor} />}
            </Box>
            <MediaTypes inputColor={inputColor} />
        </Box>
    );
};

export default Header;
