import { XTableContainer } from 'src/components';
import { Table, TableBody } from '@mui/material';
import Head from './Head';
import Item from './item';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getProducts, setPaging } from 'src/screens/bot/slice';

export default function Products() {
    const { tableData, state } = useAppSelector((x) => x.businessBot.products);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getProducts(''));
    }, [getProducts, dispatch]);

    const onPaging = (page: number, limit: number) => {
        dispatch(
            setPaging({
                page,
                limit
            })
        );
        dispatch(getProducts(''));
    };

    return (
        <XTableContainer
            totalItems={tableData.totalItems}
            page={tableData.page}
            limit={tableData.limit}
            onPaging={onPaging}
            loading={state === 'inProgress'}
            createAction={null}
        >
            <Table size="small">
                <Head />
                <TableBody>
                    {tableData.items.map((x) => (
                        <Item key={x.retailerId} product={x} />
                    ))}
                </TableBody>
            </Table>
        </XTableContainer>
    );
}
