import Loader from '../Loader';
import { lazy } from 'react';
import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
const Conversations = Loader(lazy(() => import('src/screens/conversations')));
const Content = Loader(lazy(() => import('src/screens/conversations/content')));
const ChatWindow = Loader(lazy(() => import('src/screens/conversations/content/chatWindow')));
const ChatWindowMini = Loader(lazy(() => import('src/screens/conversations/content/chatWindow/ChatWindowMini')));
import QuickReplies from 'src/screens/conversations/content/quickReplies';
import Submit from 'src/screens/conversations/content/quickReplies/submit';

const routes: RouteObject = {
    path: 'conversations',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <Conversations />
        },
        {
            path: '',
            element: <Conversations />,
            children: [
                {
                    path: 'quick-replies',
                    element: <QuickReplies />
                },
                {
                    path: 'quick-replies/new',
                    element: <Submit />
                },
                ,
                {
                    path: 'quick-replies/:id',
                    element: <Submit />
                },
                {
                    path: ':contactFilter',
                    element: <Content />,
                    children: [
                        {
                            path: ':contactId/:timestamp',
                            element: <ChatWindow />
                        }
                    ]
                },
                {
                    path: ':contactFilter/mini/:contactId/:timestamp',
                    element: <ChatWindowMini />
                }
            ]
        }
    ]
};

export default routes;
