import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getContacts, setPaging } from '../../slice';
import { XTableContainer } from 'src/components';
import { Box, Table, TableBody, Typography } from '@mui/material';
import Head from './Head';
import Item from './item';
import useAfterDelete from './useAfterDelete';
import { useParams } from 'react-router';
import Top from './Top';
import { CircularProgress } from '@mui/material';

function Contacts() {
    const { id } = useParams();
    const { tableData, state } = useAppSelector((x) => x.groups.contacts);
    const group = useAppSelector((x) => x.groups.list.tableData.items.find((x) => x.id == id));
    useAfterDelete(id);
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getContacts(id));
    }, [dispatch, id]);

    const onPaging = (page: number, limit: number) => {
        dispatch(
            setPaging({
                page,
                limit
            })
        );
        dispatch(getContacts(id));
    };

    return (
        <>
            {group?.processing ? (
                <Box
                    sx={{ mt: 10 }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection={'column'}
                >
                    <CircularProgress size={64} disableShrink thickness={3} />
                    <Typography sx={{ my: 2 }}>Processing uploaded file</Typography>
                </Box>
            ) : (
                <>
                    <Top />
                    <XTableContainer
                        totalItems={tableData.totalItems}
                        page={tableData.page}
                        limit={tableData.limit}
                        onPaging={onPaging}
                        loading={state === 'inProgress'}
                        createAction={null}
                    >
                        <Table size="small">
                            <Head />
                            <TableBody>
                                {tableData.items.map((x) => (
                                    <Item key={x.id} contact={x} />
                                ))}
                            </TableBody>
                        </Table>
                    </XTableContainer>
                </>
            )}
        </>
    );
}

export default Contacts;
