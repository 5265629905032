import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from './schema';
import { useTranslation } from 'react-i18next';

const Name = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<SubmitTemplateInputs>();

    return (
        <TextField
            fullWidth
            sx={{ mb: 2 }}
            error={errors?.name && true}
            {...register('name')}
            placeholder={t('templates.submit.templateName')}
            label={t('templates.submit.templateName')}
            size="medium"
            type="text"
            helperText={errors?.name?.message}
        />
    );
};

export default Name;
