import { OutGoingMessage } from 'src/types';
import InteractiveButtonMessagePreview from '../InteractiveButtonsPreview';
import ListPreview from '../ListPreview';
import MediaWithCaptionPreview from '../MediaWithCaptionPreview';
import { Box } from '@mui/material';
import XTemplatePreview from '../TemplatePreview';
import MediaWithoutCaptionPreview from '../MediaWithoutCaptionPreview';
import MultiProductPreview from '../MultiProductPreview';
import { TextPreview } from '../Fragments';
import EntireCatalogPreview from '../EntireCatalogPreview';

const regex = /^https:\/\/wa\.me\/c\/\d+$/;

const XOutGoingChatPreview = ({ message }: { message: OutGoingMessage }) => {
    const { interfaceType, template } = message;
    return (
        <Box sx={{ mt: 1 }}>
            {interfaceType == 'InteractiveList' && (
                <Box sx={{ mb: '10px' }}>
                    <ListPreview
                        options={message.interactiveList.options.map((x) => ({ id: x.id, title: x.title }))}
                        body={message.interactiveList.body}
                        title={message.interactiveList.title}
                    ></ListPreview>
                </Box>
            )}
            {template && <XTemplatePreview template={template} />}
            {interfaceType == 'Text' && (
                <>
                    {message.text.match(regex) ? (
                        <EntireCatalogPreview message={message.text} />
                    ) : (
                        <TextPreview variant="body2" color={'#000'}>
                            {message.text}
                        </TextPreview>
                    )}
                </>
            )}
            {/* {message.interfaceType == 'Location' && <LocationPreview message={message.location}></LocationPreview>} */}
            {interfaceType == 'MediaWithCaption' && (
                <MediaWithCaptionPreview message={message.mediaWithCaption}></MediaWithCaptionPreview>
            )}
            {interfaceType == 'MediaWithoutCaption' && (
                <MediaWithoutCaptionPreview message={message.mediaWithoutCaption}></MediaWithoutCaptionPreview>
            )}
            {interfaceType == 'InteractiveButtons' && (
                <InteractiveButtonMessagePreview message={message.interactiveButtons}></InteractiveButtonMessagePreview>
            )}
            {interfaceType == 'MultiProduct' && (
                <MultiProductPreview message={message.multiProduct}></MultiProductPreview>
            )}
        </Box>
    );
};

export default XOutGoingChatPreview;
