import { TableCell, TableHead, TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAlignment from '../Hooks/useAlignment';

export interface ColumnLabel {
    labelKey: string;
    align: Alignment;
}

export type Alignment = 'center' | 'left' | 'right';

const XTableHead = ({ columns }: { columns: ColumnLabel[] }) => {
    const { t } = useTranslation();
    const align = useAlignment();
    return (
        <TableHead>
            <TableRow>
                {columns.map((headCell, index) => (
                    <TableCell sx={{ fontWeight: 'bold' }} key={index} align={align(headCell.align)}>
                        {t(headCell.labelKey)}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default XTableHead;
