import { Box, styled, useTheme } from '@mui/material';

const LogoWrapper = styled(Box)(
    ({ theme }) => `
        color: ${theme.palette.text.primary};
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 1, mx: 1 }}>
            <img src="/static/images/logos/logo-light.png" style={{ width: '200px' }}></img>
        </Box>
    );
}

export default Logo;
