import { RouteObject } from 'react-router';
import SidebarLayout from 'src/layouts/SidebarLayout';
import Loader from '../Loader';
import { lazy } from 'react';
import WhatsAppInfo from 'src/screens/businesses/whatsapInfo';

const List = Loader(lazy(() => import('src/screens/businesses/list')));
const Submit = Loader(lazy(() => import('src/screens/businesses/submit')));

const businessRoutes: RouteObject = {
    path: 'businesses',
    element: <SidebarLayout />,
    children: [
        {
            path: '',
            element: <List />
        },
        {
            path: 'submit',
            element: <Submit />
        },
        {
            path: ':id',
            element: <Submit />
        },
        {
            path: ':id/keys',
            element: <WhatsAppInfo />
        }
    ]
};

export default businessRoutes;
