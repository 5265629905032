import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateSettings } from 'src/screens/bot/slice';
import { useAppDispatch } from 'src/store/hooks';
import { BotInterface } from 'src/types';
import { schema, CommonInputs } from './schema';

const useSubmitForm = (endConversationInterfaces: BotInterface[], api: string) => {
    const dispatch = useAppDispatch();
    const methods = useForm<CommonInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            arabic: endConversationInterfaces.find((x) => x.locale == 'ar')?.text,
            english: endConversationInterfaces.find((x) => x.locale == 'en')?.text,
            api: api
        }
    });

    const onSubmit = (inputs: CommonInputs) => {
        dispatch(updateSettings({ inputs }));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
