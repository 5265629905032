import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'src/store/hooks';

const useAfterSubmit = () => {
    const { submitState } = useAppSelector((x) => x.conversations.quickReplies);
    const navigateTo = useNavigate();
    useEffect(() => {
        if (submitState === 'successful') {
            navigateTo('/conversations/quick-replies');
        }
    }, [submitState]);
};

export default useAfterSubmit;
