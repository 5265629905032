import { TablePagination } from '@mui/material';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

const XTablePagination = ({
    total,
    page,
    limit,
    onPageNumberCahnged,
    onPageSizeCahnged
}: {
    total: number;
    page: number;
    limit: number;
    onPageNumberCahnged: (page: number) => void;
    onPageSizeCahnged: (page: number) => void;
}) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ p: 2 }}>
            <TablePagination
                component="div"
                count={total}
                onPageChange={(_, page) => onPageNumberCahnged(page)}
                onRowsPerPageChange={(event) => onPageSizeCahnged(parseInt(event.target.value))}
                page={page - 1}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, 30]}
                labelRowsPerPage={t('components.pagination.rows_per_page')}
                labelDisplayedRows={({ from, to, count }: { from: number; to: number; count: number }) => (
                    <Box dir="ltr" sx={{ display: 'inline-flex' }} component="span">
                        {`${from}–${to}`}
                        <Box component="span" sx={{ pl: 1, pr: 1 }}>
                            {`${t('components.pagination.of')} `}
                        </Box>
                        <span>{`${count !== -1 ? count : `more than ${to}`}`}</span>
                    </Box>
                )}
            />
        </Box>
    );
};

export default XTablePagination;
