import yupx from 'src/locales/yupLocale';

export interface UpdateProfileInputs {
    firstName: string;
    lastName: string;
    email: string;
    locale: string;
}

export const schema = yupx.object().shape({
    firstName: yupx.string().required().trim().label('account.yup.profile.first_name'),
    lastName: yupx.string().required().trim().label('account.yup.profile.last_name'),
    email: yupx.string().email().required().trim().label('account.yup.profile.email'),
    locale: yupx.string().required().trim().label('account.yup.profile.language')
});
