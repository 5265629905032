import { useFormContext } from 'react-hook-form';
import { XLanguageSwitch } from 'src/components';
import { UserLocale } from 'src/types';
import { SubmitSallaTemplateInputs } from './schema';
import { Box, FormHelperText, Typography } from '@mui/material';
import { t } from 'i18next';

const Locale = () => {
    const {
        setValue,
        watch,
        formState: { errors }
    } = useFormContext<SubmitSallaTemplateInputs>();

    const onChange = (value: UserLocale) => {
        setValue('locale', value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        });
    };

    const value = watch('locale');
    const id = watch('id');

    return (
        <Box sx={{ mt: 2, display: 'none' }}>
            <Typography sx={{ py: 2, fontWeight: 'bold' }} variant="subtitle2">
                {t('salla.event_setup.locale.heading')}
                <Typography> {t('salla.event_setup.locale.sub_heading')}</Typography>
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                <XLanguageSwitch onLocaleChange={onChange} value={value} disabled={!!id} />
                {errors.locale && <FormHelperText style={{ color: 'red' }}>{t('common.yup.locale')}</FormHelperText>}
            </Box>
        </Box>
    );
};

export default Locale;
