import { FormProvider } from 'react-hook-form';
import MessageField from './MessageField';
import useSubmitForm from './useSubmitForm';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Box, Typography } from '@mui/material';
import XFormButtons from 'src/components/Forms/XFormButton';
import { useTranslation } from 'react-i18next';
import { XSwitch } from 'src/components';
import React from 'react';
import { updateFeedbackStatus } from 'src/screens/bot/slice';

export default function Feedback() {
    const { t } = useTranslation();
    const { bot, loadState } = useAppSelector((x) => x.businessBot);
    const dispatch = useAppDispatch();

    const provideFeedbackInterface = bot.botInterfaces.filter(
        (x) => x.interfaceSubType == 'InteractiveButtons_ProvideFeedback'
    );
    const feedbackProvidedInterface = bot.botInterfaces.filter((x) => x.interfaceSubType == 'Text_FeedbackProvided');
    const { onSubmit, methods } = useSubmitForm({ provideFeedbackInterface, feedbackProvidedInterface });
    return (
        <>
            <Box sx={{ pb: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <Typography variant="h6" sx={{ pt: 1 }}>
                    {t('bot.settings.feedback.description')}
                </Typography>

                <XSwitch
                    label={t(
                        bot.enableCustomerFeedback ? 'bot.settings.feedback.feedbackEnabled' : 'bot.settings.feedback.feedbackDisabled'
                    )}
                    checked={bot.enableCustomerFeedback}
                    onChange={() => {
                        dispatch(updateFeedbackStatus(!bot.enableCustomerFeedback));
                    }}
                />
            </Box>

            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body2">{t('bot.settings.feedback.provideFeedback')}</Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <MessageField registerKey="provideFeedbackEnglish" messageLanguage="english" />
                            <MessageField registerKey="provideFeedbackArabic" messageLanguage="arabic" />
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body1" sx={{ pt: 1 }}>
                            {t('bot.settings.feedback.feedbackProvided')}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <MessageField registerKey="feedbackProvidedEnglish" messageLanguage="english" />
                            <MessageField registerKey="feedbackProvidedArabic" messageLanguage="arabic" />
                        </Box>
                    </Box>
                    <XFormButtons
                        authPolicy="Supervision"
                        isDirty={methods.formState.isDirty}
                        onResetClick={methods.reset}
                        returnTo="/bot"
                    />
                </form>
            </FormProvider>
        </>
    );
}
