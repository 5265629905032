import { Box, FormHelperText, IconButton, Tooltip, styled } from '@mui/material';
import AttachFileTwoToneIcon from '@mui/icons-material/AttachFileTwoTone';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const Input = styled('input')({
    display: 'none'
});

const MediaPicker = ({
    onMediaSelected,
    accept,
    label,
    maxSizeInBytes,
    uniqueId
}: {
    accept?: string;
    label?: string;
    maxSizeInBytes?: number;
    onMediaSelected: (link: string, type: string, name: string, file?: File) => void;
    uniqueId: string;
}) => {
    const { t } = useTranslation();
    const [error, setError] = useState('');
    function handleChange(event: any) {
        const file = event.target.files[0];
        if (isValidSize(file)) {
            setError('');
            onMediaSelected(URL.createObjectURL(file), file.type, file.name, file);
            event.target.value = null;
        } else {
            setError(t('components.media_picker.file_size_error').replace('{{}}', maxSizeInBytes.toString()));
        }
    }

    const isValidSize = (file: any) => {
        if (maxSizeInBytes) {
            const fileSizeInBytes = file.size;
            return fileSizeInBytes <= maxSizeInBytes;
        }
        return true;
    };

    return (
        <>
            <Input onChange={handleChange} accept={accept ?? '*'} id={uniqueId} type="file" />
            <label htmlFor={uniqueId}>
                <Box sx={{ display: 'flex', cursor: 'pointer' }}>
                    <AttachFileTwoToneIcon fontSize="small" color="primary" sx={{ mr: 1 }} />
                    <span>{label}</span>
                </Box>
            </label>
            {error && <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>}
        </>
    );
};

export default MediaPicker;
