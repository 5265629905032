import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { addTeam, getTeam, updateTeam } from '../../slice';
import { AddTeamInputs, schema } from './schema';
import { useEffect } from 'react';
import { nameOf } from 'src/utils/utils.shared';
import { Team } from 'src/types';
import _ from 'lodash';

const useSubmitForm = (id: string) => {
    const dispatch = useAppDispatch();
    const team = useAppSelector((x) => x.teams.list.tableData.items.find((x) => x.id == id));

    useEffect(() => {
        if (team && !team.schedule) {
            dispatch(getTeam(id));
        }
    }, [team]);

    const methods = useForm<AddTeamInputs>({
        resolver: yupResolver(schema),
        defaultValues: {}
    });

    useEffect(() => {
        const values = getDefaultValues(team);
        methods.setValue(nameOf('name'), team?.name);
        methods.setValue(nameOf('schedule'), values.schedule);
    }, [team]);

    const onSubmit = (inputs: AddTeamInputs) => {
        if (id) dispatch(updateTeam({ id, inputs }));
        else dispatch(addTeam(inputs));
    };

    return { edit: id && true, methods, onSubmit };
};

const getDefaultValues = (team: Team) => {
    const map = {
        Sunday: 1,
        Monday: 2,
        Tuesday: 3,
        Wednesday: 4,
        Thursday: 5,
        Friday: 6,
        Saturday: 7
    };

    let values = team
        ? _.cloneDeep(team)
        : {
              schedule: []
          };

    if (!values.schedule) {
        values.schedule = [];
    }

    values.schedule.forEach((element) => {
        element.availability = true;
    });

    if (!values?.schedule) values.schedule = [];

    Object.keys(map).forEach((x) => {
        if (!values?.schedule?.find((y) => y.dayOfWeek == x)) {
            values.schedule.push({
                dayOfWeek: x,
                shiftEnd: '',
                shiftStart: '',
                availability: false
            });
        }
    });
    values.schedule.sort((a, b) => {
        return map[a.dayOfWeek] - map[b.dayOfWeek];
    });
    return values;
};

export default useSubmitForm;
