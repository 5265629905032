import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { XComponentContainer, XLinkButton, XPageContainer } from 'src/components';
import { getReports } from '../slice';
import { useTranslation } from 'react-i18next';
import Sidebar from './sidebar';
import { Outlet, useParams } from 'react-router';

function CampaignReport() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { campaignReports } = useAppSelector((x) => x.campaigns.current.reports);
    const campaign = useAppSelector((x) => x.campaigns.list.tableData.items.find((x) => x.id == id));

    useEffect(() => {
        dispatch(getReports(id));
    }, [id, dispatch]);

    return (
        <XPageContainer
            sideBar={{
                content: <Sidebar campaignReports={campaignReports} />,
                title: t('campaigns.reports.title')
            }}
            topBar={{
                title: campaign?.name,
                action: <XLinkButton to="/campaigns" text={t('campaigns.reports.back_to_list')} />
            }}
        >
            <XComponentContainer>
                <Outlet />
            </XComponentContainer>
        </XPageContainer>
    );
}

export default CampaignReport;
