import { Feed } from '@mui/icons-material';
import { Box, TextField, Typography } from '@mui/material';
import { tr } from 'date-fns/locale';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export default function MessageField({
    registerKey,
    messageLanguage
}: {
    registerKey: string;
    messageLanguage: 'arabic' | 'english';
}) {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useFormContext();
    const { t } = useTranslation();

    return (
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
            <Typography
                variant="caption"
                sx={{
                    display: 'flex',
                    justifyContent: 'start',
                    color: 'rgb(230, 26, 128)'
                }}
            >
                {t(
                    messageLanguage == 'english'
                        ? 'bot.settings.feedback.bodyTitleEnglish'
                        : 'bot.settings.feedback.bodyTitleArabic'
                )}
            </Typography>
            <TextField
                fullWidth
                {...register(registerKey)}
                error={Boolean(errors[registerKey])}
                helperText={errors[registerKey]?.message}
                id="message"
                multiline
                rows={3}
                variant="outlined"
            />
        </Box>
    );
}
