import yupx from 'src/locales/yupLocale';

export interface Inputs {
    userName: string;
    password: string;
}

export const schema = yupx.object().shape({
    userName: yupx.string().trim().email().required().label('Email is required (البريد الالكتروني مطلوب)'),
    password: yupx.string().required().label('Password is required (كلمة المرور مطلوبة)')
});
