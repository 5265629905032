import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { setPassword } from '../slice';
import { SetPasswordInputs, schema } from './schema';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

const useSubmitForm = (reset: boolean) => {
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('t');
    const { userId } = useParams();

    const methods = useForm<SetPasswordInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            token,
            userId,
            reset
        }
    });

    const onSubmit = (data: SetPasswordInputs) => {
        dispatch(setPassword(data));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
