import { TableContainer, TablePagination } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SuspenseLoader from '../SuspenseLoader';
import XAddButton from '../Buttons/XAddButton';
import { useAppSelector } from 'src/store/hooks';
import XTablePagination from './XTablePagination';

type CreateAction = {
    label: string;
    to: string;
};

type TableContaineProps = {
    loading: boolean;
    createAction?: CreateAction;
    children: React.ReactNode | React.ReactNode[];
    page: number;
    limit: number;
    totalItems: number;
    onPaging: (page: number, limit: number) => void;
};

const XTableContainer = (props: TableContaineProps) => {
    const { t } = useTranslation();
    const direction = useAppSelector((x) => x.account.direction);

    const onPageNumberCahnged = (page: number) => {
        props.onPaging(page + 1, props.limit);
    };

    const onPageSizeCahnged = (limit: number) => {
        props.onPaging(props.page, limit);
    };

    return props.loading ? (
        <SuspenseLoader />
    ) : (
        <Box sx={{ p: 1 }} dir={direction}>
            {props.createAction && (
                <Box sx={{ mb: 1, display: 'flex', justifyContent: 'end' }}>
                    <XAddButton text={props.createAction.label} to={props.createAction.to}></XAddButton>
                </Box>
            )}
            <TableContainer>{props.children}</TableContainer>
            <XTablePagination
                total={props.totalItems}
                page={props.page}
                limit={props.limit}
                onPageNumberCahnged={onPageNumberCahnged}
                onPageSizeCahnged={onPageSizeCahnged}
            />
        </Box>
    );
};

export default XTableContainer;
