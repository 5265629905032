import Scrollbar from 'src/components/Scrollbar';
import { Box, styled, Divider, Drawer, IconButton, Typography, Grid, useTheme } from '@mui/material';
import { PageSidebarProps } from 'src/types';
import { useContext } from 'react';
import { DrawerContext } from 'src/contexts/DrawerContext';
import useDeviceInfo from 'src/components/Hooks/useDeviceInfo';

const Sidebar = styled(Box)(
    ({ theme }) => `
        width: 300px;
        height: 99vh;
`
);

const SidebarWrapper = styled(Box)(
    ({ theme }) => `
        padding: ${theme.spacing(2.5)};
  `
);

const DrawerWrapperMobile = styled(Drawer)(
    () => `
    width: 340px;
    flex-shrink: 0;

  & > .MuiPaper-root {
        width: 340px;
        z-index: 3;
  }
`
);

const SidebarContent = ({ sidebarProps }: { sidebarProps: PageSidebarProps }) => {
    const device = useDeviceInfo();
    const theme = useTheme();
    return (
        <Scrollbar>
            <SidebarWrapper>
                <Typography
                    variant="h4"
                    sx={{
                        mb: 1,
                        color: device.isSmallDevice ? 'rgb(230, 26, 128)' : theme.colors.primary.main
                    }}
                >
                    {sidebarProps.title}
                </Typography>
                <Divider />
                {sidebarProps.content}
            </SidebarWrapper>
        </Scrollbar>
    );
};

const XSidebarContainer = ({ direction, sidebarProps }: { direction: string; sidebarProps: PageSidebarProps }) => {
    const { currentDrawer, closeDrawer } = useContext(DrawerContext);
    const theme = useTheme();

    return (
        <>
            <DrawerWrapperMobile
                sx={{
                    display: { lg: 'none', xs: 'inline-block' }
                }}
                variant="temporary"
                anchor={direction === 'rtl' ? 'right' : 'left'}
                open={currentDrawer == 'Drawer2'}
                onClose={closeDrawer}
            >
                <SidebarContent sidebarProps={sidebarProps}></SidebarContent>
            </DrawerWrapperMobile>
            <Sidebar
                sx={{
                    display: { xs: 'none', lg: 'inline-block' },
                    borderRight: direction == 'ltr' ? `${theme.colors.alpha.black[10]} solid 1px` : 'none',
                    borderLeft: direction == 'rtl' ? `${theme.colors.alpha.black[10]} solid 1px` : 'none'
                }}
            >
                <SidebarContent sidebarProps={sidebarProps}></SidebarContent>
            </Sidebar>
        </>
    );
};

export default XSidebarContainer;
