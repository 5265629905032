import { TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Head = () => {
    const { t } = useTranslation();

    return (
        <TableRow>
            <TableCell width={'15%'}>
                <Typography sx={{ textTransform: 'none' }} variant="subtitle1"></Typography>
            </TableCell>
            <TableCell width={'35%'}>
                <Typography sx={{ textTransform: 'none' }} variant="subtitle1">
                    {t('campaigns.submit.placeholders.list.type')}
                </Typography>
            </TableCell>
            <TableCell width={'50%'}>
                <Typography sx={{ textTransform: 'none' }} variant="subtitle1">
                    {t('campaigns.submit.placeholders.list.fallback_value')}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default Head;
