import { configureStore } from '@reduxjs/toolkit';
import account from 'src/screens/account/slice';
import businesses from 'src/screens/businesses/slice';
import businessBot from 'src/screens/bot/slice';
import users from 'src/screens/users/slice';
import teams from 'src/screens/teams/slice';
import groups from 'src/screens/groups/slice';
import catalog from 'src/screens/catalog/slice';
import conversations from 'src/screens/conversations/slice';
import templates from 'src/screens/templates/slice';
import campaigns from 'src/screens/campaigns/slice';
import emailCampaigns from 'src/screens/emailCampaigns/slice';
import sms from 'src/screens/sms/slice';
import salla from 'src/screens/salla/slice';
import apps from 'src/screens/apps/slice';

export const store = configureStore({
    reducer: {
        account,
        businesses,
        businessBot,
        users,
        teams,
        groups,
        catalog,
        conversations,
        templates,
        campaigns,
        emailCampaigns,
        sms,
        apps,
        salla
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
