import { FormLabel, TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SubmitTemplateInputs } from '../../schema';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';

const WebsiteUrl = ({ index, inputColor }: { index: number; inputColor: string }) => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, defaultValues }
    } = useFormContext<SubmitTemplateInputs>();

    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <>
            <FormLabel>{t('templates.submit.buttons.call_to_action.website_url')}</FormLabel>
            <TextField
                fullWidth
                defaultValue={defaultValues?.body?.text}
                error={errors?.buttons?.callToactions[index]?.website && true}
                {...register(`buttons.callToactions.${index}.website`)}
                placeholder={t('templates.submit.buttons.call_to_action.website_url')}
                type="text"
                size="small"
                sx={{
                    '&.MuiFormControl-root': {
                        '.MuiOutlinedInput-root': {
                            background: inputColor
                        }
                    }
                }}
                helperText={errors?.buttons?.callToactions[index]?.website?.message}
            />
        </>
    );
};

export default WebsiteUrl;
