import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { addGroup, addContactsToGroup } from '../../slice';
import { GroupInputs, schema } from './schema';

const useSubmitForm = (id: string) => {
    const group = useAppSelector((x) => x.groups.list.tableData.items.find((x) => x.id == id));
    const dispatch = useAppDispatch();
    const methods = useForm<GroupInputs>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: group?.name
        }
    });
    const onSubmit = (inputs: GroupInputs) => {
        if (!id) dispatch(addGroup(inputs));
        else dispatch(addContactsToGroup({ id, inputs }));
    };

    return { edit: id && true, methods, onSubmit };
};

export default useSubmitForm;
