import { Box, Grid, Typography } from '@mui/material';
import { SampleTemplate } from 'src/types';
import { XTemplatePreview } from 'src/components';
import { useNavigate } from 'react-router';
import { SnackbarUtils } from 'src/components';
import i18next from 'i18next';

const Sample = ({ sampleTemplate }: { sampleTemplate: SampleTemplate }) => {
    const navigate = useNavigate();

    return (
        <Grid item xs={12} sm={6} md={3}>
            <Box
                onClick={() => {
                    navigate(`/templates/new/${sampleTemplate.id}`);
                    SnackbarUtils.info(i18next.t('templates.samples.choose_sample.selected'));
                }}
                sx={{
                    cursor: 'pointer',
                    background: 'url(/static/images/whatsapp-background.png)',
                    p: 1,
                    borderRadius: 0.3
                }}
            >
                <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold', color: '#673ab7' }}>
                    {sampleTemplate.template.name}
                </Typography>
                <XTemplatePreview template={sampleTemplate.template} />
            </Box>
        </Grid>
    );
};

export default Sample;
