import { useEffect } from 'react';
import { useAppSelector } from 'src/store/hooks';

const useAfterSubmit = () => {
    const { customiseState: customise } = useAppSelector((x) => x.campaigns.current);

    useEffect(() => {
        if (customise === 'successful') {
            window.location.href = window.location.href;
        }
    }, [customise]);
};

export default useAfterSubmit;
