import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useAppDispatch } from 'src/store/hooks';
import { Input, schema } from './schema';
import { installSalla } from '../../apps/slice';
import { AppService } from 'src/services/app.service';

const service = new AppService();

const useSubmitForm = () => {
    const dispatch = useAppDispatch();
    const methods = useForm<Input>({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (data: Input) => {
        const res = await dispatch(installSalla(data));
    };

    return { methods, onSubmit };
};

export default useSubmitForm;
