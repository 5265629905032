import CommonForm from './commonForm';

const MarketingOptOut = () => {
    return (
        <CommonForm
            interfaceSubType="Text_MarketingOptOut"
            api="MarketingOptOut"
            descriptionKey="bot.settings.marketing_opt_out.description"
            bodyEnglishKey="bot.settings.marketing_opt_out.bodyEnglish"
            bodyArabicKey="bot.settings.marketing_opt_out.bodyArabic"
        ></CommonForm>
    );
};

export default MarketingOptOut;
