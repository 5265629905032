import { ApiListResponse, Group, Contact, ApiResponse } from 'src/types';
import apiClient from './apiClient';
import { GroupInputs } from 'src/screens/groups/content/submitGroup/schema';

export class GroupsService {
    async getGroups(page: number, limit: number) {
        const { data } = await apiClient.get<ApiListResponse<Group>>('/groups', {
            params: {
                page,
                limit: 500
            }
        });
        return data;
    }

    async getGroupContacts(page: number, limit: number, groupId: string) {
        const { data } = await apiClient.get<ApiListResponse<Contact>>(`/groups/${groupId}/contacts`, {
            params: {
                page,
                limit
            }
        });
        return data;
    }

    async addGroup(inputs: GroupInputs) {
        var { data } = await apiClient.post<ApiResponse<{ groupId: string }>>(`/groups`, inputs);
        return data;
    }

    async updateGroup(id: string, name: string) {
        var { data } = await apiClient.put<ApiResponse<Group>>(`/groups/${id}`, {
            groupId: id,
            name
        });
        return data;
    }

    async addContactsToGroup(id: string, inputs: GroupInputs) {
        var { data } = await apiClient.put<ApiResponse<{ url: string }>>(`/groups/${id}/contacts`, inputs);
        return data;
    }

    async removeContactFromGroup(id: string, cid: string, deleteFromAllGroups: boolean) {
        var { data } = await apiClient.delete<ApiResponse<{ message: string; data: null; status: string }>>(
            `/groups/${id}/contacts/${cid}`,
            {
                params: {
                    deleteFromAllGroups
                }
            }
        );
        return data;
    }

    async removeGroup(id: string) {
        const { data } = await apiClient.delete<ApiResponse<{ message: string; data: null; status: string }>>(
            `/groups/${id}`
        );
        return data;
    }
}

export default new GroupsService();
