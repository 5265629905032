import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { UpdateProfileInputs } from './schema';

const FirstName = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<UpdateProfileInputs>();

    return (
        <TextField
            fullWidth
            error={errors?.firstName && true}
            {...register(nameOf<UpdateProfileInputs>('firstName'))}
            id={nameOf<UpdateProfileInputs>('firstName')}
            placeholder={t('user.submit.first_name')}
            label={t('user.submit.first_name')}
            size="medium"
            type="text"
            helperText={errors?.firstName?.message}
        />
    );
};

export default FirstName;
