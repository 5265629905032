import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { nameOf } from 'src/utils/utils.shared';
import { UpdatePasswordInputs } from './schema';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const newPassword = () => {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors }
    } = useFormContext<UpdatePasswordInputs>();

    const [showPassword, setShowPassword] = useState(false);

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">{t('account.password.current_password')}</InputLabel>
            <OutlinedInput
                dir="ltr"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                error={errors?.currentPassword && true}
                {...register(nameOf<UpdatePasswordInputs>('currentPassword'))}
                id={nameOf<UpdatePasswordInputs>('currentPassword')}
                placeholder={t('account.password.current_password')}
                label={t('account.password.current_password')}
                size="medium"
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={(event) => event.preventDefault()}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff color="secondary" /> : <Visibility color="secondary" />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <FormHelperText
                error={errors?.currentPassword?.message && true}
                id={nameOf<UpdatePasswordInputs>('currentPassword')}
            >
                {errors?.currentPassword?.message}
            </FormHelperText>
        </FormControl>
    );
};

export default newPassword;
