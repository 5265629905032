import { Box } from '@mui/material';
import EndConversationAction from './EndConversationAction';
import GoToMainMenuAction from './GoToMainMenuAction';
import InvokeListOptionAction from './InvokeListOptionAction';
import RemoveMedia from './RemoveMedia';
import TransferToTeamAction from './TransferToTeamAction';

const Actions = ({ title }: { title: string }) => {
    return (
        <Box>
            <RemoveMedia></RemoveMedia>
            <TransferToTeamAction></TransferToTeamAction>
            <GoToMainMenuAction></GoToMainMenuAction>
            <EndConversationAction></EndConversationAction>
            <InvokeListOptionAction title={title}></InvokeListOptionAction>
        </Box>
    );
};

export default Actions;
